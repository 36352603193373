import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import JoditEditor from 'jodit-react';
import React, { useRef, useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
// api
import ApiKey from '../../api/ApiKey';
import BaseApi from '../../api/BaseApi';
// components
import APNavBar from '../Elements/APNavBar';
import APSidebar from '../APSidebar/APSidebar';
// helpers
import { editorConfig } from '../../helpers';
// mui
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const APEditPageDetail = () => {
  const editor = useRef(null);
  const { slug } = useParams();
  const navigate = useNavigate();
  const tokenKey = Cookies.get('token');
  const adminID = Cookies.get('adminID');

  const [loading, setLoading] = useState(false);

  const [userData, setUserData] = useState({
    static_page_title: '',
    static_page_heading: '',
    static_page_title_ru: '',
    static_page_title_ukr: '',
    static_page_description: '',
    static_page_description_ru: '',
    static_page_description_ukr: '',
  });
  const [errors, setErrors] = useState({
    static_page_title: '',
    static_page_heading: '',
    static_page_title_ru: '',
    static_page_title_ukr: '',
    static_page_description: '',
    static_page_description_ru: '',
    static_page_description_ukr: '',
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setUserData(prev => ({
      ...prev,
      [name]: value,
    }));
    setErrors(prev => ({
      ...prev,
      [name]: '',
    }));
  };

  const getData = async () => {
    try {
      setLoading(true);

      const response = await axios.post(
        BaseApi + `/admin/page/admin_edit/${slug}`,
        null,
        {
          headers: {
            key: ApiKey,
            token: tokenKey,
            adminid: adminID,
            'Content-Type': 'application/json',
          },
        },
      );

      setLoading(false);
      setUserData(response.data.response);
    } catch (error) {
      setLoading(false);

      console.error('Cannot get data at edit Page List');
    }
  };

  const handleClick = async () => {
    try {
      const newErrors = {};

      if (userData.static_page_title_ukr === '') {
        newErrors.static_page_title_ukr = 'Page Title is required';
        window.scrollTo(0, 0);
      }
      if (userData.static_page_description_ukr === '') {
        newErrors.static_page_description_ukr = 'Page Description is required';
        window.scrollTo(0, 0);
      }

      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0) {
        const confirmationResult = await Swal.fire({
          icon: 'question',
          title: 'Update Page?',
          cancelButtonText: 'No',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          text: 'Do you want to update this Page?',
        });

        if (confirmationResult.isConfirmed) {
          setLoading(true);

          const response = await axios.post(
            BaseApi + `/admin/page/admin_edit/${slug}`,
            userData,
            {
              headers: {
                key: ApiKey,
                token: tokenKey,
                adminid: adminID,
                'Content-Type': 'application/json',
              },
            },
          );

          setLoading(false);

          if (response.data.status === 200) {
            Swal.fire({
              icon: 'success',
              confirmButtonText: 'Close',
              title: 'Page Details Updated successfully!',
            });

            navigate('/admin/pages/index');
          } else {
            Swal.fire({
              icon: 'error',
              confirmButtonText: 'Close',
              title: response.data.message,
            });
          }
        }
      }
    } catch (error) {
      setLoading(false);

      Swal.fire({
        icon: 'error',
        title: 'Failed',
        confirmButtonText: 'Close',
        text: 'Could not update username. Please try again later!',
      });

      console.error('Could not change username!', error);
    }
  };
  useEffect(() => {
    if (!tokenKey) {
      navigate('/admin');
    } else {
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]); // eslint-disable-line
  return (
    <>
      <APNavBar />
      <div className='APBasic'>
        <APSidebar />
        {
          loading
            ? (
              <div className='loader-container'></div>
            ) : (
              <div className='site-min-height'>
                <div className='breadCumb1' role='presentation'>
                  <Breadcrumbs
                    aria-label='breadcrumb'
                    separator={<NavigateNextIcon fontSize='small' />}
                  >
                    <Link
                      to='/admin/admins/dashboard'
                      underline='hover'
                      color='inherit'
                    >
                      Dashboard
                    </Link>
                    <Link
                      to='/admin/pages/index'
                      underline='hover'
                      color='inherit'
                    >
                      Content
                    </Link>
                    <Typography color='text.primary'>Edit Page Detail</Typography>
                  </Breadcrumbs>
                </div>
                <h2 className='adminPageHeading'>Edit Page Detail</h2>
                <form className='adminForm'>
                  <div className='mb-4 mt-5'>
                    <div className='mb-5 DashBoardInputBx'>
                      <label htmlFor='formFile' className='form-label'>
                          Page Title (Ukrainain)
                        <span className='RedStar'>*</span>
                      </label>
                      <input
                        type='text'
                        onChange={handleChange}
                        className='form-control'
                        id='static_page_title_ukr'
                        name='static_page_title_ukr'
                        placeholder='Page Title (Ukrainain)'
                        value={userData.static_page_title_ukr}
                      />
                      {
                        errors.static_page_title_ukr && (
                          <div className='text-danger'>{errors.static_page_title_ukr}</div>
                        )
                      }
                    </div>
                    <div className=' mb-5 DashBoardInputBx DashBoardCreatBx APJoditEditor'>
                      <label className='form-label' htmlFor='form3Example3'>
                          Description (Ukrainain)
                        <span className='RedStar'>*</span>
                      </label>
                      <JoditEditor
                        ref={editor}
                        config={editorConfig}
                        name='static_page_description_ukr'
                        value={userData.static_page_description_ukr}
                        onBlur={static_page_description_ukr =>
                          handleChange({
                            target: {
                              value: static_page_description_ukr,
                              name: 'static_page_description_ukr',
                            },
                          })
                        }
                      />
                      {
                        errors.static_page_description_ukr && (
                          <div className='text-danger'>{errors.static_page_description_ukr}</div>
                        )
                      }
                    </div>
                    <div className='mb-5 DashBoardInputBx'>
                      <label htmlFor='formFile' className='form-label'>
                        Page Title (Russian)
                      </label>
                      <input
                        type='text'
                        onChange={handleChange}
                        className='form-control'
                        id='static_page_title_ru'
                        name='static_page_title_ru'
                        placeholder='Page Title (Russian)'
                        value={userData.static_page_title_ru}
                      />
                    </div>
                    <div className='mb-5 DashBoardInputBx DashBoardCreatBx APJoditEditor'>
                      <label className='form-label' htmlFor='form3Example3'>
                        Description (Russian)
                      </label>
                      <JoditEditor
                        ref={editor}
                        config={editorConfig}
                        name='static_page_description_fra'
                        value={userData.static_page_description_ru}
                        onBlur={static_page_description_ru =>
                          handleChange({
                            target: {
                              value: static_page_description_ru,
                              name: 'static_page_description_ru',
                            },
                          })
                        }
                      />
                    </div>
                    <div className='mb-5 DashBoardInputBx'>
                      <label htmlFor='formFile' className='form-label'>
                        Page Title (English)
                      </label>
                      <input
                        type='text'
                        id='static_page_title'
                        onChange={handleChange}
                        name='static_page_title'
                        placeholder='Page Title'
                        value={userData.static_page_title}
                        className={`form-control ${errors.static_page_title && 'input-error'}`}
                      />
                    </div>
                    <div className=' mb-5 DashBoardInputBx DashBoardCreatBx APJoditEditor'>
                      <label className='form-label' htmlFor='form3Example3'>
                        Description (English)
                      </label>
                      <JoditEditor
                        ref={editor}
                        config={editorConfig}
                        name='static_page_description'
                        value={userData.static_page_description}
                        onBlur={static_page_description =>
                          handleChange({
                            target: {
                              value: static_page_description,
                              name: 'static_page_description',
                            },
                          })
                        }
                      />
                    </div>
                    <div className='adminBottomButtons'>
                      <button
                        type='button'
                        onClick={handleClick}
                        className='btn btn-primary button1'
                      >
                        SAVE
                      </button>
                      <button
                        type='button'
                        className='btn btn-primary button2'
                        onClick={() => navigate('/admin/pages/index')}
                      >
                        CANCEL
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )
        }
      </div>
    </>
  );
};

export default APEditPageDetail;
