import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
// api
import ApiKey from '../../api/ApiKey';
import BaseApi from '../../api/BaseApi';
// components
import APNavBar from '../Elements/APNavBar';
import APSidebar from '../APSidebar/APSidebar';
// forms
import CreateEditJobForm from '../../forms/CreateEditJobForm';
// helpers
import { logOutIfTokenExpired, resultInfo, scrollToElement } from '../../helpers';
// mui
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const APEditJob = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const tokenKey = Cookies.get('token');
  const { t } = useTranslation('global');
  const adminID = Cookies.get('adminID');

  const [jobData, setJobData] = useState(null);
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({
    logo: '',
    skill: '',
    user_id: '',
    category: '',
    location: '',
    job_title: '',
    last_date: '',
    work_type: '',
    experience: '',
    designation: '',
    subCategory: '',
    company_name: '',
    contact_name: '',
    annual_salary: '',
    companyProfile: '',
    contact_number: '',
    jobDescription: '',
    company_website: '',
  });

  const handleSubmit = async jobBody => {
    try {
      const newErrors = {};
      const salaryFormat = /^\d*\.?\d+$/;
      const contactNumberFormat = /^\+?3?8?(0\d{9})$/;
      const urlFormat = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;

      if (jobBody.job_title === '') {
        newErrors.job_title = t('employerCreateJob.jobTitleRequired');
        scrollToElement('job_title');
      }
      if (jobBody.category === '') {
        newErrors.category = t('employerCreateJob.categoryRequired');
        scrollToElement('category');
      }
      if (jobBody.subCategory.length === 0) {
        newErrors.subCategory = t('employerCreateJob.subCategoryRequired');
        scrollToElement('subCategory');
      }
      if (jobBody.jobDescription === '') {
        newErrors.jobDescription = t('employerCreateJob.jobDescriptionRequired');
        scrollToElement('job_description');
      }
      if (jobBody.company_name === '') {
        newErrors.company_name = t('employerCreateJob.companyNameRequired');
        scrollToElement('company_name');
      }
      if (jobBody.companyProfile === '') {
        newErrors.companyProfile = t('employerCreateJob.companyProfileRequired');
        scrollToElement('company_profile');
      }
      if (jobBody.work_type === '') {
        newErrors.work_type = t('employerCreateJob.workTypeRequired');
        scrollToElement('work_type');
      }
      if (jobBody.contact_number && !contactNumberFormat.test(jobBody.contact_number)) {
        newErrors.contact_number = t('employerCreateJob.contactNumberFormat');
        scrollToElement('contact_number');
      }
      if (jobBody.contact_number_2 && !contactNumberFormat.test(jobBody.contact_number_2)) {
        newErrors.contact_number_2 = t('employerCreateJob.contactNumberFormat');
        scrollToElement('contact_number_2');
      }
      if (jobBody.contact_number_3 && !contactNumberFormat.test(jobBody.contact_number_3)) {
        newErrors.contact_number_3 = t('employerCreateJob.contactNumberFormat');
        scrollToElement('contact_number_3');
      }
      if (jobBody.company_website) {
        if (jobBody.company_website && !urlFormat.test(jobBody.company_website)) {
          newErrors.company_website = t('employerCreateJob.urlInvalid');
          scrollToElement('company_website');
        }
      }
      if (jobBody.country === '') {
        newErrors.country = t('employerCreateJob.countryRequired');
        scrollToElement('country');
      }
      if (jobBody.city === '') {
        newErrors.city = t('employerCreateJob.cityRequired');
        scrollToElement('city');
      }
      if (jobBody.experience === '') {
        newErrors.experience = t('employerCreateJob.experienceRequired');
        scrollToElement('experience');
      }
      if (jobBody.salary_min && !salaryFormat.test(jobBody.salary_min)) {
        newErrors.salary_min = t('employerCreateJob.salaryNumeric');
        scrollToElement('salary_min');
      }
      if (jobBody.salary_max) {
        if (!salaryFormat.test(jobBody.salary_max)) {
          newErrors.salary_max = t('employerCreateJob.salaryNumeric');
          scrollToElement('salary_max');
        }
        if (parseFloat(jobBody.salary_max) < parseFloat(jobBody.salary_min)) {
          newErrors.salary_max = t('employerCreateJob.salaryMaxLowerSalaryMin');
          scrollToElement('salary_max');
        }
      }
      if (!jobBody.salary_currency && (jobBody.salary_min || jobBody.salary_max)) {
        newErrors.salary_currency = t('employerCreateJob.salaryCurrencyRequired');
        scrollToElement('salary_currency');
      }
      if (jobBody.last_date === '') {
        newErrors.last_date = t('employerCreateJob.lastDateRequired');
        scrollToElement('formLastDate');
      }
      if (jobBody.form_link) {
        if (jobBody.form_link && !urlFormat.test(jobBody.form_link)) {
          newErrors.form_link = t('employerCreateJob.urlInvalid');
          scrollToElement('form_link');
        }
      }

      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0) {
        const confirmationResult = await Swal.fire({
          icon: 'question',
          showCancelButton: true,
          cancelButtonText: t('employerCreateJob.no'),
          confirmButtonText: t('employerCreateJob.yes'),
          text: t('employerCreateJob.editJobConfirmTxt'),
          title: t('employerCreateJob.editJobConfirmTitle'),
        });

        if (confirmationResult.isConfirmed) {
          const response = await axios.post(
            BaseApi + `/admin/job/admin_edit/${slug}`,
            jobBody,
            {
              headers: {
                key: ApiKey,
                token: tokenKey,
                adminid: adminID,
                'Content-Type': 'application/json',
              },
            },
          );

          if (response.data.status === 200) {
            resultInfo('success', t('employerCreateJob.editJobSuccessTitle'), t('employerCreateJob.close'));

            navigate('/admin/jobs');
          } else if (response.data.status === 400) {
            navigate('/admin');

            Cookies.remove('fname');
            Cookies.remove('user_id');
            Cookies.remove('user_type');
            Cookies.remove('tokenClient');

            resultInfo('warning', response.data.message, t('employerCreateJob.close'));
          } else {
            Swal.fire({
              icon: 'error',
              text: response.data.message,
              confirmButtonText: t('employerCreateJob.close'),
              title: t('employerCreateJob.createJobFailedTitle'),
            });
          }
        }
      }
    } catch (error) {
      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/admin');

        logOutIfTokenExpired('Token Expired', 'Close');
      } else {
        resultInfo('error', 'Could not update this job. Please try after some time!', 'Close');
      }

      console.error('Could not submit job data', error);
    }
  };

  useEffect(() => {
    if (!tokenKey) {
      navigate('/admin');
    } else {
      const getData = async () => {
        try {
          setLoading(true);

          const response = await axios.post(
            BaseApi + `/admin/job/admin_edit/${slug}`,
            null,
            {
              headers: {
                key: ApiKey,
                token: tokenKey,
                adminid: adminID,
                'Content-Type': 'application/json',
              },
            },
          );

          setLoading(false);
          setJobData(response.data.response);
        } catch (error) {
          setLoading(false);

          console.error('No data received', error);
        }
      };

      getData();

      window.scrollTo(0, 0);
    }
  }, [slug, adminID, tokenKey, navigate]);

  return (
    <>
      <APNavBar />
      <div className='APBasic'>
        <APSidebar />
        {
          loading
            ? (
              <div className='loader-container'></div>
            ) : (
              <div className='site-min-height'>
                <div className='breadCumb1' role='presentation'>
                  <Breadcrumbs
                    aria-label='breadcrumb'
                    separator={<NavigateNextIcon fontSize='small' />}
                  >
                    <Link
                      color='inherit'
                      underline='hover'
                      to='/admin/admins/dashboard'
                    >
                      Dashboard
                    </Link>
                    <Link to='/admin/jobs' underline='hover' color='inherit'>
                      Jobs
                    </Link>
                    <Typography color='text.primary'>Edit Jobs</Typography>
                  </Breadcrumbs>
                </div>
                <h2 className='adminPageHeading'>Edit Job</h2>
                <div className='col-lg-8'>
                  {
                    jobData &&
                      <CreateEditJobForm
                        errors={errors}
                        formType='admin'
                        formData={jobData}
                        setErrors={setErrors}
                        handleSubmit={handleSubmit}
                      />
                  }
                </div>
              </div>
            )
        }
      </div>
    </>
  );
};

export default APEditJob;
