import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import HTMLReactParser from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
// api
import ApiKey from '../../api/ApiKey';
import BaseApi from '../../api/BaseApi';
// components
import APNavBar from '../Elements/APNavBar';
import APSidebar from '../APSidebar/APSidebar';
// helpers
import { resultInfo } from '../../helpers';
// mui
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Tooltip from '@mui/material/Tooltip';
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import VerifiedIcon from '@mui/icons-material/Verified';
import { IconButton, Pagination, Stack } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

const APJobsList = () => {
  const navigate = useNavigate();
  const tokenKey = Cookies.get('token');
  const adminID = Cookies.get('adminID');
  const { t } = useTranslation('global');

  const [open, setOpen] = useState(false);
  const [symbol, setSymbol] = useState('🔺');
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [selectAll, setSelectAll] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [checkedData, setCheckedData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [positionFrom, setPositionFrom] = useState(0);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'ascending' });

  const jobsPerPage = 20;

  const indexOfFirstJob = positionFrom + 1;
  const indexOfLastJob = (positionFrom + jobsPerPage) >= totalCount ? totalCount : (positionFrom + jobsPerPage);

  const totalPages = Math.ceil(totalCount / jobsPerPage);

  const handlePageChange = async page => {
    try {
      const positionNumber = page * jobsPerPage - jobsPerPage;

      setLoading(true);
      setCurrentPage(page);
      setPositionFrom(positionNumber);

      const response = await axios.post(
        BaseApi + '/admin/job/index',
        { searchQuery, positionFrom: positionNumber },
        {
          headers: {
            key: ApiKey,
            token: tokenKey,
            adminid: adminID,
            'Content-Type': 'application/json',
          },
        },
      );

      setLoading(false);
      setCheckedData([]);
      setSelectAll(true);
      setListData(response.data.response.jobs);
      setTotalCount(response.data.response.totalCount);
      setPositionFrom(response.data.response.positionFrom);
    } catch (error) {
      setLoading(false);

      console.error('Error fetching Jobs List', error);
    }
  };

  const handleOpen = plan => {
    setSelectedPayment(plan);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedPayment(null);
    setOpen(false);
  };

  const getWorkType = id => {
    if (id == 1) {
      return t('employerCreateJob.fullTime');
    }
    if (id == 2) {
      return t('employerCreateJob.partTime');
    }
    if (id == 3) {
      return t('employerCreateJob.remoteWork');
    }
  };

  const getData = async () => {
    try {
      setLoading(true);

      const response = await axios.post(
        BaseApi + '/admin/job/index',
        { searchQuery, positionFrom },
        {
          headers: {
            key: ApiKey,
            token: tokenKey,
            adminid: adminID,
            'Content-Type': 'application/json',
          },
        },
      );

      setLoading(false);
      setListData(response.data.response.jobs);
      setTotalCount(response.data.response.totalCount);
      setPositionFrom(response.data.response.positionFrom);
    } catch (error) {
      setLoading(false);

      console.error('Error fetching Jobs List', error);
    }
  };

  const handleSearchChange = e => {
    const newQuery = e.target.value.toLowerCase();

    setSearchQuery(newQuery);
  };

  const handleSearchClick = async () => {
    try {
      setLoading(true);

      const response = await axios.post(
        BaseApi + '/admin/job/index',
        { searchQuery, positionFrom: 0 },
        {
          headers: {
            key: ApiKey,
            token: tokenKey,
            adminid: adminID,
            'Content-Type': 'application/json',
          },
        },
      );

      setLoading(false);
      setCurrentPage(1);
      setCheckedData([]);
      setSelectAll(true);
      setListData(response.data.response.jobs);
      setTotalCount(response.data.response.totalCount);
      setPositionFrom(response.data.response.positionFrom);
    } catch (error) {
      setLoading(false);

      console.error('Error fetching Jobs List', error);
    }
  };

  const handleCheck = id => {
    if (id === 'selectAll') {
      setSelectAll(!selectAll);

      const updatedCheckedData = selectAll
        ? listData.map(item => item.id)
        : [];

      setCheckedData(updatedCheckedData);
    } else {
      if (checkedData.includes(id)) {
        setCheckedData(checkedData.filter(checkedId => checkedId !== id));
      } else {
        setCheckedData([...checkedData, id]);
      }
    }
  };

  const handleColumnClick = key => {
    let direction = 'ascending';

    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
      setSymbol('🔻');
    } else {
      setSymbol('🔺');
    }

    setSortConfig({ key, direction });
  };

  const handleActivate = async slug => {
    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        cancelButtonText: 'No',
        showCancelButton: true,
        title: 'Activate Job?',
        confirmButtonText: 'Yes',
        text: 'Do you want to Activate this Job?',
      });

      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/admin/job/admin_activate/${slug}`,
          null,
          {
            headers: {
              'Content-Type': 'application/json',
              key: ApiKey,
              token: tokenKey,
              adminid: adminID,
            },
          },
        );

        if (response.data.status === 200) {
          resultInfo('success', 'Job Activated successfully!', 'Close');
        } else {
          resultInfo('error', response.data.message, 'Close');
        }

        getData();
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        confirmButtonText: 'Close',
        text: 'Could not Activate Job',
        title: 'Failed. Please try after some time!',
      });

      console.error('Couldn\'t activate the record!', error.message);
    }
  };

  const handleDeactivate = async slug => {
    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        cancelButtonText: 'No',
        showCancelButton: true,
        title: 'Deactivate Job',
        confirmButtonText: 'Yes',
        text: 'Do you want to Deactivate this Job?',
      });

      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/admin/job/admin_deactivate/${slug}`,
          null,
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              adminid: adminID,
              'Content-Type': 'application/json',
            },
          },
        );

        if (response.data.status === 200) {
          resultInfo('success', 'Job Deactivated successfully!', 'Close');
        } else {
          resultInfo('error', response.data.message, 'Close');
        }

        getData();
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        confirmButtonText: 'Close',
        text: 'Could not Deactivate Job',
        title: 'Failed. Please try after some time!',
      });

      console.error('Couldn\'t deactivate the record!', error.message);
    }
  };

  const handleDelete = async slug => {
    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        title: 'Delete Job?',
        cancelButtonText: 'No',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        text: 'Do you want to Delete this Job?',
      });

      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/admin/job/admin_delete/${slug}`,
          null,
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              adminid: adminID,
              'Content-Type': 'application/json',
            },
          },
        );

        if (response.data.status === 200) {
          resultInfo('success', 'Job deleted successfully!', 'Close');
        } else {
          resultInfo('error', response.data.message, 'Close');
        }

        getData();
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        confirmButtonText: 'Close',
        text: 'Could not Delete job',
        title: 'Failed. Please try after some time!',
      });

      console.error('Couldn\'t delete the record!', error.message);
    }
  };

  const handleVerify = async slug => {
    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        title: 'Verify Job?',
        cancelButtonText: 'No',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        text: 'Do you want to Verify this Job?',
      });

      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/admin/job/verify/${slug}`,
          null,
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              adminid: adminID,
              'Content-Type': 'application/json',
            },
          },
        );

        if (response.data.status === 200) {
          resultInfo('success', 'Job verified successfully!', 'Close');
        } else {
          resultInfo('error', response.data.message, 'Close');
        }

        getData();
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        confirmButtonText: 'Close',
        text: 'Could not Verify Job',
        title: 'Failed. Please try after some time!',
      });

      console.error('Couldn\'t verify the record!', error.message);
    }
  };

  const handleMultipleDeactivate = async () => {
    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        cancelButtonText: 'No',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        title: 'Deactivate Jobs?',
        text: 'Do you want to Deactivate these Jobs?',
      });

      if (confirmationResult.isConfirmed) {
        const ids = checkedData.join(',');

        const response = await axios.post(
          BaseApi + '/admin/job/index',
          {
            idList: ids,
            action: 'deactivate',
          },
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              adminid: adminID,
              'Content-Type': 'application/json',
            },
          },
        );

        setSelectAll(true);
        setCheckedData([]);

        if (response.data.status === 200) {
          resultInfo('success', 'Jobs Deactivated successfully!', 'Close');
        } else {
          resultInfo('error', 'Couldn\'t Deactivate!', 'Close');
        }

        getData();
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        confirmButtonText: 'Close',
        text: 'Could not Deactivate Jobs',
        title: 'Failed. Please try after some time!',
      });

      console.error('Couldn\'t deactivate the record!', error.message);
    }
  };

  const handleMultipleActivate = async () => {
    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        cancelButtonText: 'No',
        showCancelButton: true,
        title: 'Activate Jobs?',
        confirmButtonText: 'Yes',
        text: 'Do you want to Activate these Jobs?',
      });

      if (confirmationResult.isConfirmed) {
        const ids = checkedData.toString();

        const response = await axios.post(
          BaseApi + '/admin/job/index',
          {
            idList: ids,
            action: 'activate',
          },
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              adminid: adminID,
              'Content-Type': 'application/json',
            },
          },
        );

        setSelectAll(true);
        setCheckedData([]);

        if (response.data.status === 200) {
          resultInfo('success', 'Jobs Activated successfully!', 'Close');
        } else {
          resultInfo('error', 'Couldn\'t Activate!', 'Close');
        }

        getData();
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        confirmButtonText: 'Close',
        text: 'Could not Activate Jobs',
        title: 'Failed. Please try after some time!',
      });

      console.error('Couldn\'t activate the record!', error.message);
    }
  };

  const handleMultipleDelete = async () => {
    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        title: 'Delete Jobs?',
        cancelButtonText: 'No',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        text: 'Do you want to Delete these Jobs?',
      });

      if (confirmationResult.isConfirmed) {
        const ids = checkedData.toString();

        const response = await axios.post(
          BaseApi + '/admin/job/index',
          {
            idList: ids,
            action: 'delete',
          },
          {
            headers: {
              'Content-Type': 'application/json',
              key: ApiKey,
              token: tokenKey,
              adminid: adminID,
            },
          },
        );

        setSelectAll(true);
        setCheckedData([]);

        if (response.data.status === 200) {
          resultInfo('success', 'Jobs Deleted successfully!', 'Close');
        } else {
          resultInfo('error', 'Couldn\'t Delete!', 'Close');
        }

        getData();
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        confirmButtonText: 'Close',
        text: 'Could not Delete Jobs',
        title: 'Failed. Please try after some time!',
      });

      console.error('Couldn\'t Delete the record!', error.message);
    }
  };

  useEffect(() => {
    if (!tokenKey) {
      navigate('/admin');
    } else {
      getData();

      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]); //eslint-disable-line

  return (
    <>
      <APNavBar />
      <div className='APBasic APManageSubadmin'>
        <APSidebar />
        {
          loading
            ? (
              <div className='loader-container'></div>
            ) : (
              <div className='site-min-height'>
                <div className='breadCumb1' role='presentation'>
                  <Breadcrumbs
                    aria-label='breadcrumb'
                    separator={<NavigateNextIcon fontSize='small' />}
                  >
                    <Link
                      underline='hover'
                      color='inherit'
                      to={'/admin/admins/dashboard'}
                    >
                      Dashboard
                    </Link>
                    <Link to='' underline='hover' color='inherit'>
                      Jobs
                    </Link>
                    <Typography color='text.primary'>Job List</Typography>
                  </Breadcrumbs>
                </div>
                <div className='ManageSubAdminHeader'>
                  <h2 className=''>Jobs List</h2>
                  <button
                    className='btn navButton1 APMSbutton'
                    onClick={() => navigate('/admin/jobs/addjob')}
                  >
                    Add Job
                  </button>
                </div>
                <div className='manageSubadminPart1'>
                  <form>
                    <div className='manageSubadminPart1Sec1'>
                      <h4>
                        Search Job by typing Vacancy ID, Company Name, Employer Name or Job Title
                      </h4>
                      <div className='APDashboardSearchBx '>
                        <input
                          type='text'
                          name='searchQuery'
                          value={searchQuery}
                          placeholder='Search..'
                          className='form-control'
                          onChange={handleSearchChange}
                        />
                      </div>
                      <button
                        onClick={handleSearchClick}
                        className='btn navButton1 APMSbutton'
                      >
                        Search
                      </button>
                    </div>
                  </form>
                  <h4>
                    {t('pagination.NoofRecords')}
                    {' '}
                    {indexOfFirstJob} - {indexOfLastJob}
                    {' '}{t('pagination.of')}{' '}
                    {totalCount}
                  </h4>
                </div>
                {
                  listData == ''
                    ? (
                      <div className='appliedJobListNoData mt-5'>
                        No Records Found
                      </div>
                    ) : (
                      <>
                        <div className='manageSubadminPart2'>
                          <table className='table'>
                            <thead>
                              <tr>
                                <td className='checkBoxCol checkBxHead'>
                                  <input
                                    type='checkbox'
                                    className='tableCheckBox'
                                    onClick={() => handleCheck('selectAll')}
                                  />
                                </td>
                                <th>ID</th>
                                <th onClick={() => handleColumnClick('company_name')}>
                                  <span style={{ cursor: 'pointer' }}>
                                    Company Name {symbol}
                                  </span>
                                </th>
                                <th onClick={() => handleColumnClick('emp_name')}>
                                  <span style={{ cursor: 'pointer' }}>
                                    Employer Name {symbol}
                                  </span>
                                </th>
                                <th onClick={() => handleColumnClick('title')}>
                                  <span style={{ cursor: 'pointer' }}>
                                    Job Title {symbol}
                                  </span>
                                </th>
                                <th>Location</th>
                                <th>Category</th>
                                <th onClick={() => handleColumnClick('created')}>
                                  <span style={{ cursor: 'pointer' }}>
                                    <CalendarMonthIcon /> Date {symbol}
                                  </span>
                                </th>
                                <th>Jobseekers</th>
                                <th scope='col'>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                listData.map(i => (
                                  <tr key={i.id}>
                                    <td className='checkBoxCol'>
                                      <input
                                        type='checkbox'
                                        className='tableCheckBox'
                                        onChange={() => handleCheck(i.id)}
                                        checked={checkedData.includes(i.id)}
                                      />
                                    </td>
                                    <td>{i.id}</td>
                                    <td>{i.company_name}</td>
                                    <td>{i.emp_name}</td>
                                    <td onClick={() => handleOpen(i)}>
                                      <span style={{ cursor: 'pointer' }}>
                                        {i.title}
                                      </span>
                                      <span className='verifiedUsername'>
                                        {i.verify === 1 ? <VerifiedIcon /> : ''}
                                      </span>
                                    </td>
                                    <td>{i.location}</td>
                                    <td>{i.cat_name}</td>
                                    <td>{i.created}</td>
                                    <td>{i.jobseeker_count}</td>
                                    <td className='APActionButton'>
                                      {
                                        i.status === 1
                                          ? (
                                            <button
                                              className='btn-secondary'
                                              onClick={() => handleDeactivate(i.slug)}
                                            >
                                              <Tooltip title='Deactivate'>
                                                <CheckIcon />
                                              </Tooltip>
                                            </button>
                                          ) : (
                                            <button
                                              className='btn-secondary'
                                              onClick={() => handleActivate(i.slug)}
                                            >
                                              <Tooltip title='Activate'>
                                                <BlockIcon />
                                              </Tooltip>
                                            </button>
                                          )
                                      }
                                      <button
                                        className='btn-primary'
                                        onClick={() => navigate(`/admin/jobs/editjob/${i.slug}`)}
                                      >
                                        <Tooltip title='Edit'>
                                          <CreateIcon />
                                        </Tooltip>
                                      </button>
                                      <button
                                        className='btn btn-secondary'
                                        onClick={() => handleDelete(i.slug)}
                                      >
                                        <Tooltip title='Delete'>
                                          <DeleteIcon />
                                        </Tooltip>
                                      </button>
                                      {
                                        !i.verify && (
                                          <button
                                            className='btn-primary'
                                            onClick={() => handleVerify(i.slug)}
                                          >
                                            <Tooltip title='Verify Now'>
                                              <VerifiedUserIcon />
                                            </Tooltip>
                                          </button>
                                        )
                                      }
                                      <button
                                        className='btn-primary'
                                        onClick={() => handleOpen(i)}
                                      >
                                        <Tooltip title='View'>
                                          <RemoveRedEyeIcon />
                                        </Tooltip>
                                      </button>
                                      <button
                                        className='btn-secondary mt-2'
                                        onClick={() => navigate(`/admin/jobs/candidates/${i.slug}`)}
                                      >
                                        <Tooltip title='Jobseeker List'>
                                          <FormatListBulletedIcon />
                                        </Tooltip>
                                      </button>
                                    </td>
                                  </tr>
                                ))
                              }
                              <div>
                                <Modal
                                  open={open}
                                  className='modalMain'
                                  onClose={handleClose}
                                  aria-labelledby='modal-modal-title'
                                  aria-describedby='modal-modal-description'
                                >
                                  <Box
                                    className='modal adminModal modal-content'
                                    sx={{
                                      width: '100%',
                                      maxHeight: '80vh',
                                      maxWidth: '1320px',
                                    }}
                                  >
                                    <IconButton
                                      onClick={handleClose}
                                      className='close-button'
                                      style={{
                                        top: 10,
                                        right: 10,
                                        position: 'absolute',
                                      }}
                                    >
                                      &times;
                                    </IconButton>
                                    <Typography
                                      variant='h6'
                                      component='h2'
                                      id='modal-modal-title'
                                    ></Typography>
                                    <Typography
                                      sx={{ mt: 3 }}
                                      id='modal-modal-description'
                                    >
                                      {
                                        selectedPayment && (
                                          <div className='modals'>
                                            <div className='modalHead'>
                                              <h1>{selectedPayment.title}</h1>
                                            </div>
                                            <div className='modalBody mt-4'>
                                              <div className='modalParent'>
                                                <div className='modalLeft'>
                                                  Search Count:{' '}
                                                </div>
                                                <div className='modalRight'>
                                                  {selectedPayment.jobseeker_count}
                                                </div>
                                              </div>
                                              <div className='modalParent'>
                                                <div className='modalLeft'>
                                                  Job View Count:{' '}
                                                </div>
                                                <div className='modalRight'>
                                                  {/* {selectedPayment.last_name} */}
                                                </div>
                                              </div>
                                              <div className='modalParent'>
                                                <div className='modalLeft'>
                                                  Employer Name:{' '}
                                                </div>
                                                <div className='modalRight'>
                                                  {selectedPayment.emp_name}
                                                </div>
                                              </div>
                                              <div className='modalParent'>
                                                <div className='modalLeft'>
                                                  Job Title:{' '}
                                                </div>
                                                <div className='modalRight'>
                                                  {selectedPayment.title}
                                                </div>
                                              </div>
                                              <div className='modalParent'>
                                                <div className='modalLeft'>
                                                  Category:{' '}
                                                </div>
                                                <div className='modalRight'>
                                                  {selectedPayment.cat_name}
                                                </div>
                                              </div>
                                              <div className='modalParent'>
                                                <div className='modalLeft'>
                                                  Company Name:{' '}
                                                </div>
                                                <div className='modalRight'>
                                                  {selectedPayment.company_name}
                                                </div>
                                              </div>
                                              <div className='modalParent'>
                                                <div className='modalLeft'>
                                                  Work Type:{' '}
                                                </div>
                                                <div className='modalRight'>
                                                  {getWorkType(selectedPayment.work_type)}
                                                </div>
                                              </div>
                                              <div className='modalParent'>
                                                <div className='modalLeft'>
                                                  Contact Name:{' '}
                                                </div>
                                                <div className='modalRight'>
                                                  {selectedPayment.contact_name}
                                                </div>
                                              </div>
                                              <div className='modalParent'>
                                                <div className='modalLeft'>
                                                  Contact Number:{' '}
                                                </div>
                                                <div className='modalRight'>
                                                  {selectedPayment.contact_number}
                                                </div>
                                              </div>
                                              <div className='modalParent'>
                                                <div className='modalLeft'>
                                                  Company Website:{' '}
                                                </div>
                                                <div className='modalRight'>
                                                  {selectedPayment.url}
                                                </div>
                                              </div>
                                              <div className='modalParent'>
                                                <div className='modalLeft'>
                                                  Job Description:{' '}
                                                </div>
                                                <div className='modalRight'>
                                                  {
                                                    selectedPayment.description
                                                      ? HTMLReactParser(selectedPayment.description)
                                                      : ''
                                                  }
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      }
                                    </Typography>
                                  </Box>
                                </Modal>
                              </div>
                            </tbody>
                          </table>
                        </div>
                        <div className='manageSubadminpart3'>
                          <div className='functionalityButton'>
                            <button
                              type='button'
                              className='btn btn-primary APButton3'
                              onClick={() => handleMultipleActivate()}
                            >
                              ACTIVATE
                            </button>
                            <button
                              type='button'
                              className='btn btn-primary APButton3'
                              onClick={() => handleMultipleDeactivate()}
                            >
                              DEACTIVATE
                            </button>
                            <button
                              type='button'
                              className='btn btn-primary APButton4'
                              onClick={() => handleMultipleDelete()}
                            >
                              DELETE
                            </button>
                          </div>
                          <div className='pagination'>
                            <Stack spacing={2}>
                              <Pagination
                                color='primary'
                                boundaryCount={2}
                                count={totalPages}
                                page={currentPage}
                                onChange={(event, page) => handlePageChange(page)}
                              />
                            </Stack>
                          </div>
                        </div>
                      </>
                    )
                }
              </div>
            )}
      </div>
    </>
  );
};

export default APJobsList;
