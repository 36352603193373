import axios from 'axios';
import Cookies from 'js-cookie';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
// api
import ApiKey from '../api/ApiKey';
import BaseApi from '../api/BaseApi';
// components
import NavBar from '../element/NavBar';
import Footer from '../element/Footer';
// helpers
import { logOutIfTokenExpired } from '../helpers';

const Jobseekers = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('global');
  const tokenKey = Cookies.get('tokenClient');
  const secondaryColor = Cookies.get('secondaryColor');

  const [loading, setLoading] = useState(false);
  const [skillList, setSkillList] = useState([]);
  const [salaryData, setSalaryData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [listingData, setListingData] = useState([]);
  const [searchButton, setSearchButton] = useState(false);
  const [experienceData, setExperienceData] = useState([]);
  const [filterItem, setFilterItem] = useState({
    skills: '',
    keyword: '',
    location: '',
    total_exp: '',
    exp_salary: '',
  });

  const jobsPerPage = 12;

  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentData = searchButton
    ? searchData.slice(indexOfFirstJob, indexOfLastJob)
    : listingData.slice(indexOfFirstJob, indexOfLastJob);

  const handlePageChange = pageNumber => setCurrentPage(pageNumber);

  const getData = async () => {
    try {
      setLoading(true);

      const response = await axios.post(BaseApi + '/candidates/listing', null, {
        headers: {
          key: ApiKey,
          token: tokenKey,
          'Content-Type': 'application/json',
        },
      });

      setLoading(false);
      setSkillList(response.data.response.skills);
      setSalaryData(response.data.response.salary);
      setListingData(response.data.response.candidates);
      setExperienceData(response.data.response.experience);
    } catch (error) {
      setLoading(false);

      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      }

      console.error('Could not get user data in profile page of favourite list', error);
    }
  };

  const handleChange = e => {
    e.preventDefault();

    const { name, value } = e.target;

    setFilterItem(prevFilter => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  const checkSkills = skills => {
    if (skills && Object.keys(skills).length > 0) {
      return Object.entries(skills).map(([key, value]) => (
        <div key={key}>{value}</div>
      ));
    } else {
      return <div>N/A</div>;
    }
  };

  const handleReset = () => {
    setFilterItem({
      skills: '',
      keyword: '',
      location: '',
      total_exp: '',
      exp_salary: '',
    });

    getData();
  };

  const handleClick = async e => {
    e.preventDefault();

    var interest_skills = document.getElementsByName('skill');

    var skillArray = [];

    interest_skills.forEach(element => {
      skillArray.push(element.value);
    });

    const updatedData = {
      ...filterItem,
      skill: skillArray,
    };

    setLoading(true);
    setSearchButton(true);

    try {
      const response = await axios.post(
        BaseApi + '/candidates/listing',
        updatedData,
        {
          headers: {
            key: ApiKey,
            token: tokenKey,
            'Content-Type': 'application/json',
          },
        },
      );

      setLoading(false);
      setSearchData(response.data.response.candidates);
    } catch (error) {
      setLoading(false);

      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      }

      console.error('Couldn\'t send the search filter data!', error);
    }
  };

  useEffect(() => {
    if (!tokenKey) {
      navigate('/user/employerlogin');
    } else {
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]); // eslint-disable-line

  return (
    <>
      <NavBar />
      {
        loading
          ? (
            <div className='loader-container'></div>
          ) : (
            <>
              <div className='privacyPolicy jobseekerListing'>
                <div className='aboutusSection1 text-center'>
                  <h1 className=''>{t('jobseekerListing.jobseekers')}</h1>
                  <h6 className='text-muted fw-normal'>
                    <Link to='/' style={{ color: 'grey' }}>
                      {t('navHeaders.home')}
                    </Link>
                    {' / '}
                    {t('jobseekerListing.jobseekers')}
                  </h6>
                </div>
                <div className='jobseekerLowerPart container'>
                  <div className='row'>
                    <div className='col-md-3'>
                      <div className='cardHead'>
                        <p>{t('jobseekerListing.jobseekerSearch')}</p>
                      </div>
                      <div className='cardBody'>
                        <form className='jobseekerListingForm'>
                          <div className='mb-2 mt-2'>
                            <div className='mb-3'>
                              <input
                                type='text'
                                name='keyword'
                                id='form3Example1'
                                onChange={handleChange}
                                className='form-control'
                                value={filterItem.keyword}
                                placeholder={t('jobseekerListing.keyword')}
                              />
                            </div>
                            <div className='mb-3 skillPackage'>
                              <Select
                                isMulti
                                isSearchable
                                name='skill'
                                classNamePrefix='select'
                                placeholder={t('jobseekerListing.selectSkills')}
                                className='basic-multi-select jobseekerListingSelect'
                                options={skillList.map(i => ({
                                  value: i.name,
                                  label: i.name,
                                }))}
                              />
                            </div>
                            <div className='mb-3'>
                              <select
                                name='total_exp'
                                className='form-select'
                                onChange={handleChange}
                                value={filterItem.total_exp}
                                aria-label='Default select example'
                              >
                                <option selected value=''>
                                  {t('jobseekerListing.chooseExp')}
                                </option>
                                {
                                  experienceData.map(i => (
                                    <option key={i.id} value={i.id}>
                                      {i.val}
                                    </option>
                                  ))
                                }
                              </select>
                            </div>
                            <div className='mb-3'>
                              <select
                                name='exp_salary'
                                className='form-select'
                                onChange={handleChange}
                                value={filterItem.exp_salary}
                                aria-label='Default select example'
                              >
                                <option selected value=''>
                                  {t('jobseekerListing.chooseSalary')}
                                </option>
                                {
                                  salaryData.map(i => (
                                    <option key={i.id} value={i.id}>
                                      {i.val}
                                    </option>
                                  ))
                                }
                              </select>
                            </div>
                            <button
                              disabled
                              type='button'
                              onClick={handleClick}
                              className='btn btn-primary button1'
                            >
                              {t('jobseekerListing.searchButton')}
                            </button>
                            <button
                              disabled
                              type='button'
                              onClick={() => handleReset()}
                              className='btn btn-primary button2'
                            >
                              {t('jobseekerListing.resetButton')}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                    {
                      loading
                        ? (
                          <div className='loader-container'></div>
                        ) : (
                          <>
                            <div className='col-md-9'>
                              <table className='table'>
                                <thead>
                                  <tr>
                                    <th>{t('jobseekerListing.name')}</th>
                                    <th>{t('jobseekerListing.skills')}</th>
                                    <th>{t('jobseekerListing.contactNumber')}</th>
                                    <th>{t('jobseekerListing.experience')}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    searchButton
                                      ? (
                                        currentData.length > 0
                                          ? (
                                            currentData.map(i => (
                                              <>
                                                <tr>
                                                  <td>
                                                    <Link
                                                      style={{ color: secondaryColor }}
                                                      to={`/candidates/profile/${i.slug}`}
                                                    >
                                                      {i.first_name} {i.last_name}
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    {checkSkills(i.skills)}
                                                  </td>
                                                  <td>
                                                    {i.contact != null ? i.contact : t('jobseekerListing.notAvailable')}
                                                  </td>
                                                  <td>
                                                    {
                                                      i.total_exp != ''
                                                        ? i.total_exp
                                                        : t('jobseekerListing.notAvailable')
                                                    }
                                                  </td>
                                                </tr>{' '}
                                              </>
                                            ))
                                          ) : (
                                            <tr className='col-12'>
                                              <td colSpan={4}>
                                                <div className='jobseekersListingNoData'>
                                                  <h3 className='text-center'>
                                                    {/* {t('jobseekerListing.belowTxt1')} */}
                                                    {t('jobseekerListing.belowTxt3')}
                                                  </h3>
                                                  {/* <h6 className='text-muted text-center mb-5 mt-3'>
                                                    {t('jobseekerListing.belowTxt2')}
                                                  </h6> */}
                                                </div>
                                              </td>
                                            </tr>
                                          )
                                      ) : listingData.length > 0
                                        ? (
                                          currentData.map(i => (
                                            <>
                                              <tr>
                                                <td>
                                                  <Link
                                                    style={{ color: secondaryColor }}
                                                    to={`/candidates/profile/${i.slug}`}
                                                  >
                                                    {i.first_name} {i.last_name}
                                                  </Link>
                                                </td>
                                                <td>
                                                  {checkSkills(i.skills)}
                                                </td>
                                                <td>
                                                  {i.contact != null ? i.contact : t('jobseekerListing.notAvailable')}
                                                </td>
                                                <td>
                                                  {i.total_exp != '' ? i.total_exp : t('jobseekerListing.notAvailable')}
                                                </td>
                                              </tr>{' '}
                                            </>
                                          ))
                                        ) : (
                                          <tr className='col-12'>
                                            <td colSpan={4}>
                                              <div className='jobseekersListingNoData'>
                                                <h3 className='text-center'>
                                                  {/* {t('jobseekerListing.belowTxt1')} */}
                                                  {t('jobseekerListing.belowTxt3')}
                                                </h3>
                                                {/* <h6 className='text-muted text-center mb-5 mt-3'>
                                                  {t('jobseekerListing.belowTxt2')}
                                                </h6> */}
                                              </div>
                                            </td>
                                          </tr>
                                        )
                                  }
                                </tbody>
                              </table>
                            </div>
                            <div className='blogPagination'>
                              <p className='text-muted paginationDetail'>
                                {t('pagination.NoofRecords')}{' '}
                                {
                                  listingData.length > 0
                                    ? indexOfFirstJob + 1
                                    : indexOfFirstJob
                                }
                                {'-'}
                                {Math.min(
                                  indexOfLastJob,
                                  searchButton ? searchData.length : listingData.length,
                                )}{' of '}
                                {searchButton ? searchData.length : listingData.length}
                              </p>
                              <div className='blogPaginationButtons'>
                                <button
                                  className='navButton1'
                                  disabled={currentPage === 1}
                                  onClick={() => handlePageChange(currentPage - 1)}
                                >
                                  {t('pagination.Prev')}
                                </button>
                                <button
                                  className='navButton1'
                                  onClick={() => handlePageChange(currentPage + 1)}
                                  disabled={
                                    searchButton
                                      ? indexOfLastJob >= searchData.length
                                      : indexOfLastJob >= listingData.length
                                  }
                                >
                                  {t('pagination.Next')}
                                </button>
                              </div>
                            </div>
                          </>
                        )
                    }
                  </div>
                </div>
              </div>
              <Footer />
            </>
          )
      }
    </>
  );
};

export default Jobseekers;
