import axios from 'axios';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
// api
import BaseApi from '../api/BaseApi';
// components
import BlogCard from './BlogCard';
import Footer from '../element/Footer';
import NavBar from '../element/NavBar';

const BlogPage = () => {
  const { t } = useTranslation('global');

  const [cardData, setcardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const postsPerPage = 9;
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = cardData
    ? cardData.slice(indexOfFirstPost, indexOfLastPost)
    : cardData.slice(indexOfFirstPost, indexOfLastPost);

  const handlePageChange = pageNumber => setCurrentPage(pageNumber);

  useEffect(() => {
    const getCardData = async () => {
      try {
        setLoading(true);

        const response = await axios.get(
          BaseApi + '/blog',
        );

        setLoading(false);
        setcardData(response.data.response.Blog);
      } catch (error) {
        setLoading(false);

        console.error('Error getting blog data', error);
      }
    };

    const metaTitle = document.querySelector('title');
    const metaDescription = document.querySelector('meta[name="description"]');

    if (metaTitle) {
      metaTitle.textContent = t('blogPage.metaTitleValue');
    }

    if (metaDescription) {
      metaDescription.content = t('blogPage.metaDescriptionValue');
    }

    getCardData();
  }, [t]);

  return (
    <>
      <NavBar />
      {
        loading
          ? (
            <div className='loader-container'></div>
          ) : (
            <>
              <div className='blogPageSection1 text-center'>
                <h1>{t('blogPage.blog')}</h1>
                <h6 className='text-muted fw-normal'>
                  <Link to='/' style={{ color: 'grey' }}>
                    {t('navHeaders.home')}
                  </Link>
                  {' / '}
                  {t('blogPage.blog')}
                </h6>
              </div>
              <div className='blogPageSection2 container'>
                <div className='row'>
                  {
                    currentPosts.map((i, index) => (
                      <div className='col-lg-4 col-md-6 mb-5' key={index}>
                        <BlogCard
                          id={i.id}
                          tag={i.tag}
                          slug={i.slug}
                          image={i.image}
                          title={i.title}
                          status={i.status}
                          created={i.created}
                          meta_title={i.meta_title}
                          description={i.description}
                          meta_keyword={i.meta_keyword}
                          meta_description={i.meta_description}
                        />
                      </div>
                    ))
                  }
                </div>
              </div>
              {/* Custom Pagination */}
              <div className='blogPagination'>
                <p className='text-muted paginationDetail'>
                  {t('pagination.NoofRecords')} {indexOfFirstPost + 1}-
                  {Math.min(indexOfLastPost, cardData.length)} of{' '}
                  {cardData ? cardData.length : cardData.length}
                </p>
                <div className='blogPaginationButtons'>
                  <button
                    className='navButton1'
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    {t('pagination.Prev')}
                  </button>
                  <button
                    className='navButton1'
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={
                      cardData
                        ? indexOfLastPost >= cardData.length
                        : indexOfLastPost >= cardData.length
                    }
                  >
                    {t('pagination.Next')}
                  </button>
                </div>
              </div>
              <Footer />
            </>
          )
      }
    </>
  );
};

export default BlogPage;
