import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
// api
import ApiKey from '../../api/ApiKey';
import BaseApi from '../../api/BaseApi';
// components
import APNavBar from '../Elements/APNavBar';
import APSidebar from '../APSidebar/APSidebar';
// mui
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const APEditBanner = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const tokenKey = Cookies.get('token');
  const adminID = Cookies.get('adminID');

  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [userData, setUserData] = useState({
    title: '',
    type: '',
    advertisement_place: '',
    url: '',
    image: '',
    text: '',
  });
  const [errors, setErrors] = useState({
    title: '',
    type: '',
    advertisement_place: '',
    url: '',
    image: '',
    text: '',
  });

  const handleChange = e => {
    const { name, value } = e.target;
    if (name === 'type') {
      const newValue = parseInt(e.target.value, 10);

      setUserData({ ...userData, type: newValue });
    }

    setUserData(prev => ({
      ...prev,
      [name]: value,
    }));
    setErrors(prev => ({
      ...prev,
      [name]: '',
    }));
  };

  const getData = async () => {
    try {
      setLoading(true);

      const response = await axios.post(
        BaseApi + `/admin/banner/admin_edit/${slug}`,
        null,
        {
          headers: {
            key: ApiKey,
            token: tokenKey,
            adminid: adminID,
            'Content-Type': 'application/json',
          },
        },
      );

      setLoading(false);
      setUserData(response.data.response);
      setSelectedImage(response.data.response.image);
    } catch (error) {
      console.error('Error at chnage username at Admin panel', error);
    }
  };

  const handleClick = async () => {
    try {
      const newErrors = {};

      if (userData.title === '') {
        newErrors.title = 'Banner Title is required';
        window.scrollTo(0, 0);
      }
      if (userData.type === '') {
        newErrors.type = 'Advertisement Type is required';
        window.scrollTo(0, 0);
      }
      if (userData.advertisement_place === '') {
        newErrors.advertisement_place = 'Advertisement Place is required';
        window.scrollTo(0, 0);
      }
      if (userData.url === '') {
        newErrors.url = 'URL is required';
        window.scrollTo(0, 0);
      } else {
        const urlFormat = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;

        if (!urlFormat.test(userData.url)) {
          newErrors.url = 'Invalid URL format';
          window.scrollTo(0, 0);
        }
      }
      if (userData.image === '') {
        newErrors.image = 'Advertisement Image is required';
        window.scrollTo(0, 0);
      }

      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0) {
        const confirmationResult = await Swal.fire({
          icon: 'question',
          title: 'Edit Banner?',
          cancelButtonText: 'No',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          text: 'Do you want to Edit this Banner?',
        });

        if (confirmationResult.isConfirmed) {
          setLoading(true);

          const response = await axios.post(
            BaseApi + `/admin/banner/admin_edit/${slug}`,
            userData,
            {
              headers: {
                key: ApiKey,
                token: tokenKey,
                adminid: adminID,
                'Content-Type': 'application/json',
              },
            },
          );

          setLoading(false);

          if (response.data.status === 200) {
            Swal.fire({
              icon: 'success',
              confirmButtonText: 'Close',
              title: 'Banner updated successfully!',
            });

            navigate('/admin/banneradvertisements');
          } else {
            Swal.fire({
              icon: 'error',
              confirmButtonText: 'Close',
              title: response.data.message,
            });
          }
        }
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        confirmButtonText: 'Close',
        text: 'Could not update banner. Please try again later!',
      });
      console.error('Could not change username!', error);
    }
  };

  const handleFileUpload1 = async e => {
    const fileInput = e.target;
    const file = fileInput.files[0];

    if (file) {
      const fileSizeInBytes = file.size;
      const maxSizeInBytes = 2 * 1024 * 1024; // 2MB

      if (fileSizeInBytes > maxSizeInBytes) {
        Swal.fire({
          icon: 'warning',
          confirmButtonText: 'Close',
          title: 'Image size should be under 2MB',
        });

        fileInput.value = ''; // This clears the input

        return;
      }

      const img = new Image();
      img.src = window.URL.createObjectURL(file);

      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;

        if (width !== 720 || height !== 320) {
          Swal.fire({
            icon: 'warning',
            confirmButtonText: 'Close',
            title: 'Image resolution should be 720x320 pixels',
          });

          fileInput.value = ''; // This clears the input
        } else {
          setErrors({
            ...errors,
            image: '',
          });

          convertToBase64(file).then(base64 => {
            setUserData({ ...userData, image: base64 });
            setSelectedImage(base64);
          });
        }
      };
    }
  };

  const deleteImage = () => {
    setSelectedImage(null);
    setUserData({ ...userData, image: '' });

    const fileInput = document.getElementById('formFile'); // Replace with the actual ID of your file input
    if (fileInput) {
      fileInput.value = '';
    }
  };

  const deleteImageWithSlug = async slug => {
    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        cancelButtonText: 'No',
        showCancelButton: true,
        title: 'Delete Image?',
        confirmButtonText: 'Yes',
        text: 'Do you want to delete this Image?',
      });

      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/admin/blog/admin_deleteBlogImage/${slug}`,
          null,
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              adminid: adminID,
              'Content-Type': 'application/json',
            },
          },
        );

        if (response.data.status === 200) {
          Swal.fire({
            icon: 'success',
            confirmButtonText: 'Close',
            title: 'Image Deleted successfully!',
          });
        } else {
          Swal.fire({
            icon: 'error',
            confirmButtonText: 'Close',
            title: response.data.message,
          });
        }
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        confirmButtonText: 'Close',
        text: 'Could not delete Image. Please try again later!',
      });

      console.error('Could not delete image!', error);
    }
  };

  useEffect(() => {
    if (!tokenKey) {
      navigate('/admin');
    } else {
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]); // eslint-disable-line

  return (
    <>
      <APNavBar />
      <div className='APBasic'>
        <APSidebar />
        {
          loading
            ? (
              <>
                <div className='loader-container'></div>
              </>
            ) : (
              <>
                <div className='site-min-height'>
                  <div className='breadCumb1' role='presentation'>
                    <Breadcrumbs
                      aria-label='breadcrumb'
                      separator={<NavigateNextIcon fontSize='small' />}
                    >
                      <Link
                        to='/admin/admins/dashboard'
                        underline='hover'
                        color='inherit'
                      >
                        Dashboard
                      </Link>
                      <Link to='/admin/banneradvertisements' underline='hover' color='inherit'>
                        Banner Advertisements
                      </Link>
                      <Typography color='text.primary'>
                        Edit Banner Advertisement
                      </Typography>
                    </Breadcrumbs>
                  </div>
                  <h2 className='adminPageHeading'>Edit Banner Advertisement</h2>
                  <form className='adminForm'>
                    <div className='mb-4 mt-5'>
                      <div className='mb-5 DashBoardInputBx'>
                        <label htmlFor='formFile' className='form-label'>
                          Place Of Advertisement
                          <span className='RedStar'>*</span>
                        </label>
                        <select
                          onChange={handleChange}
                          name='advertisement_place'
                          aria-label='Default select example'
                          value={userData.advertisement_place}
                          className={`form-select ${errors.advertisement_place && 'input-error'}`}
                        >
                          <option selected>Select Place of Advertisement</option>
                          <option value='home_ad1'>
                            Home Page Bopx Advertisement Box1 (Width:377px,
                            Height:387px)
                          </option>
                          <option value='home_ad2'>
                            Home Page Bopx Advertisement Box2 (Width:377px,
                            Height:387px)
                          </option>
                        </select>
                        {
                          errors.advertisement_place && (
                            <div className='text-danger'>{errors.advertisement_place}</div>
                          )
                        }
                      </div>
                      <div className='mb-5 DashBoardInputBx'>
                        <label htmlFor='formFile' className='form-label'>
                          Title
                          <span className='RedStar'>*</span>
                        </label>
                        <input
                          type='text'
                          name='title'
                          id='form3Example1'
                          value={userData.title}
                          onChange={handleChange}
                          placeholder='Banner Title'
                          className={`form-control ${errors.title && 'input-error'}`}
                        />
                        {
                          errors.title && (
                            <div className='text-danger'>{errors.title}</div>
                          )
                        }
                      </div>
                      <div className='APRadioInput mb-5 DashBoardInputBx'>
                        <label className='form-label' htmlFor='form3Example3'>
                          Advertisement Type
                          <span className='RedStar'>*</span>
                        </label>
                        <div className='APPaymentDetailsRadio'>
                          <input
                            value='0'
                            name='type'
                            type='radio'
                            id='pictureAdverts'
                            onChange={handleChange}
                            checked={userData.type.toString() === '0'}
                          />
                          <label className='LabelpictureAdverts' htmlFor='0'>
                            Picture Adverts
                          </label>
                          <input
                            value='1'
                            name='type'
                            type='radio'
                            id='googleAdverts'
                            onChange={handleChange}
                            checked={userData.type.toString() === '1'}
                          />
                          <label htmlFor='2'>Google Adverts</label>
                        </div>
                        {
                          errors.type && (
                            <div className='text-danger'>{errors.type}</div>
                          )
                        }
                      </div>
                      <div className='mb-5 DashBoardInputBx'>
                        <label htmlFor='formFile' className='form-label'>
                          URL
                          <span className='RedStar'>*</span>
                        </label>
                        <input
                          name='url'
                          type='text'
                          placeholder='URL'
                          id='form3Example1'
                          value={userData.url}
                          onChange={handleChange}
                          className={`form-control ${errors.url && 'input-error'}`}
                        />
                        {
                          errors.url && (
                            <div className='text-danger'>{errors.url}</div>
                          )
                        }
                        <div id='emailHelp' className='form-text'>
                          (Enter URL Like http://www.google.com)
                        </div>
                      </div>
                      <div className='mb-5 DashBoardInputBx'>
                        <label htmlFor='formFile' className='form-label'>
                          Image
                          <span className='RedStar'>*</span>
                        </label>
                        <input
                          name='logo'
                          type='file'
                          id='formFile'
                          accept='.gif, .jpeg, .png, .jpg'
                          onChange={e => handleFileUpload1(e)}
                          className={`form-control ${errors.image && 'input-error'}`}
                        />
                        {
                          errors.image && (
                            <div className='text-danger'>{errors.image}</div>
                          )
                        }
                        <div id='emailHelp' className='form-text'>
                          Supported File Types: gif, jpg, jpeg, png (Max. 2MB).
                          Standard size of Advertisement images <br />
                          1) Job Selection Page (Width:720px, Height:320px)
                        </div>
                        {
                          selectedImage && (
                            <div>
                              {
                                selectedImage
                                  ? (
                                    <img
                                      className='selectedInputImage selectedBannerImage'
                                      src={selectedImage}
                                      alt='Selected'
                                    />
                                  ) : (
                                    <img
                                      className='selectedInputImage'
                                      src='/Images/adminpanel/demoo.jpg'
                                      alt='Selected'
                                    />
                                  )
                              }
                              {
                                userData.image
                                  ? (
                                    <button
                                      className='APButton3'
                                      type='button'
                                      onClick={() => deleteImageWithSlug(slug)}
                                    >
                                      Delete
                                    </button>
                                  ) : (
                                    <button className='APButton3' onClick={deleteImage}>
                                      Delete
                                    </button>
                                  )
                              }
                            </div>
                          )
                        }
                      </div>
                      <div className='adminBottomButtons'>
                        <button
                          type='button'
                          onClick={handleClick}
                          className='btn btn-primary button1'
                        >
                          SAVE
                        </button>
                        <button
                          type='button'
                          className='btn btn-primary button2'
                          onClick={() => navigate('/admin/banneradvertisements')}
                        >
                          CANCEL
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </>
            )
        }
      </div>
    </>
  );
};

export default APEditBanner;

function convertToBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = error => {
      reject(error);
    };
  });
}
