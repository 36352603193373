import axiosInstance from '../../../axiosConfig';

export const getSubCategories = async id => {
  try {
    const data = await axiosInstance.post(`/categories/getSubCategory/${id}`);

    return data;
  } catch (error) {
    console.error('Couldn\'t get Sub category data!', error);
  }
};
