import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// api
import ApiKey from '../api/ApiKey';
import BaseApi from '../api/BaseApi';
// components
import Sidebar from './Sidebar';
import Footer from '../element/Footer';
import NavBar from '../element/NavBar';
// helpers
import { logOutIfTokenExpired, resultInfo } from '../helpers';
// mui
import Tooltip from '@mui/material/Tooltip';
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';

const ManageJob = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('global');
  const tokenKey = Cookies.get('tokenClient');

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [manageJobData, setManageJobData] = useState([]);

  const jobsPerPage = 10;

  const sortAndFilterData = (array, query) => {
    const sortedAndFilteredArray = [...array]
      .filter(
        item =>
          item.Job.title?.toLowerCase().includes(query) ||
          item.Job.id?.toString().toLowerCase().includes(query),
      );

    return sortedAndFilteredArray;
  };

  const sortedAndFilteredData = sortAndFilterData(
    manageJobData,
    searchQuery,
  );

  const filteredData = manageJobData.filter(item => {
    const searchString = searchQuery.toLowerCase();

    return (
      item.Job.title?.toLowerCase().includes(searchString) ||
      item.Job.id?.toString().toLowerCase().includes(searchString)
    );
  });

  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = sortedAndFilteredData.slice(
    indexOfFirstJob,
    indexOfLastJob,
  );

  const handlePageChange = pageNumber => setCurrentPage(pageNumber);

  const handleSearchChange = e => {
    const newQuery = e.target.value.toLowerCase();
    setSearchQuery(newQuery);
  };

  const getData = async () => {
    try {
      setLoading(true);

      const response = await axios.post(BaseApi + '/job/management', null, {
        headers: {
          key: ApiKey,
          token: tokenKey,
          'Content-Type': 'application/json',
        },
      });

      setLoading(false);

      const updatedManageJob = response.data.response.map(job => ({
        ...job,
        activated: false,
      }));

      setManageJobData(updatedManageJob);
    } catch (error) {
      setLoading(false);

      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      }

      console.error('Cannot get manage job data', error);
    }
  };

  const handleActivate = async slug => {
    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: t('employerManageJobs.no'),
        confirmButtonText: t('employerManageJobs.yes'),
        text: t('employerManageJobs.activateConfirmTxt'),
        title: t('employerManageJobs.activateConfirmTitle'),
      });

      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/job/activate/${slug}`,
          null,
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              'Content-Type': 'application/json',
            },
          },
        );

        if (response.data.status === 200) {
          resultInfo('success', t('employerManageJobs.activateSuccessTitle'), t('membershipPlan.close'));
        } else {
          resultInfo('error', response.data.message, t('membershipPlan.close'));
        }

        getData();
      }
    } catch (error) {
      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      } else {
        resultInfo('error', t('employerManageJobs.activateFailedTitle'), t('membershipPlan.close'));
      }

      console.error('Error activating job:', error);
    }
  };

  const handleDeactivate = async slug => {
    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: t('employerManageJobs.no'),
        confirmButtonText: t('employerManageJobs.yes'),
        text: t('employerManageJobs.deactivateConfirmTxt'),
        title: t('employerManageJobs.deactivateConfirmTitle'),
      });

      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/job/deactivate/${slug}`,
          null,
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              'Content-Type': 'application/json',
            },
          },
        );

        if (response.data.status === 200) {
          resultInfo('success', t('employerManageJobs.deactivateSuccessTitle'), t('employerManageJobs.close'));
        } else {
          resultInfo('error', response.data.message, t('employerManageJobs.close'));
        }

        getData();
      }
    } catch (error) {
      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      } else {
        resultInfo('error', t('employerManageJobs.deactivateFailedTitle'), t('employerManageJobs.close'));
      }

      console.error('Error deactivating job:', error);
    }
  };

  useEffect(() => {
    if (!tokenKey) {
      navigate('/user/employerlogin');
    } else {
      getData();

      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]); // eslint-disable-line

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  return (
    <>
      <NavBar />
      <div className='container manageJob'>
        <div className='row'>
          <div className='col-lg-3 col-md-3'>
            <Sidebar />
          </div>
          {
            loading
              ? (
                <div className='loader-container'></div>
              ) : (
                <>
                  <div
                    className='col-lg-9 col-md-9 mb-5'
                    style={{ borderLeft: '2px solid #e6e8e7', borderRight: '2px solid #e6e8e7' }}
                  >
                    <div className='MJHeader PageHeader'>
                      <div className='TopHaddingTitle'>
                        <h3 className=''>
                          <i>
                            <img src='/Images/employerSide/icon2color.png' alt='' />
                          </i>
                          <span>{t('employerManageJobs.manageJobs')}</span>
                        </h3>
                        <Link to='/user/createjob'>
                          <button type='button' className='btn btn-primary button1'>
                            {t('employerManageJobs.createJob')}
                          </button>
                        </Link>
                        <div className='manageSubadminPart1'>
                          <form>
                            <div className='manageSubadminPart1Sec1'>
                              <p style={{ fontSize: '18px' }}>
                                {t('employerManageJobs.searchTitle')}
                              </p>
                              <div className='APDashboardSearchBx '>
                                <input
                                  type='text'
                                  id='searchQuery'
                                  name='searchQuery'
                                  value={searchQuery}
                                  className='form-control'
                                  onChange={handleSearchChange}
                                  placeholder={t('employerManageJobs.search')}
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                        <div className='MJBody mt-5'>
                          <table className='table'>
                            <thead>
                              <tr className='table-active TrFirst'>
                                <th className='' scope='col p-3'>
                                  {t('employerManageJobs.jobs')}
                                </th>
                                <th className='' scope='col p-3'>
                                  {t('employerManageJobs.vacancyID')}
                                </th>
                                <th className='' scope='col p-3'>
                                  {t('employerManageJobs.postedOn')}
                                </th>
                                <th className='' scope='col p-3'>
                                  {t('employerManageJobs.views')}
                                </th>
                                <th className='' scope='col p-3'>
                                  {t('employerManageJobs.jobseeker')}
                                </th>
                                <th className='' scope='col p-3'>
                                  {t('employerManageJobs.notifiedJobseekers')}
                                </th>
                                <th className='' scope='col p-3'>
                                  {t('employerManageJobs.status')}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                currentJobs.map((i, index) => (
                                  <tr className='TrDefoult' key={index}>
                                    <td className=''>
                                      <Link to={`/user/managejob/accdetail/${i.Job.slug}`}>
                                        {i.Job.title}
                                      </Link>
                                    </td>
                                    <td className=''>
                                      {i.Job.id}
                                    </td>
                                    <td className=''>
                                      {i.Job.created.substring(0, 10)}
                                    </td>
                                    <td className=''>
                                      {i.Job.search_count}
                                    </td>
                                    <td className=''>
                                      {t('employerManageJobs.all')}:{' '}
                                      {i.totalCandidate}{' '}
                                      {t('employerManageJobs.new')}:{' '}
                                      {i.newApplicationCount}
                                    </td>
                                    <td className=''>{i.jobAlert}</td>
                                    <td className='manageJobActionButton'>
                                      {
                                        i.Job.status === 1
                                          ? (
                                            <button
                                              className='btn-primary'
                                              onClick={() =>handleDeactivate(i.Job.slug)}
                                            >
                                              <Tooltip title='Deactivate'>
                                                <CheckIcon />
                                              </Tooltip>
                                            </button>
                                          ) : (
                                            <button
                                              className='btn-secondary'
                                              onClick={() => handleActivate(i.Job.slug)}
                                            >
                                              <Tooltip title='Activate'>
                                                <BlockIcon />
                                              </Tooltip>
                                            </button>
                                          )
                                      }
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className='manageJobPagination'>
                      <p className='text-muted empPaginationData'>
                        {t('pagination.NoofRecords')}{' '}
                        {manageJobData.length > 0
                          ? indexOfFirstJob + 1
                          : indexOfFirstJob}
                        {'-'}{Math.min(indexOfLastJob, filteredData.length || manageJobData.length)} of{' '}
                        {
                          searchQuery
                            ? filteredData.length
                            : manageJobData.length
                        }
                      </p>
                      <div className='d-flex justify-content-center empPaginationButton'>
                        <button
                          disabled={currentPage === 1}
                          className='btn navButton1 me-2'
                          onClick={() => handlePageChange(currentPage - 1)}
                        >
                          {t('pagination.Prev')}
                        </button>
                        <button
                          className='btn navButton1'
                          onClick={() => handlePageChange(currentPage + 1)}
                          disabled={
                            searchQuery
                              ? indexOfLastJob >= filteredData.length
                              : indexOfLastJob >= manageJobData.length
                          }
                        >
                          {t('pagination.Next')}
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )
          }
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ManageJob;
