import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// components
import Footer from '../element/Footer';
import NavBar from '../element/NavBar';
// hooks
import { useWindowSize } from '../../Hooks/use-window-size';

const AboutUs = () => {
  const { width } = useWindowSize();
  const { t } = useTranslation('global');

  useEffect(() => {
    const metaTitle = document.querySelector('title');
    const metaDescription = document.querySelector('meta[name="description"]');

    if (metaTitle) {
      metaTitle.textContent = t('aboutPage.metaTitleValue');
    }

    if (metaDescription) {
      metaDescription.content = t('aboutPage.metaDescriptionValue');
    }

    window.scrollTo(0, 0);
  }, [t]);

  return (
    <div className='aboutUs'>
      <NavBar />
      <div className='aboutusSection1 text-center'>
        <h1>
          {t('aboutPage.pageTitle')}
        </h1>
        <h6 className='text-muted fw-normal'>
          <Link to='/' style={{ color: 'grey' }}>
            {t('navHeaders.home')}
          </Link>
          {' / '}
          {t('navHeaders.aboutus')}
        </h6>
      </div>
      <div className='container aboutusSection2'>
        <div className='row'>
          <div className='col-lg-3 col-md-3'>
            <img
              alt='image-1'
              className='aboutusImage'
              src='/Images/aboutUs/about-img-1.png'
            />
          </div>
          {
            width > 767 && (
              <>
                <div className='col-lg-3 col-md-3'>
                  <img
                    alt='image-2'
                    className='aboutusImage mb-2'
                    src='/Images/aboutUs/about-img-2.png'
                  />
                  <img
                    alt='image-3'
                    className='aboutusImage'
                    src='/Images/aboutUs/about-img-3.png'
                  />
                </div>
                <div className='col-lg-3 col-md-3'>
                  <img
                    alt='image-4'
                    className='aboutusImage mb-2'
                    src='/Images/aboutUs/about-img-4.png'
                  />
                  <img
                    alt='image-5'
                    className='aboutusImage'
                    src='/Images/aboutUs/about-img-5.png'
                  />
                </div>
                <div className='col-lg-3 col-md-3'>
                  <img
                    alt='image-6'
                    className='aboutusImage'
                    src='/Images/aboutUs/about-img-6.png'
                  />
                </div>
              </>
            )
          }
        </div>
      </div>
      <div className='container aboutusSection3 text-center'>
        <div className='row aboutusSection3Row'>
          <div className='col aboutusSection3Col'>
            <h2>{t('aboutPage.item1.title')}</h2>
            <p className='col-lg-8'>{t('aboutPage.item1.desc')}</p>
          </div>
          <div className='col aboutusSection3Col'>
            <h2>{t('aboutPage.item2.title')}</h2>
            <p className='col-lg-8'>{t('aboutPage.item2.desc')}</p>
          </div>
          <div className='col aboutusSection3Col'>
            <h2>{t('aboutPage.item3.title')}</h2>
            <p className='col-lg-8'>{t('aboutPage.item3.desc')}</p>
          </div>
          <div className='col aboutusSection3Col'>
            <h2>{t('aboutPage.item4.title')}</h2>
            <p className='col-lg-8'>{t('aboutPage.item4.desc')}</p>
          </div>
        </div>
        <div className='aboutusDivider'>
          <hr />
        </div>
      </div>
      <div className='container aboutusSection4 text-center'>
        <h2 className='aboutusSec4Header mx-auto' style={{ margin: '5% 0 5% 0' }}>
          {t('aboutPage.aboutusHeader1.1')}
          <span className='textGradient'>
            {' '}
            <span className='SubHaddingTxt'>
              {t('aboutPage.aboutusHeader1.2')}
            </span>
          </span>
        </h2>
        <div className='cards row justify-content-center'>
          <div className='card col-lg-3 col-sm-12'>
            <div className='card-body p-4'>
              <img
                className='aboutUsCardImage1'
                src='/Images/aboutUs/aboutUs-iconBG1.png'
                alt=''
              />
              <img
                className='aboutUsCardImage2'
                src='/Images/aboutUs/aboutUs-icon1.png'
                alt=''
              />
              <h3 className='pt-3 mb-4'>
                <span className='fw-semi-bold'>
                  {t('aboutPage.card1.header1.1')}
                </span>
              </h3>
              <p>{t('aboutPage.card1.desc')}</p>
            </div>
          </div>
          <div className='card col-lg-3 col-sm-12'>
            <div className='card-body p-4'>
              <img
                className='aboutUsCardImage1'
                src='/Images/aboutUs/aboutUs-iconBG2.png'
                alt=''
              />
              <img
                className='aboutUsCardImage2'
                src='/Images/aboutUs/aboutUs-icon2.png'
                alt=''
              />
              <h3 className='pt-3'>
                <span className='fw-semi-bold'>
                  {t('aboutPage.card2.header1.1')}
                </span>
              </h3>
              <p>{t('aboutPage.card2.desc')}</p>
            </div>
          </div>
          <div className='card col-lg-3 col-sm-12'>
            <div className='card-body p-4'>
              <img
                className='aboutUsCardImage1'
                src='/Images/aboutUs/aboutUs-iconBG3.png'
                alt=''
              />
              <img
                className='aboutUsCardImage2'
                src='/Images/aboutUs/aboutUs-icon3.png'
                alt=''
              />
              <h3 className='pt-3 mb-4'>
                <span className='fw-semi-bold'>
                  {t('aboutPage.card3.header1.1')}
                </span>
              </h3>
              <p>{t('aboutPage.card3.desc1')}</p>
              <p>{t('aboutPage.card3.desc2')}</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
