export const editorConfig = {
  askBeforePasteHTML: false,
  askBeforePasteFromWord: false,
  events: {
    afterInit: function (editor) {
      function removeStyleAttributes() {
        const elements = editor.editor.querySelectorAll('*');
        elements.forEach(element => {
          element.removeAttribute('style');
        });
      }

      function addNoFollowToLinks() {
        const links = editor.editor.querySelectorAll('a');
        links.forEach(link => {
          link.setAttribute('target', '_blank');
          link.setAttribute('rel', 'nofollow noopener noreferrer');
        });
      }

      editor.events.on('paste', () => {
        addNoFollowToLinks();
        removeStyleAttributes();
      });
      editor.events.on('change', () => {
        addNoFollowToLinks();
        removeStyleAttributes();
      });
      editor.events.on('afterInsertLink', () => {
        addNoFollowToLinks();
        removeStyleAttributes();
      });
    },
  },
};

export const selectStyles = {
  menu: baseStyles => ({
    ...baseStyles,
    zIndex: 10,
  }),
  input: baseStyles => ({
    ...baseStyles,
    height: '58px',
  }),
  control: baseStyles => ({
    ...baseStyles,
    border: 'none',
  }),
  container: baseStyles => ({
    ...baseStyles,
    fontSize: '17px',
    borderRadius: '6px',
    border: '1px dashed #a7a6a6',
  }),
  valueContainer: baseStyles => ({
    ...baseStyles,
    paddingLeft: '20px',
  }),
};
