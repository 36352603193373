import React, { useEffect, useState } from 'react';
import APNavBar from '../Elements/APNavBar';
import APSidebar from '../APSidebar/APSidebar';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import BaseApi from '../../api/BaseApi';
import ApiKey from '../../api/ApiKey';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useRef } from 'react';
import Cookies from 'js-cookie';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const APEditJobseeker = () => {
  // eslint-disable-next-line unused-imports/no-unused-vars
  const editor = useRef(null);

  const [userData, setUserData] = useState({
    first_name: '',
    last_name: '',
    contact: '',
    location: '',
    profile_image: '',
    email_address: '',
    new_password: '',
    confirm_password: '',
    status: '',
  });
  const [errors, setErrors] = useState({
    first_name: '',
    last_name: '',
    contact: '',
    location: '',
    email_address: '',
    new_password: '',
    confirm_password: '',
  });
  const [loading, setLoading] = useState(false);
  const tokenKey = Cookies.get('token');
  const adminID = Cookies.get('adminID');
  const mapKey = Cookies.get('mapKey');

  const navigate = useNavigate();
  const { slug } = useParams();
  const [selectedImage, setSelectedImage] = useState(null);

  const handleChange = e => {
    const { name, value } = e.target;
    setUserData(prev => ({
      ...prev,
      [name]: value,
    }));
    setErrors(prev => ({
      ...prev,
      [name]: '',
    }));
  };
  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        BaseApi + `/admin/users/editusers/${slug}`,
        null, // Pass null as the request body if not required
        {
          headers: {
            'Content-Type': 'application/json',
            key: ApiKey,
            token: tokenKey,
            adminid: adminID,
          },
        },
      );
      setLoading(false);
      setUserData(response.data.response);
      setSelectedImage(response.data.response.profile_image);

      //   console.log(paymentHistory);
    } catch (error) {
      setLoading(false);
      console.log('Cannot get plans data at APmanageplans');
    }
  };

  // const getData = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await axios.get(
  //       BaseApi + `/admin/candidates/editusers/${slug}`,
  //       null,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           key: ApiKey,
  //           token: tokenKey,
  //           adminid: adminID,
  //         },
  //       }
  //     );
  //     setLoading(false);
  //     setUserData(response.data.response.userdetails);
  //     setSelectedImage(response.data.response.profile_image);
  //   } catch (error) {
  //     setLoading(false);
  //     console.log("Error at edit blog at Admin panel");
  //   }
  // };

  const validateEmail = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateContactNumber = number => {
    const numberRegex = /^(\+\d{1,3}-?)?\d{10}$/;
    return numberRegex.test(number);
  };

  const handleClick = async () => {
    try {
      // const newErrors = {};

      // if (userData.first_name === "") {
      //   newErrors.first_name = "First Name is required";
      //   window.scrollTo(0, 0);
      // }
      // if (userData.last_name === "") {
      //   newErrors.last_name = "Last Name is required";
      //   window.scrollTo(0, 0);
      // }
      // if (userData.contact === "") {
      //   newErrors.contact = "Contact is required";
      //   window.scrollTo(0, 0);
      // }
      // if (userData.location === "") {
      //   newErrors.location = "Location is required";
      //   window.scrollTo(0, 0);
      // }
      // if (userData.email_address === "") {
      //   newErrors.email_address = "Email Address is required";
      //   window.scrollTo(0, 0);
      // } else if (!isValidEmail(userData.email_address)) {
      //   newErrors.email_address = "Invalid email format";
      //   window.scrollTo(0, 0);
      // }
      // if (userData.password === "") {
      //   newErrors.password = "Password is required";
      //   window.scrollTo(0, 0);
      // }
      // if (userData.confirm_password === "") {
      //   newErrors.confirm_password = "Confirm Password is required";
      //   window.scrollTo(0, 0);
      // }

      // setErrors(newErrors);

      // // Function to validate email format
      // function isValidEmail(email) {
      //   // Use a regular expression to validate email format
      //   const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      //   return emailPattern.test(email);
      // }

      const {
        first_name,
        last_name,
        contact,
        location,
        email_address,
        new_password,
        confirm_password,
      } = userData;

      if (
        !first_name ||
        !last_name ||
        !contact ||
        !location ||
        !email_address
      ) {
        setErrors({
          first_name: first_name ? '' : 'First Name is required',
          last_name: last_name ? '' : 'Last Name is required',
          contact: contact ? '' : 'Contact Number is required',
          location: location ? '' : 'Location is required',
          email_address: email_address ? '' : 'Email is required',
        });
        return;
      }
      if (new_password) {
        if (!new_password || !confirm_password) {
          setErrors({
            new_password: new_password ? '' : 'New Password is required',
            confirm_password: confirm_password
              ? ''
              : 'Confirm password is required',
          });
          return;
        }
        // Check if new email and confirm email match
        if (new_password !== confirm_password) {
          setErrors({
            confirm_password: 'New Password and Confirm Password do not match',
          });
          return;
        }

        if (new_password.length < 8 || confirm_password.length < 8) {
          setErrors({
            new_password: 'Please enter atleast 8 characters',
            confirm_password: 'Please enter atleast 8 characters',
          });
          return;
        }
      }

      // Validate email using the validateEmail function
      if (!validateEmail(email_address)) {
        setErrors({
          email_address: 'Invalid Email Address',
        });
        return;
      }

      // Validate contact using the validateEmail function
      if (!validateContactNumber(contact)) {
        setErrors({
          contact: 'Please enter contact number under 10 digits',
        });
        return;
      }

      // if (Object.keys(newErrors).length === 0) {

      const confirmationResult = await Swal.fire({
        title: 'Update Jobseeker?',
        text: 'Do you want to Update this Jobseeker?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      });

      if (confirmationResult.isConfirmed) {
        setLoading(true);

        const response = await axios.post(
          BaseApi + `/admin/candidates/editusers/${slug}`,
          userData,
          {
            headers: {
              'Content-Type': 'application/json',
              key: ApiKey,
              token: tokenKey,
              adminid: adminID,
            },
          },
        );

        setLoading(false);

        if (response.data.status === 200) {
          Swal.fire({
            title: 'Jobseeker Updated successfully!',
            icon: 'success',
            confirmButtonText: 'Close',
          });
          // setUserData({
          //   ...userData,
          //   new_username: "",
          //   conf_username: "",
          // });
          // window.scrollTo(0, 0);
          navigate('/admin/candidates');
        } else {
          Swal.fire({
            title: response.data.message,
            icon: 'error',
            confirmButtonText: 'Close',
          });
        }
      }
      // }
      // }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: 'Failed',
        text: 'Could not update jobseeker. Please try again later!',
        icon: 'error',
        confirmButtonText: 'Close',
      });
      console.log('Could not change username!', error);
    }
  };

  const handleFileUpload1 = async e => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    setUserData({ ...userData, profile_image: base64 });
    setSelectedImage(base64);

    // Clear the image error
    setErrors({
      ...errors,
      profile_image: '',
    });
  };

  // eslint-disable-next-line unused-imports/no-unused-vars
  const deleteImage = () => {
    setSelectedImage(null);
    setUserData({ ...userData, profile_image: '' });

    window.location.reload();
  };

  const handleStatusChange = e => {
    e.preventDefault();
    if (userData.status === 1) {
      setUserData({ ...userData, status: 0 });
    }
    if (userData.status === 0) {
      setUserData({ ...userData, status: 1 });
    }
  };

  // Code for loading Location

  const [autocompleteService, setAutocompleteService] = useState(null);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    // Load Google Maps AutocompleteService after component mounts
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${mapKey}&libraries=places`;
    script.onload = () => {
      setAutocompleteService(
        new window.google.maps.places.AutocompleteService(),
      );
      console.log(autocompleteService);
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleLocationChange = e => {
    const { value } = e.target;
    setSuggestionTaken(false);
    if (value == '') {
      setSuggestionTaken(true);
    }
    if (value != '') {
      setErrors({
        location: '',
      });
    }

    setUserData(prevFilter => ({
      ...prevFilter,
      location: value,
    }));

    if (autocompleteService) {
      // Call Google Maps Autocomplete API
      autocompleteService.getPlacePredictions(
        {
          input: value,
          types: ['(cities)'], // Restrict to cities if needed
        },
        (predictions, status) => {
          if (status === 'OK' && predictions) {
            setSuggestions(
              predictions.map(prediction => prediction.description),
            );
          } else {
            setSuggestions([]);
          }
        },
      );
    }
    if (userData.location === '') {
      setSuggestions([]);
    }
  };
  const [suggestionTaken, setSuggestionTaken] = useState(false);

  const handleSuggestionClick = suggestion => {
    // Update the input value with the clicked suggestion
    handleLocationChange({ target: { name: 'location', value: suggestion } });

    setSuggestionTaken(true);
    // Clear the suggestions
    setSuggestions([]);
    // console.log(filterItem);
  };

  useEffect(() => {
    // Check if tokenKey is not present
    if (!tokenKey) {
      // Redirect to the home page
      navigate('/admin');
    } else {
      // TokenKey is present, fetch data or perform other actions
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]);

  return (
    <>
      <APNavBar />
      <div className='APBasic'>
        <APSidebar />

        {loading ? (
          <>
            <div className='loader-container'></div>
          </>
        ) : (
          <>
            <div className='site-min-height'>
              <div className='breadCumb' role='presentation'>
                <Breadcrumbs
                  aria-label='breadcrumb'
                  separator={<NavigateNextIcon fontSize='small' />}
                >
                  <Link
                    underline='hover'
                    color='inherit'
                    onClick={() => navigate('/admin/admins/dashboard')}
                  >
                    Dashboard
                  </Link>
                  <Link
                    underline='hover'
                    color='inherit'
                    onClick={() => navigate('/admin/candidates')}
                  >
                    Jobseeker
                  </Link>

                  <Typography color='text.primary'>
                    Edit Jobseeker Details
                  </Typography>
                </Breadcrumbs>
              </div>
              <h2 className='adminPageHeading'>Edit Jobseeker</h2>
              <form className='adminForm'>
                <div className='mb-4 mt-5'>
                  <div className='mb-5 DashBoardInputBx'>
                    <label htmlFor='formFile' className='form-label'>
                      First Name<span className='RedStar'>*</span>
                    </label>
                    <input
                      type='text'
                      id='form3Example1'
                      className={`form-control ${
                        errors.first_name && 'input-error'
                      }`}
                      name='first_name'
                      value={userData.first_name}
                      placeholder='First Name'
                      onChange={handleChange}
                    />
                    {errors.first_name && (
                      <div className='text-danger'>{errors.first_name}</div>
                    )}
                  </div>
                  <div className='mb-5 DashBoardInputBx'>
                    <label htmlFor='formFile' className='form-label'>
                      Last Name<span className='RedStar'>*</span>
                    </label>
                    <input
                      type='text'
                      id='form3Example1'
                      className={`form-control ${
                        errors.last_name && 'input-error'
                      }`}
                      name='last_name'
                      value={userData.last_name}
                      placeholder='Last Name'
                      onChange={handleChange}
                    />
                    {errors.last_name && (
                      <div className='text-danger'>{errors.last_name}</div>
                    )}
                  </div>

                  <div className='mb-5 DashBoardInputBx'>
                    <label htmlFor='formFile' className='form-label'>
                      Location<span className='RedStar'>*</span>
                    </label>
                    <input
                      type='text'
                      id='form3Example1'
                      className={`form-control ${
                        errors.location && 'input-error'
                      }`}
                      name='location'
                      value={userData.location}
                      placeholder='Location'
                      onChange={handleLocationChange}
                    />
                    {suggestions.length > 0 && (
                      <div
                        className='suggestionsAdminSide'
                        style={{ display: suggestionTaken ? 'none' : '' }}
                      >
                        <ul className='locationDropdown'>
                          {suggestions.map((suggestion, index) => (
                            <div key={index} className='suggestion-item'>
                              <li
                                onClick={() =>
                                  handleSuggestionClick(suggestion)
                                }
                              >
                                <div className='eachLocation'>
                                  <div className='locationIcon'>
                                    <LocationOnIcon fontSize='small' />
                                  </div>{' '}
                                  <div className='locationSuggestion'>
                                    {suggestion}
                                  </div>
                                </div>{' '}
                              </li>
                            </div>
                          ))}
                        </ul>
                      </div>
                    )}
                    {errors.location && (
                      <div className='text-danger'>{errors.location}</div>
                    )}
                  </div>
                  <div className='mb-5 DashBoardInputBx'>
                    <label htmlFor='formFile' className='form-label'>
                      Contact Number<span className='RedStar'>*</span>
                    </label>
                    <input
                      type='text'
                      id='form3Example1'
                      className={`form-control ${
                        errors.contact && 'input-error'
                      }`}
                      name='contact'
                      value={userData.contact}
                      placeholder='Contact Number'
                      onChange={handleChange}
                    />
                    {errors.contact && (
                      <div className='text-danger'>{errors.contact}</div>
                    )}
                    <div id='emailHelp' className='form-text'>
                      Eg: 0671234567 or +380671234567
                    </div>
                  </div>
                  <div className='mb-5 DashBoardInputBx'>
                    <label htmlFor='formFile' className='form-label'>
                      Profile Image
                    </label>
                    <input
                      className='form-control'
                      type='file'
                      id='formFile'
                      label='profile_image'
                      name='logo'
                      accept='.jpeg, .png, .jpg'
                      onChange={e => handleFileUpload1(e)}
                    />

                    <div id='emailHelp' className='form-text'>
                      Supported File Types: gif, jpg, jpeg, png (Max. 10MB). Min
                      file size 250 X 250 pixels.
                    </div>
                    {selectedImage && (
                      <div>
                        <img
                          className='selectedInputImage'
                          src={selectedImage}
                          alt='Selected'
                        />
                        {/* <button className="APButton3" onClick={deleteImage}>
                          Delete
                        </button> */}
                      </div>
                    )}
                  </div>

                  <hr />
                  <h4 className='mb-4'>Login Details</h4>

                  <div className='mb-5 DashBoardInputBx'>
                    <label htmlFor='formFile' className='form-label'>
                      Email Address<span className='RedStar'>*</span>
                    </label>
                    <input
                      type='text'
                      id='form3Example1'
                      className={`form-control ${
                        errors.email_address && 'input-error'
                      }`}
                      name='email_address'
                      value={userData.email_address}
                      placeholder='Email Address'
                      onChange={handleChange}
                      disabled
                    />
                    {errors.email_address && (
                      <div className='text-danger'>{errors.email_address}</div>
                    )}
                  </div>
                  <div className='mb-5 DashBoardInputBx'>
                    <label htmlFor='formFile' className='form-label'>
                      Password<span className='RedStar'>*</span>
                    </label>
                    <input
                      type='password'
                      id='form3Example1'
                      className={`form-control ${
                        errors.password && 'input-error'
                      }`}
                      name='new_password'
                      value={userData.new_password}
                      placeholder='Password'
                      onChange={handleChange}
                    />
                    {errors.new_password && (
                      <div className='text-danger'>{errors.new_password}</div>
                    )}
                    <div id='emailHelp' className='form-text'>
                      * Note: If You want to change User&apos;s password, only then
                      fill password below otherwise leave it blank.
                    </div>
                  </div>
                  <div className='mb-5 DashBoardInputBx'>
                    <label htmlFor='formFile' className='form-label'>
                      Confirm Password<span className='RedStar'>*</span>
                    </label>
                    <input
                      type='password'
                      id='form3Example1'
                      className={`form-control ${
                        errors.confirm_password && 'input-error'
                      }`}
                      name='confirm_password'
                      value={userData.confirm_password}
                      placeholder='Confirm Password'
                      onChange={handleChange}
                    />
                    {errors.confirm_password && (
                      <div className='text-danger'>
                        {errors.confirm_password}
                      </div>
                    )}
                  </div>

                  <hr />
                  <div className='otherSection'>
                    <h4 className=''>Other Details</h4>
                    <div className='otherSectionBody'>
                      <p>Jobseeker Status</p>
                      <div className='activeButtonBox'>
                        {userData.status === 1 ? (
                          <button
                            className='activeTrue'
                            onClick={handleStatusChange}
                          >
                            Active
                          </button>
                        ) : (
                          <button
                            className='activeFalse'
                            onClick={handleStatusChange}
                          >
                            Inactive
                          </button>
                        )}
                      </div>
                    </div>

                    <div id='emailHelp' className='form-text'>
                      Click above to change Employer&apos;s status. Changes will be
                      reflect only after submitting form.
                    </div>
                    <div id='emailHelp' className='text-danger'>
                      Changes will be reflect only after submitting form.
                    </div>
                  </div>

                  <div className='adminBottomButtons'>
                    <button
                      type='button'
                      className='btn btn-primary button1'
                      onClick={handleClick}
                    >
                      SAVE
                    </button>
                    <button
                      type='button'
                      className='btn btn-primary button2'
                      onClick={() => navigate('/admin/admins/dashboard')}
                    >
                      CANCEL
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default APEditJobseeker;

function convertToBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = error => {
      reject(error);
    };
  });
}
