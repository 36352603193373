import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
// api
import ApiKey from '../../api/ApiKey';
import BaseApi from '../../api/BaseApi';
// components
import APNavBar from '../Elements/APNavBar';
import APSidebar from '../APSidebar/APSidebar';
//mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CreateIcon from '@mui/icons-material/Create';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const APHomePageSlider = () => {
  const navigate = useNavigate();
  const tokenKey = Cookies.get('token');
  const adminID = Cookies.get('adminID');

  const [open, setOpen] = useState(false);
  const [symbol, setSymbol] = useState('🔺');
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [openOrder, setOpenOrder] = useState(false);
  const [orderNumber, setOrderNumber] = useState(1);
  const [orderValue, setOrderValue] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'ascending' });

  const itemsPerPage = 10;

  const sortAndFilterData = (array, key, direction, query) => {
    const sortedAndFilteredArray = [...array]
      .filter(
        item =>
          item.company_name?.toLowerCase().includes(query) ||
          item.full_name?.toLowerCase().includes(query) ||
          item.email_address?.toLowerCase().includes(query) ||
          item.created?.toLowerCase().includes(query),
      )
      .sort((a, b) => {
        if (a[key] < b[key]) {
          return direction === 'ascending' ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    return sortedAndFilteredArray;
  };

  const sortedAndFilteredData = sortAndFilterData(
    listData,
    sortConfig.key,
    sortConfig.direction,
    searchQuery,
  );

  const filteredData = listData.filter(item => {
    const searchString = searchQuery.toLowerCase();
    return (
      item.company_name?.toLowerCase().includes(searchString) ||
      item.full_name?.toLowerCase().includes(searchString) ||
      item.email_address?.toLowerCase().includes(searchString) ||
      item.created?.toLowerCase().includes(searchString)
    );
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedAndFilteredData.slice(
    indexOfFirstItem,
    indexOfLastItem,
  );

  const totalPages = Math.ceil(sortedAndFilteredData.length / itemsPerPage);

  const handleColumnClick = key => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
      setSymbol('🔻');
    } else {
      setSymbol('🔺');
    }
    setSortConfig({ key, direction });
  };

  const handleSearchChange = e => {
    const newQuery = e.target.value.toLowerCase();
    setSearchQuery(newQuery);
  };

  const handleOpen = plan => {
    setSelectedPayment(plan);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedPayment(null);
    setOpen(false);
  };

  const handleEditOrder = order => {
    setOrderValue(order);
    setOrderNumber(order.display_order);
    setOpenOrder(true);
  };

  const handleEditOrderClose = () => {
    setOrderValue(null);
    setOpenOrder(false);
  };

  const handleChange = e => {
    setOrderNumber(e.target.value);
  };

  const handleReset = () => {
    setOrderValue(null);
    setOpenOrder(false);
  };

  const handleClick = async () => {
    const confirmationResult = await Swal.fire({
      icon: 'question',
      cancelButtonText: 'No',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      title: 'Update Employer order?',
      text: 'Do you want to update the Employer Slider List Order?',
    });

    try {
      if (confirmationResult.isConfirmed) {
        setLoading(true);

        const response = await axios.post(
          BaseApi + '/admin/users/updateemployerorder',
          { id: orderValue.id, order: orderNumber },
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              adminid: adminID,
              'Content-Type': 'application/json',
            },
          },
        );

        setLoading(false);

        if (response.data.status === 200) {
          Swal.fire({
            icon: 'success',
            confirmButtonText: 'Close',
            title: 'Employer Order updated successfully!',
          });

          window.location.reload();
        } else {
          Swal.fire({
            icon: 'error',
            confirmButtonText: 'Close',
            title: response.data.message,
          });
        }
      }
    } catch (error) {
      setLoading(false);

      console.error('Cannot get plans data at APmanageplans', error);
    }
  };

  const getData = async () => {
    try {
      setLoading(true);

      const response = await axios.post(
        BaseApi + '/admin/users/selectforslider',
        null,
        {
          headers: {
            key: ApiKey,
            token: tokenKey,
            adminid: adminID,
            'Content-Type': 'application/json',
          },
        },
      );

      setLoading(false);
      setListData(response.data.response);
    } catch (error) {
      setLoading(false);

      console.error('Cannot get plans data at APmanageplans', error);
    }
  };

  const handleActivate = async slug => {
    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        cancelButtonText: 'No',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        title: 'Activate Slider?',
        text: 'Do you want to Activate this Slider?',
      });

      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/admin/users/activateslider/${slug}`,
          null,
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              adminid: adminID,
              'Content-Type': 'application/json',
            },
          },
        );

        if (response.data.status === 200) {
          Swal.fire({
            icon: 'success',
            confirmButtonText: 'Close',
            title: 'Slider Activated successfully!',
          });
        } else {
          Swal.fire({
            icon: 'error',
            confirmButtonText: 'Close',
            title: response.data.message,
          });
        }

        getData();
      }
    } catch (error) {
      setLoading(false);

      Swal.fire({
        icon: 'error',
        confirmButtonText: 'Close',
        text: 'Could not Activate Slider',
        title: 'Failed. Please try after some time!',
      });

      console.error('Couldn\'t activate the record!', error.message);
    }
  };

  const handleDeactivate = async slug => {
    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        cancelButtonText: 'No',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        title: 'Deactivate Slider?',
        text: 'Do you want to Deactivate this Slider?',
      });

      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/admin/users/deactivateslider/${slug}`,
          null,
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              adminid: adminID,
              'Content-Type': 'application/json',
            },
          },
        );

        if (response.data.status === 200) {
          Swal.fire({
            icon: 'success',
            confirmButtonText: 'Close',
            title: 'Slider Deactivated successfully!',
          });
        } else {
          Swal.fire({
            icon: 'error',
            confirmButtonText: 'Close',
            title: response.data.message,
          });
        }

        getData();
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        confirmButtonText: 'Close',
        text: 'Could not Deactivate Slider',
        title: 'Failed. Please try after some time!',
      });

      console.error('Couldn\'t deactivate the record!', error.message);
    }
  };

  useEffect(() => {
    if (!tokenKey) {
      navigate('/admin');
    } else {
      getData();

      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]); // eslint-disable-line

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  return (
    <>
      <APNavBar />
      <div className='APBasic APManageSubadmin'>
        <APSidebar />
        {
          loading
            ? (
              <div className='loader-container'></div>
            ) : (
              <div className='site-min-height'>
                <div className='breadCumb1' role='presentation'>
                  <Breadcrumbs
                    aria-label='breadcrumb'
                    separator={<NavigateNextIcon fontSize='small' />}
                  >
                    <Link
                      underline='hover'
                      color='inherit'
                      onClick={() => navigate('/admin/admins/dashboard')}
                    >
                      Dashboard
                    </Link>
                    <Link
                      underline='hover'
                      color='inherit'
                      onClick={() => navigate('')}
                    >
                      Employers
                    </Link>
                    <Typography color='text.primary'>
                      Employers Logo List
                    </Typography>
                  </Breadcrumbs>
                </div>
                <div className='ManageSubAdminHeader'>
                  <h2 className=''>Employer Logo List</h2>
                  <button
                    className='btn navButton1 APMSbutton'
                    onClick={() => navigate('/admin/users/addhomepageslider')}
                  >
                    Add Employer To Slider
                  </button>
                </div>
                {
                  listData != ''
                    ? (
                      <>
                        <div className='manageSubadminPart1'>
                          <form>
                            <div className='manageSubadminPart1Sec1'>
                              <h4>
                                Search employer by typing Company name, Email or Name
                              </h4>
                              <div className='APDashboardSearchBx '>
                                <input
                                  type='text'
                                  id='form3Example1'
                                  className='form-control'
                                  name='searchQuery'
                                  placeholder='Search..'
                                  value={searchQuery}
                                  onChange={handleSearchChange}
                                />
                              </div>
                            </div>
                          </form>
                          <h4>
                            No. of Results {indexOfFirstItem + 1}-
                            {Math.min(
                              indexOfLastItem,
                              filteredData.length || listData.length,
                            )}{' '}
                            of {listData.length}
                          </h4>
                        </div>
                        <div className='manageSubadminPart2'>
                          <table className='table'>
                            <thead>
                              <tr>
                                <th onClick={() => handleColumnClick('company_name')}>
                                  <span style={{ cursor: 'pointer' }}>
                                    Company Name {symbol}
                                  </span>
                                </th>
                                <th onClick={() => handleColumnClick('full_name')}>
                                  <span style={{ cursor: 'pointer' }}>
                                    Full Name {symbol}
                                  </span>
                                </th>
                                <th onClick={() => handleColumnClick('email_address')}>
                                  <span style={{ cursor: 'pointer' }}>
                                    Email {symbol}
                                  </span>
                                </th>
                                <th>Logo</th>
                                <th>Order Number</th>
                                <th onClick={() => handleColumnClick('created')}>
                                  <span style={{ cursor: 'pointer' }}>
                                    <CalendarMonthIcon /> Created
                                  </span>
                                </th>
                                <th scope='col'>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                currentItems.map(i => (
                                  <tr key={i.id}>
                                    <td>{i.company_name}</td>
                                    <td>{i.full_name}</td>
                                    <td>{i.email_address}</td>
                                    <td>
                                      {
                                        i.company_logo
                                          ? (
                                            <div className='profileImageBox'>
                                              <img
                                                className='profileImage'
                                                src={i.company_logo}
                                                alt='company logo'
                                              />
                                            </div>
                                          ) : (
                                            <img
                                              src='/Images/adminpanel/demoo.jpg'
                                              alt='Company'
                                            />
                                          )
                                      }
                                    </td>
                                    <td>{i.display_order}</td>
                                    <td>{i.created}</td>
                                    <td className='APActionButton'>
                                      {
                                        i.home_slider === 1
                                          ? (
                                            <button
                                              className='btn-secondary'
                                              onClick={() => handleDeactivate(i.slug)}
                                            >
                                              <Tooltip title='Deactivate'>
                                                <CheckIcon />
                                              </Tooltip>
                                            </button>
                                          ) : (
                                            <button
                                              className='btn-secondary'
                                              onClick={() => handleActivate(i.slug)}
                                            >
                                              <Tooltip title='Activate'>
                                                <BlockIcon />
                                              </Tooltip>
                                            </button>
                                          )
                                      }
                                      <button
                                        className='btn-primary'
                                        onClick={() => handleEditOrder(i)}
                                      >
                                        <Tooltip title='Edit Order'>
                                          <CreateIcon />
                                        </Tooltip>
                                      </button>
                                      <button
                                        className='btn-primary'
                                        onClick={() => handleOpen(i)}
                                      >
                                        <Tooltip title='View'>
                                          <RemoveRedEyeIcon />
                                        </Tooltip>
                                      </button>
                                    </td>
                                  </tr>
                                ))
                              }
                              <div>
                                <Modal
                                  className='modalMain'
                                  open={openOrder}
                                  onClose={handleEditOrderClose}
                                  aria-labelledby='modal-modal-title'
                                  aria-describedby='modal-modal-description'
                                >
                                  <Box className='modal adminModal modal-content'>
                                    <IconButton
                                      onClick={handleEditOrderClose}
                                      className='close-button'
                                      style={{
                                        top: 10,
                                        right: 10,
                                        position: 'absolute',
                                      }}
                                    >
                                      &times;
                                    </IconButton>
                                    <Typography
                                      variant='h6'
                                      component='h2'
                                      id='modal-modal-title'
                                    ></Typography>
                                    <Typography
                                      id='modal-modal-description'
                                      sx={{ mt: 3 }}
                                    >
                                      {
                                        orderValue && (
                                          <div className='modals '>
                                            <div className='modalHead'>
                                              <h1>{orderValue.full_name}</h1>
                                            </div>
                                            <div className='modalBody mt-4'>
                                              <div className='row'>
                                                <div className='col APBasic'>
                                                  <div className='mb-5 mt-4 DashBoardInputBx'>
                                                    <label
                                                      htmlFor='formFile'
                                                      className='form-label'
                                                    >
                                                      Order Number
                                                      <span className='RedStar'>*</span>
                                                    </label>
                                                    <input
                                                      type='number'
                                                      id='form3Example1'
                                                      className='form-control'
                                                      name='Order_Number'
                                                      value={orderNumber}
                                                      placeholder='Order Number'
                                                      onChange={handleChange}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className='adminBottomButtons'>
                                                <button
                                                  type='button'
                                                  className='btn btn-primary button1'
                                                  onClick={handleClick}
                                                >
                                                  SAVE
                                                </button>
                                                <button
                                                  type='button'
                                                  className='btn btn-primary button2'
                                                  onClick={() => handleReset()}
                                                >
                                                  RESET
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      }
                                    </Typography>
                                  </Box>
                                </Modal>
                                <Modal
                                  open={open}
                                  className='modalMain'
                                  onClose={handleClose}
                                  aria-labelledby='modal-modal-title'
                                  aria-describedby='modal-modal-description'
                                >
                                  <Box className='modal adminModal modal-content'>
                                    <IconButton
                                      onClick={handleClose}
                                      className='close-button'
                                      style={{
                                        top: 10,
                                        right: 10,
                                        position: 'absolute',
                                      }}
                                    >
                                      &times;
                                    </IconButton>
                                    <Typography
                                      id='modal-modal-title'
                                      variant='h6'
                                      component='h2'
                                    ></Typography>
                                    <Typography
                                      id='modal-modal-description'
                                      sx={{ mt: 3 }}
                                    >
                                      {
                                        selectedPayment && (
                                          <div className='modals '>
                                            <div className='modalHead'>
                                              <h1>{selectedPayment.full_name}</h1>
                                            </div>
                                            <div className='modalBody mt-4'>
                                              <div className='row'>
                                                <div className='col-md-4 fw-bold m-2 leftData'>
                                                  Company Name:{' '}
                                                </div>
                                                <div className='col-md-4 m-2 rightData'>
                                                  {selectedPayment.company_name}
                                                </div>
                                              </div>
                                              <div className='row'>
                                                <div className='col-md-4 fw-bold m-2 leftData'>
                                                  Position:{' '}
                                                </div>
                                                <div className='col-md-4 m-2 rightData'>
                                                  {selectedPayment.position}
                                                </div>
                                              </div>
                                              <div className='row'>
                                                <div className='col-md-4 fw-bold m-2 leftData'>
                                                  Name:{' '}
                                                </div>
                                                <div className='col-md-4 m-2 rightData'>
                                                  {selectedPayment.full_name}
                                                </div>
                                              </div>
                                              <div className='row'>
                                                <div className='col-md-4 fw-bold m-2 leftData'>
                                                  Email Address:{' '}
                                                </div>
                                                <div className='col-md-4 m-2 rightData'>
                                                  {selectedPayment.email_address}
                                                </div>
                                              </div>
                                              <div className='row'>
                                                <div className='col-md-4 fw-bold m-2 leftData'>
                                                  Address:{' '}
                                                </div>
                                                <div className='col-md-4 m-2 rightData'>
                                                  {selectedPayment.address}
                                                </div>
                                              </div>
                                              <div className='row'>
                                                <div className='col-md-4 fw-bold m-2 leftData'>
                                                  Location:{' '}
                                                </div>
                                                <div className='col-md-4 m-2 rightData'>
                                                  {selectedPayment.location}
                                                </div>
                                              </div>
                                              <div className='row'>
                                                <div className='col-md-4 fw-bold m-2 leftData'>
                                                  Contact Number:{' '}
                                                </div>
                                                <div className='col-md-4 m-2 rightData'>
                                                  {selectedPayment.contact}
                                                </div>
                                              </div>
                                              <div className='row'>
                                                <div className='col-md-4 fw-bold m-2 leftData'>
                                                  Company Number:{' '}
                                                </div>
                                                <div className='col-md-4 m-2 rightData'>
                                                  {selectedPayment.contact}
                                                </div>
                                              </div>
                                              <div className='row'>
                                                <div className='col-md-4 fw-bold m-2 leftData'>
                                                  Website:{' '}
                                                </div>
                                                <div className='col-md-4 m-2 rightData'>
                                                  {selectedPayment.url}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      }
                                    </Typography>
                                  </Box>
                                </Modal>
                              </div>
                            </tbody>
                          </table>
                        </div>
                        <div className='manageSubadminpart3'>
                          <div className='pagination'>
                            <Stack spacing={2} sx={{ mb: '20px' }}>
                              <Pagination
                                color='primary'
                                count={totalPages}
                                page={currentPage}
                                onChange={(event, page) => setCurrentPage(page)}
                              />
                            </Stack>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className='appliedJobListNoData'>No Records Found</div>
                    )
                }
              </div>
            )
        }
      </div>
    </>
  );
};

export default APHomePageSlider;
