import axios from 'axios';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import Marquee from 'react-fast-marquee';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
// api
import ApiKey from '../api/ApiKey';
import BaseApi from '../api/BaseApi';
// components
import Footer from '../element/Footer';
import NavBar from '../element/NavBar';
import JobCard from '../element/JobCard';
import CategoryCard from '../element/CategoryCard';
import MembershipCard from '../element/MembershipCard';
import BrowseJobToggler from '../element/BrowseJobToggler';
// mui
import SearchIcon from '@mui/icons-material/Search';

const UserPage = () => {
  // const navigate = useNavigate();
  const { t } = useTranslation('global');
  const tokenKey = Cookies.get('tokenClient');
  const primaryColor = Cookies.get('primaryColor');

  const [keyword, setKeyword] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [totalPageData, setTotalPageData] = useState([]);

  const {
    keywords = [],
    job_title = [],
    skillList = [],
    categories = [],
    // sliderList = [],
    site_setting = {},
    top_employer = [],
    bannerDetails = [],
    latestJobList = [],
    plans_details = [],
    announcementList = [],
    categories_listing = [],
  } = totalPageData;

  const encodeKeyword = keyword => encodeURIComponent(keyword);

  const getData = async () => {
    try {
      setLoading(true);

      const response = await axios.get(BaseApi + '/home', {
        headers: {
          key: ApiKey,
          token: tokenKey,
          'Content-Type': 'application/json',
        },
      });

      const totalData = response.data.response;

      setLoading(false);
      setTotalPageData(totalData);
    } catch (error) {
      setLoading(false);

      console.error('Cannot get home page data!');
    }
  };

  useEffect(() => {
    const metaTitle = document.querySelector('title');
    const metaDescription = document.querySelector('meta[name="description"]');

    if (metaTitle) {
      metaTitle.textContent = t('userpage.metaTitleValue');
    }

    if (metaDescription) {
      metaDescription.content = t('userpage.metaDescriptionValue');
    }

    getData();
  }, [t]); // eslint-disable-line

  return (
    <>
      <NavBar />
      {
        loading
          ? (
            <div className='loader-container'></div>
          ) : (
            <div>
              <div className='sliderbody'>
                <div className='lowerSlider'>
                  <div className='container container'>
                    <div className='row'>
                      <div className='col'>
                        <div className='slidertext'>
                          <h1>{site_setting.slogan_title}</h1>
                          <p>{site_setting.slogan_text}</p>
                        </div>
                        <div className='searcharea'>
                          <div className='inputGrp'>
                            <SearchIcon
                              sx={{
                                color: '#3C3C3C',
                                width: { xs: 24, sm: 32 },
                                height: { xs: 24, sm: 32 },
                              }}
                            />
                            <input
                              type='search'
                              name='keyword'
                              value={keyword}
                              aria-label='Search'
                              className='form-control'
                              aria-describedby='search-addon'
                              onChange={e => setKeyword(e.target.value)}
                              placeholder={t('userpage.jobTitleKeyword')}
                            />
                          </div>
                          <Link
                            type='button'
                            className='btn btn-primary button1'
                            to={keyword ? `/searchjob/k-${encodeKeyword(keyword)}` : '/searchjob'}
                          >
                            {t('userpage.searchButton')}
                          </Link>
                          {/* <button
                            type='button'
                            className='btn btn-primary uploadCvButton'
                            onClick={() => navigate('/user/jobseekerlogin')}
                          >
                            {t('userpage.uploadCVButton')}
                          </button> */}
                        </div>
                        {
                          selectedFile && (
                            <div className='mt-3 d-flex'>
                              <p className='pt-2'>
                                      Selected File: {selectedFile.name}
                              </p>
                              <button
                                className='btn btn-outline-dark ms-2'
                                onClick={() => setSelectedFile()}
                              >
                                {t('userpage.deleteButton')}
                              </button>
                            </div>
                          )
                        }
                        <div className='slidertext2'>
                          <div className='jobs-clients-posted'>
                            <h3>{totalPageData.jobscount}</h3>
                            <h6 className='text-muted'>
                              {t('userpage.jobsPosted')}
                            </h6>
                          </div>
                          <div className='jobs-clients-posted'>
                            <h3>{totalPageData.freelancers}</h3>
                            <h6 className='text-muted'>
                              {t('userpage.employers')}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <section className='CategorySection'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-xs-12 col-md-4 col-lg-4'>
                      <Link to='/searchjob'>
                        <div className='CategoryBx'>
                          <img src='/Images/SearchDesiredJob.jpg' alt='' />
                          <h5 className=''>{t('userpage.searchDesiredJob')}</h5>
                          <p>{t('userpage.belowTxt2')}</p>
                        </div>
                      </Link>
                    </div>
                    <div className='col-xs-12 col-md-4 col-lg-4'>
                      <Link to='/user/register/employer'>
                        <div className='CategoryBx'>
                          <img src='/Images/CreateAnAccount.jpg' alt='' />
                          <h5 className=''>{t('userpage.createAnAcount')}</h5>
                          <p>{t('userpage.belowTxt1')}</p>
                        </div>
                      </Link>
                    </div>
                    <div className='col-xs-12 col-md-4 col-lg-4'>
                      <Link to='/user/createjob'>
                        <div className='CategoryBx'>
                          <img src='/Images/SendYourResume.jpg' alt='' />
                          <h5 className=''>{t('userpage.postVacancy')}</h5>
                          <p>{t('userpage.belowTxt3')}</p>
                        </div>
                      </Link>
                    </div>
                  </div>
                  {
                    categories_listing.length > 0 && (
                      <div className='subsecondblock'>
                        <div className='LeftDotImg'>
                          <img src='/Images/dotimg.png' alt='' />
                        </div>
                        <div className='RightDotImg'>
                          <img src='/Images/dotimg.png' alt='' />
                        </div>
                        <h3 className='subsecondblockHeading'>
                          {t('userpage.explore')}
                          <span className='textGradient'>
                            {' '}
                            <span className='SubHaddingTxt'>
                              {t('userpage.categories')}{' '}
                            </span>
                          </span>
                        </h3>
                        <div className='ExploreCategory'>
                          <div className='row'>
                            {
                              categories_listing.map(i => (
                                <div key={i.id} className='col-md-6 col-lg-3 HomeCategorysCardBx'>
                                  <CategoryCard
                                    id={i.id}
                                    slug={i.slug}
                                    title={i.name}
                                    image2={i.image}
                                    footer={i.sub_cat}
                                    image1='/Images/Back-1.png'
                                  />
                                </div>
                              ))
                            }
                          </div>
                          <Link
                            type='button'
                            to='/allcategory'
                            className='btn btn-primary button1'
                          >
                            {t('userpage.exploreAllCategories')}
                          </Link>
                        </div>
                      </div>
                    )
                  }
                </div>
              </section>
              <div className='thirdBlock'>
                <div className='container'>
                  <div className='thirdBlockData'>
                    <h3 className='BrowseJobsHadding'>
                      {t('userpage.browseJobs')}
                    </h3>
                    <BrowseJobToggler
                      bySkill={skillList}
                      byTitle={job_title}
                      byCategory={categories}
                      byPopularSearch={keywords}
                    />
                  </div>
                </div>
              </div>
              {
                latestJobList.length > 0 && (
                  <section className='FeaturedJobsSection'>
                    <div className='container fourthBlock text-center'>
                      <h3 className='subsecondblockHeading'>
                        {t('userpage.featured')}
                        <span className='textGradient'>
                          {' '}
                          <span className='SubHaddingTxt'>
                            {t('userpage.jobs')}
                          </span>
                        </span>
                      </h3>
                      <div className='FBitem'>
                        <div className='row'>
                          {
                            latestJobList.slice(0, 6).map(i => (
                              <div key={i.id} className='col-md-6 col-lg-4'>
                                <JobCard
                                  logo={i.logo}
                                  slug={i.slug}
                                  title={i.title}
                                  created={i.created}
                                  min_exp={i.min_exp}
                                  cat_slug={i.cat_slug}
                                  job_city={i.job_city}
                                  desc={i.brief_abtcomp}
                                  work_type={i.work_type}
                                  salary_min={i.salary_min}
                                  salary_max={i.salary_max}
                                  company_name={i.company_name}
                                  salary_currency={i.salary_currency}
                                />
                              </div>
                            ))
                          }
                        </div>
                      </div>
                      <Link to='/searchjob'>
                        <button className='btn btn-primary button1'>
                          {t('userpage.viewAllJobsButton')}
                        </button>
                      </Link>
                    </div>
                  </section>
                )
              }
              {
                plans_details.length > 0 && (
                  <section className='MembershipSection'>
                    <div className='container text-center'>
                      <h3 className='subsecondblockHeading'>
                        {t('userpage.membership')}
                        <span className='textGradient'>
                          {' '}
                          <span className='SubHaddingTxt'>
                            {t('userpage.plan')}
                          </span>{' '}
                        </span>
                      </h3>
                      <div className='MembershipMainBx'>
                        <MembershipCard />
                      </div>
                    </div>
                  </section>
                )
              }
              {
                top_employer.length > 0 && (
                  <section className='TopEmployersSection'>
                    <div className='container text-center'>
                      <h3 className='subsecondblockHeading'>
                        {t('userpage.top')}
                        <span className='textGradient'>
                          {' '}
                          <span className='SubHaddingTxt'>
                            {t('userpage.employer')}
                          </span>{' '}
                        </span>
                      </h3>
                      <div className='TopEmployersBx'>
                        <div className='row m-0'>
                          {
                            top_employer.map(i => (
                              <div key={i.id} className='col-xs-12 col-md-3 col-lg-3 p-0 TopEmployersBxCardBx'>
                                <div className='EmployersLogoBx BorderRight BorderBottom'>
                                  <Link className='EmployersLogoLink' to={`/companyprofile/${i.slug}`}>
                                    <img
                                      alt={i.slug}
                                      src={i.company_logo}
                                      className='employersLogo'
                                    />
                                  </Link>
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  </section>
                )
              }
              <div className='sixthBlock'>
                <section className='DownloadAppSection'>
                  <Link to='/searchjob/k-arterium'>
                    <img
                      alt='image'
                      id='flexImage'
                      className='flexImage'
                      src='/Images/mainBanner/arteriumMainBanner.png'
                    />
                  </Link>
                </section>
                {
                  bannerDetails.length > 0 && (
                    <div className='section2'>
                      <div className='row'>
                        {
                          bannerDetails.map((i, index) => (
                            <div key={index} className='col-md-6 col-sm-12'>
                              <Link to={i.url}>
                                <img
                                  alt='image'
                                  src={i.image}
                                  className='flexImage'
                                  id={`flexImage${index + 1}`}
                                />
                              </Link>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  )
                }
              </div>
              <Marquee
                pauseOnHover={true}
                className='marqueeText'
                style={{ backgroundColor: primaryColor }}
              >
                {
                  announcementList.map(i => (
                    <Link key={i.id} to={i.url} className='marqueeLink' target='_blank'>
                      {`| ${i.name} |`}
                    </Link>
                  ))
                }
              </Marquee>
              <Footer />
            </div>
          )
      }
    </>
  );
};

export default UserPage;

// document.addEventListener('mousemove', parallax);

// function parallax(e) {
//   this.querySelectorAll('.layer').forEach(layer => {
//     const speed = layer.getAttribute('data-speed');
//     const x = (window.innerWidth - e.pageX * speed) / 900;
//     const y = (window.innerWidth - e.pageY * speed) / 900;
//     layer.style.transform = `translate(${x}px, ${y}px)`;
//   });
// }
