import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
// api
import ApiKey from '../../api/ApiKey';
import BaseApi from '../../api/BaseApi';
// components
import APNavBar from '../Elements/APNavBar';
import APSidebar from '../APSidebar/APSidebar';
// mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import Pagination from '@mui/material/Pagination';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { IconButton, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// import AddIcon from '@mui/icons-material/Add';
// import CreateIcon from "@mui/icons-material/Create";
// import DeleteIcon from "@mui/icons-material/Delete";
// import VerifiedIcon from "@mui/icons-material/Verified";
// import ContentCopyIcon from "@mui/icons-material/ContentCopy";
// import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const APTransactionList = () => {
  const itemsPerPage = 20;
  const navigate = useNavigate();
  const tokenKey = Cookies.get('token');
  const adminID = Cookies.get('adminID');

  const [open, setOpen] = useState(false);
  const [symbol, setSymbol] = useState('🔻');
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'ascending' });

  // const [checkedData, setCheckedData] = useState([]);
  // const [dataPerPage, setDataPerPage] = useState([]);

  const sortAndFilterData = (array, key, direction, query) => {
    const sortedAndFilteredArray = [...array]
      .filter(
        item =>
          item.company_name?.toLowerCase().includes(query) ||
          item.employer_id?.toString().toLowerCase().includes(query) ||
          item.full_name?.toLowerCase().includes(query) ||
          item.plan_name?.toLowerCase().includes(query) ||
          item.created?.toLowerCase().includes(query),
      )
      .sort((a, b) => {
        if (a[key] < b[key]) {
          return direction === 'ascending' ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    return sortedAndFilteredArray;
  };

  const sortedAndFilteredData = sortAndFilterData(
    listData,
    sortConfig.key,
    sortConfig.direction,
    searchQuery,
  );

  const filteredData = listData.filter(item => {
    const searchString = searchQuery.toLowerCase();
    return (
      item.company_name?.toLowerCase().includes(searchString) ||
      item.employer_id?.toString().toLowerCase().includes(searchString) ||
      item.full_name?.toLowerCase().includes(searchString) ||
      item.plan_name?.toLowerCase().includes(searchString) ||
      item.created?.toLowerCase().includes(searchString)
    );
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedAndFilteredData.slice(
    indexOfFirstItem,
    indexOfLastItem,
  );

  const totalPages = Math.ceil(sortedAndFilteredData.length / itemsPerPage);

  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const getData = async () => {
    try {
      setLoading(true);

      const response = await axios.post(
        BaseApi + '/admin/payment/index',
        null,
        {
          headers: {
            key: ApiKey,
            token: tokenKey,
            adminid: adminID,
            'Content-Type': 'application/json',
          },
        },
      );

      setLoading(false);
      setListData(response.data.response);
    } catch (error) {
      setLoading(false);

      console.error('Cannot get plans data at APmanageplans');
    }
  };

  const handleOpen = plan => {
    setSelectedPayment(plan);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedPayment(null);
    setOpen(false);
  };

  const handleSearchChange = e => {
    const newQuery = e.target.value.toLowerCase();
    setSearchQuery(newQuery);
  };

  const handleColumnClick = key => {
    let direction = 'ascending';

    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
      setSymbol('🔺');
    } else {
      setSymbol('🔻');
    }

    setSortConfig({ key, direction });
  };

  const handleActivate = async planId => {
    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        cancelButtonText: 'No',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        title: 'Activate User Plan',
        text: 'Do you want to Activate this user?',
      });

      if (confirmationResult.isConfirmed) {
        setLoading(true);

        const response = await axios.post(
          BaseApi + '/plans/activate',
          { id: planId },
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              adminid: adminID,
              'Content-Type': 'application/json',
            },
          },
        );

        setLoading(false);

        if (response.data.status === 200) {
          Swal.fire({
            icon: 'success',
            confirmButtonText: 'Close',
            title: 'User Activated successfully!',
          });
        } else {
          Swal.fire({
            icon: 'error',
            confirmButtonText: 'Close',
            title: response.data.message,
          });
        }

        getData();
      }
    } catch (error) {
      setLoading(false);

      Swal.fire({
        icon: 'error',
        confirmButtonText: 'Close',
        text: 'Could not Activate user',
        title: 'Failed. Please try after some time!',
      });

      console.error('Couldn\'t activate the record!', error.message);
    }
  };

  const handleDeactivate = async planId => {
    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        cancelButtonText: 'No',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        title: 'Deactivate User Plan',
        text: 'Do you want to Deactivate this user?',
      });

      if (confirmationResult.isConfirmed) {
        setLoading(true);

        const response = await axios.post(
          BaseApi + '/plans/deactivate',
          { id: planId },
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              adminid: adminID,
              'Content-Type': 'application/json',
            },
          },
        );

        setLoading(false);

        if (response.data.status === 200) {
          Swal.fire({
            icon: 'success',
            confirmButtonText: 'Close',
            title: 'User Deactivated successfully!',
          });
        } else {
          Swal.fire({
            icon: 'error',
            confirmButtonText: 'Close',
            title: response.data.message,
          });
        }

        getData();
      }
    } catch (error) {
      setLoading(false);

      Swal.fire({
        icon: 'error',
        confirmButtonText: 'Close',
        text: 'Could not Deactivate user',
        title: 'Failed. Please try after some time!',
      });

      console.error('Couldn\'t deactivate the record!', error.message);
    }
  };

  const getCurrentDateFormatted = () => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  };

  const handleDownload = async () => {
    try {
      await fetch(
        BaseApi + '/admin/payment/generatexcel',
        {
          method: 'GET',
          headers: {
            'key': ApiKey,
            'token': tokenKey,
            'adminid': adminID,
          },
        },
      )
        .then(response => {
          if ((response.ok === true) & (response.status === 200)) {
            return response.blob();
          }
        })
        .then(data => {
          const url = window.URL.createObjectURL(data);
          const link = document.createElement('a');
          link.href = url;

          const fileName = `all_payments_${getCurrentDateFormatted()}.xlsx`;

          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  // const handleDelete = async (slug) => {
  //   try {
  //     const confirmationResult = await Swal.fire({
  //       title: "Delete Sub-Admin",
  //       text: "Do you want to Delete this Sub-Admin?",
  //       icon: "question",
  //       showCancelButton: true,
  //       confirmButtonText: "Yes",
  //       cancelButtonText: "No",
  //     });
  //     if (confirmationResult.isConfirmed) {
  //       // setLoading(true);
  //       const response = await axios.post(
  //         BaseApi + `/admin/deleteadmins/${slug}`,
  //         null, // Pass null as the request body if not required
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             key: ApiKey,
  //             token: tokenKey,
  //           },
  //         }
  //       );
  //       // setLoading(false);
  //       if (response.data.status === 200) {
  //         Swal.fire({
  //           title: "Sub-Admin deleted successfully!",
  //           icon: "success",
  //           confirmButtonText: "Close",
  //         });
  //       } else {
  //         Swal.fire({
  //           title: response.data.message,
  //           icon: "error",
  //           confirmButtonText: "Close",
  //         });
  //       }
  //       getData();
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     Swal.fire({
  //       title: "Failed. Please try after some time!",
  //       text: "Could not Delete Sub-Admin",
  //       icon: "error",
  //       confirmButtonText: "Close",
  //     });
  //     console.log("Couldn't delete the record!", error.message);
  //   }
  // };

  // const handleCheck = id => {
  //   if (checkedData.includes(id)) {
  //     // If the ID is already in the array, remove it
  //     setCheckedData(checkedData.filter(checkedId => checkedId !== id));
  //   } else {
  //     // If the ID is not in the array, add it
  //     setCheckedData([...checkedData, id]);
  //   }
  // };

  // const handleMultipleDeactivate = async () => {
  //   try {
  //     const confirmationResult = await Swal.fire({
  //       title: 'Deactivate Sub-Admins',
  //       text: 'Do you want to Deactivate Sub-Admins?',
  //       icon: 'question',
  //       showCancelButton: true,
  //       confirmButtonText: 'Yes',
  //       cancelButtonText: 'No',
  //     });
  //     if (confirmationResult.isConfirmed) {
  //       const ids = checkedData.join(',');
  //       // console.log(ids);
  //       // setLoading(true);
  //       const response = await axios.post(
  //         BaseApi + '/admin/manage',
  //         {
  //           idList: ids,
  //           action: 'deactivate',
  //         }, // Pass null as the request body if not required
  //         {
  //           headers: {
  //             'Content-Type': 'application/json',
  //             key: ApiKey,
  //             token: tokenKey,
  //             adminid: adminID,
  //           },
  //         },
  //       );
  //       // setLoading(false);
  //       if (response.data.status === 200) {
  //         Swal.fire({
  //           title: 'Sub-Admins Deactivated successfully!',
  //           icon: 'success',
  //           confirmButtonText: 'Close',
  //         });
  //       } else {
  //         Swal.fire({
  //           title: 'Couldn\'t Deactivate!',
  //           icon: 'error',
  //           confirmButtonText: 'Close',
  //         });
  //       }
  //       getData();
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     Swal.fire({
  //       title: 'Failed. Please try after some time!',
  //       text: 'Could not Deactivate Sub-Admins',
  //       icon: 'error',
  //       confirmButtonText: 'Close',
  //     });
  //     console.error('Couldn\'t deactivate the record!', error.message);
  //   }
  // };
  // const handleMultipleActivate = async () => {
  //   try {
  //     const confirmationResult = await Swal.fire({
  //       title: 'Activate Sub-Admins',
  //       text: 'Do you want to Activate Sub-Admins?',
  //       icon: 'question',
  //       showCancelButton: true,
  //       confirmButtonText: 'Yes',
  //       cancelButtonText: 'No',
  //     });
  //     if (confirmationResult.isConfirmed) {
  //       const ids = checkedData.toString();
  //       // console.log(ids);
  //       // setLoading(true);
  //       const response = await axios.post(
  //         BaseApi + '/admin/manage',
  //         {
  //           idList: ids,
  //           action: 'activate',
  //         }, // Pass null as the request body if not required
  //         {
  //           headers: {
  //             'Content-Type': 'application/json',
  //             key: ApiKey,
  //             token: tokenKey,
  //             adminid: adminID,
  //           },
  //         },
  //       );
  //       // setLoading(false);
  //       if (response.data.status === 200) {
  //         Swal.fire({
  //           title: 'Sub-Admins Activated successfully!',
  //           icon: 'success',
  //           confirmButtonText: 'Close',
  //         });
  //       } else {
  //         Swal.fire({
  //           title: 'Couldn\'t Activate!',
  //           icon: 'error',
  //           confirmButtonText: 'Close',
  //         });
  //       }
  //       getData();
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     Swal.fire({
  //       title: 'Failed. Please try after some time!',
  //       text: 'Could not Activate Sub-Admins',
  //       icon: 'error',
  //       confirmButtonText: 'Close',
  //     });
  //     console.error('Couldn\'t activate the record!', error.message);
  //   }
  // };
  // const handleMultipleDelete = async () => {
  //   try {
  //     const confirmationResult = await Swal.fire({
  //       title: 'Delete Sub-Admins',
  //       text: 'Do you want to Delete Sub-Admins?',
  //       icon: 'question',
  //       showCancelButton: true,
  //       confirmButtonText: 'Yes',
  //       cancelButtonText: 'No',
  //     });
  //     if (confirmationResult.isConfirmed) {
  //       const ids = checkedData.toString();
  //       // console.log(ids);
  //       // setLoading(true);
  //       const response = await axios.post(
  //         BaseApi + '/admin/manage',
  //         {
  //           idList: ids,
  //           action: 'delete',
  //         }, // Pass null as the request body if not required
  //         {
  //           headers: {
  //             'Content-Type': 'application/json',
  //             key: ApiKey,
  //             token: tokenKey,
  //             adminid: adminID,
  //           },
  //         },
  //       );
  //       // setLoading(false);
  //       if (response.data.status === 200) {
  //         Swal.fire({
  //           title: 'Sub-Admins Delete successfully!',
  //           icon: 'success',
  //           confirmButtonText: 'Close',
  //         });
  //       } else {
  //         Swal.fire({
  //           title: 'Couldn\'t Delete!',
  //           icon: 'error',
  //           confirmButtonText: 'Close',
  //         });
  //       }
  //       getData();
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     Swal.fire({
  //       title: 'Failed. Please try after some time!',
  //       text: 'Could not Delete Sub-Admins',
  //       icon: 'error',
  //       confirmButtonText: 'Close',
  //     });
  //     console.error('Couldn\'t Delete the record!', error.message);
  //   }
  // };

  useEffect(() => {
    if (!tokenKey) {
      navigate('/admin');
    } else {
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]); // eslint-disable-line

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  return (
    <>
      <APNavBar />
      <div className='APBasic APManageSubadmin'>
        <APSidebar />
        {
          loading
            ? (
              <>
                <div className='loader-container'></div>
              </>
            ) : (
              <>
                <div className='site-min-height pb-3'>
                  <div className='breadCumb1' role='presentation'>
                    <Breadcrumbs
                      aria-label='breadcrumb'
                      separator={<NavigateNextIcon fontSize='small' />}
                    >
                      <Link
                        underline='hover'
                        color='inherit'
                        onClick={() => navigate('/admin/admins/dashboard')}
                      >
                        Dashboard
                      </Link>
                      <Typography color='text.primary'>
                        List Transaction History
                      </Typography>
                    </Breadcrumbs>
                  </div>
                  <div className='ManageSubAdminHeader'>
                    <h2 className=''>Transaction List</h2>
                  </div>
                  {
                    listData != ''
                      ? (
                        <>
                          <div className='manageSubadminPart1'>
                            <form>
                              <div className='manageSubadminPart1Sec1'>
                                <h4>
                                  Search Transaction by Company name, Compaby ID,
                                  Full Name, Plan Name or Request Date
                                </h4>
                                <div className='APDashboardSearchBx '>
                                  <input
                                    type='text'
                                    id='form3Example1'
                                    name='searchQuery'
                                    value={searchQuery}
                                    placeholder='Search...'
                                    className='form-control'
                                    onChange={handleSearchChange}
                                  />
                                </div>
                              </div>
                            </form>
                            <div className='row'>
                              <h4 className='col-lg-10'>
                                No. of Results {indexOfFirstItem + 1}-
                                {Math.min(
                                  indexOfLastItem,
                                  filteredData.length || listData.length,
                                )}
                                {' '}
                                of {listData.length}
                              </h4>
                              <div className='col-lg-2'>
                                <button className='btn button1' onClick={handleDownload}>Download List</button>
                              </div>
                            </div>
                          </div>
                          <div className='manageSubadminPart2'>
                            <table className='table'>
                              <thead>
                                <tr>
                                  <th onClick={() => handleColumnClick('company_name')}>
                                    <span>
                                      Company Name{symbol}
                                    </span>
                                  </th>
                                  <th onClick={() => handleColumnClick('company_name')}>
                                    <span>
                                      Company ID{symbol}
                                    </span>
                                  </th>
                                  <th onClick={() => handleColumnClick('full_name')}>
                                    <span>
                                      Full Name{symbol}
                                    </span>
                                  </th>
                                  <th onClick={() => handleColumnClick('plan_name')}>
                                    <span>
                                      Plan Name{symbol}
                                    </span>
                                  </th>
                                  <th onClick={() => handleColumnClick('price')}>
                                    <span>
                                      Price{symbol}
                                    </span>
                                  </th>
                                  <th onClick={() => handleColumnClick('plan_name')}>
                                    <span>
                                      Activation{symbol}
                                    </span>
                                  </th>
                                  <th onClick={() => handleColumnClick('created')}>
                                    <span>
                                      Request Date{symbol}
                                    </span>
                                  </th>
                                  <th onClick={() => handleColumnClick('plan_name')}>
                                    <span>
                                      Activation Date{symbol}
                                    </span>
                                  </th>
                                  <th onClick={() => handleColumnClick('plan_name')}>
                                    <span>
                                      Expire Date{symbol}
                                    </span>
                                  </th>
                                  {/* <th onClick={() => handleColumnClick('transaction_id')}>
                                    Transaction#{symbol}
                                  </th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  currentItems.map((i, index) => (
                                    <tr key={index}>
                                      <td>{i.company_name}</td>
                                      <td>{i.employer_id}</td>
                                      <td>{i.full_name}</td>
                                      <td>
                                        <button
                                          onClick={() => handleOpen(i)}
                                          className='APTransactionListButton'
                                        >
                                          {i.plan_name}
                                        </button>
                                      </td>
                                      <td>{i.price ? i.price : '0'}</td>
                                      <td className='APActionButton'>
                                        {
                                          i.status === true
                                            ? (
                                              <button
                                                className='btn-secondary activated'
                                                onClick={() => handleDeactivate(i.id)}
                                              >
                                                <Tooltip title='Deactivate'>
                                                  <CheckIcon />
                                                </Tooltip>
                                              </button>
                                            ) : (
                                              <button
                                                className='btn-secondary'
                                                onClick={() => handleActivate(i.id)}
                                              >
                                                <Tooltip title='Activate'>
                                                  <BlockIcon />
                                                </Tooltip>
                                              </button>
                                            )
                                        }
                                      </td>
                                      <td>{i.created}</td>
                                      <td>{i.start_date}</td>
                                      <td>{i.end_date}</td>
                                      {/* <td>{i.transaction_id}</td> */}
                                    </tr>
                                  ))
                                }
                                <div>
                                  <Modal
                                    open={open}
                                    className='modalMain'
                                    onClose={handleClose}
                                    aria-labelledby='modal-modal-title'
                                    aria-describedby='modal-modal-description'
                                  >
                                    <Box className='modal adminModal modal-content'>
                                      <IconButton
                                        onClick={handleClose}
                                        className='close-button'
                                        sx={{
                                          top: 10,
                                          right: 10,
                                          position: 'absolute',
                                        }}
                                      >
                                      &times;
                                      </IconButton>
                                      <Typography
                                        id='modal-modal-title'
                                        variant='h6'
                                        component='h2'
                                      ></Typography>
                                      <Typography
                                        id='modal-modal-description'
                                        sx={{ mt: 3 }}
                                      >
                                        {
                                          selectedPayment && (
                                            <div className='modals'>
                                              <div className='modalHead'>
                                                <h4>
                                                  {' '}
                                                  Transaction ID -
                                                  {' '}
                                                  {selectedPayment.transaction_id}
                                                </h4>
                                              </div>
                                              <div className='modalBody mt-4'>
                                                <div className='modalParent'>
                                                  <div className='modalLeft'>
                                                    Plan Name:{' '}
                                                  </div>
                                                  <div className='modalRight'>
                                                    {selectedPayment.plan_name}
                                                  </div>
                                                </div>
                                                <div className='modalParent'>
                                                  <div className='modalLeft'>
                                                    Amount:{' '}
                                                  </div>
                                                  <div className='modalRight'>
                                                    {selectedPayment.price}
                                                  </div>
                                                </div>
                                                <div className='modalParent'>
                                                  <div className='modalLeft'>
                                                    Transaction ID:{' '}
                                                  </div>
                                                  <div className='modalRight'>
                                                    {selectedPayment.transaction_id}
                                                  </div>
                                                </div>
                                                <div className='modalParent'>
                                                  <div className='modalLeft'>
                                                    Start Date:{' '}
                                                  </div>
                                                  <div className='modalRight'>
                                                    {selectedPayment.start_date}
                                                  </div>
                                                </div>
                                                <div className='modalParent'>
                                                  <div className='modalLeft'>
                                                    End Date:{' '}
                                                  </div>
                                                  <div className='modalRight'>
                                                    {selectedPayment.end_date}
                                                  </div>
                                                </div>
                                                <div className='modalParent'>
                                                  <div className='modalLeft'>
                                                  Features:{' '}
                                                  </div>
                                                  <div className='modalRight'>
                                                    {
                                                      Object.values(selectedPayment.features).map((value, index, key) => ( // eslint-disable-line
                                                        <div key={index}>
                                                          {index + 1}. {value}
                                                          <br />
                                                        </div>
                                                      ))
                                                    }
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        }
                                      </Typography>
                                    </Box>
                                  </Modal>
                                </div>
                              </tbody>
                            </table>
                          </div>
                          <div className='manageSubadminpart3'>
                            <div className='pagination'>
                              <Stack spacing={2}>
                                <Pagination
                                  color='primary'
                                  count={totalPages}
                                  page={currentPage}
                                  onChange={(event, page) => setCurrentPage(page)}
                                />
                              </Stack>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className='appliedJobListNoData mt-5'>
                          No Records Found
                        </div>
                      )
                  }
                </div>
              </>
            )
        }
      </div>
    </>
  );
};

export default APTransactionList;
