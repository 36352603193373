import axios from 'axios';
import Cookies from 'js-cookie';
// api
import ApiKey from './Components/api/ApiKey';
import BaseApi from './Components/api/BaseApi';

const token = Cookies.get('tokenClient');
const key = ApiKey;

const axiosInstance = axios.create({
  baseURL: BaseApi,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  config => {
    if (token) {
      config.headers.key = key;
      config.headers.token = token;
    }
    return config;
  },
  error => Promise.reject(error),
);

export default axiosInstance;
