import Swal from 'sweetalert2';
import Cookies from 'js-cookie';

export const resultInfo = (icon, title, confirmButtonText) => {
  Swal.fire({
    icon: icon,
    title: title,
    confirmButtonText: confirmButtonText,
  });
};

export const logOutIfTokenExpired = (title, confirmButtonText) => {
  Cookies.remove('fname');
  Cookies.remove('user_id');
  Cookies.remove('user_type');
  Cookies.remove('tokenClient');

  Swal.fire({
    title: title,
    icon: 'warning',
    confirmButtonText: confirmButtonText,
  }).then(result => {
    if (result.isConfirmed) {
      window.location.reload();
    }
  });
};
