import axios from 'axios';
import { useTranslation } from 'react-i18next';
import HTMLReactParser from 'html-react-parser';
import { Link, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
// api
import BaseApi from '../api/BaseApi';
// components
import Footer from '../element/Footer';
import NavBar from '../element/NavBar';

const DynamicBlogPage = () => {
  const { slug } = useParams();
  const { t } = useTranslation('global');

  const [loading, setLoading] = useState(false);
  const [dynamicBlogData, setdynamicBlogData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);

        const metaTitle = document.querySelector('title');
        const metaDescription = document.querySelector('meta[name="description"]');

        const response = await axios.get(BaseApi + `/blog/${slug}`);

        if (metaTitle) {
          metaTitle.textContent = response.data.response.Blog.meta_title;
        }

        if (metaDescription) {
          metaDescription.content = response.data.response.Blog.meta_description;
        }

        setLoading(false);
        setdynamicBlogData(response.data.response.Blog);
      } catch (error) {
        setLoading(false);

        console.error('Error getting blog data', error);
      }
    };

    getData();
  }, [slug]);

  return (
    <>
      <NavBar />
      {
        loading
          ? (
            <div className='loader-container'></div>
          ) : (
            <>
              <div className='dynamicBlogPage'>
                <div className='DBPSection1 text-center'>
                  <h1>
                    {
                      dynamicBlogData.title
                        ? HTMLReactParser(dynamicBlogData.title)
                        : ''
                    }
                  </h1>
                  <h6 className='text-muted fw-normal'>
                    <Link to='/' style={{ color: 'grey' }}>
                      {t('navHeaders.home')}
                    </Link>
                    {' / '}
                    <Link to='/blog' style={{ color: 'grey' }}>
                      {t('blogPage.blog')}
                    </Link>
                    {' / '}
                    {dynamicBlogData.title}
                  </h6>
                </div>
                <div className='container DBPSection2'>
                  <div className=''>
                    <div className='text-center'>
                      {
                        dynamicBlogData.image
                          ? (
                            <img
                              className=''
                              src={dynamicBlogData.image}
                              alt='This is a relevant visual of post'
                            />
                          ) : (
                            <img
                              className=''
                              alt='This is a relevant visual of post'
                              src='https://mdbootstrap.com/img/Mockups/Lightbox/Thumbnail/img%20(67).webp'
                            />
                          )
                      }
                    </div>
                    <div className='text-left pb-0'>
                      <h5 className='blue-text pb-2'>
                        <p>
                          {t('blogPage.posted')}: {dynamicBlogData.created}
                        </p>
                      </h5>
                      <hr />
                      <p className='text-justify card-text standartListStyle'>
                        {
                          dynamicBlogData.description
                            ? HTMLReactParser(dynamicBlogData.description)
                            : ''
                        }
                      </p>
                      <div className='text-muted text-center mt-4'></div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </>
          )
      }
    </>
  );
};

export default DynamicBlogPage;
