import axios from 'axios';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import HTMLReactParser from 'html-react-parser';
import { Link, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
// api
import ApiKey from '../api/ApiKey';
import BaseApi from '../api/BaseApi';
// components
import Footer from '../element/Footer';
import NavBar from '../element/NavBar';

const CompanyProfile = () => {
  const { slug } = useParams();
  const { t } = useTranslation('global');
  const tokenKey = Cookies.get('tokenClient');

  const [loading, setLoading] = useState(false);
  const [companyJobs, setCompanyJobs] = useState([]);
  const [companyDetails, setCompanyDetails] = useState([]);

  const getCurrentMonthYear = () => {
    const months = [
      'січень', 'лютий', 'березень', 'квітень', 'травень', 'червень',
      'липень', 'серпень', 'вересень', 'жовтень', 'листопад', 'грудень',
    ];

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = months[currentDate.getMonth()];

    return `${month} ${year}`;
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);

        const response = await axios.post(
          BaseApi + `/candidates/companyprofile/${slug}`,
          null,
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              'Content-Type': 'application/json',
            },
          },
        );

        const { jobDetails, userDetails } = response.data.response;

        const metaTitle = document.querySelector('title');
        const metaDescription = document.querySelector('meta[name="description"]');
        const metaTitleValue = `${userDetails.company_name} з відкритими ${jobDetails.length} вакансіями станом на ${getCurrentMonthYear()} | GRC.ua`; // eslint-disable-line
        const metaDescriptionValue = `${userDetails.company_name} здійснює свою діяльність в місті ${userDetails.address}. Ознайомтеся докладніше на сайті GRC.ua`; // eslint-disable-line

        if (metaTitle) {
          metaTitle.textContent = metaTitleValue;
        }

        if (metaDescription) {
          metaDescription.content = metaDescriptionValue;
        }

        setLoading(false);
        setCompanyJobs(response.data.response.jobDetails);
        setCompanyDetails(response.data.response.userDetails);
      } catch (error) {
        setLoading(false);

        console.error('Error getting company profile', error);
      }
    };

    getData();

    window.scrollTo(0, 0);
  }, [slug, tokenKey, companyJobs.length]);

  return (
    <>
      <NavBar />
      {
        loading
          ? (
            <div className='loader-container'></div>
          ) : (
            <>
              <div className='blogPageSection1 text-center'>
                <h1>{t('companyProfile.companyProfile')}{' '}{companyDetails.company_name}</h1>
                <h6 className='text-muted mt-2'>
                  {' '}
                  <Link to='/' style={{ color: 'grey' }}>
                    {t('navHeaders.home')}
                  </Link>
                  {' / '}
                  {t('companyProfile.companyProfile')}
                </h6>
              </div>
              <div className='blogPageSection2 container'>
                <div className='row'>
                  <div className='row'>
                    <div className='col-md-9 col-lg-9'>
                      <div className='card-body companyProfileCardLeft standartListStyle'>
                        <div className='row'>
                          {
                            companyDetails.company_about
                              ? HTMLReactParser(companyDetails.company_about)
                              : ''
                          }
                        </div>
                        <div className='card-footer companyProfileCardFooter'>
                          <h5 className='companyProfileLocation text-muted'>
                            <i className='fa-sharp fa-solid fa-location-dot' />
                            {' '}
                            {t('companyProfile.location')}:
                            {' '}
                            {companyDetails.address}
                          </h5>
                          <h5 className='companyProfileContact text-muted'>
                            <i className='fa-sharp fa-solid fa-phone' />
                            {' '}
                            {t('companyProfile.contact')}:
                            {' '}
                            <a href={`tel:${companyDetails.company_contact}`}>
                              {companyDetails.company_contact}
                            </a>
                          </h5>
                        </div>
                      </div>
                      {
                        companyJobs.length > 0 && (
                          <>
                            <h2 className='ms-3 mb-3 mt-5'>
                              {t('companyProfile.jobPostedBy')}
                              {' '}
                              {companyDetails.company_name}
                            </h2>
                            {
                              companyJobs.map(i => (
                                <div key={i.id}>
                                  <div className='card-body companyProfileCardLeft'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                      <div>
                                        <h4>{i.title}</h4>
                                        <h6 className='text-muted CPTPart2'>
                                          <i className='fa-sharp fa-solid fa-location-dot' />
                                          {' '}
                                          {i.job_city}
                                        </h6>
                                      </div>
                                      <Link
                                        className='btn btn-primary button1'
                                        to={`/jobdescription/${i.jslug}/${i.cslug}`}
                                      >
                                        {t('companyProfile.detailsButton')}
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              ))
                            }
                          </>
                        )
                      }
                    </div>
                    <div className='col-md-3 col-lg-3'>
                      <div className='card-body companyProfileCardRight'>
                        <div className='row'>
                          <img
                            src={companyDetails.company_logo}
                            className='companyProfileCardRightImage'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </>
          )
      }
    </>
  );
};

export default CompanyProfile;
