import axios from 'axios';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// api
import ApiKey from '../api/ApiKey';
import BaseApi from '../api/BaseApi';
// components
import Sidebar from './Sidebar';
import NavBar from '../element/NavBar';
import Footer from '../element/Footer';
// helpers
import { logOutIfTokenExpired } from '../helpers';

const Documents = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('global');
  const tokenKey = Cookies.get('tokenClient');

  const [loading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState([]);

  const getData = async () => {
    try {
      setLoading(true);

      const response = await axios.post(BaseApi + '/users/myaccount', null, {
        headers: {
          key: ApiKey,
          token: tokenKey,
          'Content-Type': 'application/json',
        },
      });

      setLoading(false);

      if(response.data.status === 200) {
        setProfileData(response.data.response);
      }
    } catch (error) {
      setLoading(false);

      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      }

      console.error('Cannot get profile data!', error);
    }
  };

  useEffect(() => {
    if (!tokenKey) {
      navigate('/user/employerlogin');
    } else {
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]); //eslint-disable-line

  return (
    <>
      <NavBar />
      <div className='container myProfile'>
        <div className='row'>
          <div className='col-lg-3'>
            <Sidebar />
          </div>
          {
            loading
              ? (
                <div className='loader-container'></div>
              ) : (
                <>
                  <div
                    className='col-lg-9 mb-5'
                    style={{
                      borderLeft: '2px solid #e6e8e7',
                      borderRight: '2px solid #e6e8e7',
                    }}
                  >
                    <div className='d-flex mx-3'>
                      <img src='/Images/employerSide/documents-32-color.png' alt='' />
                      <h3 className='mx-2'>{t('employerDocuments.documents')}</h3>
                    </div>
                    <div className='myProfileEmployerBody mx-3'>
                      <div className='col'>
                        <div className='card cardOne'>
                          <div className='card-title'>
                            <h4>{t('employerDocuments.offerAgreement')}</h4>
                          </div>
                          <div className='nameSection'>
                            <Link to='/offer-agreement' target='_blank'>
                              <i className='fas fa-external-link-alt me-2' />
                              {t('employerDocuments.offerAgreement')}
                            </Link>
                            <a href='/Оферта.docx' download>
                              <i className='fa-solid fa-download me-2' />
                              {t('employerDocuments.offerAgreement')}
                            </a>
                            <p className='text-muted'>
                              {t('employerDocuments.signingDate')}:
                              {' '}
                              {profileData.created}
                            </p>
                          </div>
                        </div>
                        <div className='card cardTwo'>
                          <div className='card-title'>
                            <h4>{t('employerDocuments.registrationDocuments')}</h4>
                          </div>
                          <div className='nameSection'>
                            <a href='/Довідка_про_відкриття_рахунку_ОТП_банк.pdf' download>
                              <i className='fa-solid fa-download me-2' />
                              {t('employerDocuments.certificate')}
                            </a>
                            <a href='/Виписка_12.04.2024.pdf' download>
                              <i className='fa-solid fa-download me-2' />
                              {t('employerDocuments.extract')}
                            </a>
                            <a href='/Витяг_з_реєстру_платників_єдиного_податку_з_01.01.2024р.pdf' download>
                              <i className='fa-solid fa-download me-2' />
                              {t('employerDocuments.taxExtract')}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )
          }
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Documents;
