import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// api
import ApiKey from '../api/ApiKey';
import BaseApi from '../api/BaseApi';
// components
import Sidebar from './Sidebar';
import Footer from '../element/Footer';
import NavBar from '../element/NavBar';
// helpers
import { logOutIfTokenExpired } from '../helpers';
// mui
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { IconButton, Typography } from '@mui/material';

const PaymentHistory = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('global');
  const tokenKey = Cookies.get('tokenClient');
  const secondaryColor = Cookies.get('secondaryColor');

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  // const [DownloadURL, setDownloadURL] = useState('');
  // const [downloadActive, setDownloadActive] = useState();
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState(null);

  const paymentDataPerPage = 10;

  const indexOfLastPayment = currentPage * paymentDataPerPage;
  const indexOfFirstPayment = indexOfLastPayment - paymentDataPerPage;
  const currentData = paymentHistory
    ? paymentHistory.slice(indexOfFirstPayment, indexOfLastPayment)
    : paymentHistory.slice(indexOfFirstPayment, indexOfLastPayment);

  const handlePageChange = pageNumber => setCurrentPage(pageNumber);

  const handleOpen = plan => {
    setSelectedPayment(plan);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedPayment(null);
    setOpen(false);
  };

  const style = {
    p: 4,
    top: '50%',
    left: '50%',
    boxShadow: 24,
    position: 'absolute',
    border: '2px solid #000',
    bgcolor: 'background.paper',
    width: { xs: 350, md: 500 },
    transform: 'translate(-50%, -50%)',
  };

  const getData = async () => {
    try {
      setLoading(true);

      const response = await axios.post(
        BaseApi + '/payments/history',
        null,
        {
          headers: {
            key: ApiKey,
            token: tokenKey,
            'Content-Type': 'application/json',
          },
        },
      );

      setLoading(false);

      if (response.data.status === 200) {
        setPaymentHistory(response.data.response);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message,
          confirmButtonText: t('membershipPlan.close'),
          title: t('employerFavouriteListProfile.addFavFailedTitle'),
        });
      }
    } catch (error) {
      setLoading(false);

      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      }

      console.error('Cannot get payment data', error.message);
    }
  };

  // const generateDownload = async id => {
  //   try {
  //     setLoading(true);

  //     const response = await axios.post(
  //       BaseApi + `/users/generateinvoice/${id}`,
  //       null,
  //       {
  //         headers: {
  //           key: ApiKey,
  //           token: tokenKey,
  //           'Content-Type': 'application/json',
  //         },
  //       },
  //     );

  //     setLoading(false);

  //     if (response.data.status === 200) {
  //       setDownloadActive(id);
  //       setDownloadURL(response.data.response.invoice);
  //     } else {
  //       Swal.fire({
  //         icon: 'error',
  //         text: response.data.message,
  //         confirmButtonText: t('membershipPlan.close'),
  //         title: t('employerFavouriteListProfile.addFavFailedTitle'),
  //       });
  //     }
  //   } catch (error) {
  //     setLoading(false);

  //     if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
  //   navigate('/');

  //   logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
  // }

  //     console.error('Cannot get CV data pdf format', error.message);
  //   }
  // };

  // useEffect(() => {
  //   if (downloadActive && DownloadURL) {
  //     // Create a hidden link element
  //     const link = document.createElement('a');
  //     link.style.display = 'none';
  //     link.href = DownloadURL;
  //     link.download = 'generated-cv.doc';
  //     document.body.appendChild(link);

  //     // Trigger a click on the link
  //     link.click();

  //     // Clean up
  //     document.body.removeChild(link);
  //     setDownloadURL('');
  //     setDownloadActive(null);
  //   }
  // }, [downloadActive, DownloadURL]);

  useEffect(() => {
    if (!tokenKey) {
      navigate('/user/employerlogin');
    } else {
      getData();

      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]); // eslint-disable-line

  return (
    <>
      <NavBar />
      <div className='container paymentHistory'>
        <div className='row'>
          <div className='col-lg-3 col-md-3'>
            <Sidebar />
          </div>
          {
            loading
              ? (
                <div className='loader-container'></div>
              ) : (
                <>
                  <div
                    className='col-lg-9 col-md-9 mb-5'
                    style={{
                      borderLeft: '2px solid #e6e8e7',
                      borderRight: '2px solid #e6e8e7',
                    }}
                  >
                    <div className='PHHeader PageHeader'>
                      <div className='d-flex'>
                        <img src='/Images/employerSide/icon3color.png' alt='' />
                        <h3 className='mx-2'>{t('employerPaymentHistory.paymentHistory')}</h3>
                      </div>
                      <div className='PHBody mt-5'>
                        <table className='table'>
                          <thead>
                            <tr className='table-active TrFirst'>
                              <th className='' scope='col p-3'>
                                {t('employerPaymentHistory.no')}
                              </th>
                              <th className='' scope='col p-3'>
                                {t('employerPaymentHistory.planName')}
                              </th>
                              <th className='' scope='col p-3'>
                                {t('employerPaymentHistory.amount')}
                              </th>
                              <th className='' scope='col p-3'>
                                {t('employerPaymentHistory.status')}
                              </th>
                              <th className='' scope='col p-3'>
                                {t('employerPaymentHistory.startDate')}
                              </th>
                              <th className='' scope='col p-3'>
                                {t('employerPaymentHistory.endDate')}
                              </th>
                              <th className='' scope='col p-3'>
                                {t('employerPaymentHistory.availableServices')}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              currentData.map((i, index) => (
                                <>
                                  <tr className='TrDefoult' key={index}>
                                    <td className=''>{index + 1}</td>
                                    <td className=''>
                                      <button
                                        type='button'
                                        onClick={() => handleOpen(i)}
                                        className={`btn paymentButton ${i.status === 0 && 'deactivated'}`}
                                      >
                                        {i.plan_name}
                                      </button>
                                    </td>
                                    <td className=''>{i.amount}</td>
                                    <td className=''>
                                      {i.status === 0
                                        ? t('employerPaymentHistory.deactivated')
                                        : t('employerPaymentHistory.activated')
                                      }
                                    </td>
                                    <td className=''>{i.start_date}</td>
                                    <td className=''>{i.end_date}</td>
                                    <td className='TrActions'>
                                      <Link
                                        onClick={() => handleOpen(i)}
                                        className='btn btn-primary button1'
                                      >
                                        <i className='fa fa-eye'></i>
                                      </Link>
                                      {/* <Link
                                        onClick={() => generateDownload(i.id)}
                                        className='btn btn-secondary'
                                        style={{
                                          backgroundColor: secondaryColor,
                                          color: 'white',
                                          border: secondaryColor,
                                        }}
                                      >
                                        <i className='fa fa-download'></i>
                                      </Link> */}
                                    </td>
                                  </tr>
                                </>
                              ))
                            }
                            <div>
                              <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby='modal-modal-title'
                                aria-describedby='modal-modal-description'
                              >
                                <Box sx={{ ...style }}>
                                  <IconButton
                                    onClick={handleClose}
                                    className='close-button'
                                    style={{
                                      top: 10,
                                      right: 10,
                                      position: 'absolute',
                                    }}
                                  >
                                    &times;
                                  </IconButton>
                                  <Typography
                                    id='modal-modal-title'
                                    variant='h6'
                                    component='h2'
                                  ></Typography>
                                  <Box sx={{ mt: 6 }} id='modal-modal-description'>
                                    {
                                      selectedPayment && (
                                        <div className='modals '>
                                          <div className='modalHead'>
                                            <h1 style={{ color: secondaryColor }}>
                                              {selectedPayment.transaction_id}
                                            </h1>
                                          </div>
                                          <div className='modalBody mt-4'>
                                            <div className='row'>
                                              <div className='col-md-4 fw-bold m-2'>
                                                {t('employerPaymentHistory.planName')}:{' '}
                                              </div>
                                              <div className='col-md-6 m-2'>
                                                {selectedPayment.plan_name}
                                              </div>
                                            </div>
                                            <div className='row'>
                                              <div className='col-md-4 fw-bold m-2'>
                                                {t('employerPaymentHistory.amount')}:{' '}
                                              </div>
                                              <div className='col-md-4 m-2'>
                                                {selectedPayment.amount}
                                              </div>
                                            </div>
                                            <div className='row'>
                                              <div className='col-md-4 fw-bold m-2'>
                                                {t('employerPaymentHistory.transactionId')}:{' '}
                                              </div>
                                              <div className='col-md-4 m-2'>
                                                {selectedPayment.transaction_id}
                                              </div>
                                            </div>
                                            <div className='row'>
                                              <div className='col-md-4 fw-bold m-2'>
                                                {t('employerPaymentHistory.startDate')}:{' '}
                                              </div>
                                              <div className='col-md-4 m-2'>
                                                {selectedPayment.start_date}
                                              </div>
                                            </div>
                                            <div className='row'>
                                              <div className='col-md-4 fw-bold m-2'>
                                                {t('employerPaymentHistory.endDate')}:{' '}
                                              </div>
                                              <div className='col-md-4 m-2'>
                                                {selectedPayment.end_date}
                                              </div>
                                            </div>
                                            <div className='col-md-12 fw-bold m-2'>
                                              {t('employerPaymentHistory.availableForUse')}:{' '}
                                            </div>
                                            <div className='col-md-12 m-2'>
                                              {
                                                Object.values(selectedPayment.features).map((value, index) => (
                                                  <div key={index}>
                                                    {index + 1}. {value}
                                                    <br />
                                                  </div>
                                                ))
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    }
                                  </Box>
                                </Box>
                              </Modal>
                            </div>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className='paymentHistoryPagination'>
                      <p className='text-muted empPaginationData'>
                        {t('pagination.NoofRecords')}{' '}
                        {paymentHistory.length > 0 ? indexOfFirstPayment + 1 : indexOfFirstPayment}-
                        {Math.min(indexOfLastPayment, paymentHistory.length)}{' of '}
                        {paymentHistory
                          ? paymentHistory.length
                          : paymentHistory.length}
                      </p>
                      <div className='d-flex justify-content-center empPaginationButton'>
                        <button
                          className='navButton1 me-2'
                          disabled={currentPage === 1}
                          onClick={() => handlePageChange(currentPage - 1)}
                        >
                          {t('pagination.Prev')}
                        </button>
                        <button
                          className='navButton1'
                          onClick={() => handlePageChange(currentPage + 1)}
                          disabled={
                            paymentHistory
                              ? indexOfLastPayment >= paymentHistory.length
                              : indexOfLastPayment >= paymentHistory.length
                          }
                        >
                          {t('pagination.Next')}
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )
          }
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PaymentHistory;
