/* eslint-disable unused-imports/no-unused-vars */
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import HTMLReactParser from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  EmailShareButton,
  TwitterShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  PinterestShareButton,
  InstapaperShareButton,
} from 'react-share';
import {
  EmailIcon,
  TwitterIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  WhatsappIcon,
  PinterestIcon,
  InstapaperIcon,
} from 'react-share';
// api
import ApiKey from '../api/ApiKey';
import BaseApi from '../api/BaseApi';
// components
import Sidebar from './Sidebar';
import Footer from '../element/Footer';
import NavBar from '../element/NavBar';
// helpers
import { logOutIfTokenExpired, resultInfo } from '../helpers';
// mui
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Tooltip from '@mui/material/Tooltip';
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import { IconButton, Button, Typography } from '@mui/material';

const InnerAccountdetail = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const curr = Cookies.get('curr');
  const { t } = useTranslation('global');
  const tokenKey = Cookies.get('tokenClient');
  const primaryColor = Cookies.get('primaryColor');
  const secondaryColor = Cookies.get('secondaryColor');

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accDetail, setAccDetail] = useState([]);
  const [jobDetail, setJobDetail] = useState([]);
  const [sendMail, setSendMail] = useState(false);
  const [userDetail, setUserDetail] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [activeOptions, setActiveOptions] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState([]); // Track the selected payment
  const [hoverSearchColor, setHoverSearchColor] = useState(false);
  const [hoverUploadCVColor, setHoverUploadCVColor] = useState(false);
  const [mailReplyError, setMailReplyError] = useState({
    subject: '',
    message: '',
  });
  const [mailUsers, setMailUsers] = useState({
    // email_address: [],
    job_id: '',
    subject: '',
    message: '',
  });

  const getData = async () => {
    try {
      setLoading(true);

      const response = await axios.post(
        BaseApi + `/job/accdetail/${slug}`,
        null,
        {
          headers: {
            key: ApiKey,
            token: tokenKey,
            'Content-Type': 'application/json',
          },
        },
      );

      if (response.data.status === 200) {
        setLoading(false);
        setAccDetail(response.data.response);
        setJobDetail(response.data.response.jobInfo);
        setCandidates(response.data.response.candidates);
        setUserDetail(response.data.response.userDetails);
        setActiveOptions(response.data.response.active_option);
      } else if (response.data.status === 400) {
        navigate('/');

        Cookies.remove('fname');
        Cookies.remove('user_id');
        Cookies.remove('user_type');
        Cookies.remove('tokenClient');

        resultInfo('warning', response.data.message, t('employerFavouriteListProfile.close'));
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message,
          confirmButtonText: t('employerFavouriteListProfile.close'),
          title: t('employerFavouriteListProfile.addFavFailedTitle'),
        });
      }
    } catch (error) {
      setLoading(false);

      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      }

      console.error('Could not get user data in inner account detail page', error);
    }
  };

  // ... your existing code ...

  const handleCheckboxChange = email => {
    // Check if the email is already in the array
    if (selectedEmails.includes(email)) {
      // If it exists, remove it from the array
      setSelectedEmails(prevSelectedEmails =>
        prevSelectedEmails.filter(e => e !== email),
      );
    } else {
      // If it doesn't exist, add it to the array
      setSelectedEmails(prevSelectedEmails => [...prevSelectedEmails, email]);
    }

    setMailUsers({ ...mailUsers, job_id: jobDetail.job_id });
  };

  const handleGlobalCheckboxChange = () => {
    // Get all email addresses from the candidates
    const allEmails = candidates.map(i => i.email_address);

    // If all emails are already selected, unselect all; otherwise, select all
    if (selectedEmails.length === allEmails.length) {
      setSelectedEmails([]);
    } else {
      setSelectedEmails(allEmails);
    }

    setMailUsers({ ...mailUsers, job_id: jobDetail.job_id });
  };

  const handleMailReplyChange = e => {
    const { name, value } = e.target;
    setMailUsers(prev => ({
      ...prev,
      [name]: value,
    }));
    setMailReplyError(prev => ({
      ...prev,
      [name]: '',
    }));
  };

  const handleMailReply = async slug => {
    try {
      const newErrors = {};

      if (mailUsers.subject === '') {
        newErrors.subject = t('employerInnerAccountdetail.sujectRequired');
      }

      if (mailUsers.message === '') {
        newErrors.message = t('employerInnerAccountdetail.messageRequired');
      }

      setMailReplyError(newErrors);

      if (Object.keys(newErrors).length === 0) {
        const confirmationResult = await Swal.fire({
          icon: 'question',
          showCancelButton: true,
          cancelButtonText: t('employerInnerAccountdetail.no'),
          confirmButtonText: t('employerInnerAccountdetail.yes'),
          text: t('employerInnerAccountdetail.sendMailConfirmTxt'),
          title: t('employerInnerAccountdetail.sendMailConfirmTitle'),
        });

        if (confirmationResult.isConfirmed) {
          setLoading(true);

          const response = await axios.post(
            BaseApi + '/candidates/sendmailToalljobseekers',
            mailUsers,
            {
              headers: {
                key: ApiKey,
                token: tokenKey,
                'Content-Type': 'application/json',
              },
            },
          );

          if (response.data.status === 200) {
            setLoading(false);

            resultInfo('success', t('employerInnerAccountdetail.sendMailSuccessTitle'), t('employerInnerAccountdetail.close')); // eslint-disable-line

            setSendMail(false);
            setSelectedEmails('');
            setMailUsers({
              ...mailUsers,
              message: '',
              subject: '',
              email_address: [],
            });
          } else if (response.data.status === 400) {
            navigate('/');

            Cookies.remove('fname');
            Cookies.remove('user_id');
            Cookies.remove('user_type');
            Cookies.remove('tokenClient');

            resultInfo('warning', response.data.message, t('employerInnerAccountdetail.close'));
          } else {
            Swal.fire({
              icon: 'error',
              text: response.data.message,
              confirmButtonText: t('employerInnerAccountdetail.close'),
              title: t('employerFavouriteListProfile.addFavFailedTitle'),
            });
          }

          getData();
        }
      }
    } catch (error) {
      setLoading(false);

      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      } else {
        resultInfo('error', t('employerInnerAccountdetail.sendMailFailedTitle'), t('employerInnerAccountdetail.close'));
      }

      console.error('Could not change password!', error);
    }
  };

  const handleSendButton = () => {
    if (selectedEmails.length === 0) {
      resultInfo('warning', t('employerInnerAccountdetail.selectMailEmptyTitle'), t('employerInnerAccountdetail.close')); // eslint-disable-line
    } else {
      setSendMail(true);
      setMailUsers({ ...mailUsers, email_address: selectedEmails.join(',') });
    }
  };

  const handleCancelClick = () => {
    setSendMail(false);
    setSelectedEmails('');
  };

  const handleOpen = () => {
    // console.log("Clicked payment:", plan); // Add this line
    // setSelectedPayment(plan);
    setOpen(true);
  };

  const handleClose = () => {
    // setSelectedPayment(null);
    setOpen(false);
  };

  const style = {
    p: 4,
    top: '50%',
    left: '50%',
    width: '90%', // Adjusted width for mobile responsiveness
    boxShadow: 24,
    maxWidth: '400px',
    position: 'absolute',
    border: '2px solid #000',
    bgcolor: 'background.paper',
    transform: 'translate(-50%, -50%)',
  };
  const [openCoverModal, setOpenCoverModal] = useState(false);

  const handleOpenCoverModal = plan => {
    // console.log('Clicked payment:', plan); // Add this line
    setSelectedPayment(plan);

    // console.log(selectedPayment, 'cover letter details');
    setOpenCoverModal(true);
  };

  const handleCloseCoverModal = () => {
    // setSelectedPayment();
    setOpenCoverModal(false);
  };

  const handleSearchMouseEnter = () => {
    setHoverSearchColor(true);
  };

  const handleSearchMouseLeave = () => {
    setHoverSearchColor(false);
  };

  const handleUploadCVMouseEnter = () => {
    setHoverUploadCVColor(true);
  };

  const handleUploadCVMouseLeave = () => {
    setHoverUploadCVColor(false);
  };

  const handleStatusChange = async (id, value, userId) => {
    try {
      setLoading(true);

      await axios.post(
        BaseApi + `/job/accdetail/${slug}`,
        { candidate_id: id, status_change: value, user_id: userId },
        {
          headers: {
            key: ApiKey,
            token: tokenKey,
            'Content-Type': 'application/json',
          },
        },
      );

      getData();
      setLoading(false);
      // console.log('Status changed of inner account details page of employer side');
    } catch (error) {
      setLoading(false);

      console.error('Could not change status of user in inner account detail page.', error);
    }
  };

  const handleDelete = async slug => {
    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: t('employerInnerAccountdetail.no'),
        confirmButtonText: t('employerInnerAccountdetail.yes'),
        text: t('employerInnerAccountdetail.deleteJobConfirmTxt'),
        title: t('employerInnerAccountdetail.deleteJobConfirmTitle'),
      });

      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/job/delete/${slug}`,
          null,
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              'Content-Type': 'application/json',
            },
          },
        );

        if (response.data.status === 200) {
          getData();

          navigate('/user/managejob');

          resultInfo('success', t('employerInnerAccountdetail.deleteJobSuccessTitle'), t('employerInnerAccountdetail.close')); // eslint-disable-line
        } else if (response.data.status === 400) {
          navigate('/');

          Cookies.remove('fname');
          Cookies.remove('user_id');
          Cookies.remove('user_type');
          Cookies.remove('tokenClient');

          resultInfo('warning', response.data.message, t('employerInnerAccountdetail.close'));
        } else {
          Swal.fire({
            icon: 'error',
            text: response.data.message,
            confirmButtonText: t('employerInnerAccountdetail.close'),
            title: t('employerFavouriteListProfile.addFavFailedTitle'),
          });
        }
      }
    } catch (error) {
      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      } else {
        resultInfo('error', t('employerInnerAccountdetail.deleteJobFailedTitle'), t('employerInnerAccountdetail.close')); // eslint-disable-line
      }

      console.error('Could not delete the user in inner account detail page', error);
    }
  };

  useEffect(() => {
    // Check if tokenKey is not present
    if (!tokenKey) {
      // Redirect to the home page
      navigate('/user/employerlogin');
    } else {
      // TokenKey is present, fetch data or perform other actions
      getData();
      // window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateRating = async (val, id) => {
    try {
      const response = await axios.post(
        BaseApi + `/job/updateRating/${id}/${val}`,
        null,
        {
          headers: {
            key: ApiKey,
            token: tokenKey,
            'Content-Type': 'application/json',
          },
        },
      );
      // console.log('user rating updated successfully');
    } catch (error) {
      console.error('Could not change user rating.');
    }
  };

  const handleStarClick = (event, val, id) => {
    var node = event.target;
    var allStarElement = node.parentNode.childNodes;

    allStarElement.forEach((element, index) => {
      if (index <= val) {
        $(element).removeClass('fa-regular');
        $(element).addClass('fa-solid');
        $(element).css('color', '#dde01f');
      } else {
        $(element).removeClass('fa-solid');
        $(element).addClass('fa-regular');
        $(element).css('color', '');
      }
    });

    updateRating(val + 1, id);
  };

  const renderStars = (rating, id) => {
    const stars = [];

    for (let j = 0; j < 5; j++) {
      if (j < rating) {
        const col = {
          color: '#dde01f',
        };
        stars.push(
          <i
            style={col}
            className='fa-solid fa-star'
            onClick={event => handleStarClick(event, j, id)}
          />,
        );
      } else {
        stars.push(
          <i
            className='fa-regular fa-star'
            onClick={event => handleStarClick(event, j, id)}
          />,
        );
      }
    }

    return stars;
  };

  const handleActivate = async slug => {
    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: t('employerInnerAccountdetail.no'),
        confirmButtonText: t('employerInnerAccountdetail.yes'),
        text: t('employerInnerAccountdetail.activateJobConfirmTxt'),
        title: t('employerInnerAccountdetail.activateJobConfirmTitle'),
      });

      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/job/activate/${slug}`,
          null,
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              'Content-Type': 'application/json',
            },
          },
        );

        getData();

        if (response.data.status === 200) {
          resultInfo('success', t('employerInnerAccountdetail.activateJobSuccessTitle'), t('employerInnerAccountdetail.close')); // eslint-disable-line
        } else if (response.data.status === 400) {
          navigate('/');

          Cookies.remove('fname');
          Cookies.remove('user_id');
          Cookies.remove('user_type');
          Cookies.remove('tokenClient');

          resultInfo('warning', response.data.message, t('employerInnerAccountdetail.close'));
        } else {
          Swal.fire({
            icon: 'error',
            text: response.data.message,
            confirmButtonText: t('employerInnerAccountdetail.close'),
            title: t('employerFavouriteListProfile.addFavFailedTitle'),
          });
        }
      }
    } catch (error) {
      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      } else {
        resultInfo('error', t('employerInnerAccountdetail.activateJobFailedTitle'), t('employerInnerAccountdetail.close')); // eslint-disable-line
      }

      console.error('Error activating job:', error);
    }
  };

  const handleDeactivate = async slug => {
    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: t('employerInnerAccountdetail.no'),
        confirmButtonText: t('employerInnerAccountdetail.yes'),
        text: t('employerInnerAccountdetail.deactivateJobConfirmTxt'),
        title: t('employerInnerAccountdetail.deactivateJobConfirmTitle'),
      });

      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/job/deactivate/${slug}`,
          null,
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              'Content-Type': 'application/json',
            },
          },
        );

        if (response.data.status === 200) {
          resultInfo('success', t('employerInnerAccountdetail.deactivateJobSuccessTitle'), t('employerInnerAccountdetail.close')); // eslint-disable-line
        } else if (response.data.status === 400) {
          navigate('/');

          Cookies.remove('fname');
          Cookies.remove('user_id');
          Cookies.remove('user_type');
          Cookies.remove('tokenClient');

          resultInfo('warning', response.data.message, t('employerInnerAccountdetail.close'));
        } else {
          Swal.fire({
            icon: 'error',
            text: response.data.message,
            confirmButtonText: t('employerInnerAccountdetail.close'),
            title: t('employerFavouriteListProfile.addFavFailedTitle'),
          });
        }

        getData();
      }
    } catch (error) {
      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      } else {
        resultInfo('error', t('employerInnerAccountdetail.deactivateJobFailedTitle'), t('employerInnerAccountdetail.close')); // eslint-disable-line
      }

      console.error('Error deactivating job:', error);
    }
  };

  return (
    <>
      <NavBar />
      {
        loading
          ? (
            <div className='loader-container'></div>
          ) : (
            <>
              <section className='MyProfileTopSection'>
                <div className='container MYProfileHeader'>
                  <div className='MyProfileImg'>
                    <img
                      src={
                        userDetail.company_logo
                          ? userDetail.company_logo
                          : '/Images/jobseekerSide/dummy-profile.png'
                      }
                      alt='img'
                    />
                  </div>
                  <div className='MyProfileDetails'>
                    <h2>
                      {userDetail.first_name} {userDetail.last_name}
                    </h2>
                    <h6>({userDetail.user_type})</h6>
                    <div className='MyProfileUpgratePlan'>
                      <span>{accDetail.planDetails}</span>
                      <Link
                        to='/plans/purchase'
                        class='btn btn-primary ms-4'
                        style={{
                          backgroundColor: secondaryColor,
                          border: secondaryColor,
                        }}
                      >
                        {t('employerInnerAccountdetail.upgradePlan')}
                      </Link>
                    </div>
                  </div>
                </div>
                <Link
                  to='/user/changelogo'
                  class='btn btn-primary UploadBackBg'
                  style={{
                    backgroundColor: primaryColor,
                    border: primaryColor,
                  }}
                >
                  {t('employerInnerAccountdetail.uploadEstablishmentPhoto')}
                </Link>
              </section>
              <div className='container IADsecondSegment'>
                <div className='row'>
                  <div className='col-lg-3 col-md-3 mt-4'>
                    <Sidebar />
                  </div>
                  <div
                    className='col-lg-9 col-md-9 mb-5 IADMainBx'
                    style={{
                      borderLeft: '2px solid #e6e8e7',
                      borderRight: '2px solid #e6e8e7',
                    }}
                  >
                    <div className='JobseekerProfileBx'>
                      <div className='JobseekerProfileTopBx'>
                        <h3>
                          <span>{jobDetail.title}</span>
                        </h3>
                        <span className='EditJobseekerProfileTag'>
                          <Link to={`/job/edit/${jobDetail.slug}`}>
                            <i className='fa fa-pencil' aria-hidden='true'></i>
                          </Link>
                          <Link onClick={() => handleDelete(jobDetail.slug)}>
                            <i className='fa fa-trash' aria-hidden='true'></i>
                          </Link>
                          <Link to={`/jobs/createJob/${jobDetail.slug}`}>
                            <i className='fa fa-file-o' aria-hidden='true'></i>
                          </Link>
                          <Link
                            to={`/jobdescription/${jobDetail.slug}/${jobDetail.cat_slug}`}
                          >
                            <i className='fa fa-eye' aria-hidden='true'></i>
                          </Link>
                          <Link onClick={() => handleOpen()}>
                            <i className='fa fa-share-alt' aria-hidden='true'></i>
                          </Link>
                          <div>
                            <Modal
                              open={open}
                              onClose={handleClose}
                              aria-labelledby='modal-modal-title'
                              aria-describedby='modal-modal-description'
                            >
                              <Box sx={{ ...style, width: 700 }}>
                                <Button
                                  onClick={handleClose} // Call handleClose when the button is clicked
                                  sx={{
                                    position: 'absolute',
                                    top: 10,
                                    right: 10,
                                  }} // Position the button
                                >
                                  {t('employerInnerAccountdetail.close')}
                                </Button>
                                <Typography
                                  id='modal-modal-title'
                                  variant='h6'
                                  component='h2'
                                ></Typography>
                                <Typography
                                  id='modal-modal-description'
                                  sx={{ mt: 6 }}
                                >
                                  <div className='modals '>
                                    <div className='modalHead'>
                                      <h3>
                                        {t('employerInnerAccountdetail.shareNow')}
                                      </h3>
                                    </div>
                                    <div className='modalBody mt-4'>
                                      <ul className='shareIconsList'>
                                        <li className='shareIconsButtons'>
                                          <FacebookShareButton
                                            url={userDetail.job_url}
                                          >
                                            <FacebookIcon
                                              logoFillColor='white'
                                              round={true}
                                            ></FacebookIcon>
                                          </FacebookShareButton>
                                        </li>
                                        <li className='shareIconsButtons'>
                                          <TwitterShareButton
                                            url={userDetail.job_url}
                                          >
                                            <TwitterIcon
                                              logoFillColor='white'
                                              round={true}
                                            ></TwitterIcon>
                                          </TwitterShareButton>
                                        </li>
                                        <li className='shareIconsButtons'>
                                          <WhatsappShareButton
                                            url={userDetail.job_url}
                                          >
                                            <WhatsappIcon
                                              logoFillColor='white'
                                              round={true}
                                            ></WhatsappIcon>
                                          </WhatsappShareButton>
                                        </li>
                                        <li className='shareIconsButtons'>
                                          <LinkedinShareButton
                                            url={userDetail.job_url}
                                          >
                                            <LinkedinIcon
                                              logoFillColor='white'
                                              round={true}
                                            ></LinkedinIcon>
                                          </LinkedinShareButton>
                                        </li>

                                        <li className='shareIconsButtons'>
                                          <EmailShareButton
                                            url={userDetail.job_url}
                                          >
                                            <EmailIcon
                                              logoFillColor='white'
                                              round={true}
                                            ></EmailIcon>
                                          </EmailShareButton>
                                        </li>
                                        <li className='shareIconsButtons'>
                                          <PinterestShareButton
                                            url={userDetail.job_url}
                                          >
                                            <PinterestIcon
                                              logoFillColor='white'
                                              round={true}
                                            ></PinterestIcon>
                                          </PinterestShareButton>
                                        </li>
                                        <li className='shareIconsButtons'>
                                          <InstapaperShareButton
                                            url={userDetail.job_url}
                                          >
                                            <InstapaperIcon
                                              logoFillColor='white'
                                              round={true}
                                            ></InstapaperIcon>
                                          </InstapaperShareButton>
                                        </li>
                                        <li className='shareIconsButtons'>
                                          <TelegramShareButton
                                            url={userDetail.job_url}
                                          >
                                            <TelegramIcon
                                              logoFillColor='white'
                                              round={true}
                                            ></TelegramIcon>
                                          </TelegramShareButton>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </Typography>
                              </Box>
                            </Modal>
                          </div>
                        </span>
                      </div>
                      <div className='JobseekerProfileDetails'>
                        <div className='JobseekerProfileActive manageJobActionButton'>
                          {
                            jobDetail.status === 1
                              ? (
                                <button
                                  className='btn-primary'
                                  onClick={() => handleDeactivate(jobDetail.slug)}
                                >
                                  <Tooltip title='Deactivate'>
                                    <CheckIcon />
                                  </Tooltip>
                                </button>
                              ) : (
                                <button
                                  className='btn-secondary'
                                  onClick={() => handleActivate(jobDetail.slug)}
                                >
                                  <Tooltip title='Activate'>
                                    <BlockIcon />
                                  </Tooltip>
                                </button>
                              )
                          }
                          <div className='CreatedDate' style={{ color: primaryColor }}>
                            {t('employerInnerAccountdetail.created')}
                            {' '}
                            <strong>{jobDetail.created}</strong>
                          </div>
                        </div>
                        <div className='JobseekerProfileSearch'>
                          <div className='Calcultn'>
                            <div className='LeftSideCalu'>
                              <i className='fa fa-search icon_calcultn' />
                            </div>
                            <div className='RightSideCalu'>
                              <h4>{jobDetail.search_count}</h4>
                              <h6>{t('employerInnerAccountdetail.searchViews')}</h6>
                            </div>
                          </div>
                          <div className='Calcultn'>
                            <div className='LeftSideCalu'>
                              <i className='fa fa-suitcase icon_calcultn' />
                            </div>
                            <div className='RightSideCalu'>
                              <h4>{jobDetail.view_count}</h4>
                              <h6>{t('employerInnerAccountdetail.jobViews')}</h6>
                            </div>
                          </div>
                          <div className='Calcultn'>
                            <div className='LeftSideCalu'>
                              <i className='fa fa-clock-o icon_calcultn' />
                            </div>
                            <div className='RightSideCalu'>
                              <h4>{accDetail.totalCandidate}</h4>
                              <h6>
                                {t('employerInnerAccountdetail.applications')}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className='JobseekersTabsBx'>
                          <ul className='nav JobseekersTabs' id='myTab' role='tablist'>
                            <li className='nav-item' role='presentation'>
                              <button
                                role='tab'
                                type='button'
                                id='jobseekers-tab'
                                aria-selected='true'
                                data-bs-toggle='tab'
                                className='nav-link active'
                                aria-controls='jobseekers-tab-pane'
                                data-bs-target='#jobseekers-tab-pane'
                              >
                                {t('employerInnerAccountdetail.jobseekers')}
                              </button>
                            </li>
                            <li className='nav-item' role='presentation'>
                              <button
                                role='tab'
                                type='button'
                                id='jobdetails-tab'
                                className='nav-link'
                                data-bs-toggle='tab'
                                aria-selected='false'
                                aria-controls='jobdetails-tab-pane'
                                data-bs-target='#jobdetails-tab-pane'
                              >
                                {t('employerInnerAccountdetail.jobDetails')}
                              </button>
                            </li>
                          </ul>
                          <div className='tab-content' id='myTabContent'>
                            <div
                              tabIndex='0'
                              role='tabpanel'
                              id='jobseekers-tab-pane'
                              aria-labelledby='jobseekers-tab'
                              className='tab-pane fade show active'
                            >
                              <div className='JobseekersTabsContent'>
                                <div className='JobseekersContentTop'>
                                  <ul>
                                    <li>
                                      <span>
                                        {t('employerInnerAccountdetail.active')}
                                      </span>
                                      <Link>{accDetail.activeJobs}</Link>
                                    </li>
                                    <li>
                                      <span>
                                        {t('employerInnerAccountdetail.shortlist')}
                                      </span>
                                      <Link>{accDetail.shortList}</Link>
                                    </li>
                                    <li>
                                      <span>
                                        {t('employerInnerAccountdetail.interview')}
                                      </span>
                                      <Link>{accDetail.interview}</Link>
                                    </li>
                                    <li>
                                      <span>
                                        {t('employerInnerAccountdetail.offer')}
                                      </span>
                                      <Link>{accDetail.offer}</Link>
                                    </li>
                                    <li>
                                      <span>
                                        {t('employerInnerAccountdetail.accept')}
                                      </span>
                                      <Link>{accDetail.accept}</Link>
                                    </li>
                                    <li>
                                      <span>
                                        {t(
                                          'employerInnerAccountdetail.notSuitable',
                                        )}
                                      </span>
                                      <Link>{accDetail.notSuitable}</Link>
                                    </li>
                                    <li>
                                      <span>
                                        {t('employerInnerAccountdetail.total')}
                                      </span>
                                      <Link>{accDetail.totalCandidate}</Link>
                                    </li>
                                    <li>
                                      <span>
                                        {t('employerInnerAccountdetail.new')}
                                      </span>
                                      <Link>{accDetail.newApplicationCount}</Link>
                                    </li>
                                  </ul>
                                  {/* <div class="JobseekersContentSearch">
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder="Search"
                                    />
                                    <span>
                                      <img
                                        src="/Images/jobseekerSide/search-icon.png"
                                        alt="icon"
                                      />
                                    </span>
                                  </div> */}
                                </div>
                                {!sendMail && (
                                  <div className='JobseekersSearchContent'>
                                    <div className='no_found'>
                                      <div className='card'>
                                        <table
                                          className='table table-borderless'
                                          style={{ textAlign: 'left' }}
                                        >
                                          <thead
                                            style={{
                                              backgroundColor: primaryColor,
                                              color: 'white',
                                            }}
                                          >
                                            <tr>
                                              <th scope='col'>
                                                <input
                                                  name='global'
                                                  type='checkbox'
                                                  onClick={handleGlobalCheckboxChange}
                                                />
                                              </th>
                                              <th scope='col'>
                                                <p>
                                                  {t(
                                                    'employerInnerAccountdetail.name',
                                                  )}
                                                </p>
                                              </th>
                                              <th scope='col'>
                                                <p>
                                                  {t(
                                                    'employerInnerAccountdetail.rating',
                                                  )}
                                                </p>
                                              </th>
                                              {/* <th scope="col">
                                            <p>
                                              {t(
                                                "employerInnerAccountdetail.contactNo"
                                              )}
                                            </p>
                                          </th> */}
                                              <th scope='col'>
                                                <p>
                                                  {t(
                                                    'employerInnerAccountdetail.status',
                                                  )}
                                                </p>
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {candidates.map(i => (
                                              <>
                                                <tr>
                                                  <th scope='row'>
                                                    <input
                                                      name='individual'
                                                      type='checkbox'
                                                      checked={selectedEmails.includes(
                                                        i.email_address,
                                                      )}
                                                      onChange={() =>
                                                        handleCheckboxChange(
                                                          i.email_address,
                                                        )
                                                      }
                                                    />
                                                  </th>
                                                  <td>
                                                    <div className='d-block usernameInnerAccDetails'>
                                                      <Link
                                                        to={`/candidates/profile/${i.slug}`}
                                                      >
                                                        <p
                                                          style={{
                                                            color: primaryColor,
                                                          }}
                                                        >
                                                          {i.name}
                                                        </p>
                                                      </Link>
                                                    </div>
                                                    <div className='d-block'>
                                                      <i className='fa-solid fa-calendar-days'></i>{' '}
                                                      {i.created}
                                                    </div>
                                                    <Link
                                                      className='text-primary'
                                                      onClick={() =>
                                                        handleOpenCoverModal(
                                                          i.coverletters,
                                                        )
                                                      }
                                                    >
                                                      <i className='fa-solid fa-copy'></i>{' '}
                                                      {t(
                                                        'employerInnerAccountdetail.coverLetter',
                                                      )}
                                                    </Link>
                                                    <div>
                                                      <Modal
                                                        open={openCoverModal}
                                                        onClose={
                                                          handleCloseCoverModal
                                                        }
                                                        aria-labelledby='modal-modal-title'
                                                        aria-describedby='modal-modal-description'
                                                      >
                                                        <Box sx={{ ...style, width: 500 }}>
                                                          <IconButton
                                                            className='close-button'
                                                            onClick={handleCloseCoverModal}
                                                            style={{
                                                              top: 10,
                                                              right: 10,
                                                              position: 'absolute',
                                                            }}
                                                          >
                                                          &times;
                                                          </IconButton>
                                                          <Typography
                                                            id='modal-modal-title'
                                                            variant='h6'
                                                            component='h2'
                                                          ></Typography>
                                                          <Typography
                                                            id='modal-modal-description'
                                                            sx={{ mt: 0 }}
                                                          >
                                                            <div className='modals'>
                                                              <div className='modalHead'>
                                                                <h3
                                                                  style={{
                                                                    color:
                                                                    secondaryColor,
                                                                  }}
                                                                >
                                                                  {t(
                                                                    'employerInnerAccountdetail.coverLetterDetails',
                                                                  )}
                                                                </h3>
                                                              </div>
                                                              <div className='modalBody mt-4'>
                                                                <div className='modalBody mt-4'>
                                                                  {selectedPayment?.length >
                                                                0
                                                                    ? selectedPayment.map(
                                                                      j => (
                                                                        <>
                                                                          <div className='row mb-2'>
                                                                            <div className='col-md-4 fw-bold'>
                                                                              {t(
                                                                            'employerInnerAccountdetail.coverLetterTitle', //eslint-disable-line
                                                                              )}

                                                                                :
                                                                            </div>
                                                                            <div className='col-md-8'>
                                                                              {
                                                                                j.title
                                                                              }
                                                                            </div>
                                                                          </div>
                                                                          <div className='row'>
                                                                            <div className='col-md-4 fw-bold'>
                                                                              {t(
                                                                            'employerInnerAccountdetail.coverLetterDescription', //eslint-disable-line
                                                                              )}

                                                                                :
                                                                            </div>
                                                                            <div className='col-md-8'>
                                                                              {
                                                                                j.description
                                                                              }
                                                                            </div>
                                                                          </div>
                                                                        </>
                                                                      ),
                                                                    )
                                                                    : t(
                                                                      'employerInnerAccountdetail.coverLetterNotAvailable', //eslint-disable-line
                                                                    )}
                                                                  <p></p>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </Typography>
                                                        </Box>
                                                      </Modal>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    {renderStars(i.rating, i.id)}
                                                  </td>
                                                  {/* <td>{i.contact}</td> */}
                                                  <td>
                                                    {i.apply_status ? (
                                                      <>
                                                        <select
                                                          className='selectFormInnerAccDetails form-select'
                                                          aria-label='Default select example'
                                                          value={i.apply_status}
                                                          onChange={e =>
                                                            handleStatusChange(
                                                              i.id,
                                                              e.target.value,
                                                              i.user_id,
                                                            )
                                                          }
                                                        >
                                                          <option selected>
                                                            {t(
                                                              'employerInnerAccountdetail.selectStatus',
                                                            )}
                                                          </option>
                                                          {Object.entries(
                                                            activeOptions,
                                                          ).map(
                                                            ([key, value]) => (
                                                              <option
                                                                key={key}
                                                                value={key}
                                                              >
                                                                {value}
                                                              </option>
                                                            ),
                                                          )}
                                                        </select>
                                                      </>
                                                    ) : (
                                                      <select
                                                        className='form-select'
                                                        aria-label='Default select example'
                                                        value={i.apply_status}
                                                      >
                                                        {Object.entries(
                                                          activeOptions,
                                                        ).map(([key, value]) => (
                                                          <option
                                                            key={key}
                                                            value={key}
                                                          >
                                                            {value}
                                                          </option>
                                                        ))}
                                                      </select>
                                                    )}
                                                  </td>
                                                </tr>
                                              </>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                      {candidates != '' && (
                                        <button
                                          type='button'
                                          className='sendMailManageJobs'
                                          onClick={handleSendButton}
                                          style={{
                                            backgroundColor: primaryColor,
                                            border: primaryColor,
                                          }}
                                        >
                                          {t(
                                            'employerInnerAccountdetail.sendMailButton',
                                          )}
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                )}
                                {/* Send main UI if candidates are present */}
                                {sendMail && (
                                  <div className='sendMailComponent'>
                                    <div className='no_found'>
                                      <div className='card'>
                                        <div
                                          className='sendMailHeader'
                                          style={{
                                            backgroundColor: primaryColor,
                                          }}
                                        >
                                          {t(
                                            'employerInnerAccountdetail.sendMailButton',
                                          )}
                                        </div>
                                        <div className='form-outline sendMailInputManageJobs DashBoardInputBx'>
                                          <label
                                            className='form-label'
                                            htmlFor='form3Example3'
                                          >
                                            {t('employerInnerAccountdetail.to')}
                                          </label>
                                          <input
                                            type='text'
                                            id='form3Example3'
                                            className='form-control'
                                            placeholder={t(
                                              'employerInnerAccountdetail.email',
                                            )}
                                            value={mailUsers.email_address}
                                            name='email_address'
                                            disabled
                                            // onChange={handleChange}
                                          />
                                        </div>
                                        <div className='form-outline sendMailInputManageJobs DashBoardInputBx'>
                                          <label
                                            className='form-label'
                                            htmlFor='form3Example3'
                                          >
                                            {t(
                                              'employerInnerAccountdetail.subject',
                                            )}{' '}
                                            <span className='RedStar'>*</span>
                                          </label>
                                          <input
                                            type='text'
                                            id='form3Example3'
                                            className='form-control'
                                            placeholder={t(
                                              'employerInnerAccountdetail.subject',
                                            )}
                                            value={mailUsers.subject}
                                            name='subject'
                                            onChange={handleMailReplyChange}
                                          />
                                          {mailReplyError.subject && (
                                            <div className='text-danger'>
                                              {mailReplyError.subject}
                                            </div>
                                          )}
                                        </div>
                                        <div className='form-outline sendMailInputManageJobs DashBoardInputBx'>
                                          <label
                                            className='form-label'
                                            htmlFor='form3Example3'
                                          >
                                            {t(
                                              'employerInnerAccountdetail.message',
                                            )}{' '}
                                            <span className='RedStar'>*</span>
                                          </label>
                                          <input
                                            type='text'
                                            id='form3Example3'
                                            className='form-control'
                                            placeholder={t(
                                              'employerInnerAccountdetail.message',
                                            )}
                                            value={mailUsers.message}
                                            name='message'
                                            onChange={handleMailReplyChange}
                                          />
                                          {mailReplyError.message && (
                                            <div className='text-danger'>
                                              {mailReplyError.message}
                                            </div>
                                          )}
                                        </div>
                                        <div className='sendMailManageJobsButtons'>
                                          <button
                                            type='button'
                                            className='btn btn-primary button1'
                                            onClick={() =>
                                              handleMailReply(jobDetail.slug)
                                            }
                                            // data-bs-dismiss="modal"
                                            // aria-label="Close"
                                            style={{
                                              backgroundColor: hoverSearchColor
                                                ? secondaryColor
                                                : primaryColor,
                                              border: hoverSearchColor
                                                ? secondaryColor
                                                : primaryColor,
                                            }}
                                            onMouseEnter={handleSearchMouseEnter}
                                            onMouseLeave={handleSearchMouseLeave}
                                          >
                                            {t(
                                              'employerInnerAccountdetail.sendButton',
                                            )}
                                          </button>
                                          <button
                                            type='button'
                                            className='btn btn-primary button1'
                                            onClick={handleCancelClick}
                                            style={{
                                              color: hoverUploadCVColor
                                                ? primaryColor
                                                : secondaryColor,
                                              backgroundColor: 'white',
                                              border: hoverUploadCVColor
                                                ? `2px solid ${primaryColor}`
                                                : `2px solid ${secondaryColor}`,
                                            }}
                                            onMouseEnter={handleUploadCVMouseEnter}
                                            onMouseLeave={handleUploadCVMouseLeave}
                                          >
                                            {t(
                                              'employerInnerAccountdetail.cancelButton',
                                            )}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div
                              className='tab-pane fade '
                              id='jobdetails-tab-pane'
                              role='tabpanel'
                              aria-labelledby='jobdetails-tab'
                              tabIndex='0'
                            >
                              <div className='JobseekersTabsContent'>
                                <div className='JobseekersTabsDetails'>
                                  <div className='JobseekersTabsDetailsList'>
                                    <label>
                                      {t('employerInnerAccountdetail.vacancyID')}
                                    </label>
                                    <span>
                                      <em>{jobDetail.job_id}</em>
                                    </span>
                                  </div>
                                  <div className='JobseekersTabsDetailsList'>
                                    <label>
                                      {t('employerInnerAccountdetail.category')}
                                    </label>
                                    <span>
                                      <em>{jobDetail.category}</em>
                                    </span>
                                  </div>
                                  {
                                    jobDetail.subcategory ? (
                                      <div className='JobseekersTabsDetailsList'>
                                        <label>
                                          {t(
                                            'employerInnerAccountdetail.subCategory',
                                          )}
                                        </label>
                                        <span>
                                          <em>{jobDetail.subcategory}</em>
                                        </span>
                                      </div>
                                    ) : (
                                      ''
                                    )
                                  }
                                  <div className='JobseekersTabsDetailsList'>
                                    <label>
                                      {t('employerInnerAccountdetail.contactName')}
                                    </label>
                                    <span>
                                      <em>{jobDetail.contact_name}</em>
                                    </span>
                                  </div>
                                  <div className='JobseekersTabsDetailsList'>
                                    <label>
                                      {t('employerInnerAccountdetail.contactNumber')}
                                    </label>
                                    <span>
                                      <em>{jobDetail.contact_number}</em>
                                    </span>
                                  </div>
                                  <div className='JobseekersTabsDetailsList'>
                                    <label>
                                      {t('employerInnerAccountdetail.skills')}
                                    </label>
                                    <span>
                                      <em>{jobDetail.skill}</em>
                                    </span>
                                  </div>
                                  {
                                    jobDetail.lang_skills && (
                                      <div className='JobseekersTabsDetailsList'>
                                        <label>
                                          {t('employerInnerAccountdetail.additionalLanguages')}
                                        </label>
                                        <span>
                                          <em>{jobDetail.lang_skills[0]}</em>
                                        </span>
                                        {
                                          jobDetail.lang_skills[1] && (
                                            <>
                                              <label>
                                                {' '}
                                              </label><span>
                                                <em>{jobDetail.lang_skills[1]}</em>
                                              </span>
                                            </>
                                          )
                                        }
                                      </div>
                                    )
                                  }
                                  <div className='JobseekersTabsDetailsList'>
                                    <label>
                                      {t('employerInnerAccountdetail.location')}
                                    </label>
                                    <span>
                                      <em>{jobDetail.city_region}</em>
                                    </span>
                                  </div>
                                  <div className='JobseekersTabsDetailsList'>
                                    <label>
                                      {t('employerInnerAccountdetail.workType')}
                                    </label>
                                    <span>
                                      <em>{jobDetail.work_type}</em>
                                    </span>
                                  </div>
                                  <div className='JobseekersTabsDetailsList standartListStyle'>
                                    <label>
                                      {t('employerInnerAccountdetail.description')}
                                    </label>
                                    <span>
                                      <em>
                                        {
                                          jobDetail.description
                                            ? HTMLReactParser(jobDetail.description)
                                            : ''
                                        }
                                      </em>{' '}
                                    </span>
                                  </div>
                                  <div className='JobseekersTabsDetailsList'>
                                    <label>
                                      {t('employerInnerAccountdetail.salary')}
                                    </label>
                                    <span>
                                      <em>
                                        {curr} {jobDetail.salary_min} - {curr}{' '}
                                        {jobDetail.salary_max}
                                      </em>
                                    </span>
                                  </div>
                                  <div className='JobseekersTabsDetailsList'>
                                    <label>
                                      {t('employerInnerAccountdetail.experience')}
                                    </label>
                                    <span>
                                      <em>
                                        {jobDetail.min_exp}-{jobDetail.max_exp}{' '}
                                        {t('employerInnerAccountdetail.year')}
                                      </em>
                                    </span>
                                  </div>
                                  <div className='JobseekersTabsDetailsList'>
                                    <label>
                                      {t('employerInnerAccountdetail.companyName')}
                                    </label>
                                    <span>
                                      <em>{jobDetail.company_name}</em>
                                    </span>
                                  </div>
                                  <div className='JobseekersTabsDetailsList standartListStyle'>
                                    <label>
                                      {t(
                                        'employerInnerAccountdetail.companyProfile',
                                      )}
                                    </label>
                                    <span>
                                      <em>
                                        {jobDetail.company_profile
                                          ? HTMLReactParser(
                                            jobDetail.company_profile,
                                          )
                                          : ''}
                                      </em>
                                    </span>
                                  </div>
                                  <div className='JobseekersTabsDetailsList'>
                                    <label>
                                      {t(
                                        'employerInnerAccountdetail.companyWebsite',
                                      )}
                                    </label>
                                    <span>
                                      <em>
                                        <a href={jobDetail.url} target='blank'>
                                          {jobDetail.url}
                                        </a>
                                      </em>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </>
          )
      }
    </>
  );
};

export default InnerAccountdetail;
