import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import HTMLReactParser from 'html-react-parser';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// api
import ApiKey from '../api/ApiKey';
import BaseApi from '../api/BaseApi';
// components
import Sidebar from './Sidebar';
import NavBar from '../element/NavBar';
import Footer from '../element/Footer';
// forms
import CreateEditJobForm from '../forms/CreateEditJobForm';
// helpers
import { logOutIfTokenExpired, resultInfo, scrollToElement } from '../helpers';

const DetailEditInner = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation('global');
  const tokenKey = Cookies.get('tokenClient');

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(null);

  const [errors, setErrors] = useState({
    skill: '',
    category: '',
    location: '',
    job_title: '',
    last_date: '',
    work_type: '',
    experience: '',
    salary_min: '',
    salary_max: '',
    subCategory: [],
    company_name: '',
    contact_name: '',
    annual_salary: '',
    companyProfile: '',
    jobDescription: '',
    contact_number: '',
    salary_currency: '',
  });

  const handleSubmit = async jobBody => {
    try {
      const newErrors = {};
      const salaryFormat = /^\d*\.?\d+$/;
      const contactNumberFormat = /^\+?3?8?(0\d{9})$/;
      const urlFormat = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;

      if (jobBody.job_title === '') {
        newErrors.job_title = t('employerCreateJob.jobTitleRequired');
        scrollToElement('job_title');
      }
      if (jobBody.category === '') {
        newErrors.category = t('employerCreateJob.categoryRequired');
        scrollToElement('category');
      }
      if (jobBody.subCategory.length === 0) {
        newErrors.subCategory = t('employerCreateJob.subCategoryRequired');
        scrollToElement('subCategory');
      }
      if (jobBody.jobDescription === '') {
        newErrors.jobDescription = t('employerCreateJob.jobDescriptionRequired');
        scrollToElement('job_description');
      }
      if (jobBody.company_name === '') {
        newErrors.company_name = t('employerCreateJob.companyNameRequired');
        scrollToElement('company_name');
      }
      if (jobBody.companyProfile === '') {
        newErrors.companyProfile = t('employerCreateJob.companyProfileRequired');
        scrollToElement('company_profile');
      }
      if (jobBody.work_type === '') {
        newErrors.work_type = t('employerCreateJob.workTypeRequired');
        scrollToElement('work_type');
      }
      if (jobBody.contact_number && !contactNumberFormat.test(jobBody.contact_number)) {
        newErrors.contact_number = t('employerCreateJob.contactNumberFormat');
        scrollToElement('contact_number');
      }
      if (jobBody.contact_number_2 && !contactNumberFormat.test(jobBody.contact_number_2)) {
        newErrors.contact_number_2 = t('employerCreateJob.contactNumberFormat');
        scrollToElement('contact_number_2');
      }
      if (jobBody.contact_number_3 && !contactNumberFormat.test(jobBody.contact_number_3)) {
        newErrors.contact_number_3 = t('employerCreateJob.contactNumberFormat');
        scrollToElement('contact_number_3');
      }
      if (jobBody.company_website) {
        if (jobBody.company_website && !urlFormat.test(jobBody.company_website)) {
          newErrors.company_website = t('employerCreateJob.urlInvalid');
          scrollToElement('company_website');
        }
      }
      if (jobBody.country === '') {
        newErrors.country = t('employerCreateJob.countryRequired');
        scrollToElement('country');
      }
      if (jobBody.city === '') {
        newErrors.city = t('employerCreateJob.cityRequired');
        scrollToElement('city');
      }
      if (jobBody.experience === '') {
        newErrors.experience = t('employerCreateJob.experienceRequired');
        scrollToElement('experience');
      }
      if (jobBody.salary_min && !salaryFormat.test(jobBody.salary_min)) {
        newErrors.salary_min = t('employerCreateJob.salaryNumeric');
        scrollToElement('salary_min');
      }
      if (jobBody.salary_max) {
        if (!salaryFormat.test(jobBody.salary_max)) {
          newErrors.salary_max = t('employerCreateJob.salaryNumeric');
          scrollToElement('salary_max');
        }
        if (parseFloat(jobBody.salary_max) < parseFloat(jobBody.salary_min)) {
          newErrors.salary_max = t('employerCreateJob.salaryMaxLowerSalaryMin');
          scrollToElement('salary_max');
        }
      }
      if (!jobBody.salary_currency && (jobBody.salary_min || jobBody.salary_max)) {
        newErrors.salary_currency = t('employerCreateJob.salaryCurrencyRequired');
        scrollToElement('salary_currency');
      }
      if (jobBody.last_date === '') {
        newErrors.last_date = t('employerCreateJob.lastDateRequired');
        scrollToElement('formLastDate');
      }
      if (jobBody.form_link) {
        if (jobBody.form_link && !urlFormat.test(jobBody.form_link)) {
          newErrors.form_link = t('employerCreateJob.urlInvalid');
          scrollToElement('form_link');
        }
      }

      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0) {
        const confirmationResult = await Swal.fire({
          icon: 'question',
          showCancelButton: true,
          cancelButtonText: t('employerCreateJob.no'),
          confirmButtonText: t('employerCreateJob.yes'),
          text: t('employerCreateJob.editJobConfirmTxt'),
          title: t('employerCreateJob.editJobConfirmTitle'),
        });

        if (confirmationResult.isConfirmed) {
          const response = await axios.post(
            BaseApi + `/job/edit/${slug}`,
            jobBody,
            {
              headers: {
                key: ApiKey,
                token: tokenKey,
                'Content-Type': 'application/json',
              },
            },
          );

          if (response.data.status === 200) {
            resultInfo('success', t('employerCreateJob.editJobSuccessTitle'), t('employerCreateJob.close'));

            window.history.back();
          } else if (response.data.status === 400) {
            navigate('/');

            Cookies.remove('fname');
            Cookies.remove('user_id');
            Cookies.remove('user_type');
            Cookies.remove('tokenClient');

            resultInfo('warning', response.data.message, t('employerCreateJob.close'));
          } else {
            Swal.fire({
              icon: 'error',
              text: HTMLReactParser(response.data.message),
              confirmButtonText: t('employerCreateJob.close'),
              title: t('employerCreateJob.createJobFailedTitle'),
            });
          }
        }
      }
    } catch (error) {
      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      } else {
        resultInfo('error', t('employerCreateJob.editJobFailedTitle'), t('employerCreateJob.close'));
      }

      console.error('Could not submit job data', error);
    }
  };

  useEffect(() => {
    if (!tokenKey) {
      navigate('/user/employerlogin');
    } else {
      const fetchData = async () => {
        try {
          setLoading(true);

          const response = await axios.post(
            BaseApi + `/job/edit/${slug}`,
            null,
            {
              headers: {
                key: ApiKey,
                token: tokenKey,
                'Content-Type': 'application/json',
              },
            },
          );

          setLoading(false);

          if (response.data.status === 200) {
            const data = response.data.response;

            setFormData(data);
          } else if (response.data.status === 400) {
            navigate('/');

            Cookies.remove('fname');
            Cookies.remove('user_id');
            Cookies.remove('user_type');
            Cookies.remove('tokenClient');

            resultInfo('warning', response.data.message, t('employerCreateJob.close'));
          } else {
            navigate('/plans/purchase');

            resultInfo('warning', response.data.message, t('employerCreateJob.close'));
          }
        } catch (error) {
          setLoading(false);

          if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
            navigate('/');

            logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
          }

          console.error('No data received', error);
        }
      };

      fetchData();
    }
  }, [t, slug, navigate, tokenKey]);

  return (
    <>
      <NavBar />
      <div className='container createJob'>
        <div className='row'>
          <div className='col-lg-3'>
            <Sidebar />
          </div>
          {
            loading
              ? (
                <div className='loader-container'></div>
              ) : (
                <div
                  className='col-lg-9 mb-5 CLPanelRight'
                  style={{
                    borderLeft: '2px solid #e6e8e7',
                    borderRight: '2px solid #e6e8e7',
                  }}
                >
                  <div className='d-flex PageHeader'>
                    <img src='/Images/employerSide/icon1color.png' alt='' />
                    <h3 className='mx-2'>{t('employerCreateJob.editJob')}</h3>
                  </div>
                  {
                    formData &&
                      <CreateEditJobForm
                        errors={errors}
                        formData={formData}
                        setErrors={setErrors}
                        handleSubmit={handleSubmit}
                      />
                  }
                </div>
              )
          }
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DetailEditInner;
