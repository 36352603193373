export const assistanceLinks = {
  links: [
    {
      name: 'viber',
      type: 'Viber',
      prop: 'brands',
      link: 'viber://chat?number=+380674410054',
    },
    {
      prop: 'brands',
      name: 'telegram',
      type: 'Telegram',
      link: 'tg://resolve?domain=feedback_grc',
    },
    {
      prop: 'brands',
      name: 'whatsapp',
      type: 'WhatsApp',
      link: 'whatsapp://send?phone=+380674410054',
    },
    {
      prop: 'solid',
      name: 'envelope',
      type: 'feedback@grc.ua',
      link: 'mailto:feedback@grc.ua',
    },
  ],
  phoneNumbers: [
    {
      title: 'info@grc.ua',
      link: 'mailto:info@grc.ua',
    },
    {
      link: 'tel:+380442308739',
      title: '+38 (044) 230 87 39',
    },
    {
      link: 'tel:+380931709030',
      title: '+38 (093) 170 90 30',
    },
    {
      link: 'tel:+380951709030',
      title: '+38 (095) 170 90 30',
    },
  ],
};

export const contractsLinks = [
  {
    title: 'offerAgreement',
    link: '/offer-agreement',
  },
  {
    title: 'userAgreement',
    link: '/user-agreement',
  },
  {
    title: 'serviceTerms',
    link: '/service-terms',
  },
  {
    title: 'privacyPolicy',
    link: '/privacy-policy',
  },
  {
    title: 'termsOfUse',
    link: '/terms-of-use',
  },
  {
    title: 'dataProcessingConsent',
    link: '/data-processing-consent',
  },
];

export const quickLinks = [
  {
    title: 'jobsInUkraine',
    link: 'https://t.me/grcjobportal',
  },
  {
    title: 'salesJobs',
    link: 'https://t.me/grcsalesjob',
  },
  {
    title: 'hrJobs',
    link: 'https://t.me/grc_hr',
  },
  {
    title: 'marketingJobs',
    link: 'https://t.me/grcmarketing',
  },
  {
    title: 'ITJobs',
    link: 'http://t.me/grcuaitjob',
  },
  {
    title: 'managementJobs',
    link: 'https://t.me/grcpremiumjob',
  },
];

export const socialLinks = [
  {
    icon: 'facebook-f',
    link: 'https://www.facebook.com/robota.vakansii.ukraine',
  },
  {
    icon: 'instagram',
    link: 'https://www.instagram.com/grc.ua_smm',
  },
  {
    icon: 'linkedin-in',
    link: 'https://www.linkedin.com/groups/4086458',
  },
];
