export const ourLifeSettings = [
  {
    flexDirection: { xs: 'column', lg: 'row' },
    image: 'our-life-1.png',
    title: 'Наші співробітники — це сертифіковані міжнародні фахівці',
    text1: 'Програма «Cертифікований міжнародний фахівець» дозволяє всім співробітникам DHL Express отримати базові знання, які допомагають їм дотримуватися єдиних стандартів поведінки незалежно від країни або регіону. Ми залучаємо кожного співробітника DHL Express в роботу компанії і даємо спеціалізовані знання, необхідні для компетентного і впевненого виконання обов\'язків.', // eslint-disable-line
    text2: '',
    text3: '',
  },
  {
    flexDirection: { xs: 'column', lg: 'row-reverse' },
    image: 'our-life-2.png',
    title: 'Наша культура',
    text1: 'У нашій компанії спираються на 2 основні принципи корпоративної культури DHL Express — це «повага» та «результат».', // eslint-disable-line
    text2: 'Ми знаємо, що співробітники — це ключ до успіху в будь-якій організації, тому ми прагнемо визнавати заслуги співробітників і заохочувати їх за якісно виконану роботу. Ми віримо, що визнання сприяє створенню атмосфери справедливості і допомагає нам рухатися в правильному напрямку, щоб кожен міг виконувати ту роботу, яка виходить у нього найкраще.', // eslint-disable-line
    text3: 'Розуміння нашої культури допомагає всім працівникам успішно реалізовувати принцип «Best Day Every Day».',
  },
  {
    flexDirection: { xs: 'column', lg: 'row' },
    image: 'our-life-3.png',
    title: 'Відповідальність як спосіб життя',
    text1: 'Компанія DHL згідно з нашим девізом — «Відповідальність як спосіб життя», ми приділяємо особисту увагу захисту навколишнього середовища (GoGreen), допомозі при стихійних лихах та катастрофах (GoHelp), освіті (GoTeach) та підтримці волонтерської діяльності співробітників (Міжнародний день волонтера, фонд «Відповідальність як спосіб життя»).', // eslint-disable-line
    text2: '',
    text3: '',
  },
];
