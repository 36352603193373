/* eslint-disable react/prop-types */
import Select from 'react-select';
import JoditEditor from 'jodit-react';
import AsyncSelect from 'react-select/async';
import { useTranslation } from 'react-i18next';
import HTMLReactParser from 'html-react-parser';
import React, { useRef, useState } from 'react';
// api
import { getCities } from '../employersSide/api/getCities';
import { getSubCategories } from '../employersSide/api/getSubCategories';
// helpers
import { editorConfig, selectStyles } from '../helpers';
// mui
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { IconButton } from '@mui/material';

const CreateEditJobForm = ({ errors, formData, setErrors, handleSubmit, formType = '' }) => {
  const editor = useRef(null);
  const { t } = useTranslation('global');

  const {
    job = {},
    worktype,
    currencies,
    langs = [],
    experience,
    jobData = {},
    countries = [],
    skillList = [],
    categories = [],
    lang_skills = [],
    subcategories = [],
  } = formData || {};

  const getDefaultCountryName = id => {
    for (let i = 0; i < countries.length; i++) {
      if (countries[i].id == id) {
        return countries[i].name;
      }
    }
  };

  const getDefaultSubCategoryName = id => {
    for (let i = 0; i < subcategories.length; i++) {
      if (subcategories[i].id == id) {
        return { label: subcategories[i].name, value: subcategories[i].id };
      }
    }
  };

  const getDefaultSkillName = id => {
    for (let i = 0; i < skillList.length; i++) {
      if (skillList[i].id == id) {
        return { label: skillList[i].name, value: skillList[i].id };
      }
    }
  };

  const getCategoryName = id => {
    for (let i = 0; i < categories.length; i++) {
      if (categories[i].id == id) {
        return categories[i].name;
      }
    }
  };

  const getLangName = id => {
    for (let i = 0; i < langs.length; i++) {
      if (langs[i].id == id) {
        return langs[i].name;
      }
    }
  };

  const getLangSkillName = id => {
    for (let i = 0; i < lang_skills.length; i++) {
      if (lang_skills[i].id == id) {
        return lang_skills[i].name;
      }
    }
  };

  const getSalaryCurrencyName = id => {
    if (id == 1) {
      return t('employerCreateJob.uah');
    }
    if (id == 2) {
      return t('employerCreateJob.usd');
    }
    if (id == 3) {
      return t('employerCreateJob.eur');
    }
  };

  const getWorkType = id => {
    if (id == 1) {
      return t('employerCreateJob.fullTime');
    }
    if (id == 2) {
      return t('employerCreateJob.partTime');
    }
    if (id == 3) {
      return t('employerCreateJob.remoteWork');
    }
  };

  const defaultSkills = job.skill !== undefined && job.skill.map(id => getDefaultSkillName(parseInt(id)));
  const defaultSubCategories = job.subcategory_id !== undefined && job.subcategory_id.map(
    id => getDefaultSubCategoryName(parseInt(id)),
  );

  const [open, setOpen] = useState(false);
  const [showSecondLanguage, setShowSecondLanguage] = useState(!!job.lng_2 || false);

  const [subCategories, setSubCategories] = useState(subcategories || []);
  const [selectedSkills, setSelectedSkills] = useState(defaultSkills || []);
  const [selectedCategories, setSelectedSubCategories] = useState(defaultSubCategories || []);
  const [selectedCity, setSelectedCity] = useState({ label: job.city_region || t('employerCreateJob.selectCity') });
  const [jobBody, setJobBody] = useState({
    city: job.city || '',
    lng_1: job.lng_1 || '',
    lng_2: job.lng_2 || '',
    skill: job.skill || [],
    country: job.country || 5,
    category: job.category || '',
    form_link: job.form_link || '',
    job_title: job.job_title || '',
    last_date: job.last_date || '',
    work_type: job.work_type || '',
    experience: job.experience || '',
    logo: jobData.company_logo || '',
    salary_max: job.salary_max || '',
    salary_min: job.salary_min || '',
    lng_skill_1: job.lng_skill_1 || '',
    lng_skill_2: job.lng_skill_2 || '',
    contact_name: job.contact_name || '',
    subCategory: job.subcategory_id || [],
    contact_email: job.contact_email || '',
    company_name: jobData.company_name || '',
    jobDescription: job.jobDescription || '',
    salary_currency: job.salary_currency || '',
    contact_number_2: job.contact_number_2 || '',
    contact_number_3: job.contact_number_3 || '',
    companyProfile: jobData.companyProfile || '',
    company_website: jobData.company_website || '',
    contact_number: job.contact_number || jobData.contact_number || '',
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = e => {
    const { name, value } = e.target;

    const currentDate = new Date();
    const selectedDate = new Date(value);

    if (name === 'last_date') {
      if (selectedDate < currentDate) {
        const formattedCurrentDate = currentDate.toISOString().split('T')[0];

        setJobBody({
          ...jobBody,
          [name]: formattedCurrentDate,
        });
      } else {
        setJobBody({
          ...jobBody,
          [name]: value,
        });
      }

      setErrors(prev => ({
        ...prev,
        [name]: '',
      }));
    } else {
      setJobBody(prevJobBody => ({
        ...prevJobBody,
        [name]: value,
      }));
      setErrors(prev => ({
        ...prev,
        [name]: '',
      }));
    }
  };

  const handleRemoveSecondLanguage = () => {
    setJobBody({
      ...jobBody,
      lng_2: '',
      lng_skill_2: '',
    });

    setShowSecondLanguage(false);
  };

  const handleSkillsChange = selectedOption => {
    setSelectedSkills(selectedOption);

    const ids = selectedOption.map(item => item.value);

    setJobBody(prevState => ({
      ...prevState,
      skill: ids,
    }));
  };

  const handleCityChange = selectedOption => {
    setSelectedCity(selectedOption);

    setJobBody(prevState => ({
      ...prevState,
      city: selectedOption ? selectedOption.value : '',
    }));

    setErrors(prev => ({
      ...prev,
      city: '',
    }));
  };

  const handleSubCategory = selectedOption => {
    if (selectedOption.length > 5) {
      setErrors(prev => ({
        ...prev,
        subCategory: t('employerCreateJob.subCategoryLimit'),
      }));

      return;
    }

    setSelectedSubCategories(selectedOption);

    const ids = selectedOption.map(item => item.value);

    setJobBody(prevJobBody => ({
      ...prevJobBody,
      subCategory: ids,
    }));

    setErrors(prev => ({
      ...prev,
      subCategory: '',
    }));
  };

  const getSubCategoriesOptions = async id => {
    setSelectedSubCategories([]);
    setJobBody((prevState => ({
      ...prevState,
      subCategory: [],
    })));

    try {
      const response = await getSubCategories(id);
      const data = response.data.response;

      setSubCategories(data);
    } catch (error) {
      console.error('Couldn\'t get Sub category data!', error);
    }
  };

  const getCitiesOptions = async inputValue => {
    if (!jobBody.country || inputValue.length < 2) {
      return [];
    }

    try {
      const cities = await getCities(parseInt(jobBody.country), inputValue);

      const options = cities.data.response.map(city => ({
        value: city.id,
        label: `${city.name}${city.region && `, ${city.region}`}`,
      }));

      return options;
    } catch (error) {
      console.error('Error fetching cities:', error);
      return [];
    }
  };

  return (
    <form>
      <div className='mb-5 mt-4'>
        {
          formType === 'admin' && (
            <div className='form-outline mb-5 DashBoardInputBx DashBoardCreatBx'>
              <label id='company_name' className='form-label' htmlFor='form3Example1'>
                {t('employerCreateJob.companyName')}
              </label>
              <input
                disabled
                type='text'
                name='company_name'
                onChange={handleChange}
                value={jobBody.company_name}
                placeholder={t('employerCreateJob.companyName')}
                className={`form-control ${errors.company_name && 'input-error'}`}
              />
            </div>
          )
        }
        <div className='form-outline mb-5 DashBoardInputBx DashBoardCreatBx'>
          <label id='job_title' className='form-label' htmlFor='form3Example1'>
            {t('employerCreateJob.jobTitle')}
            <span className='RedStar'>*</span>
          </label>
          <input
            type='text'
            name='job_title'
            onChange={handleChange}
            value={jobBody.job_title}
            placeholder={t('employerCreateJob.jobTitle')}
            className={`form-control ${errors.job_title && 'input-error'}`}
          />
          {
            errors.job_title && (
              <div className='text-danger'>{errors.job_title}</div>
            )
          }
        </div>
        <div className='form-outline mb-5 DashBoardInputBx DashBoardCreatBx'>
          <label id='category' className='form-label' htmlFor='form3Example1'>
            {t('employerCreateJob.category')}
            <span className='RedStar'>*</span>
          </label>
          <select
            name='category'
            value={jobBody.category}
            aria-label='Default select example'
            className={`form-select ${errors.category && 'input-error'}`}
            onChange={e => {
              handleChange(e);
              getSubCategoriesOptions(e.target.value);
            }}
          >
            <option value=''>
              {t('employerCreateJob.selectJobCategory')}
            </option>
            {
              categories.map((i, index) => (
                <option value={i.id} key={index}>
                  {i.name}
                </option>
              ))
            }
          </select>
          {
            errors.category && (
              <div className='text-danger'>{errors.category}</div>
            )
          }
        </div>
      </div>
      {
        jobBody.category && subCategories && (
          <div className='form-outline mb-5 DashBoardInputBx DashBoardCreatBx'>
            <label id='subCategory' className='form-label' htmlFor='form3Example1'>
              {t('employerCreateJob.subCategory')}
              <span className='RedStar'>*</span>
            </label>
            <Select
              isMulti
              isSearchable
              name='subCategory'
              styles={selectStyles}
              value={selectedCategories}
              onChange={handleSubCategory}
              placeholder={t('employerCreateJob.selectSubCategory')}
              className={`${errors.subCategory && 'input-error-react-select'}`}
              options={subCategories.map(i => ({ value: i.id, label: i.name }))}
              noOptionsMessage={() => t('employerCreateJob.selectSubCategoryFirst')}
            />
            {
              errors.subCategory && (
                <div className='text-danger'>{errors.subCategory}</div>
              )
            }
          </div>
        )
      }
      <div className='row'>
        <div className='col-lg-4 mb-5 form-outline DashBoardInputBx DashBoardCreatBx'>
          <label id='lng_1' className='form-label' htmlFor='form3Example3'>
            {t('employerCreateJob.language')}
          </label>
          <select
            name='lng_1'
            value={jobBody.lng_1}
            className='form-select'
            onChange={handleChange}
            aria-label='Default select example'
          >
            <option value=''>
              {t('employerCreateJob.selectLanguage')}
            </option>
            {
              langs.map(i => (
                <option key={i.id} value={i.id}>
                  {i.name}
                </option>
              ))
            }
          </select>
        </div>
        <div className='col-lg-4 mb-5 form-outline DashBoardInputBx DashBoardCreatBx'>
          <label id='lng_skill_1' className='form-label' htmlFor='form3Example3'>
            {t('employerCreateJob.proficiency')}
          </label>
          <select
            name='lng_skill_1'
            className='form-select'
            onChange={handleChange}
            value={jobBody.lng_skill_1}
            aria-label='Default select example'
          >
            <option value=''>
              {t('employerCreateJob.selectProficiency')}
            </option>
            {
              lang_skills.map(i => (
                <option key={i.id} value={i.id}>
                  {i.name}
                </option>
              ))
            }
          </select>
        </div>
        <div className='col-lg-4 mb-5 d-flex align-items-center justify-content-center'>
          <button
            type='button'
            disabled={showSecondLanguage}
            className='btn btn-primary button1'
            onClick={() => setShowSecondLanguage(true)}
          >
            {t('employerCreateJob.add')}
          </button>
        </div>
      </div>
      {
        showSecondLanguage && (
          <div className='row'>
            <div className='col-lg-4 mb-5 form-outline DashBoardInputBx DashBoardCreatBx'>
              <label id='lng_2' className='form-label' htmlFor='form3Example3'>
                {t('employerCreateJob.language')}
              </label>
              <select
                name='lng_2'
                value={jobBody.lng_2}
                className='form-select'
                onChange={handleChange}
                aria-label='Default select example'
              >
                <option value=''>
                  {t('employerCreateJob.selectLanguage')}
                </option>
                {
                  langs.map(i => (
                    <option key={i.id} value={i.id}>
                      {i.name}
                    </option>
                  ))
                }
              </select>
            </div>
            <div className='col-lg-4 mb-5 form-outline DashBoardInputBx DashBoardCreatBx'>
              <label id='lng_skill_2' className='form-label' htmlFor='form3Example3'>
                {t('employerCreateJob.proficiency')}
              </label>
              <select
                name='lng_skill_2'
                className='form-select'
                onChange={handleChange}
                value={jobBody.lng_skill_2}
                aria-label='Default select example'
              >
                <option value=''>
                  {t('employerCreateJob.selectProficiency')}
                </option>
                {
                  lang_skills.map(i => (
                    <option key={i.id} value={i.id}>
                      {i.name}
                    </option>
                  ))
                }
              </select>
            </div>
            <div className='col-lg-4 mb-5 d-flex align-items-center justify-content-center'>
              <button
                type='button'
                className='btn btn-primary button1'
                onClick={() => handleRemoveSecondLanguage()}
              >
                {t('employerCreateJob.delete')}
              </button>
            </div>
          </div>
        )
      }
      <div className='form-outline mb-5 DashBoardInputBx DashBoardCreatBx'>
        <label id='experience' className='form-label' htmlFor='form3Example1'>
          {t('employerCreateJob.experience(inYears)')}
          <span className='RedStar'>*</span>
        </label>
        <select
          name='experience'
          onChange={handleChange}
          value={jobBody.experience}
          aria-label='Default select example'
          className={`form-select ${errors.experience && 'input-error'}`}
        >
          <option selected value=''>
            {t('employerCreateJob.chooseExp')}
          </option>
          {
            Object.entries(experience).map(([key, value]) => (
              <option key={key} value={key}>
                {value}
              </option>
            ))
          }
        </select>
        {
          errors.experience && (
            <div className='text-danger'>{errors.experience}</div>
          )
        }
      </div>
      <div className='form-outline mb-5 DashBoardInputBx DashBoardCreatBx'>
        <label id='work_type' className='form-label' htmlFor='form3Example1'>
          {t('employerCreateJob.workType')}
          <span className='RedStar'>*</span>
        </label>
        <select
          name='work_type'
          onChange={handleChange}
          value={jobBody.work_type}
          aria-label='Default select example'
          className={`form-select ${errors.work_type && 'input-error'}`}
        >
          <option selected value=''>
            {t('employerCreateJob.selectWorkType')}
          </option>
          {
            Object.entries(worktype).map(([key, value]) => (
              <option key={key} value={key}>
                {value}
              </option>
            ))
          }
        </select>
        {
          errors.work_type && (
            <div className='text-danger'>{errors.work_type}</div>
          )
        }
      </div>
      <div className='form-outline mb-5 DashBoardInputBx DashBoardCreatBx'>
        <label className='form-label' htmlFor='form3Example1'>
          {t('employerCreateJob.skills')}
        </label>
        <Select
          isMulti
          isSearchable
          name='skill'
          styles={selectStyles}
          value={selectedSkills}
          onChange={handleSkillsChange}
          className='basic-multi-select'
          placeholder={t('employerCreateJob.selectSkills')}
          options={skillList.map(i => ({
            value: i.id,
            label: i.name,
          }))}
        />
      </div>
      <div className='row'>
        <div className='col-lg-4 form-outline mb-5 DashBoardInputBx DashBoardCreatBx'>
          <label id='salary_min' className='form-label' htmlFor='form3Example3'>
            {t('employerCreateJob.annualSalaryFrom')}
          </label>
          <input
            type='text'
            name='salary_min'
            onChange={handleChange}
            value={jobBody.salary_min}
            placeholder={t('employerCreateJob.from')}
            className={`form-control ${errors.salary_min && 'input-error'}`}
          />
          {
            errors.salary_min && (
              <div className='text-danger'>
                {errors.salary_min}
              </div>
            )
          }
        </div>
        <div className='col-lg-4 form-outline mb-5 DashBoardInputBx DashBoardCreatBx'>
          <label id='salary_max' className='form-label' htmlFor='form3Example3'>
            {t('employerCreateJob.annualSalaryTo')}
          </label>
          <input
            type='text'
            name='salary_max'
            onChange={handleChange}
            value={jobBody.salary_max}
            placeholder={t('employerCreateJob.to')}
            className={`form-control ${errors.salary_max && 'input-error'}`}
          />
          {
            errors.salary_max && (
              <div className='text-danger'>
                {errors.salary_max}
              </div>
            )
          }
        </div>
        <div className='col-lg-4 form-outline mb-5 DashBoardInputBx DashBoardCreatBx selectCurrency'>
          <label id='salary_currency' className='form-label' htmlFor='form3Example1'>
            {t('employerCreateJob.selectCurrency')}
            {
              (jobBody.salary_min || jobBody.salary_max) &&
              <span className='RedStar'>*</span>
            }
          </label>
          <select
            name='salary_currency'
            onChange={handleChange}
            value={jobBody.salary_currency}
            aria-label='Default select example'
            className={`form-select ${errors.salary_currency && 'input-error'}`}
          >
            <option selected value=''>
              {t('employerCreateJob.selectCurrency')}
            </option>
            {
              Object.entries(currencies).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))
            }
          </select>
          {
            errors.salary_currency && (
              <div className='text-danger'>{errors.salary_currency}</div>
            )
          }
        </div>
      </div>
      <div className='form-outline mb-5 DashBoardInputBx DashBoardCreatBx APJoditEditor'>
        <label id='job_description' className='form-label' htmlFor='form3Example3'>
          {t('employerCreateJob.jobDescription')}
          <span className='RedStar'>*</span>
        </label>
        <JoditEditor
          ref={editor}
          config={editorConfig}
          name='jobDescription'
          value={jobBody.jobDescription}
          placeholder={t('employerCreateJob.jobDescription')}
          onBlur={jobDescription =>
            handleChange({
              target: {
                value: jobDescription,
                name: 'jobDescription',
              },
            })
          }
        />
        {
          errors.jobDescription && (
            <div className='text-danger'>{errors.jobDescription}</div>
          )
        }
      </div>
      <div className='form-outline mb-5 DashBoardInputBx DashBoardCreatBx'>
        <label id='country' className='form-label' htmlFor='form3Example1'>
          {t('employerCreateJob.country')}
          <span className='RedStar'>*</span>
        </label>
        <select
          name='country'
          value={jobBody.country}
          onChange={e => handleChange(e)}
          aria-label='Default select example'
          className={`form-select ${errors.country && 'input-error'}`}
        >
          <option value=''>
            {t('employerCreateJob.selectCountry')}
          </option>
          {
            countries.map((i, index) => (
              <option value={i.id} key={index}>
                {i.name}
              </option>
            ))
          }
        </select>
        {
          errors.country && (
            <div className='text-danger'>{errors.country}</div>
          )
        }
      </div>
      {
        jobBody.country && (
          <div className='form-outline mb-5 DashBoardInputBx DashBoardCreatBx'>
            <label id='city' className='form-label' htmlFor='form3Example1'>
              {t('employerCreateJob.city')}
              <span className='RedStar'>*</span>
            </label>
            <AsyncSelect
              defaultOptions
              value={selectedCity}
              styles={selectStyles}
              onChange={handleCityChange}
              loadOptions={getCitiesOptions}
              placeholder={t('employerCreateJob.selectCity')}
              className={`${errors.city && 'input-error-react-select'}`}
              noOptionsMessage={() => t('employerCreateJob.enterCityName')}
            />
            {
              errors.city && (
                <div className='text-danger'>{errors.city}</div>
              )
            }
          </div>
        )
      }
      <div className='form-outline mb-4 DashBoardInputBx DashBoardCreatBx'>
        <label id='formLastDate' className='form-label' htmlFor='form3Example3'>
          {t('employerCreateJob.lastDate')}
          <span className='RedStar'>*</span>
        </label>
        <div className='date-picker-wrapper'>
          <input
            type='date'
            name='last_date'
            onChange={handleChange}
            value={jobBody.last_date}
            placeholder={t('employerCreateJob.lastDate')}
            className={`form-control ${errors.last_date && 'input-error'}`}
          />
        </div>
        {
          errors.last_date && (
            <div className='text-danger'>{errors.last_date}</div>
          )
        }
      </div>
      <h3 className='mb-4'>Контактна інформація</h3>
      <div className='form-outline mb-5 DashBoardInputBx DashBoardCreatBx'>
        <label id='contact_name' className='form-label' htmlFor='form3Example3'>
          {t('employerCreateJob.contactName')}
        </label>
        <input
          type='text'
          name='contact_name'
          onChange={handleChange}
          value={jobBody.contact_name}
          placeholder={t('employerCreateJob.contactName')}
          className={`form-control ${errors.contact_name && 'input-error'}`}
        />
        {
          errors.contact_name && (
            <div className='text-danger'>{errors.contact_name}</div>
          )
        }
      </div>
      <div className='form-outline mb-5 DashBoardInputBx DashBoardCreatBx'>
        <label id='contact_email' className='form-label' htmlFor='form3Example3'>
          {t('employerCreateJob.contactEmail')}
        </label>
        <input
          type='text'
          name='contact_email'
          onChange={handleChange}
          value={jobBody.contact_email}
          placeholder={t('employerCreateJob.contactEmail')}
          className={`form-control ${errors.contact_email && 'input-error'}`}
        />
        {
          errors.contact_email && (
            <div className='text-danger'>{errors.contact_email}</div>
          )
        }
      </div>
      <div className='row mb-5'>
        <div className='col-lg-4 form-outline mb-5 mb-lg-0 DashBoardInputBx DashBoardCreatBx'>
          <label id='contact_number' className='form-label' htmlFor='form3Example3'>
            {t('employerCreateJob.contactNumber')}
          </label>
          <input
            type='text'
            name='contact_number'
            onChange={handleChange}
            value={jobBody.contact_number}
            placeholder={t('employerCreateJob.contactNumber')}
            className={`form-control ${errors.contact_number && 'input-error'}`}
          />
        </div>
        <div className='col-lg-4 form-outline mb-5 mb-lg-0 DashBoardInputBx DashBoardCreatBx'>
          <label id='contact_number_2' className='form-label' htmlFor='form3Example3'>
            {t('employerCreateJob.contactNumber')}
          </label>
          <input
            type='text'
            name='contact_number_2'
            onChange={handleChange}
            value={jobBody.contact_number_2}
            placeholder={t('employerCreateJob.contactNumber')}
            className={`form-control ${errors.contact_number_2 && 'input-error'}`}
          />
        </div>
        <div className='col-lg-4 form-outline DashBoardInputBx DashBoardCreatBx'>
          <label id='contact_number_3' className='form-label' htmlFor='form3Example3'>
            {t('employerCreateJob.contactNumber')}
          </label>
          <input
            type='text'
            name='contact_number_3'
            onChange={handleChange}
            value={jobBody.contact_number_3}
            placeholder={t('employerCreateJob.contactNumber')}
            className={`form-control ${errors.contact_number_3 && 'input-error'}`}
          />
        </div>
        {
          (errors.contact_number || errors.contact_number_2 || errors.contact_number_3) && (
            <div className='text-danger'>
              {errors.contact_number || errors.contact_number_2 || errors.contact_number_3}
            </div>
          )
        }
        <div id='emailHelp' className='form-text'>
          Eg: 0671234567 or +380671234567
        </div>
      </div>
      <div className='form-outline mb-5 DashBoardInputBx DashBoardCreatBx'>
        <label id='form_link' className='form-label' htmlFor='form3Example3'>
          {t('employerCreateJob.formLink')}
        </label>
        <input
          type='text'
          name='form_link'
          onChange={handleChange}
          value={jobBody.form_link}
          placeholder={t('employerCreateJob.formLink')}
          className={`form-control ${errors.form_link && 'input-error'}`}
        />
        {
          errors.form_link && (
            <div className='text-danger'>{errors.form_link}</div>
          )
        }
      </div>
      <div className='bottomButtons'>
        <button
          type='button'
          className='btn btn-primary button1'
          onClick={() => handleSubmit(jobBody)}
        >
          {t('employerCreateJob.postButton')}
        </button>
        <button
          type='button'
          onClick={() => handleOpen()}
          className='btn btn-primary button2'
        >
          {t('employerCreateJob.previewButton')}
        </button>
        <button
          type='button'
          className='btn btn-primary button2'
          onClick={() => window.history.back()}
        >
          {t('employerCreateJob.cancelButton')}
        </button>
        <div>
          <Modal
            open={open}
            onClose={handleClose}
            className='modalMain standartListStyle'
          >
            <Box
              className='modal adminModal modal-content'
              sx={{
                width: '100%',
                maxHeight: '80vh',
                maxWidth: '1320px',
              }}
            >
              <IconButton
                onClick={handleClose}
                className='close-button'
                style={{
                  top: 0,
                  right: 0,
                  position: 'absolute',
                }}
              >
                &times;
              </IconButton>
              <Box
                className='col-lg-7 col-md-7'
                sx={{
                  p: '20px',
                  width: '100%',
                  borderRadius: '5px',
                  background: '#f1f1f1',
                }}
              >
                <div className='single-job-detail'>
                  <div className='logo-detail'>
                    <img
                      alt='Company name'
                      className='JDImage'
                      src={jobBody.logo ? jobBody.logo : '/Images/jobseekerSide/dummy-profile.png'}
                    />
                  </div>
                  <div className='job-meta-detail'>
                    <h2>
                      {
                        jobBody.job_title
                          ? jobBody.job_title
                          : t('jobDescription.notAvailable')
                      }
                    </h2>
                    <p>
                      <i className='fa-sharp fa-solid fa-briefcase'></i>{' '}
                      {
                        jobBody.experience
                          ? (`${jobBody.experience} ${t('membershipPlan.years')}`)
                          : t('jobDescription.notAvailable')
                      }
                    </p>
                    <p>
                      <i className='fa-sharp fa-solid fa-location-dot'></i>{' '}
                      {
                        `${selectedCity.label !== undefined ? `${selectedCity.label}, ` : ''}
                        ${getDefaultCountryName(jobBody.country)}`
                      }
                    </p>
                    <p>
                      <i className='fa-solid fa-calendar-days'></i>{' '}
                      {
                        jobBody.last_date
                          ? jobBody.last_date
                          : t('jobDescription.notAvailable')
                      }
                    </p>
                  </div>
                </div>
              </Box>
              <div className='container JDSection2'>
                <div className='row'>
                  <div className='col-lg-8 col-md-8'>
                    <div className='JobDescriptionBx'>
                      <h2 className='mb-4'>{t('jobDescription.jobDescription')}</h2>
                      {
                        jobBody.jobDescription
                          ? HTMLReactParser(jobBody.jobDescription)
                          : t('jobDescription.notAvailable')
                      }
                      <h2 className='mt-5 mb-4'>{t('jobDescription.skillRequired')}</h2>
                      <div>
                        {
                          selectedSkills.length > 0
                            ? selectedSkills.map((i, index) => (
                              i !== undefined && (
                                <p className='keySkillitem' key={index}>
                                  <i className='fa-solid fa-arrow-right' />
                                  {' '}
                                  {i.label}
                                </p>
                              )
                            ))
                            : t('jobDescription.notAvailable')
                        }
                      </div>
                      {
                        jobBody.lng_1 && (
                          <div>
                            <h2 className='mt-5 mb-4'>{t('employerCreateJob.additionalLanguages')}</h2>
                            <p className='keySkillitem'>
                              <i className='fa-solid fa-arrow-right'></i>
                              {' '}
                              {getLangName(jobBody.lng_1)}
                              {' - '}
                              {getLangSkillName(jobBody.lng_skill_1)}
                            </p>
                            {
                              jobBody.lng_2 && (
                                <p className='keySkillitem'>
                                  <i className='fa-solid fa-arrow-right'></i>
                                  {' '}
                                  {getLangName(jobBody.lng_2)}
                                  {' - '}
                                  {getLangSkillName(jobBody.lng_skill_2)}
                                </p>
                              )
                            }
                          </div>
                        )
                      }
                    </div>
                  </div>
                  <Box className='col-lg-4 col-md-4' sx={{ pt: '20px' }}>
                    <Box className='card Jcard JobDetailsCard'>
                      <div className='card-title'>
                        <h2>
                          {t('jobDescription.jobOverview')}
                        </h2>
                      </div>
                      <div className='card-body JcardBody'>
                        <div className='JobDetailsInfo'>
                          <ul>
                            <li>
                              <span className='jobDescriptionKeys'>
                                <i className='fa-solid fa-calendar-days'></i> {t('jobDescription.datePosted')}:{' '}
                              </span>
                              {
                                jobBody.last_date
                                  ? jobBody.last_date
                                  : t('jobDescription.notAvailable')
                              }
                            </li>
                            <li>
                              <span className='jobDescriptionKeys'>
                                <i className='fa-solid fa-sitemap'></i> {t('jobDescription.category')}:{' '}
                              </span>
                              {
                                jobBody.category
                                  ? getCategoryName(jobBody.category)
                                  : t('jobDescription.notAvailable')
                              }
                            </li>
                            <li>
                              <span className='jobDescriptionKeys'>
                                <i className='fa-solid fa-sitemap'></i> {t('jobDescription.subCategory')}:{' '}
                              </span>
                              {
                                selectedCategories.length > 0
                                  ? selectedCategories.map((i, index) => (
                                    i !== undefined && (
                                      <p className='keySkillitem' key={index}>
                                        <i className='fa-solid fa-arrow-right'></i> {i.label}
                                      </p>
                                    )
                                  ))
                                  : t('jobDescription.notAvailable')
                              }
                            </li>
                            <li>
                              <span className='jobDescriptionKeys'>
                                <i className='fa-solid fa-clock'></i> {t('jobDescription.jobType')}:{' '}
                              </span>
                              {
                                jobBody.work_type
                                  ? getWorkType(jobBody.work_type)
                                  : t('jobDescription.notAvailable')
                              }
                            </li>
                            <li>
                              <span className='jobDescriptionKeys'>
                                <i className='fa-solid fa-dollar-sign'></i>{' '}
                                {t('jobDescription.salary')}:{' '}
                              </span>
                              {
                                jobBody.salary_min && jobBody.salary_max && jobBody.salary_currency
                                  ? (
                                    `${jobBody.salary_min}-${jobBody.salary_max}
                                    ${getSalaryCurrencyName(jobBody.salary_currency)}`
                                  )
                                  : t('jobDescription.notAvailable')
                              }
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Box>
                    <div className='card Jcard JobDetailsCard'>
                      <div className='card-title'>
                        <h2>
                          {t('jobDescription.contactInfo')}
                        </h2>
                      </div>
                      <div className='card-body JcardBody'>
                        <div className='JobDetailsInfo'>
                          <ul>
                            <li>
                              <span className='jobDescriptionKeys'>
                                <i className='fa-solid fa-users'></i> {t('jobDescription.companyName')}:{' '}
                              </span>
                              {
                                jobBody.company_name
                                  ? jobBody.company_name
                                  : t('jobDescription.notAvailable')
                              }
                            </li>
                            <li>
                              <span className='jobDescriptionKeys'>
                                <i className='fa-solid fa-user'></i> {t('jobDescription.recruiterName')}:{' '}
                              </span>
                              {
                                jobBody.contact_name
                                  ? jobBody.contact_name
                                  : t('jobDescription.notAvailable')
                              }
                            </li>
                            <li>
                              <span className='jobDescriptionKeys'>
                                <i className='fa-solid fa-phone'></i> {t('jobDescription.contactCompany')}:{' '}
                              </span>
                              {
                                jobBody.contact_number
                                  ? (
                                    <a href={`tel:${jobBody.contact_number}`}>
                                      {jobBody.contact_number}
                                    </a>
                                  )
                                  : t('jobDescription.notAvailable')
                              }
                            </li>
                            <li>
                              <span className='jobDescriptionKeys'>
                                <i className='fa-solid fa-share'></i> {t('jobDescription.website')}:{' '}
                              </span>
                              {
                                jobBody.company_website
                                  ? (
                                    <a href={jobBody.company_website} target='blank'>
                                      {jobBody.company_website}
                                    </a>
                                  )
                                  : t('jobDescription.notAvailable')
                              }
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Box>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
    </form>
  );
};

export default CreateEditJobForm;
