import axios from 'axios';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
// api
import ApiKey from '../../../api/ApiKey';
import BaseApi from '../../../api/BaseApi';
// components
import Footer from '../../../element/Footer';
import NavBar from '../../../element/NavBar';
// hooks
import { useWindowSize } from '../../../../Hooks/use-window-size';
// mui
import { Box, Typography } from '@mui/material';
// settings
import { ourLifeSettings } from './settings/ourLife';
import { advantagesSettings } from './settings/advantages';

const DHLBrandedPage = () => {
  const { slug } = useParams();
  const { width } = useWindowSize();
  const { t } = useTranslation('global');
  const tokenKey = Cookies.get('tokenClient');

  const [loading, setLoading] = useState(false);
  const [companyJobs, setCompanyJobs] = useState([]);
  const [companyDetails, setCompanyDetails] = useState([]);

  const getCurrentMonthYear = () => {
    const months = [
      'січень', 'лютий', 'березень', 'квітень', 'травень', 'червень',
      'липень', 'серпень', 'вересень', 'жовтень', 'листопад', 'грудень',
    ];

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = months[currentDate.getMonth()];

    return `${month} ${year}`;
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);

        const response = await axios.post(
          BaseApi + `/candidates/companyprofile/${slug}`,
          null,
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              'Content-Type': 'application/json',
            },
          },
        );

        const { jobDetails, userDetails } = response.data.response;

        const metaTitle = document.querySelector('title');
        const metaDescription = document.querySelector('meta[name="description"]');
        const metaTitleValue = `${userDetails.company_name} з відкритими ${jobDetails.length} вакансіями станом на ${getCurrentMonthYear()} | GRC.ua`; // eslint-disable-line
        const metaDescriptionValue = `${userDetails.company_name} здійснює свою діяльність в місті ${userDetails.address}. Ознайомтеся докладніше на сайті GRC.ua`; // eslint-disable-line

        if (metaTitle) {
          metaTitle.textContent = metaTitleValue;
        }

        if (metaDescription) {
          metaDescription.content = metaDescriptionValue;
        }

        setLoading(false);
        setCompanyJobs(response.data.response.jobDetails);
        setCompanyDetails(response.data.response.userDetails);
      } catch (error) {
        setLoading(false);

        console.error('Error getting company profile', error);
      }
    };

    getData();

    window.scrollTo(0, 0);
  }, [slug, tokenKey, companyJobs.length]);

  return (
    <>
      <NavBar />
      {
        loading
          ? (
            <div className='loader-container'></div>
          ) : (
            <>
              <div className='blogPageSection1 text-center'>
                <h1>{t('companyProfile.companyProfile')}{' '}{companyDetails.company_name}</h1>
                <h6 className='text-muted mt-2'>
                  {' '}
                  <Link to='/' style={{ color: 'grey' }}>
                    {t('navHeaders.home')}
                  </Link>
                  {' / '}
                  {t('companyProfile.companyProfile')}
                </h6>
              </div>
              <Box sx={{ mb: '24px', mx: 'auto', maxWidth: '1250px' }}>
                <Box
                  sx={{
                    px: '10px',
                    backgroundColor: '#FFCB05',
                    pb: { xs: '82px', lg: '162px' },
                    pt: { xs: '20px', lg: '306px' },
                    mb: { xs: '40px', lg: '80px' },
                    width: { xs: '100%', lg: 'auto' },
                    backgroundImage: {
                      xs: 'none',
                      lg: 'url("/Images/brandedPages/DHL/dhl-hero(desktop).png")',
                    },
                    backgroundSize: { xs: '100% 100%' },
                  }}
                >
                  {
                    width < 1200 && (
                      <Box sx={{ mx: 'auto', width: '100%', height: '100%', maxWidth: '645px', maxHeight: '386px' }}>
                        <img alt='dhl-hero.png' src='/Images/brandedPages/DHL/dhl-hero(mobile).png' />
                      </Box>
                    )
                  }
                  <Typography
                    sx={{
                      color: '#E50000',
                      maxWidth: '614px',
                      fontWeight: 'bold',
                      lineHeight: '115%',
                      textAlign: 'center',
                      mx: { xs: 'auto', lg: 0 },
                      ml: { lg: 'auto' },
                      fontSize: { xs: '24px', lg: '32px' },
                      fontFamily: '"Trebuchet MS", sans-serif',
                    }}
                  >
                    ЛОГІСТИКА — ЦЕ ПОСЛУГИ ДОСТАВКИ В ПОТРІБНЕ МІСЦЕ В ПОТРІБНИЙ ЧАС
                  </Typography>
                </Box>
                <Box sx={{ mx: 'auto', maxWidth: '986px' }}>
                  <Typography
                    sx={{
                      mb: '40px',
                      mx: 'auto',
                      color: '#E50000',
                      fontSize: '30px',
                      maxWidth: '614px',
                      fontWeight: 'bold',
                      lineHeight: '115%',
                      textAlign: 'center',
                      fontFamily: '"Trebuchet MS", sans-serif',
                    }}
                  >
                    ПРО КОМПАНІЮ
                  </Typography>
                  <Box
                    sx={{
                      mb: '40px',
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: { xs: 'column', lg: 'row' },
                    }}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        maxWidth: '548px',
                        maxHeight: '323px',
                        mr: { xs: 0, lg: '-62px' },
                      }}
                    >
                      <img alt='about-1.png' src='/Images/brandedPages/DHL/about-1.png' />
                    </Box>
                    <Box sx={{ p: '34px 22px', background: '#FFCB05' }}>
                      <Typography
                        sx={{
                          mb: '32px',
                          color: '#252525',
                          fontSize: '18px',
                          maxWidth: '336px',
                          fontWeight: 'bold',
                          lineHeight: '115%',
                          textAlign: 'start',
                          fontFamily: '"Trebuchet MS", sans-serif',
                        }}
                      >
                        DHL Express надає логістичні послуги в галузі експрес-доставки
                      </Typography>
                      <Typography
                        sx={{
                          color: '#252525',
                          fontSize: '15px',
                          fontWeight: '400',
                          maxWidth: '456px',
                          lineHeight: '115%',
                          textAlign: 'start',
                          fontFamily: '"Trebuchet MS", sans-serif',
                        }}
                      >
                        Сьогодні компанія DHL постійно розвивається і є міжнародним лідером в області логістики
                        та експрес-доставки, що діє в масштабах усього світу. На даний момент налічує більше 100
                        тисяч сервісних пунктів, 280 літаків, 500 аеропортів, близько 500 тисяч співробітників в
                        більш ніж 220 країнах по всьому світу, що робить нас однією з 10 найбільших
                        компаній-роботодавців у світі.
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      mb: '40px',
                      display: 'flex',
                      alignItems: 'center',
                      position: 'relative',
                      height: { xs: 'auto', lg: '343px' },
                      flexDirection: { xs: 'column-reverse', lg: 'row' },
                    }}
                  >
                    <Box sx={{ zIndex: 1, p: '34px 22px', background: '#FFCB05' }}>
                      <Typography
                        sx={{
                          mb: '32px',
                          color: '#252525',
                          fontSize: '18px',
                          maxWidth: '426px',
                          fontWeight: 'bold',
                          lineHeight: '115%',
                          textAlign: 'start',
                          fontFamily: '"Trebuchet MS", sans-serif',
                        }}
                      >
                        DHL Express в Україні ровесник Незалежності нашої держави!
                      </Typography>
                      <Typography
                        sx={{
                          color: '#252525',
                          fontSize: '15px',
                          fontWeight: '400',
                          maxWidth: '460px',
                          lineHeight: '115%',
                          textAlign: 'start',
                          fontFamily: '"Trebuchet MS", sans-serif',
                        }}
                      >
                        Компанія почала працювати у 1991 році та досягла значного успіху. За цей час територія,
                        що обслуговується компанією, розширилася до більш ніж 100 населених пунктів по Україні,
                        наші відділення працюють у 28 містах нашої країни. Ми маємо більш ніж 115 автомобілів та
                        один з найпотужніших в Європі сортувальний центр вхідних та вихідних вантажів. На даний час
                        в компанії працює понад 400 співробітників.
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        zIndex: 0,
                        left: '508px',
                        width: '100%',
                        height: '100%',
                        maxWidth: '616px',
                        maxHeight: '343px',
                        ml: { xs: 0, lg: '-62px' },
                        position: { xs: 'static', lg: 'absolute' },
                      }}
                    >
                      <img alt='about-2.png' src='/Images/brandedPages/DHL/about-2.png' />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      mb: '80px',
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: { xs: 'column', lg: 'row' },
                    }}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        maxWidth: '548px',
                        maxHeight: '530px',
                        mr: { xs: 0, lg: '-62px' },
                      }}
                    >
                      <img alt='about-3.png' src='/Images/brandedPages/DHL/about-3.png' />
                    </Box>
                    <Box sx={{ p: '24px 22px', background: '#FFCB05' }}>
                      <Typography
                        sx={{
                          mb: '18px',
                          color: '#252525',
                          fontSize: '18px',
                          maxWidth: '336px',
                          fontWeight: 'bold',
                          lineHeight: '115%',
                          textAlign: 'start',
                          fontFamily: '"Trebuchet MS", sans-serif',
                        }}
                      >
                        Наше теперішнє життя
                      </Typography>
                      <Typography
                        sx={{
                          mb: '18px',
                          color: '#252525',
                          fontSize: '15px',
                          fontWeight: '400',
                          maxWidth: '456px',
                          lineHeight: '115%',
                          textAlign: 'start',
                          fontFamily: '"Trebuchet MS", sans-serif',
                        }}
                      >
                        З початком вторгнення РФ на територію нашої Держави наше життя змінилося, як у більшості
                        наших співвітчизників.
                      </Typography>
                      <Typography
                        sx={{
                          mb: '18px',
                          color: '#252525',
                          fontSize: '15px',
                          fontWeight: '400',
                          maxWidth: '456px',
                          lineHeight: '115%',
                          textAlign: 'start',
                          fontFamily: '"Trebuchet MS", sans-serif',
                        }}
                      >
                        Компанія DHL Express в такий важкий для всіх час дуже підтримує своїх
                        співробітників. Не зважаючи на те, що компанія зараз не працює на повну потужність, ми
                        зберігаємо всі робочі місця, надаємо медичне страхування, всім працівникам виплачується
                        заробітна плата та була виплачена грошова допомога.
                      </Typography>
                      <Typography
                        sx={{
                          mb: '18px',
                          color: '#252525',
                          fontSize: '15px',
                          fontWeight: '400',
                          maxWidth: '456px',
                          lineHeight: '115%',
                          textAlign: 'start',
                          fontFamily: '"Trebuchet MS", sans-serif',
                        }}
                      >
                        Ми цінуємо кожного співробітника і чекаємо того часу коли ми знову зможемо працювати
                        на повну потужність. Компанія DHL Express і кожен наш співробітник на своєму місці
                        робить все можливе для скорішої перемоги над ворогом. В лавах ЗСУ та Тероборони є наші
                        працівники, які боронять нашу країну від ворога зі зброєю у руках, а також ті які
                        займаються волонтерською діяльністю.
                      </Typography>
                      <Typography
                        sx={{
                          mb: '18px',
                          color: '#252525',
                          fontSize: '15px',
                          fontWeight: '400',
                          maxWidth: '456px',
                          lineHeight: '115%',
                          textAlign: 'start',
                          fontFamily: '"Trebuchet MS", sans-serif',
                        }}
                      >
                        Ми пишаємося нашою компанією та нашими колегами і віримо у швидку Перемогу над ворогом!
                      </Typography>
                      <Typography
                        sx={{
                          color: '#252525',
                          fontSize: '15px',
                          maxWidth: '456px',
                          fontWeight: 'bold',
                          lineHeight: '115%',
                          textAlign: 'start',
                          fontFamily: '"Trebuchet MS", sans-serif',
                        }}
                      >
                        Все буде Україна!
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ mx: 'auto', maxWidth: '986px' }}>
                  <Typography
                    sx={{
                      mb: '40px',
                      mx: 'auto',
                      color: '#E50000',
                      fontSize: '30px',
                      maxWidth: '614px',
                      fontWeight: 'bold',
                      lineHeight: '115%',
                      textAlign: 'center',
                      fontFamily: '"Trebuchet MS", sans-serif',
                    }}
                  >
                    НАША ДІЯЛЬНІСТЬ
                  </Typography>
                  <Box
                    sx={{
                      mb: '80px',
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: { xs: 'column', lg: 'row' },
                    }}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        maxWidth: '545px',
                        maxHeight: '323px',
                        mr: { xs: 0, lg: '-62px' },
                      }}
                    >
                      <img alt='activity-1.png' src='/Images/brandedPages/DHL/activity-1.png' />
                    </Box>
                    <Box sx={{ p: '62px 22px', background: '#FFCB05' }}>
                      <Typography
                        sx={{
                          color: '#252525',
                          fontSize: '15px',
                          fontWeight: '400',
                          maxWidth: '456px',
                          lineHeight: '115%',
                          textAlign: 'start',
                          fontFamily: '"Trebuchet MS", sans-serif',
                        }}
                      >
                        Це міжнародна експрес доставка документів і товарів по всьому світу, доставляючи вантажі
                        завжди вчасно від дверей до дверей. Будучи глобальним мережевим оператором, ми застосовуємо
                        стандартизовані процеси і постійно оптимізуємо наші послуги, щоб виконувати дані клієнту
                        обіцянки. Все це, а також дотримання принципу «клієнт — у центрі уваги» робить нас провідним
                        постачальником послуг у сфері міжнародної доставки.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ mx: 'auto', maxWidth: '986px' }}>
                  <Typography
                    sx={{
                      mb: '40px',
                      mx: 'auto',
                      color: '#E50000',
                      fontSize: '30px',
                      maxWidth: '614px',
                      fontWeight: 'bold',
                      lineHeight: '115%',
                      textAlign: 'center',
                      fontFamily: '"Trebuchet MS", sans-serif',
                    }}
                  >
                    ІСТОРІЯ КОМПАНІЇ
                  </Typography>
                  <Box
                    sx={{
                      mb: '80px',
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: { xs: 'column-reverse', lg: 'row' },
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          mb: '18px',
                          color: '#252525',
                          fontSize: '15px',
                          fontWeight: '400',
                          lineHeight: '115%',
                          textAlign: 'start',
                          p: { xs: '22px 22px 0', lg: 0 },
                          maxWidth: { xs: '456px', lg: '421px' },
                          fontFamily: '"Trebuchet MS", sans-serif',
                        }}
                      >
                        У 1969 році  було покладено початок індустрії міжнародної експрес-доставки і компанії DHL.
                      </Typography>
                      <Typography
                        sx={{
                          mb: '18px',
                          color: '#252525',
                          fontSize: '15px',
                          fontWeight: '400',
                          lineHeight: '115%',
                          textAlign: 'start',
                          px: { xs: '22px', lg: 0 },
                          maxWidth: { xs: '456px', lg: '421px' },
                          fontFamily: '"Trebuchet MS", sans-serif',
                        }}
                      >
                        Адріан Далсі, Ларрі Хіллблома і Роберта Лінн створили інноваційну послугу авіадоставки
                        супровідних документів на термінові вантажі з Сан-Франциско в Гонолулу і назад. Партнери
                        вирішили скоротити час митного декларування вантажів за рахунок збільшення швидкості доставки
                        документів, необхідних для митниці. Завдяки терміновій кур&apos;єрській доставці папери
                        надходили на митний пункт ще до фактичного прибуття вантажів у порт призначення, прискорюючи
                        процес випуску товарів.
                      </Typography>
                      <Typography
                        sx={{
                          color: '#252525',
                          fontSize: '15px',
                          fontWeight: '400',
                          lineHeight: '115%',
                          textAlign: 'start',
                          px: { xs: '22px', lg: 0 },
                          maxWidth: { xs: '456px', lg: '421px' },
                          fontFamily: '"Trebuchet MS", sans-serif',
                        }}
                      >
                        20 вересня 1969 року Далсі, Хіллблом і Лінн зареєстрували свою компанію, назвавши її за
                        першими літерами прізвищ.
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        maxWidth: '545px',
                        maxHeight: '323px',
                        ml: { xs: 0, lg: '20px' },
                      }}
                    >
                      <img alt='history-1.png' src='/Images/brandedPages/DHL/history-1.png' />
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ mx: 'auto', maxWidth: '1220px' }}>
                  <Typography
                    sx={{
                      mb: '40px',
                      mx: 'auto',
                      color: '#E50000',
                      fontSize: '30px',
                      maxWidth: '614px',
                      fontWeight: 'bold',
                      lineHeight: '115%',
                      textAlign: 'center',
                      fontFamily: '"Trebuchet MS", sans-serif',
                    }}
                  >
                    НАШІ ПЕРЕВАГИ
                  </Typography>
                  <Box sx={{ mb: '80px', gap: '20px', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                    {
                      advantagesSettings.map(({ text, image, bgColor }) => (
                        <Box
                          key={image}
                          sx={{
                            width: '290px',
                            display: 'flex',
                            height: '130px',
                            borderRadius: '3px',
                            alignItems: 'center',
                            backgroundColor: bgColor,
                            justifyContent: 'center',
                            border: '2px solid #FFCB05',
                          }}
                        >
                          <Box sx={{ mr: '20px', width: '52px', height: '52px' }}>
                            <img alt={image} src={`/Images/brandedPages/DHL/${image}`} />
                          </Box>
                          <Typography
                            sx={{
                              color: '#000000',
                              fontSize: '15px',
                              maxWidth: '170px',
                              fontWeight: '400',
                              lineHeight: '115%',
                              textAlign: 'center',
                              fontFamily: '"Trebuchet MS", sans-serif',
                            }}
                          >
                            {text}
                          </Typography>
                        </Box>
                      ))
                    }
                  </Box>
                </Box>
                <Box sx={{ mx: 'auto', maxWidth: '986px' }}>
                  <Typography
                    sx={{
                      mb: '40px',
                      mx: 'auto',
                      color: '#E50000',
                      fontSize: '30px',
                      maxWidth: '614px',
                      fontWeight: 'bold',
                      lineHeight: '115%',
                      textAlign: 'center',
                      fontFamily: '"Trebuchet MS", sans-serif',
                    }}
                  >
                    НАШІ НАГОРОДИ ТА ВІДЗНАКИ
                  </Typography>
                  {
                    width >= 1200
                      ? (
                        <Box sx={{ mb: '80px', height: '430px', position: 'relative' }}>
                          <Box sx={{ top: 0, right: 0, width: '905px', height: '360px', position: 'absolute' }}>
                            <Box sx={{ width: '905px', height: '360px' }}>
                              <img alt='awards-top.png' src='/Images/brandedPages/DHL/awards-top.png' />
                            </Box>
                          </Box>
                          <Typography
                            sx={{
                              top: '50px',
                              left: '90px',
                              color: '#252525',
                              fontSize: '15px',
                              fontWeight: '400',
                              maxWidth: '636px',
                              lineHeight: '115%',
                              textAlign: 'start',
                              position: 'absolute',
                              fontFamily: '"Trebuchet MS", sans-serif',
                            }}
                          >
                            Наша компанія
                            <span style={{ fontWeight: 'bold' }}>
                              {' '}DHL Express Україна{' '}
                            </span>
                            два роки поспіль 2021 – 2022 отримувала міжнародну відзнаку
                            <span style={{ fontWeight: 'bold' }}>
                              {' '}Great Place to Work.
                            </span>
                          </Typography>
                          <Box sx={{ bottom: 0, left: 0, width: '660px', height: '280px', position: 'absolute' }}>
                            <Box sx={{ width: '660px', height: '280px' }}>
                              <img alt='awards-bottom.png' src='/Images/brandedPages/DHL/awards-bottom.png' />
                            </Box>
                          </Box>
                          <Typography
                            sx={{
                              bottom: '88px',
                              right: '288px',
                              color: '#252525',
                              fontSize: '15px',
                              fontWeight: '400',
                              maxWidth: '228px',
                              lineHeight: '115%',
                              textAlign: 'start',
                              position: 'absolute',
                              fontFamily: '"Trebuchet MS", sans-serif',
                            }}
                          >
                            <span style={{ fontWeight: 'bold' }}>
                            DHL Express Україна{' '}
                            </span>
                            визнана найкращим роботодавцем у сфері транспорту та логістики за результатами дослідження
                            <span style={{ fontWeight: 'bold' }}>
                              {' '}Randstad Employer Brand Research Ukraine — 2021.
                            </span>
                          </Typography>
                        </Box>
                      ) : (
                        <Box sx={{ mb: '80px' }}>
                          <Box
                            sx={{
                              mb: '22px',
                              mx: 'auto',
                              width: '100%',
                              height: '100%',
                              maxWidth: '224px',
                              maxHeight: '360px',
                            }}
                          >
                            <img alt='awards-top(mobile).png' src='/Images/brandedPages/DHL/awards-top(mobile).png' />
                          </Box>
                          <Typography
                            sx={{
                              mb: '22px',
                              mx: 'auto',
                              px: '22px',
                              color: '#252525',
                              fontSize: '15px',
                              fontWeight: '400',
                              maxWidth: '636px',
                              lineHeight: '115%',
                              textAlign: 'start',
                              fontFamily: '"Trebuchet MS", sans-serif',
                            }}
                          >
                            Наша компанія
                            <span style={{ fontWeight: 'bold' }}>
                              {' '}DHL Express Україна{' '}
                            </span>
                            два роки поспіль 2021 – 2022 отримувала міжнародну відзнаку
                            <span style={{ fontWeight: 'bold' }}>
                              {' '}Great Place to Work.
                            </span>
                          </Typography>
                          <Box
                            sx={{
                              mb: '22px',
                              mx: 'auto',
                              width: '100%',
                              height: '100%',
                              maxWidth: '464px',
                              maxHeight: '280px',
                            }}
                          >
                            <img
                              alt='awards-bottom(mobile).png'
                              src='/Images/brandedPages/DHL/awards-bottom(mobile).png'
                            />
                          </Box>
                          <Typography
                            sx={{
                              mx: 'auto',
                              px: '22px',
                              color: '#252525',
                              fontSize: '15px',
                              fontWeight: '400',
                              maxWidth: '636px',
                              lineHeight: '115%',
                              textAlign: 'start',
                              fontFamily: '"Trebuchet MS", sans-serif',
                            }}
                          >
                            <span style={{ fontWeight: 'bold' }}>
                            DHL Express Україна{' '}
                            </span>
                            визнана найкращим роботодавцем у сфері транспорту та логістики за результатами дослідження
                            <span style={{ fontWeight: 'bold' }}>
                              {' '}Randstad Employer Brand Research Ukraine — 2021.
                            </span>
                          </Typography>
                        </Box>
                      )
                  }
                </Box>
                <Box sx={{ mb: '80px', mx: 'auto', maxWidth: '986px' }}>
                  <Typography
                    sx={{
                      mb: '40px',
                      mx: 'auto',
                      color: '#E50000',
                      fontSize: '30px',
                      maxWidth: '614px',
                      fontWeight: 'bold',
                      lineHeight: '115%',
                      textAlign: 'center',
                      fontFamily: '"Trebuchet MS", sans-serif',
                    }}
                  >
                    НАШЕ ЖИТТЯ ТА КУЛЬТУРА
                  </Typography>
                  <Box sx={{ mb: '40px', gap: '20px', display: 'flex', flexDirection: 'column' }}>
                    {
                      ourLifeSettings.map(({ image, title, text1, text2, text3, flexDirection }) => (
                        <Box
                          key={image}
                          sx={{ gap: '20px', display: 'flex', alignItems: 'center', flexDirection: flexDirection }}
                        >
                          <Box sx={{ width: '100%', height: '100%', maxWidth: '480px', maxHeight: '342px' }}>
                            <img alt={image} src={`/Images/brandedPages/DHL/${image}`} />
                          </Box>
                          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography
                              sx={{
                                mb: '20px',
                                color: '#E50000',
                                fontSize: '18px',
                                maxWidth: '468px',
                                fontWeight: 'bold',
                                lineHeight: '115%',
                                textAlign: 'start',
                                px: { xs: '22px', lg: 0 },
                                textTransform: 'uppercase',
                                fontFamily: '"Trebuchet MS", sans-serif',
                              }}
                            >
                              {title}
                            </Typography>
                            <Typography
                              sx={{
                                color: '#252525',
                                fontSize: '15px',
                                fontWeight: '400',
                                maxWidth: '482px',
                                lineHeight: '115%',
                                textAlign: 'start',
                                mb: text2 && '18px',
                                px: { xs: '22px', lg: 0 },
                                fontFamily: '"Trebuchet MS", sans-serif',
                              }}
                            >
                              {text1}
                            </Typography>
                            {
                              text2 && (
                                <Typography
                                  sx={{
                                    mb: '18px',
                                    color: '#252525',
                                    fontSize: '15px',
                                    fontWeight: '400',
                                    maxWidth: '482px',
                                    lineHeight: '115%',
                                    textAlign: 'start',
                                    px: { xs: '22px', lg: 0 },
                                    fontFamily: '"Trebuchet MS", sans-serif',
                                  }}
                                >
                                  {text2}
                                </Typography>
                              )
                            }
                            {
                              text3 && (
                                <Typography
                                  sx={{
                                    color: '#252525',
                                    fontSize: '15px',
                                    fontWeight: '400',
                                    maxWidth: '482px',
                                    lineHeight: '115%',
                                    textAlign: 'start',
                                    px: { xs: '22px', lg: 0 },
                                    fontFamily: '"Trebuchet MS", sans-serif',
                                  }}
                                >
                                  {text3}
                                </Typography>
                              )
                            }
                          </Box>
                        </Box>
                      ))
                    }
                  </Box>
                  <Typography
                    sx={{
                      mb: '40px',
                      color: '#E50000',
                      fontSize: '18px',
                      maxWidth: '100%',
                      fontWeight: 'bold',
                      lineHeight: '115%',
                      textAlign: 'center',
                      textTransform: 'uppercase',
                      fontFamily: '"Trebuchet MS", sans-serif',
                    }}
                  >
                    НАШЕ ЖИТТЯ
                  </Typography>
                  <Box
                    sx={{
                      mb: '24px',
                      gap: '24px',
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Box sx={{  width: '100%', height: '100%', maxWidth: '312px', maxHeight: '342px' }}>
                      <img alt='our-life-4.png' src='/Images/brandedPages/DHL/our-life-4.png'/>
                    </Box>
                    <Box sx={{  width: '100%', height: '100%', maxWidth: '312px', maxHeight: '342px' }}>
                      <img alt='our-life-4.png' src='/Images/brandedPages/DHL/our-life-5.png'/>
                    </Box>
                    <Box sx={{  width: '100%', height: '100%', maxWidth: '312px', maxHeight: '342px' }}>
                      <img alt='our-life-4.png' src='/Images/brandedPages/DHL/our-life-6.png'/>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      mb: '24px',
                      gap: '24px',
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: { xs: 'column', lg: 'row' },
                    }}
                  >
                    <Box sx={{ width: '100%', height: '100%', maxWidth: '448px', maxHeight: '342px' }}>
                      <img alt='our-life-5.png' src='/Images/brandedPages/DHL/our-life-7.png'/>
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          mb: '18px',
                          color: '#252525',
                          fontSize: '15px',
                          fontWeight: '400',
                          maxWidth: '506px',
                          lineHeight: '115%',
                          textAlign: 'start',
                          px: { xs: '22px', lg: 0 },
                          fontFamily: '"Trebuchet MS", sans-serif',
                        }}
                      >
                        В нашій компанії існує принцип:
                      </Typography>
                      <Typography
                        sx={{
                          mb: '18px',
                          color: '#252525',
                          fontSize: '15px',
                          fontWeight: '400',
                          maxWidth: '506px',
                          lineHeight: '115%',
                          textAlign: 'start',
                          fontStyle: 'italic',
                          px: { xs: '22px', lg: 0 },
                          fontFamily: '"Trebuchet MS", sans-serif',
                        }}
                      >
                        «<span style={{ color: '#E50000' }}>Best Day Every Day</span>» — це не тільки виконання
                        своїх посадових обов’язків, а і вміння проводити вільний час разом у дружньому колективі
                        на наших різноманітних заходах.
                      </Typography>
                      <Typography
                        sx={{
                          mb: '18px',
                          color: '#252525',
                          fontSize: '15px',
                          fontWeight: '400',
                          maxWidth: '506px',
                          lineHeight: '115%',
                          textAlign: 'start',
                          px: { xs: '22px', lg: 0 },
                          fontFamily: '"Trebuchet MS", sans-serif',
                        }}
                      >
                        На даний час всі наші корпоративні заходи залишилися у минулому житті, але ці спогади нас
                        об’єднують, надихають,  надають нам сили та надію, що все буде гаразд!
                        <br/>
                        З перших днів війни деякі наші колеги стали на захист нашої Держави в лавах ЗСУ та
                        Територіальній обороні і кожен день ризикують своїм життям… деякі допомагають іншим та
                        займаються волонтерською діяльністю… хтось подовжує виконувати свої посадові обов’язки.
                        <br/>
                        Кожен на своєму місті  робить все можливе за для Перемоги!
                        І всі ми віримо, що вона обов’язково найближчим часом буде!
                      </Typography>
                      <Typography
                        sx={{
                          color: '#252525',
                          fontSize: '15px',
                          maxWidth: '506px',
                          fontWeight: 'bold',
                          lineHeight: '115%',
                          textAlign: 'start',
                          px: { xs: '22px', lg: 0 },
                          fontFamily: '"Trebuchet MS", sans-serif',
                        }}
                      >
                        Слава Україні!!!
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      mb: '24px',
                      gap: '24px',
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Box sx={{  width: '100%', height: '100%', maxWidth: '312px', maxHeight: '342px' }}>
                      <img alt='our-life-4.png' src='/Images/brandedPages/DHL/our-life-8.png'/>
                    </Box>
                    <Box sx={{  width: '100%', height: '100%', maxWidth: '312px', maxHeight: '342px' }}>
                      <img alt='our-life-4.png' src='/Images/brandedPages/DHL/our-life-9.png'/>
                    </Box>
                    <Box sx={{  width: '100%', height: '100%', maxWidth: '312px', maxHeight: '342px' }}>
                      <img alt='our-life-4.png' src='/Images/brandedPages/DHL/our-life-10.png'/>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    px: { xs: '22px', lg: 0 },
                    pb: { xs: '22px', lg: '90px' },
                    pt: { xs: '22px', lg: '100px' },
                    backgroundImage: 'url("/Images/brandedPages/DHL/footer.png")',
                  }}
                >
                  <Box
                    sx={{
                      maxWidth: '502px',
                      borderRadius: '8px',
                      p: '26px 22px 24px',
                      backgroundColor: '#FFCB05',
                      ml: { xs: 0, lg: '132px' },
                    }}
                  >
                    <Typography
                      sx={{
                        mb: '20px',
                        color: '#E50000',
                        fontSize: '30px',
                        maxWidth: '334px',
                        fontWeight: 'bold',
                        lineHeight: '115%',
                        textAlign: 'start',
                        fontFamily: '"Trebuchet MS", sans-serif',
                      }}
                    >
                      НАШІ ВАКАНСІЇ
                    </Typography>
                    <Typography
                      sx={{
                        mb: '18px',
                        color: '#252525',
                        fontSize: '15px',
                        fontWeight: '400',
                        maxWidth: '460px',
                        lineHeight: '115%',
                        textAlign: 'start',
                        fontFamily: '"Trebuchet MS", sans-serif',
                      }}
                    >
                      Ставши частиною нашої команди, ви дізнаєтесь, як міжнародна мережа орієнтована на надання
                      високої якості сервісу і постійний розвиток, об&apos;єднує людей, підвищуючи якість їх життя.
                    </Typography>
                    <Typography
                      sx={{
                        mb: '18px',
                        color: '#252525',
                        fontSize: '15px',
                        fontWeight: '400',
                        maxWidth: '460px',
                        lineHeight: '115%',
                        textAlign: 'start',
                        fontFamily: '"Trebuchet MS", sans-serif',
                      }}
                    >
                      Це стосується не тільки наших клієнтів, але і кожного співробітника нашої команди.
                    </Typography>
                    <Typography
                      sx={{
                        mb: '18px',
                        color: '#252525',
                        fontSize: '15px',
                        fontWeight: '400',
                        maxWidth: '460px',
                        lineHeight: '115%',
                        textAlign: 'start',
                        fontFamily: '"Trebuchet MS", sans-serif',
                      }}
                    >
                      Приєднуйся до нашої дружньої команди DHL Express Україна!
                    </Typography>
                    <Typography
                      sx={{
                        color: '#252525',
                        fontSize: '15px',
                        fontWeight: '400',
                        maxWidth: '460px',
                        lineHeight: '115%',
                        textAlign: 'start',
                        fontFamily: '"Trebuchet MS", sans-serif',
                      }}
                    >
                      Посилання на наш сайт:{' '}
                      <a
                        target='_blank'
                        href='https://www.dhl.com.ua'
                        rel='nofollow noopener noreferrer'
                        style={{ color: '#000000', textDecoration: 'underline' }}
                      >
                        www.dhl.com.ua
                      </a>
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <div className='blogPageSection2 container'>
                <div className='row'>
                  <div className='row'>
                    <div className='col-md-9 col-lg-9'>
                      {
                        companyJobs.length > 0 && (
                          <>
                            <h2 className='ms-3 mb-3 mt-5'>
                              {t('companyProfile.jobPostedBy')}
                              {' '}
                              {companyDetails.company_name}
                            </h2>
                            {
                              companyJobs.map(i => (
                                <div key={i.id}>
                                  <div className='card-body companyProfileCardLeft'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                      <div>
                                        <h4>{i.title}</h4>
                                        <h6 className='text-muted CPTPart2'>
                                          <i className='fa-sharp fa-solid fa-location-dot' />
                                          {' '}
                                          {i.job_city}
                                        </h6>
                                      </div>
                                      <Link
                                        className='btn btn-primary button1'
                                        to={`/jobdescription/${i.jslug}/${i.cslug}`}
                                      >
                                        {t('companyProfile.detailsButton')}
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              ))
                            }
                          </>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </>
          )
      }
    </>
  );
};

export default DHLBrandedPage;
