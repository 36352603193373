import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// api
import ApiKey from '../api/ApiKey';
import BaseApi from '../api/BaseApi';
// components
import JSSidebar from './JSSidebar';
import Footer from '../element/Footer';
import NavBar from '../element/NavBar';
// helpers
import { logOutIfTokenExpired, resultInfo } from '../helpers';

const JSManageAlerts = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('global');
  const tokenKey = Cookies.get('tokenClient');

  const [alert, setAlert] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const alertsPerPage = 10;

  const indexOfLastAlert = currentPage * alertsPerPage;
  const indexOfFirstAlert = indexOfLastAlert - alertsPerPage;
  const currentData = alert
    ? alert.slice(indexOfFirstAlert, indexOfLastAlert)
    : alert.slice(indexOfFirstAlert, indexOfLastAlert);

  const handlePageChange = pageNumber => setCurrentPage(pageNumber);

  const getData = async () => {
    try {
      setLoading(true);

      const response = await axios.post(
        BaseApi + '/alerts/index',
        null,
        {
          headers: {
            key: ApiKey,
            token: tokenKey,
            'Content-Type': 'application/json',
          },
        },
      );

      setLoading(false);

      if (response.data.status === 200) {
        setAlert(response.data.response.alerts);
      } else if (response.data.status === 400) {
        navigate('/');

        Cookies.remove('fname');
        Cookies.remove('user_id');
        Cookies.remove('user_type');
        Cookies.remove('tokenClient');

        resultInfo('warning', response.data.message, t('jobseekerManageAlert.close'));
      } else {
        resultInfo('error', response.data.message, t('jobseekerManageAlert.close'));
      }
    } catch (error) {
      setLoading(false);

      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      }

      console.error('Cannot get payment data', error);
    }
  };

  const handleClick = async slug => {
    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        showCancelButton: true,
        text: t('jobseekerManageAlert.confirmTxt'),
        title: t('jobseekerManageAlert.confirmTitle'),
        cancelButtonText: t('jobseekerManageAlert.no'),
        confirmButtonText: t('jobseekerManageAlert.yes'),
      });

      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/alerts/delete/${slug}`,
          null,
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              'Content-Type': 'application/json',
            },
          },
        );

        if (response.data.status === 200) {
          resultInfo('success', t('jobseekerManageAlert.successTxt'), t('jobseekerManageAlert.close'));
        } else if (response.data.status === 400) {
          navigate('/');

          Cookies.remove('fname');
          Cookies.remove('user_id');
          Cookies.remove('user_type');
          Cookies.remove('tokenClient');

          resultInfo('warning', response.data.message, t('jobseekerManageAlert.close'));
        } else if (response.data.status === 500) {
          resultInfo('error', response.data.message, t('jobseekerManageAlert.close'));
        }
        else {
          resultInfo('error', t('jobseekerManageAlert.failedTxt'), t('jobseekerManageAlert.close'));
        }
      }

      getData();
    } catch (error) {
      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      }

      console.error('Cannot delete!', error);
    }
  };

  useEffect(() => {
    if (!tokenKey) {
      navigate('/user/jobseekerlogin');
    } else {
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]); // eslint-disable-line

  return (
    <>
      <NavBar />
      <div className='container paymentHistory'>
        <div className='row'>
          <div className='col-lg-3 col-md-3'>
            <JSSidebar />
          </div>
          {
            loading
              ? (
                <div className='loader-container'></div>
              ) : (
                <div
                  className='col-lg-9 col-md-9 mb-5'
                  style={{
                    borderLeft: '2px solid #e6e8e7',
                    borderRight: '2px solid #e6e8e7',
                  }}
                >
                  <div className='PHHeader'>
                    <div className='JSMASection2'>
                      <div className='d-flex JSManageAlertHeader PageHeader'>
                        <img
                          alt='alert'
                          src='/Images/jobseekerSide/Manage-Alerts2.png'
                        />
                        <h3 className='ms-1' style={{ color: '#AC7CE4' }}>
                          {t('jobseekerManageAlert.manageAlert')}
                        </h3>
                      </div>
                      <Link
                        to='/alerts/add'
                        className='btn btn-primary button1 JSMAHeaderButton'
                      >
                        {t('jobseekerManageAlert.addAlert')}
                      </Link>
                    </div>
                    <div className='PHBody mt-5'>
                      <table className='table'>
                        <thead>
                          <tr className='table-active TrFirst'>
                            <th className='' scope='col p-3'>
                              {t('jobseekerManageAlert.SNo.')}
                            </th>
                            <th className='' scope='col p-3'>
                              {t('jobseekerManageAlert.location')}
                            </th>
                            <th className='' scope='col p-3'>
                              {t('jobseekerManageAlert.workingRelation')}
                            </th>
                            <th className='' scope='col p-3'>
                              {t('jobseekerManageAlert.action')}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            currentData.map((i, index) => (
                              <tr key={index} className='TrDefoult'>
                                <td className=''>{index + 1}</td>
                                <td className=''>{i.location}</td>
                                <td className=''>{i.designation}</td>
                                <td className='TrActions'>
                                  <Link
                                    className='btn button2'
                                    to={`/alerts/edit/${i.slug}`}
                                  >
                                    <i className='fa-solid fa-pen-to-square'></i>
                                  </Link>
                                  <button
                                    className='btn button1'
                                    onClick={() => handleClick(i.slug)}
                                  >
                                    <i className='fa-solid fa-trash'></i>
                                  </button>
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className='JSmanageAlertPagination'>
                    <p className='text-muted empPaginationData'>
                      {t('pagination.NoofRecords')} {alert.length > 0 ? indexOfFirstAlert + 1 : indexOfFirstAlert}-
                      {Math.min(indexOfLastAlert, alert.length)}
                      {' of '}
                      {alert ? alert.length : alert.length}
                    </p>
                    <div className='d-flex justify-content-center empPaginationButton'>
                      <button
                        className='navButton1 me-2'
                        disabled={currentPage === 1}
                        onClick={() => handlePageChange(currentPage - 1)}
                      >
                        {t('pagination.Prev')}
                      </button>
                      <button
                        className='navButton1'
                        disabled={
                          alert
                            ? indexOfLastAlert >= alert.length
                            : indexOfLastAlert >= alert.length
                        }
                        onClick={() => handlePageChange(currentPage + 1)}
                      >
                        {t('pagination.Next')}
                      </button>
                    </div>
                  </div>
                </div>
              )
          }
        </div>
      </div>
      <Footer />
    </>
  );
};

export default JSManageAlerts;
