import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// components
import Error from './Error';

const RedirectHandler = () => {
  const location = useLocation();
  const path = location.pathname;

  if (/^\/account\/login/.test(path)) {
    return <Navigate to='/user/employerlogin' />;
  }

  if (
    /^\/ru\/resume/.test(path) ||
    /^\/ru\/vacancies/.test(path) ||
    /^\/vacancies/.test(path) ||
    /^\/resume/.test(path)
  ) {
    return <Navigate to='/searchjob' />;
  }

  return <Error />;
};

export default RedirectHandler;
