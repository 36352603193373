import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
// api
import ApiKey from '../../api/ApiKey';
import BaseApi from '../../api/BaseApi';
// components
import APNavBar from '../Elements/APNavBar';
import APSidebar from '../APSidebar/APSidebar';
// mui
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const APHomePageSlider = () =>{
  const navigate = useNavigate();
  const tokenKey = Cookies.get('token');
  const adminID = Cookies.get('adminID');

  const [loading, setLoading] = useState(false);
  const [employersList , setEmployersList] = useState([]);
  const [selectedEmployer , setSelectedEmployer] = useState([]);
  const [employerValidationError , setEmployerValidationError] = useState('');

  const handleEmployerChange = selected => {
    setSelectedEmployer(selected);
    setEmployerValidationError('');
  };

  const getData = async () => {
    try {
      setLoading(true);

      const response = await axios.post(
        BaseApi + '/admin/users/addemployertoslider',
        null,
        {
          headers: {
            key: ApiKey,
            token: tokenKey,
            adminid: adminID,
            'Content-Type': 'application/json',
          },
        },
      );
      setLoading(false);

      setEmployersList(response.data.response);
    } catch (error) {
      setLoading(false);

      console.error('Cannot get plans data at APmanageplans', error);
    }
  };

  const handleClick = async () =>{
    if (selectedEmployer.length == 0) {
      setEmployerValidationError('Select Employer');
    }

    if (employerValidationError == '') {
      try {
        const confirmationResult = await Swal.fire({
          icon: 'question',
          cancelButtonText: 'No',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          title: 'Update Employer Slider List?',
          text: 'Do you want to update the Employer Slider List?',
        });

        if (confirmationResult.isConfirmed) {
          setLoading(true);

          const response = await axios.post(
            BaseApi + '/admin/users/addemployertoslider',
            selectedEmployer,
            {
              headers: {
                key: ApiKey,
                token: tokenKey,
                adminid: adminID,
                'Content-Type': 'application/json',
              },
            },
          );

          setLoading(false);

          if (response.data.status === 200) {
            Swal.fire({
              icon: 'success',
              confirmButtonText: 'Close',
              title: 'Employer updated successfully!',
            });

            navigate('/admin/users/selectforslider');
          } else {
            Swal.fire({
              icon: 'error',
              confirmButtonText: 'Close',
              title: response.data.message,
            });
          }
        }
      } catch (error) {
        setLoading(false);

        console.error('Cannot get plans data at APmanageplans', error);
      }
    }
  };

  useEffect(() => {
    if (!tokenKey) {
      navigate('/admin');
    } else {
      getData();

      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]); // eslint-disable-line

  return(
    <>
      <APNavBar />
      <div className='APBasic' style = {{ height: '100vh' }}>
        <APSidebar />
        {
          loading
            ?(
              <div className='loader-container'></div>
            ):(
              <div className='site-min-height'>
                <div className='breadCumb1' role='presentation'>
                  <Breadcrumbs
                    aria-label='breadcrumb'
                    separator={<NavigateNextIcon fontSize='small' />}
                  >
                    <Link
                      color='inherit'
                      underline='hover'
                      onClick={() => navigate('/admin/admins/dashboard')}
                    >
                      Dashboard
                    </Link>
                    <Link
                      underline='hover'
                      color='inherit'
                      onClick={() => navigate('/admin/users/selectforslider')}
                    >
                      Employers Slider
                    </Link>
                    <Typography color='text.primary'>
                      Add Employer To Slider
                    </Typography>
                  </Breadcrumbs>
                </div>
                <h2 className='adminPageHeading'>Add Employer To Slider</h2>
                <form className='adminForm'>
                  <div className='mb-4 mt-5'>
                    <div className='form-outline mb-5 DashBoardInputBx DashBoardCreatBx skillPackage'>
                      <label htmlFor='formFile' className='form-label'>
                        Select Employer
                        <span className='RedStar'>*</span>
                      </label>
                      <Select
                        isSearchable
                        name='employer'
                        value={selectedEmployer}
                        classNamePrefix='select'
                        className='basic-multi-select'
                        onChange={handleEmployerChange}
                        options={employersList.map(i => ({
                          value: i.id,
                          label: i.name,
                        }))}
                      />
                      {
                        employerValidationError && (
                          <div className='text-danger'>{employerValidationError}</div>
                        )
                      }
                    </div>
                    <div className='adminBottomButtons'>
                      <button
                        type='button'
                        className='btn btn-primary button1'
                        onClick={handleClick}
                      >
                        SAVE
                      </button>
                      <button
                        type='button'
                        className='btn btn-primary button2'
                        onClick={() => navigate('/admin/users/selectforslider')}
                      >
                        CANCEL
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )
        }
      </div>
    </>
  );

};

export default APHomePageSlider;
