/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HTMLReactParser from 'html-react-parser';

const BlogCard = props => {

  const { t } = useTranslation('global');

  return (
    <div className='blogCard card'>
      <div className='blogCardImageBox'>
        <Link to={`/blog/${props.slug}`}>
          {
            props.image
              ? (
                <img
                  alt='Card cap'
                  src={props.image}
                  className='p-2'
                />
              ) : (
                <img
                  alt='Card cap'
                  className='p-2'
                  src='https://mdbootstrap.com/img/Mockups/Lightbox/Thumbnail/img%20(67).webp'
                />
              )
          }
        </Link>
      </div>
      <div className='blogCardBody card-body'>
        <Link to={`/blog/${props.slug}`}>
          <p className='text-muted mb-2'>{t('blogPage.posted')}: {props.created}</p>
          <h4 className='card-title'>
            {props.title ? HTMLReactParser(props.title) : ''}
          </h4>
          <p className='card-text text-muted standartListStyle'>
            {
              props.description
                ? HTMLReactParser(props.description.substring(0, 100))
                : ''
            }
              ...
          </p>
        </Link>
      </div>
      <div className='card-footer blogCardFooter'>
        <Link className='mt-3' to={`/blog/${props.slug}`}>
          {t('blogPage.learnMore')} &gt;&gt;&gt;
        </Link>
      </div>
    </div>
  );
};

export default BlogCard;
