import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useNavigate } from 'react-router-dom';
// api
import ApiKey from '../api/ApiKey';
import BaseApi from '../api/BaseApi';
// hooks
import { useWindowSize } from '../../Hooks/use-window-size';

const JSSidebar = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const { t } = useTranslation('global');
  const tokenKey = Cookies.get('tokenClient');

  const [isQuickLinksVisible, setIsQuickLinksVisible] = useState(false);
  const [isSettingLinksVisible, setIsSettingLinksVisible] = useState(false);
  const [isMyprofileLinksVisible, setIsMyprofileLinksVisible] = useState(false);

  const toggle1 = () => {
    setIsMyprofileLinksVisible(!isMyprofileLinksVisible);
  };

  const toggle2 = () => {
    setIsQuickLinksVisible(!isQuickLinksVisible);
  };

  const toggle3 = () => {
    setIsSettingLinksVisible(!isSettingLinksVisible);
  };

  const handleLogOut = async () => {
    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        showCancelButton: true,
        text: t('jobseekerSidebar.confirmTxt'),
        title: t('jobseekerSidebar.confirmTitle'),
        cancelButtonText: t('jobseekerSidebar.no'),
        confirmButtonText: t('jobseekerSidebar.yes'),
      });

      if (confirmationResult.isConfirmed) {
        await axios.post(BaseApi + '/users/logout', null, {
          headers: {
            key: ApiKey,
            token: tokenKey,
            'Content-Type': 'application/json',
          },
        });

        Cookies.remove('fname');
        Cookies.remove('user_id');
        Cookies.remove('user_type');
        Cookies.remove('tokenClient');

        navigate('/');

        window.location.reload();

        const Toast = Swal.mixin({
          timer: 3000,
          toast: true,
          position: 'top-end',
          timerProgressBar: true,
          showConfirmButton: false,
          didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: 'success',
          title: t('jobseekerSidebar.successTitle'),
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: t('jobseekerSidebar.failedTitle'),
        confirmButtonText: t('jobseekerSidebar.close'),
      });
    }
  };

  // const handleDeleteAccount = async () => {
  //   try {
  //     const confirmationResult = await Swal.fire({
  //       icon: 'question',
  //       cancelButtonText: 'No',
  //       showCancelButton: true,
  //       title: 'Delete Account',
  //       confirmButtonText: 'Yes',
  //       text: 'Do you want to delete this account?',
  //     });

  //     if (confirmationResult.isConfirmed) {
  //       const response = await axios.post(
  //         BaseApi + '/users/deleteAccount',
  //         null,
  //         {
  //           headers: {
  //             key: ApiKey,
  //             token: tokenKey,
  //             'Content-Type': 'application/json',
  //           },
  //         },
  //       );

  //       if (response.data.status === 200) {
  //         sessionStorage.clear();

  //         navigate('/');

  //         const Toast = Swal.mixin({
  //           timer: 3000,
  //           toast: true,
  //           position: 'top-end',
  //           timerProgressBar: true,
  //           showConfirmButton: false,
  //           didOpen: toast => {
  //             toast.addEventListener('mouseenter', Swal.stopTimer);
  //             toast.addEventListener('mouseleave', Swal.resumeTimer);
  //           },
  //         });

  //         Toast.fire({
  //           icon: 'success',
  //           title: 'Account deleted successfully!',
  //         });
  //       }
  //     }
  //   } catch (error) {
  //     Swal.fire({
  //       icon: 'error',
  //       confirmButtonText: 'Close',
  //       title: 'Could not delete account!',
  //     });

  //     console.error('Cannot delete account!');
  //   }
  // };

  return (
    <>
      {
        width > 768
          ? (
            <>
              <div className='SidebarSection1 pe-4'>
                <div className='SBHeader'>
                  <h3>{t('jobseekerSidebar.myProfile')}</h3>
                  <hr style={{ border: '1px solid rgb(211 209 209)' }} />
                </div>
                <div className='SBBody'>
                  <NavLink
                    to='/candidates/myaccount'
                    className='bodyItem SidebarCreatJob'
                  >
                    <div className='SidebarImages SidebarCreatJobBg'>
                      <img
                        className=''
                        src='/Images/employerSide/icon7color.png'
                        alt=''
                      />
                    </div>
                    <div className='menuTitle'>
                      {' '}
                      {t('jobseekerSidebar.myProfile')}
                    </div>
                  </NavLink>
                  <NavLink
                    to='/candidates/editprofile'
                    className='bodyItem SidebarManageJob'
                  >
                    <div className='SidebarImages SidebarManageJobBg'>
                      <img
                        className=''
                        src='/Images/employerSide/icon8color.png'
                        alt=''
                      />
                    </div>
                    <div className='menuTitle'>
                      {t('jobseekerSidebar.editProfile')}
                    </div>
                  </NavLink>
                  <NavLink
                    to='/candidates/editEducation'
                    className='bodyItem SidebarPaymentJob'
                  >
                    <div className='SidebarImages SidebarPaymentJobBg'>
                      <img
                        className=''
                        src='/Images/jobseekerSide/Education-black.png'
                        alt=''
                      />
                    </div>
                    <div className='menuTitle'>
                      {' '}
                      {t('jobseekerSidebar.education')}
                    </div>
                  </NavLink>
                  <NavLink
                    to='/candidates/editExperience'
                    className='bodyItem SidebarFavouriteJob'
                  >
                    <div className='SidebarImages SidebarFavouriteJobBg'>
                      <img
                        className=''
                        src='/Images/jobseekerSide/Experience-Black.png'
                        alt=''
                      />
                    </div>
                    <div className='menuTitle'>
                      {t('jobseekerSidebar.experience')}
                    </div>
                  </NavLink>
                  <NavLink
                    to='/candidates/editProfessional'
                    className='bodyItem SidebarImportJob'
                  >
                    <div className='SidebarImages SidebarImportJobBg'>
                      <img
                        className=''
                        src='/Images/jobseekerSide/Professional-Registration-Black.png'
                        alt=''
                      />
                    </div>
                    <div className='menuTitle'>
                      {t('jobseekerSidebar.professionalRegistration')}
                    </div>
                  </NavLink>
                  <NavLink
                    to='/candidates/addvideocv'
                    className='bodyItem SidebarMailHistory'
                  >
                    <div className='SidebarImages SidebarMailHistoryBg'>
                      <img
                        className=''
                        src='/Images/employerSide/icon5color.png'
                        alt=''
                      />
                    </div>
                    <div className='menuTitle'>{t('jobseekerSidebar.videoCV')}</div>
                  </NavLink>
                  <NavLink
                    to='/candidates/makecv'
                    className='bodyItem SidebarChangeLogo'
                  >
                    <div className='SidebarImages SidebarChangeLogoBg'>
                      <img
                        className=''
                        src='/Images/jobseekerSide/Make-A-CV-black.png'
                        alt=''
                      />
                    </div>
                    <div className='menuTitle'>{t('jobseekerSidebar.makeCV')}</div>
                  </NavLink>
                  <NavLink
                    to='/candidates/addcvdocuments'
                    className='bodyItem SidebarChangeLogo'
                  >
                    <div className='SidebarImages SidebarChangeLogoBg'>
                      <img
                        className=''
                        src='/Images/jobseekerSide/Make-A-CV-black.png'
                        alt=''
                      />
                    </div>
                    <div className='menuTitle'>
                      {t('jobseekerSidebar.addCvDocuments')}
                    </div>
                  </NavLink>
                </div>
              </div>
              <div className='SidebarSection2 pe-4'>
                <div className='SBHeader mt-3'>
                  <h3>{t('jobseekerSidebar.quickLinks')}</h3>
                  <hr style={{ border: '1px solid rgb(211 209 209)' }} />
                </div>
                <div className='body'>
                  <NavLink
                    to='/payments/history'
                    activeClassName='active'
                    className='bodyItem SidebarPaymentJob'
                  >
                    <div className='SidebarImages SidebarPaymentJobBg'>
                      <img
                        className=''
                        src='/Images/employerSide/icon3color.png'
                        alt=''
                      />
                    </div>
                    <div className='menuTitle'>
                      {t('jobseekerSidebar.paymentHistory')}
                    </div>
                  </NavLink>
                  <NavLink
                    to='/alerts/index'
                    className='bodyItem SidebarMyProfile'
                    activeClassName='active'
                  >
                    <div className='SidebarImages SidebarMyProfileBg'>
                      <img
                        className=''
                        src='/Images/jobseekerSide/Manage-Alerts.png'
                        alt=''
                      />
                    </div>
                    <div className='menuTitle'>
                      {t('jobseekerSidebar.manageAlerts')}
                    </div>
                  </NavLink>
                  <NavLink
                    to='/jobs/savedjobs'
                    activeClassName='active'
                    className='bodyItem SidebarFavouriteJob'
                  >
                    <div className='SidebarImages SidebarFavouriteJobBg'>
                      <img
                        className=''
                        src='/Images/employerSide/icon4color.png'
                        alt=''
                      />
                    </div>
                    <div className='menuTitle'>
                      {t('jobseekerSidebar.savedJobs')}
                    </div>
                  </NavLink>
                  <NavLink
                    to='/jobs/applied'
                    activeClassName='active'
                    className='bodyItem SidebarChangePass'
                  >
                    <div className='SidebarImages SidebarChangePassBg'>
                      <img
                        className=''
                        src='/Images/jobseekerSide/Applied-Jobs-black.png'
                        alt=''
                      />
                    </div>
                    <div className='menuTitle'>
                      {' '}
                      {t('jobseekerSidebar.appliedJobs')}
                    </div>
                  </NavLink>
                  <NavLink
                    to='/searchjob'
                    activeClassName='Active'
                    className='bodyItem SidebarChangeLogo'
                  >
                    <div className='SidebarImages SidebarChangeLogoBg'>
                      <img
                        className=''
                        src='/Images/jobseekerSide/search-icon.png'
                        alt=''
                      />
                    </div>
                    <div className='menuTitle'>{t('jobseekerSidebar.searchJobs')}</div>
                  </NavLink>
                </div>
              </div>
              <div className='SidebarSection3 pe-4'>
                <div className='SBHeader mt-3'>
                  <h3 className='text-black'>{t('jobseekerSidebar.setting')}</h3>
                  <hr style={{ border: '1px solid rgb(211 209 209)' }} />
                </div>
                <div className='body'>
                  <NavLink
                    to='/candidates/mailhistory'
                    activeClassName='Active'
                    className='bodyItem SidebarMailHistory'
                  >
                    <div className='SidebarImages SidebarMailHistoryBg'>
                      <img
                        className=''
                        src='/Images/employerSide/icon6color.png'
                        alt=''
                      />
                    </div>
                    <div className='menuTitle'>{t('jobseekerSidebar.mailHistory')}</div>
                  </NavLink>
                  <NavLink
                    to='/candidates/changepassword'
                    activeClassName='Active'
                    className='bodyItem SidebarChangePass'
                  >
                    <div className='SidebarImages SidebarChangePassBg'>
                      <img
                        className=''
                        src='/Images/employerSide/icon9color.png'
                        alt=''
                      />
                    </div>
                    <div className='menuTitle'>{t('jobseekerSidebar.changePassword')}</div>
                  </NavLink>
                  <NavLink
                    to='/candidates/uploadPhoto'
                    activeClassName='active'
                    className='bodyItem SidebarChangeLogo'
                  >
                    <div className='SidebarImages SidebarChangeLogoBg'>
                      <img
                        className=''
                        src='/Images/employerSide/icon10color.png'
                        alt=''
                      />
                    </div>
                    <div className='menuTitle'>{t('jobseekerSidebar.changePhoto')}</div>
                  </NavLink>
                  <NavLink
                    to=''
                    activeClassName='active'
                    className='bodyItem SidebarImportJob'
                  >
                    <div className='SidebarImages SidebarImportJobBg'>
                      <img
                        className=''
                        src='/Images/employerSide/icon11color.png'
                        alt=''
                      />
                    </div>
                    <div className='menuTitle' onClick={handleLogOut}>
                      {t('jobseekerSidebar.logOut')}
                    </div>
                  </NavLink>
                  <NavLink
                    to='/candidates/deleteAccount'
                    activeClassName='Active'
                    className='bodyItem SidebarEditProfile'
                  >
                    <div className='SidebarImages SidebarEditProfileBg'>
                      <img
                        className=''
                        src='/Images/employerSide/icon12color.png'
                        alt=''
                      />
                    </div>
                    <div className='menuTitle'>{t('jobseekerSidebar.deleteAccount')}</div>
                  </NavLink>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className='SidebarSection1 mt-3 pe-4'>
                <div className='SBHeader'>
                  <div className='sidebarEachHeader'>
                    <h3>{t('jobseekerSidebar.myProfile')}</h3>
                    <Link className='sidebarPlusLink' onClick={toggle1}>
                      {
                        isMyprofileLinksVisible
                          ? <i className='fa-solid fa-circle-minus'></i>
                          : <i className='fa-solid fa-circle-plus'></i>
                      }
                    </Link>
                  </div>
                  <hr style={{ border: '1px solid rgb(211 209 209)' }} />
                </div>
                <div className={`SBBody ${isMyprofileLinksVisible ? 'open' : ''}`}>
                  <ul style={{ display: isMyprofileLinksVisible ? 'block' : 'none' }}>
                    <li>
                      <NavLink
                        to='/candidates/myaccount'
                        className='bodyItem SidebarCreatJob'
                      >
                        <div className='SidebarImages SidebarCreatJobBg'>
                          <img
                            className=''
                            src='/Images/employerSide/icon7color.png'
                            alt=''
                          />
                        </div>
                        <div className='menuTitle'>{t('jobseekerSidebar.myProfile')}</div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to='/candidates/editprofile'
                        className='bodyItem SidebarManageJob'
                      >
                        <div className='SidebarImages SidebarManageJobBg'>
                          <img
                            className=''
                            src='/Images/employerSide/icon8color.png'
                            alt=''
                          />
                        </div>
                        <div className='menuTitle'>{t('jobseekerSidebar.editProfile')}</div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to='/candidates/editEducation'
                        className='bodyItem SidebarPaymentJob'
                      >
                        <div className='SidebarImages SidebarPaymentJobBg'>
                          <img
                            className=''
                            src='/Images/jobseekerSide/Education-black.png'
                            alt=''
                          />
                        </div>
                        <div className='menuTitle'>{t('jobseekerSidebar.education')}</div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to='/candidates/editExperience'
                        className='bodyItem SidebarFavouriteJob'
                      >
                        <div className='SidebarImages SidebarFavouriteJobBg'>
                          <img
                            className=''
                            src='/Images/jobseekerSide/Experience-Black.png'
                            alt=''
                          />
                        </div>
                        <div className='menuTitle'>{t('jobseekerSidebar.experience')}</div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to='/candidates/editProfessional'
                        className='bodyItem SidebarImportJob'
                      >
                        <div className='SidebarImages SidebarImportJobBg'>
                          <img
                            className=''
                            src='/Images/jobseekerSide/Professional-Registration-Black.png'
                            alt=''
                          />
                        </div>
                        <div className='menuTitle'>{t('jobseekerSidebar.professionalRegistration')}</div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to='/candidates/addvideocv'
                        className='bodyItem SidebarMailHistory'
                      >
                        <div className='SidebarImages SidebarMailHistoryBg'>
                          <img
                            className=''
                            src='/Images/employerSide/icon5color.png'
                            alt=''
                          />
                        </div>
                        <div className='menuTitle'>{t('jobseekerSidebar.videoCV')}</div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to='/candidates/makecv'
                        className='bodyItem SidebarChangeLogo'
                      >
                        <div className='SidebarImages SidebarChangeLogoBg'>
                          <img
                            className=''
                            src='/Images/jobseekerSide/Make-A-CV-black.png'
                            alt=''
                          />
                        </div>
                        <div className='menuTitle'>{t('jobseekerSidebar.makeCV')}</div>
                      </NavLink>
                      <NavLink
                        to='/candidates/addcvdocuments'
                        className='bodyItem SidebarChangeLogo'
                      >
                        <div className='SidebarImages SidebarChangeLogoBg'>
                          <img
                            className=''
                            src='/Images/jobseekerSide/Make-A-CV-black.png'
                            alt=''
                          />
                        </div>
                        <div className='menuTitle'>
                          {t('jobseekerSidebar.addCvDocuments')}
                        </div>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='SidebarSection2 pe-4'>
                <div className='SBHeader mt-3'>
                  <div className='sidebarEachHeader'>
                    <h3>{t('jobseekerSidebar.quickLinks')}</h3>
                    <Link className='sidebarPlusLink' onClick={toggle2}>
                      {
                        isQuickLinksVisible
                          ? <i className='fa-solid fa-circle-minus'></i>
                          : <i className='fa-solid fa-circle-plus'></i>
                      }
                    </Link>
                  </div>
                  <hr style={{ border: '1px solid rgb(211 209 209)' }} />
                </div>
                <div className={`SBBody ${isQuickLinksVisible ? 'open' : ''}`}>
                  <ul style={{ display: isQuickLinksVisible ? 'block' : 'none' }}>
                    <li>
                      <NavLink
                        to='/payments/history'
                        activeClassName='active'
                        className='bodyItem SidebarPaymentJob'
                      >
                        <div className='SidebarImages SidebarPaymentJobBg'>
                          <img
                            className=''
                            src='/Images/employerSide/icon3color.png'
                            alt=''
                          />
                        </div>
                        <div className='menuTitle'>{t('jobseekerSidebar.paymentHistory')}</div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to='/alerts/index'
                        className='bodyItem SidebarMyProfile'
                        activeClassName='active'
                      >
                        <div className='SidebarImages SidebarMyProfileBg'>
                          <img
                            className=''
                            src='/Images/jobseekerSide/Manage-Alerts.png'
                            alt=''
                          />
                        </div>
                        <div className='menuTitle'>{t('jobseekerSidebar.manageAlerts')}</div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to='/jobs/savedjobs'
                        activeClassName='active'
                        className='bodyItem SidebarFavouriteJob'
                      >
                        <div className='SidebarImages SidebarFavouriteJobBg'>
                          <img
                            className=''
                            src='/Images/employerSide/icon4color.png'
                            alt=''
                          />
                        </div>
                        <div className='menuTitle'>{t('jobseekerSidebar.savedJobs')}</div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to='/jobs/applied'
                        activeClassName='active'
                        className='bodyItem SidebarChangePass'
                      >
                        <div className='SidebarImages SidebarChangePassBg'>
                          <img
                            className=''
                            src='/Images/jobseekerSide/Applied-Jobs-black.png'
                            alt=''
                          />
                        </div>
                        <div className='menuTitle'>{t('jobseekerSidebar.appliedJobs')}</div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to='/searchjob'
                        activeClassName='Active'
                        className='bodyItem SidebarChangeLogo'
                      >
                        <div className='SidebarImages SidebarChangeLogoBg'>
                          <img
                            className=''
                            src='/Images/jobseekerSide/search-icon.png'
                            alt=''
                          />
                        </div>
                        <div className='menuTitle'>{t('jobseekerSidebar.searchJobs')}</div>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='SidebarSection3 pe-4'>
                <div className='SBHeader mt-3'>
                  <div className='sidebarEachHeader'>
                    <h3 className='text-black'>{t('jobseekerSidebar.setting')}</h3>
                    <Link className='sidebarPlusLink' onClick={toggle3}>
                      {
                        isSettingLinksVisible
                          ? <i className='fa-solid fa-circle-minus'></i>
                          : <i className='fa-solid fa-circle-plus'></i>
                      }
                    </Link>
                  </div>
                  <hr style={{ border: '1px solid rgb(211 209 209)' }} />
                </div>
                <div className={`SBBody ${isSettingLinksVisible ? 'open' : ''}`}>
                  <ul style={{ display: isSettingLinksVisible ? 'block' : 'none' }}>
                    <li>
                      <NavLink
                        to='/candidates/mailhistory'
                        activeClassName='Active'
                        className='bodyItem SidebarMailHistory'
                      >
                        <div className='SidebarImages SidebarMailHistoryBg'>
                          <img
                            className=''
                            src='/Images/employerSide/icon6color.png'
                            alt=''
                          />
                        </div>
                        <div className='menuTitle'>{t('jobseekerSidebar.mailHistory')}</div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to='/candidates/changepassword'
                        activeClassName='Active'
                        className='bodyItem SidebarChangePass'
                      >
                        <div className='SidebarImages SidebarChangePassBg'>
                          <img
                            className=''
                            src='/Images/employerSide/icon9color.png'
                            alt=''
                          />
                        </div>
                        <div className='menuTitle'>{t('jobseekerSidebar.changePassword')}</div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to='/candidates/uploadPhoto'
                        activeClassName='active'
                        className='bodyItem SidebarChangeLogo'
                      >
                        <div className='SidebarImages SidebarChangeLogoBg'>
                          <img
                            className=''
                            src='/Images/employerSide/icon10color.png'
                            alt=''
                          />
                        </div>
                        <div className='menuTitle'>{t('jobseekerSidebar.changePhoto')}</div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to=''
                        activeClassName='active'
                        className='bodyItem SidebarImportJob'
                      >
                        <div className='SidebarImages SidebarImportJobBg'>
                          <img
                            className=''
                            src='/Images/employerSide/icon11color.png'
                            alt=''
                          />
                        </div>
                        <div className='menuTitle' onClick={handleLogOut}>
                          {t('jobseekerSidebar.logOut')}
                        </div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to=''
                        activeClassName='Active'
                        className='bodyItem SidebarEditProfile'
                      >
                        <div className='SidebarImages SidebarEditProfileBg'>
                          <img
                            className=''
                            src='/Images/employerSide/icon12color.png'
                            alt=''
                          />
                        </div>
                        <div className='menuTitle'>
                          {t('jobseekerSidebar.deleteAccount')}
                        </div>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </>
          )
      }
    </>
  );
};

export default JSSidebar;
