import axios from 'axios';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HTMLReactParser from 'html-react-parser';
import React, { useEffect, useState } from 'react';
// api
import BaseApi from '../api/BaseApi';
// components
import Footer from '../element/Footer';
import NavBar from '../element/NavBar';

const UserAgreement = () => {
  const { t } = useTranslation('global');
  const currentLanguage = Cookies.get('selectedLanguage') || 'ukr';

  const [loading, setLoading] = useState(false);
  const [pageContent, setPageContent] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const response = await axios.post(
          BaseApi + '/page/staticpage/user-agreement',
          { language: currentLanguage },
        );

        setLoading(false);
        setPageContent(response.data.response);
      } catch (error) {
        setLoading(false);
        console.error('Cannot get data of terms and condition page');
      }
    };

    const metaTitle = document.querySelector('title');
    const metaDescription = document.querySelector('meta[name="description"]');

    if (metaTitle) {
      metaTitle.textContent = t('contracts.userAgreementMetaTitleValue');
    }

    if (metaDescription) {
      metaDescription.content = t('contracts.userAgreementMetaDescriptionValue');
    }

    getData();
    window.scrollTo(0, 0);
  }, [t, currentLanguage]);

  return (
    <>
      <NavBar />
      {
        loading
          ? (
            <div className='loader-container'></div>
          ) : (
            <>
              <div className='privacyPolicy'>
                <div className='aboutusSection1 text-center'>
                  <h1 className=''>{t('contracts.userAgreement')}</h1>
                  <h6 className='text-muted fw-normal'>
                    {' '}
                    <Link to='/' style={{ color: 'grey' }}>
                      {t('navHeaders.home')}
                    </Link>
                    {' / '}
                    {t('contracts.userAgreement')}
                  </h6>
                </div>
                <div className='container'>
                  <div className='row'>
                    <div className='card termsOfUseCard'>
                      <div className='card-body standartListStyle'>
                        {pageContent.page_description && HTMLReactParser(pageContent.page_description)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </>
          )
      }
    </>
  );
};

export default UserAgreement;
