import React, { useEffect, useState } from 'react';
import APNavBar from '../Elements/APNavBar';
import APSidebar from '../APSidebar/APSidebar';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import axios from 'axios';
import BaseApi from '../../api/BaseApi';
import ApiKey from '../../api/ApiKey';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const APChangeFavicon = () => {
  const [logo, setLogo] = useState({
    favicon: '',
    // favicon_path: "",
  });
  // eslint-disable-next-line unused-imports/no-unused-vars
  const [errors, setErrors] = useState({
    favicon: '',
  });
  // const [logoData, setLogoData] = useState(null);
  const [loading, setLoading] = useState(false);
  const tokenKey = Cookies.get('token');
  const adminID = Cookies.get('adminID');
  const [selectedImage, setSelectedImage] = useState(null);

  const navigate = useNavigate();

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        BaseApi + '/admin/changeFavicon',
        null, // Pass null as the request body if not required
        {
          headers: {
            'Content-Type': 'application/json',
            key: ApiKey,
            token: tokenKey,
            adminid: adminID,
          },
        },
      );
      setLoading(false);
      setSelectedImage(response.data.response.favicon_path);
      // setLogo(response.data.response);
      // setLogoData(response.data.response.favicon_path);
      console.log(response.data.response.favicon_path);
    } catch (error) {
      // setLoading(false);
      console.log('Cannot get profile photo data');
    }
  };

  const handleClick = async () => {
    try {
      if (!logo.favicon || logo.favicon === null) {
        Swal.fire({
          title: 'Please select a Favicon!',
          icon: 'warning',
          confirmButtonText: 'Close',
        });
      } else {
        const confirmationResult = await Swal.fire({
          title: 'Upload Favicon',
          text: 'Do you want to upload this Favicon?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        });
        if (confirmationResult.isConfirmed) {
          // setLoading(true);
          const response = await axios.post(
            BaseApi + '/admin/changeFavicon',
            logo,
            {
              headers: {
                'Content-Type': 'application/json',
                key: ApiKey,
                token: tokenKey,
                adminid: adminID,
              },
            },
          );
          if (response.data.status === 200) {
            Swal.fire({
              title: 'Favicon updated successfully!',
              icon: 'success',
              confirmButtonText: 'Close',
            });
            // setSelectedImage(logo.favicon_path)
            getData();

            window.scrollTo(0, 0);
          } else {
            Swal.fire({
              title: response.data.message,
              icon: 'error',
              confirmButtonText: 'Close',
            });
          }
        }
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: 'Could not update Favicon. Please try after some time!',
        icon: 'error',
        confirmButtonText: 'Close',
      });
      console.log('Could not update photo!');
    }
  };

  const handleFileUpload1 = async e => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    setLogo({ ...logo, favicon: base64 });
    setSelectedImage(base64);
  };

  useEffect(() => {
    // Check if tokenKey is not present
    if (!tokenKey) {
      // Redirect to the home page
      navigate('/admin');
    } else {
      // TokenKey is present, fetch data or perform other actions
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]);

  return (
    <>
      <APNavBar />
      <div className='APBasic'>
        <APSidebar />

        {loading ? (
          <>
            <div className='loader-container'></div>
          </>
        ) : (
          <>
            <div className='site-min-height'>
              <div className='breadCumb1' role='presentation'>
                <Breadcrumbs
                  aria-label='breadcrumb'
                  separator={<NavigateNextIcon fontSize='small' />}
                >
                  <Link
                    underline='hover'
                    color='inherit'
                    onClick={() => navigate('/admin/admins/dashboard')}
                  >
                    Dashboard
                  </Link>

                  <Typography color='text.primary'>Change Favicon</Typography>
                </Breadcrumbs>
              </div>
              <h2 className='adminPageHeading'>Change Favicon</h2>
              <form className='adminForm'>
                <div className='mb-5 mt-5'>
                  <div className='mb-5 DashBoardInputBx'>
                    <div className='JSChangePhoto mb-4 mt-4'>
                      {selectedImage && (
                        <img
                          className='selectedInputImage'
                          src={selectedImage}
                          alt='Selected'
                        />
                      )}
                    </div>
                    <form>
                      <div className='mt-5'>
                        <div className='DashBoardInputBx'>
                          <label htmlFor='formFile' className='form-label'>
                            New Favicon<span className='RedStar'>*</span>
                          </label>
                          <input
                            className='form-control'
                            type='file'
                            id='formFile'
                            // eslint-disable-next-line react/no-unknown-property
                            lable='Image'
                            name='favicon'
                            accept='.ico'
                            onChange={e => handleFileUpload1(e)}
                          />
                          <div id='emailHelp' className='form-text'>
                            Supported File Types: ico (Max. 10MB). Min file size
                            16 X 16 pixels.
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className='adminBottomButtons'>
                    <button
                      type='button'
                      className='btn btn-primary button1'
                      onClick={handleClick}
                    >
                      UPDATE
                    </button>
                    <button
                      type='button'
                      className='btn btn-primary button2'
                      onClick={() => navigate('/admin/admins/dashboard')}
                    >
                      CANCEL
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default APChangeFavicon;

function convertToBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = error => {
      reject(error);
    };
  });
}
