import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// components
import Footer from '../element/Footer';
import NavBar from '../element/NavBar';
import { companiesList } from './settings/companiesList';

const ForJobseekers = () => {
  const { t } = useTranslation('global');

  useEffect(() => {
    const metaTitle = document.querySelector('title');
    const metaDescription = document.querySelector('meta[name="description"]');

    if (metaTitle) {
      metaTitle.textContent = t('forJobseekersPage.metaTitleValue');
    }

    if (metaDescription) {
      metaDescription.content = t('forJobseekersPage.metaDescriptionValue');
    }

    window.scrollTo(0, 0);
  }, [t]);

  return (
    <div className='forEmployers'>
      <NavBar />
      <div className='aboutusSection1 text-center'>
        <h1>
          {t('forJobseekersPage.pageTitle')}
        </h1>
        <h6 className='text-muted fw-normal'>
          <Link to='/' style={{ color: 'grey' }}>
            {t('navHeaders.home')}
          </Link>
          {' / '}
          {t('navHeaders.forJobseekers')}
        </h6>
      </div>
      <div className='container forEmployersSection2'>
        <h2 className='aboutusSec4Header text-center' style={{ margin: '5% 0 5% 0' }}>
          {t('forJobseekersPage.clientsHeader1.1')}
          <span className='textGradient'>
            {' '}
            <span className='SubHaddingTxt'>
              {t('forJobseekersPage.clientsHeader1.2')}
            </span>
          </span>
        </h2>
        <div className='companiesLogoBx'>
          {
            companiesList && (
              companiesList.map(i => (
                <div key={i} className='col companiesLogo'>
                  <img
                    alt={i}
                    className=''
                    src={`/Images/forJobseekers/companiesLogo/${i}.png`}
                  />
                </div>
              ))
            )
          }
        </div>
        <div className='aboutusDivider'>
          <hr />
        </div>
      </div>
      <div className='container aboutusSection4 text-center'>
        <h2 className='aboutusSec4Header' style={{ margin: '5% auto' }}>
          {t('forJobseekersPage.cardsHeader2.1')}
          <span className='textGradient'>
            {' '}
            <span className='SubHaddingTxt'>
              {t('forJobseekersPage.cardsHeader2.2')}
            </span>
          </span>
          {' '}
          {t('forJobseekersPage.cardsHeader2.3')}
          <span className='textGradient'>
            {' '}
            <span className='SubHaddingTxt'>
              {t('forJobseekersPage.cardsHeader2.4')}
            </span>
          </span>
          {' '}
          {t('forJobseekersPage.cardsHeader2.5')}
          <span className='textGradient'>
            {' '}
            <span className='SubHaddingTxt'>
              {t('forJobseekersPage.cardsHeader2.6')}
            </span>
          </span>
          {' '}
          {t('forJobseekersPage.cardsHeader2.7')}
          <span className='textGradient'>
            {' '}
            <span className='SubHaddingTxt'>
              {t('forJobseekersPage.cardsHeader2.8')}
            </span>
          </span>
        </h2>
        <div className='cards forJobseekersCardBx'>
          <div className='card p-3 card1'>
            <div className='card-body p-2'>
              <img
                alt='card1'
                className='aboutUsCardImage2 mb-1'
                src='/Images/forJobseekers/card1.png'
              />
              <div className='mt-5 text-start'>
                <p>{t('forJobseekersPage.card1.desc1')}</p>
                <Link to='/searchjob'>{t('forJobseekersPage.card1.desc2')}</Link>
              </div>
            </div>
          </div>
          <div className='card col-lg-3 col-sm-12 p-3'>
            <div className='card-body p-2'>
              <img
                className='aboutUsCardImage2'
                src='/Images/forJobseekers/card2.png'
                alt=''
              />
              <div className='mt-5 text-start'>
                <p className='mb-3'>{t('forJobseekersPage.card2.desc1')}</p>
                <p>{t('forJobseekersPage.card2.desc2')}</p>
                <p>{t('forJobseekersPage.card2.desc3')}</p>
                <p>{t('forJobseekersPage.card2.desc4')}</p>
                <p>{t('forJobseekersPage.card2.desc5')}</p>
              </div>
            </div>
          </div>
          <div className='card col-lg-3 col-sm-12 p-3'>
            <div className='card-body p-2'>
              <img
                className='aboutUsCardImage2'
                src='/Images/forJobseekers/card3.png'
                alt=''
              />
              <div className='mt-5 text-start'>
                <p className='mb-3'>{t('forJobseekersPage.card3.desc1')}</p>
                <p className='mb-3'>{t('forJobseekersPage.card3.desc2')}</p>
                <p>{t('forJobseekersPage.card3.desc3')}</p>
              </div>
            </div>
          </div>
          <div className='card p-3 card1'>
            <div className='card-body p-3'>
              <img
                className='aboutUsCardImage2'
                src='/Images/forJobseekers/card4.png'
                alt=''
              />
              <div className='mt-5 text-start'>
                <p>{t('forJobseekersPage.card4.desc1')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ForJobseekers;
