import React, { useEffect, useState } from 'react';
import APNavBar from '../Elements/APNavBar';
import APSidebar from '../APSidebar/APSidebar';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import axios from 'axios';
import BaseApi from '../../api/BaseApi';
import ApiKey from '../../api/ApiKey';
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';

const APChangeUsername = () => {
  const [userData, setUserData] = useState({
    name: '',
  });
  const [errors, setErrors] = useState({
    name: '',
  });
  const [loading, setLoading] = useState(false);
  const tokenKey = Cookies.get('token');
  const adminID = Cookies.get('adminID');

  const navigate = useNavigate();
  const { slug } = useParams();

  const handleChange = e => {
    const { name, value } = e.target;
    setUserData(prev => ({
      ...prev,
      [name]: value,
    }));
    setErrors(prev => ({
      ...prev,
      [name]: '',
    }));
  };

  const getData = async () => {
    try {
      const response = await axios.post(
        BaseApi + `/admin/subcategory/admin_add/${slug}`,
        null,
        {
          headers: {
            'Content-Type': 'application/json',
            key: ApiKey,
            token: tokenKey,
            adminid: adminID,
          },
        },
      );
      setUserData(response.data.response);
    } catch (error) {
      console.log('Error at chnage username at Admin panel');
    }
  };

  const handleClick = async () => {
    try {
      const { name } = userData;

      if (!name) {
        setErrors({
          name: name ? '' : 'Sub Category Name is required',
        });
        return;
      }

      const confirmationResult = await Swal.fire({
        title: 'Add Sub-Category',
        text: 'Do you want to add Sub-Category?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      });

      if (confirmationResult.isConfirmed) {
        setLoading(true);

        const response = await axios.post(
          BaseApi + `/admin/subcategory/admin_add/${slug}`,
          userData,
          {
            headers: {
              'Content-Type': 'application/json',
              key: ApiKey,
              token: tokenKey,
              adminid: adminID,
            },
          },
        );

        setLoading(false);

        if (response.data.status === 200) {
          Swal.fire({
            title: 'Sub-Category successfully!',
            icon: 'success',
            confirmButtonText: 'Close',
          });
          // getData();
          // setUserData({
          //   ...userData,
          //   new_username: "",
          //   conf_username: "",
          // });
          // window.scrollTo(0, 0)
          navigate(`/admin/categories/subindex/${slug}`);
        } else {
          Swal.fire({
            title: response.data.message,
            icon: 'error',
            confirmButtonText: 'Close',
          });
        }
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: 'Failed',
        text: 'Could not update username. Please try again later!',
        icon: 'error',
        confirmButtonText: 'Close',
      });
      console.log('Could not change username!', error);
    }
  };

  useEffect(() => {
    // Check if tokenKey is not present
    if (!tokenKey) {
      // Redirect to the home page
      navigate('/admin');
    } else {
      // TokenKey is present, fetch data or perform other actions
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]);

  return (
    <>
      <APNavBar />
      <div className='APBasic'>
        <APSidebar />

        {loading ? (
          <>
            <div className='loader-container'></div>
          </>
        ) : (
          <>
            <div className='site-min-height'>
              <div className='breadCumb' role='presentation'>
                <Breadcrumbs
                  aria-label='breadcrumb'
                  separator={<NavigateNextIcon fontSize='small' />}
                >
                  <Link
                    underline='hover'
                    color='inherit'
                    onClick={() => navigate('/admin/admins/dashboard')}
                  >
                    Dashboard
                  </Link>
                  <Link
                    underline='hover'
                    color='inherit'
                    onClick={() => navigate('/admin/categories')}
                  >
                    Categories
                  </Link>
                  <Link
                    underline='hover'
                    color='inherit'
                    onClick={() =>
                      navigate(`/admin/categories/subindex/${slug}`)
                    }
                  >
                    {userData.cateogory_name}
                  </Link>

                  <Typography color='text.primary'>Add Sub Category</Typography>
                </Breadcrumbs>
              </div>

              <h2 className='adminPageHeading'>Add Sub Category</h2>
              <form className='adminForm'>
                <div className='mb-4 mt-5'>
                  <div className='mb-5 DashBoardInputBx'>
                    <label htmlFor='formFile' className='form-label'>
                      Sub Category Name<span className='RedStar'>*</span>
                    </label>
                    <input
                      type='text'
                      id='form3Example1'
                      className={`form-control ${errors.name && 'input-error'}`}
                      name='name'
                      placeholder='Add Sub Category'
                      value={userData.name}
                      onChange={handleChange}
                    />
                    {errors.name && (
                      <div className='text-danger'>{errors.name}</div>
                    )}
                  </div>
                  <div className='adminBottomButtons'>
                    <button
                      type='button'
                      className='btn btn-primary button1'
                      onClick={handleClick}
                    >
                      SAVE
                    </button>
                    <button
                      type='button'
                      className='btn btn-primary button2'
                      onClick={() => setUserData({ ...userData, name: '' })}
                    >
                      RESET
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default APChangeUsername;
