import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// components
import Footer from '../element/Footer';
import NavBar from '../element/NavBar';

const EmailConfirmed = () => {
  const { t } = useTranslation('global');

  return (
    <div className='aboutUs'>
      <NavBar />
      <div className='aboutusSection1 text-center'>
        <h1>
          {t('emailConfirmed.pageTitle')}
        </h1>
        <h6 className='text-muted fw-normal'>
          <Link to='/' style={{ color: 'grey' }}>
            {t('navHeaders.home')}
          </Link>
          {' / '}
          {t('emailConfirmed.pageTitle')}
        </h6>
      </div>
      <div className='container aboutusSection4 text-center'>
        <div className='cards row justify-content-center'>
          <div className='card col'>
            <div className='card-body p-4'>
              <h2 className='aboutusSec4Header mx-auto' style={{ margin: '3% 0 3% 0' }}>
                {t('emailConfirmed.emailConfirmedHeader1.1')}
                <span className='textGradient'>
                  {' '}
                  <span className='SubHaddingTxt'>
                    {t('emailConfirmed.emailConfirmedHeader1.2')}
                  </span>
                </span>
              </h2>
              <p className='mb-4'>{t('emailConfirmed.description1')}</p>
              <p className='mb-4'>{t('emailConfirmed.description2')}
                {' '}
                <a href='mailto:feedback@grc.ua'>
                  feedback@grc.ua
                </a>
              </p>
            </div>
          </div>
        </div>
        <Link to='/'>
          <button className='btn button1'>
            {t('emailConfirmed.backToHome')}
          </button>
        </Link>
      </div>
      <Footer />
    </div>
  );
};

export default EmailConfirmed;
