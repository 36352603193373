import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import JoditEditor from 'jodit-react';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// api
import ApiKey from '../../api/ApiKey';
import BaseApi from '../../api/BaseApi';
// components
import APNavBar from '../Elements/APNavBar';
import APSidebar from '../APSidebar/APSidebar';
// helpers
import { editorConfig } from '../../helpers';
// mui
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const APEditEmployerDetails = () => {
  const editor = useRef(null);
  const { slug } = useParams();
  const navigate = useNavigate();
  const tokenKey = Cookies.get('token');
  const adminID = Cookies.get('adminID');

  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [errors, setErrors] = useState({
    url: '',
    address: '',
    contact: '',
    location: '',
    position: '',
    last_name: '',
    first_name: '',
    company_name: '',
    new_password: '',
    company_about: '',
    email_address: '',
    company_contact: '',
    confirm_password: '',
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setUserData(prev => ({
      ...prev,
      [name]: value,
    }));
    setErrors(prev => ({
      ...prev,
      [name]: '',
    }));
  };

  const handleClick = async () => {
    try {
      const newErrors = {};

      const isValidEmail = email => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        return emailPattern.test(email);
      };

      if (userData.company_name === '' || userData.company_name === null) {
        newErrors.company_name = 'Company Name is required';
        window.scrollTo(0, 0);
      }
      if (userData.company_about === '' || userData.company_about === null) {
        newErrors.company_about = 'Company Profile is required';
        window.scrollTo(0, 0);
      }
      if (userData.position === '' || userData.position === null) {
        newErrors.position = 'Position is required';
        window.scrollTo(0, 0);
      }
      if (userData.first_name === '') {
        newErrors.first_name = 'First Name is required';
        window.scrollTo(0, 0);
      }
      if (userData.last_name === '') {
        newErrors.last_name = 'Last Name is required';
        window.scrollTo(0, 0);
      }
      // if (userData.address === '' || userData.address === null) {
      //   newErrors.address = 'Address is required';
      //   window.scrollTo(0, 0);
      // }
      // if (userData.location === '' || userData.location === null) {
      //   newErrors.location = 'Location is required';
      //   window.scrollTo(0, 0);
      // }
      if (userData.contact === '') {
        newErrors.contact = 'Contact Number is required';
        window.scrollTo(0, 0);
      }
      else if (!/^\+?3?8?(0\d{9})$/.test(userData.contact)) {
        newErrors.contact = 'The contact number should be in the format: +380XXXXXXXXX or 0XXXXXXXXX';
        window.scrollTo(0, 0);
      }
      if (userData.company_contact === '') {
        newErrors.company_contact = 'Company Contact Number is required';
        window.scrollTo(0, 0);

      } else if (!/^\+?3?8?(0\d{9})$/.test(userData.company_contact)) {
        newErrors.company_contact = 'The contact number should be in the format: +380XXXXXXXXX or 0XXXXXXXXX';
        window.scrollTo(0, 0);
      }
      if (userData.email_address === '') {
        newErrors.email_address = 'Email Address is required';
        window.scrollTo(0, 0);
      } else if (!isValidEmail(userData.email_address)) {
        newErrors.email_address = 'Invalid email format';
        window.scrollTo(0, 0);
      }
      if (userData.new_password === '') {
        newErrors.new_password = 'Password is required';
        window.scrollTo(0, 0);
      }
      if (userData.confirm_password === '') {
        newErrors.confirm_password = 'Confirm Password is required';
        window.scrollTo(0, 0);
      }
      if (userData.new_password) {
        if (userData.new_password.length < 8) {
          newErrors.new_password = 'Please enter atleast 8 characters';
          window.scrollTo(0, 0);
        }
      }
      if (userData.confirm_password) {
        if (userData.confirm_password.length < 8) {
          newErrors.confirm_password = 'Please enter atleast 8 characters';
          window.scrollTo(0, 0);
        }
      }
      if (userData.new_password && userData.confirm_password) {
        if (userData.new_password !== userData.confirm_password) {
          newErrors.confirm_password = 'Password and Confirm Password do not match';
          window.scrollTo(0, 0);
        }
      }
      if (userData.url) {
        const urlFormat = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;

        if (userData.url && !urlFormat.test(userData.url)) {
          newErrors.url = 'Invalid URL format';
        }
      }

      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0) {

        const confirmationResult = await Swal.fire({
          icon: 'question',
          cancelButtonText: 'No',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          title: 'Update Employer?',
          text: 'Do you want to update the Employer?',
        });

        if (confirmationResult.isConfirmed) {
          setLoading(true);

          const response = await axios.post(
            BaseApi + `/admin/users/editusers/${slug}`,
            userData,
            {
              headers: {
                key: ApiKey,
                token: tokenKey,
                adminid: adminID,
                'Content-Type': 'application/json',
              },
            },
          );

          setLoading(false);

          if (response.data.status === 200) {
            Swal.fire({
              icon: 'success',
              confirmButtonText: 'Close',
              title: 'Employer updated successfully!',
            });

            navigate('/admin/users');
          } else {
            Swal.fire({
              icon: 'error',
              confirmButtonText: 'Close',
              title: response.data.message,
            });
          }
        }
      }
    } catch (error) {
      setLoading(false);

      Swal.fire({
        icon: 'error',
        title: 'Failed',
        confirmButtonText: 'Close',
        text: 'Could not update employer. Please try again later!',
      });

      console.error('Could not change username!', error);
    }
  };

  const handleFileUpload1 = async e => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    setUserData({ ...userData, company_logo: base64 });
    setSelectedImage(base64);
  };

  const deleteImage = () => {
    setSelectedImage(null);
    setUserData({ ...userData, profile_image: '' });
  };

  const handleStatusChange = e => {
    e.preventDefault();
    if (userData.status === 1) {
      setUserData({ ...userData, status: 0 });
    }
    if (userData.status === 0) {
      setUserData({ ...userData, status: 1 });
    }
  };

  useEffect(() => {
    if (!tokenKey) {
      navigate('/admin');
    } else {
      const getData = async () => {
        try {
          setLoading(true);

          const response = await axios.post(
            BaseApi + `/admin/users/editusers/${slug}`,
            null,
            {
              headers: {
                key: ApiKey,
                token: tokenKey,
                adminid: adminID,
                'Content-Type': 'application/json',
              },
            },
          );

          setLoading(false);
          setUserData(response.data.response);
          setSelectedImage(response.data.response.company_logo);
        } catch (error) {
          setLoading(false);

          console.error('Cannot get plans data at APmanageplans', error);
        }
      };

      getData();

      window.scrollTo(0, 0);
    }
  }, [slug, adminID, tokenKey, navigate]);

  return (
    <>
      <APNavBar />
      <div className='APBasic'>
        <APSidebar />
        {
          loading
            ? (
              <div className='loader-container'></div>
            ) : (
              <div className='site-min-height'>
                <div className='breadCumb1' role='presentation'>
                  <Breadcrumbs
                    aria-label='breadcrumb'
                    separator={<NavigateNextIcon fontSize='small' />}
                  >
                    <Link
                      underline='hover'
                      color='inherit'
                      onClick={() => navigate('/admin/admins/dashboard')}
                    >
                      Dashboard
                    </Link>
                    <Link
                      underline='hover'
                      color='inherit'
                      onClick={() => navigate('/admin/users')}
                    >
                      Employers
                    </Link>
                    <Typography color='text.primary'>
                      Edit Employer Details
                    </Typography>
                  </Breadcrumbs>
                </div>
                <h2 className='adminPageHeading'>Edit Employer</h2>
                <form className='adminForm'>
                  <div className='mb-4 mt-5'>
                    <div className='mb-5 DashBoardInputBx'>
                      <label htmlFor='formFile' className='form-label'>
                        Company Name
                        <span className='RedStar'>*</span>
                      </label>
                      <input
                        type='text'
                        id='company_name'
                        name='company_name'
                        onChange={handleChange}
                        placeholder='Company Name'
                        value={userData.company_name}
                        className={`form-control ${errors.company_name && 'input-error'}`}
                      />
                      {
                        errors.company_name && (
                          <div className='text-danger'>{errors.company_name}</div>
                        )
                      }
                    </div>
                    <div className=' mb-5 DashBoardInputBx DashBoardCreatBx APJoditEditor'>
                      <label className='form-label' htmlFor='form3Example3'>
                        Company Profile
                        <span className='RedStar'>*</span>
                      </label>
                      <JoditEditor
                        ref={editor}
                        name='company_about'
                        config={editorConfig}
                        value={userData.company_about}
                        onBlur={company_about =>
                          handleChange({
                            target: {
                              value: company_about,
                              name: 'company_about',
                            },
                          })
                        }
                      />
                      {
                        errors.company_about && (
                          <div className='text-danger'>{errors.company_about}</div>
                        )
                      }
                    </div>
                    <div className='mb-5 DashBoardInputBx'>
                      <label htmlFor='formFile' className='form-label'>
                        Position
                        <span className='RedStar'>*</span>
                      </label>
                      <input
                        type='text'
                        id='position'
                        name='position'
                        placeholder='Position'
                        onChange={handleChange}
                        value={userData.position}
                        className={`form-control ${errors.position && 'input-error'}`}
                      />
                      {
                        errors.position && (
                          <div className='text-danger'>{errors.position}</div>
                        )
                      }
                    </div>
                    <div className='mb-5 DashBoardInputBx'>
                      <label htmlFor='formFile' className='form-label'>
                        First Name
                        <span className='RedStar'>*</span>
                      </label>
                      <input
                        type='text'
                        id='first_name'
                        name='first_name'
                        onChange={handleChange}
                        placeholder='First Name'
                        value={userData.first_name}
                        className={`form-control ${errors.first_name && 'input-error'}`}
                      />
                      {
                        errors.first_name && (
                          <div className='text-danger'>{errors.first_name}</div>
                        )
                      }
                    </div>
                    <div className='mb-5 DashBoardInputBx'>
                      <label htmlFor='formFile' className='form-label'>
                        Last Name
                        <span className='RedStar'>*</span>
                      </label>
                      <input
                        type='text'
                        id='last_name'
                        name='last_name'
                        onChange={handleChange}
                        placeholder='Last Name'
                        value={userData.last_name}
                        className={`form-control ${errors.last_name && 'input-error'}`}
                      />
                      {
                        errors.last_name && (
                          <div className='text-danger'>{errors.last_name}</div>
                        )
                      }
                    </div>
                    <div className='mb-5 DashBoardInputBx'>
                      <label htmlFor='formFile' className='form-label'>
                        Address
                        {/* <span className='RedStar'>*</span> */}
                      </label>
                      <textarea
                        type='text'
                        id='address'
                        name='address'
                        placeholder='Address'
                        onChange={handleChange}
                        value={userData.address}
                        className={`form-control ${errors.address && 'input-error'}`}
                      />
                      {
                        errors.address && (
                          <div className='text-danger'>{errors.address}</div>
                        )
                      }
                    </div>
                    <div className='mb-5 DashBoardInputBx'>
                      <label htmlFor='formFile' className='form-label'>
                        Location
                        {/* <span className='RedStar'>*</span> */}
                      </label>
                      <input
                        type='text'
                        id='location'
                        name='location'
                        placeholder='Location'
                        onChange={handleChange}
                        value={userData.location}
                        className={`form-control ${errors.location && 'input-error'}`}
                      />
                      {
                        errors.location && (
                          <div className='text-danger'>{errors.location}</div>
                        )
                      }
                    </div>
                    <div className='mb-5 DashBoardInputBx'>
                      <label htmlFor='formFile' className='form-label'>
                        Contact Number
                        <span className='RedStar'>*</span>
                      </label>
                      <input
                        type='text'
                        id='contact'
                        name='contact'
                        onChange={handleChange}
                        value={userData.contact}
                        placeholder='Contact Number'
                        className={`form-control ${errors.contact && 'input-error'}`}
                      />
                      {
                        errors.contact && (
                          <div className='text-danger'>{errors.contact}</div>
                        )
                      }
                      <div id='emailHelp' className='form-text'>
                        Eg: 0671234567 or +380671234567
                      </div>
                    </div>
                    <div className='mb-5 DashBoardInputBx'>
                      <label htmlFor='formFile' className='form-label'>
                        Company Contact Number
                        <span className='RedStar'>*</span>
                      </label>
                      <input
                        type='text'
                        id='company_contact'
                        name='company_contact'
                        onChange={handleChange}
                        value={userData.company_contact}
                        placeholder='Company Contact Number'
                        className={`form-control ${errors.company_contact && 'input-error'}`}
                      />
                      {
                        errors.company_contact && (
                          <div className='text-danger'>{errors.company_contact}</div>
                        )
                      }
                      <div id='emailHelp' className='form-text'>
                        Eg: 0671234567 or +380671234567
                      </div>
                    </div>
                    <div className='mb-5 DashBoardInputBx'>
                      <label htmlFor='formFile' className='form-label'>
                        Company Website
                      </label>
                      <input
                        id='url'
                        name='url'
                        type='text'
                        value={userData.url}
                        onChange={handleChange}
                        placeholder='Company Website'
                        className={`form-control ${errors.url && 'input-error'}`}
                      />
                      {
                        errors.url && (
                          <div className='text-danger'>{errors.url}</div>
                        )
                      }
                      <div id='emailHelp' className='form-text'>
                        Eg: https://www.google.com or https://google.com
                      </div>
                    </div>
                    <div className='mb-5 DashBoardInputBx'>
                      <label htmlFor='formFile' className='form-label'>
                        Company Logo
                      </label>
                      <input
                        id='logo'
                        name='logo'
                        type='file'
                        label='Image'
                        className='form-control'
                        accept='.jpeg, .png, .jpg'
                        onChange={e => handleFileUpload1(e)}
                      />
                      <div id='emailHelp' className='form-text'>
                        Supported File Types: gif, jpg, jpeg, png (Max. 10MB). Min
                        file size 250 X 250 pixels.
                      </div>
                      {
                        selectedImage && (
                          <div>
                            <img
                              alt='Selected'
                              src={selectedImage}
                              className='selectedInputImage'
                            />
                            {
                              userData.profile_image && (
                                <button className='APButton3' onClick={deleteImage}>
                                  Delete
                                </button>
                              )
                            }
                          </div>
                        )
                      }
                    </div>
                    <div className='mb-5 DashBoardInputBx'>
                      <label htmlFor='formFile' className='form-label'>
                        Comments
                      </label>
                      <textarea
                        rows={4}
                        id='notes'
                        name='notes'
                        placeholder='Comments'
                        value={userData.notes}
                        onChange={handleChange}
                        className={`form-control ${errors.contact && 'input-error'}`}
                      />
                    </div>
                    <hr />
                    <h4 className='mb-4'>Login Details</h4>
                    <div className='mb-5 DashBoardInputBx'>
                      <label htmlFor='formFile' className='form-label'>
                        Email Address
                        <span className='RedStar'>*</span>
                      </label>
                      <input
                        type='text'
                        id='email_address'
                        name='email_address'
                        onChange={handleChange}
                        placeholder='Email Address'
                        value={userData.email_address}
                        className={`form-control ${errors.email_address && 'input-error'}`}
                      />
                      {
                        errors.email_address && (
                          <div className='text-danger'>{errors.email_address}</div>
                        )
                      }
                    </div>
                    <div className='mb-5 DashBoardInputBx'>
                      <label htmlFor='formFile' className='form-label'>
                        Password
                        <span className='RedStar'>*</span>
                      </label>
                      <input
                        type='password'
                        id='new_password'
                        name='new_password'
                        placeholder='Password'
                        onChange={handleChange}
                        value={userData.new_password}
                        className={`form-control ${errors.password && 'input-error'}`}
                      />
                      {
                        errors.new_password && (
                          <div className='text-danger'>{errors.new_password}</div>
                        )
                      }
                      <div id='emailHelp' className='form-text'>
                        * Note: If You want to change User&apos;s password, only then
                        fill password below otherwise leave it blank.
                      </div>
                    </div>
                    <div className='mb-5 DashBoardInputBx'>
                      <label htmlFor='formFile' className='form-label'>
                        Confirm Password
                        <span className='RedStar'>*</span>
                      </label>
                      <input
                        type='password'
                        id='confirm_password'
                        name='confirm_password'
                        onChange={handleChange}
                        placeholder='Confirm Password'
                        value={userData.confirm_password}
                        className={`form-control ${errors.confirm_password && 'input-error'}`}
                      />
                      {
                        errors.confirm_password && (
                          <div className='text-danger'>{errors.confirm_password}</div>
                        )
                      }
                    </div>
                    <hr />
                    <div className='otherSection'>
                      <h4 className=''>Other Details</h4>
                      <div className='otherSectionBody'>
                        <p>Employer Status</p>
                        <div className='activeButtonBox'>
                          {
                            userData.status === 1
                              ? (
                                <button className='activeTrue' onClick={handleStatusChange}>
                                  Active
                                </button>
                              ) : (
                                <button className='activeFalse' onClick={handleStatusChange}>
                                  Inactive
                                </button>
                              )
                          }
                        </div>
                      </div>
                      <div id='emailHelp' className='form-text'>
                        Click above to change Employer&apos;s status. Changes will be
                        reflect only after submitting form.
                      </div>
                      <div id='emailHelp' className='text-danger'>
                        Changes will be reflect only after submitting form.
                      </div>
                    </div>
                    <div className='adminBottomButtons'>
                      <button
                        type='button'
                        onClick={handleClick}
                        className='btn btn-primary button1'
                      >
                        SAVE
                      </button>
                      <button
                        type='button'
                        className='btn btn-primary button2'
                        onClick={() => navigate('/admin/users')}
                      >
                        CANCEL
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )
        }
      </div>
    </>
  );
};

export default APEditEmployerDetails;

function convertToBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = error => {
      reject(error);
    };
  });
}
