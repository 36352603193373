/* eslint-disable unused-imports/no-unused-imports */
/* eslint-disable unused-imports/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import APNavBar from '../Elements/APNavBar';
import APSidebar from '../APSidebar/APSidebar';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import axios from 'axios';
import BaseApi from '../../api/BaseApi';
import ApiKey from '../../api/ApiKey';
import Swal from 'sweetalert2';
// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CreateIcon from '@mui/icons-material/Create';
// import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
// import ContentCopyIcon from "@mui/icons-material/ContentCopy";
// import BlockIcon from "@mui/icons-material/Block";
// import CheckIcon from "@mui/icons-material/Check";
import Tooltip from '@mui/material/Tooltip';
// import AddIcon from "@mui/icons-material/Add";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
// import VerifiedIcon from "@mui/icons-material/Verified";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import HTMLReactParser from 'html-react-parser';
import { Button, IconButton } from '@mui/material';
import Cookies from 'js-cookie';

const APEmailTemplateSetting = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  // const [checkedData, setCheckedData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const tokenKey = Cookies.get('token');
  const adminID = Cookies.get('adminID');

  const [currentPage, setCurrentPage] = useState(1);
  // const [dataPerPage, setDataPerPage] = useState([]);
  const itemsPerPage = 20;
  const [open, setOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const handleOpen = plan => {
    setSelectedPlan(plan);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedPlan(null);
    setOpen(false);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%', // Adjusted width for mobile responsiveness
    maxWidth: '400px',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        BaseApi + '/admin/emailtemplates',
        null, // Pass null as the request body if not required
        {
          headers: {
            'Content-Type': 'application/json',
            key: ApiKey,
            token: tokenKey,
            adminid: adminID,
          },
        },
      );
      setLoading(false);
      setListData(response.data.response.email);
    } catch (error) {
      setLoading(false);
      console.log('Cannot get plans data at APmanageplans');
    }
  };
  // const handleActivate = async (slug) => {
  //   try {
  //     const confirmationResult = await Swal.fire({
  //       title: "Activate Page?",
  //       text: "Do you want to Activate this Page?",
  //       icon: "question",
  //       showCancelButton: true,
  //       confirmButtonText: "Yes",
  //       cancelButtonText: "No",
  //     });
  //     if (confirmationResult.isConfirmed) {
  //       // setLoading(true);
  //       const response = await axios.post(
  //         BaseApi + `/admin/page/admin_activate/${slug}`,
  //         null, // Pass null as the request body if not required
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             key: ApiKey,
  //             token: tokenKey,
  //           },
  //         }
  //       );
  //       // setLoading(false);
  //       if (response.data.status === 200) {
  //         Swal.fire({
  //           title: "Page Activated successfully!",
  //           icon: "success",
  //           confirmButtonText: "Close",
  //         });
  //       } else {
  //         Swal.fire({
  //           title: response.data.message,
  //           icon: "error",
  //           confirmButtonText: "Close",
  //         });
  //       }
  //       getData();
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     Swal.fire({
  //       title: "Failed. Please try after some time!",
  //       text: "Could not Activate Page",
  //       icon: "error",
  //       confirmButtonText: "Close",
  //     });
  //     console.log("Couldn't activate the record!", error.message);
  //   }
  // };
  // const handleDeactivate = async (slug) => {
  //   try {
  //     const confirmationResult = await Swal.fire({
  //       title: "Deactivate Page?",
  //       text: "Do you want to Deactivate this Page?",
  //       icon: "question",
  //       showCancelButton: true,
  //       confirmButtonText: "Yes",
  //       cancelButtonText: "No",
  //     });
  //     if (confirmationResult.isConfirmed) {
  //       // setLoading(true);
  //       const response = await axios.post(
  //         BaseApi + `/admin/page/admin_deactivate/${slug}`,
  //         null, // Pass null as the request body if not required
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             key: ApiKey,
  //             token: tokenKey,
  //           },
  //         }
  //       );
  //       // setLoading(false);
  //       if (response.data.status === 200) {
  //         Swal.fire({
  //           title: "Page Deactivated successfully!",
  //           icon: "success",
  //           confirmButtonText: "Close",
  //         });
  //       } else {
  //         Swal.fire({
  //           title: response.data.message,
  //           icon: "error",
  //           confirmButtonText: "Close",
  //         });
  //       }
  //       getData();
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     Swal.fire({
  //       title: "Failed. Please try after some time!",
  //       text: "Could not Deactivate Page",
  //       icon: "error",
  //       confirmButtonText: "Close",
  //     });
  //     console.log("Couldn't deactivate the record!", error.message);
  //   }
  // };

  // const handleDelete = async (slug) => {
  //   try {
  //     const confirmationResult = await Swal.fire({
  //       title: "Delete Page?",
  //       text: "Do you want to Delete this Page?",
  //       icon: "question",
  //       showCancelButton: true,
  //       confirmButtonText: "Yes",
  //       cancelButtonText: "No",
  //     });
  //     if (confirmationResult.isConfirmed) {
  //       // setLoading(true);
  //       const response = await axios.post(
  //         BaseApi + `/admin/page/admin_delete/${slug}`,
  //         null, // Pass null as the request body if not required
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             key: ApiKey,
  //             token: tokenKey,
  //           },
  //         }
  //       );
  //       // setLoading(false);
  //       if (response.data.status === 200) {
  //         Swal.fire({
  //           title: "Page Deleted successfully!",
  //           icon: "success",
  //           confirmButtonText: "Close",
  //         });
  //       } else {
  //         Swal.fire({
  //           title: response.data.message,
  //           icon: "error",
  //           confirmButtonText: "Close",
  //         });
  //       }
  //       getData();
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     Swal.fire({
  //       title: "Failed. Please try after some time!",
  //       text: "Could not Delete Page",
  //       icon: "error",
  //       confirmButtonText: "Close",
  //     });
  //     console.log("Couldn't delete the record!", error.message);
  //   }
  // };

  // const handleCheck = (id) => {
  //   if (checkedData.includes(id)) {
  //     // If the ID is already in the array, remove it
  //     setCheckedData(checkedData.filter((checkedId) => checkedId !== id));
  //   } else {
  //     // If the ID is not in the array, add it
  //     setCheckedData([...checkedData, id]);
  //   }
  // };

  // const handleMultipleDeactivate = async () => {
  //   try {
  //     const confirmationResult = await Swal.fire({
  //       title: "Deactivate Pages?",
  //       text: "Do you want to Deactivate Pages?",
  //       icon: "question",
  //       showCancelButton: true,
  //       confirmButtonText: "Yes",
  //       cancelButtonText: "No",
  //     });
  //     if (confirmationResult.isConfirmed) {
  //       const ids = checkedData.join(",");
  //       console.log(ids);
  //       // setLoading(true);
  //       const response = await axios.post(
  //         BaseApi + "/admin",
  //         {
  //           idList: ids,
  //           action: "deactivate",
  //         }, // Pass null as the request body if not required
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             key: ApiKey,
  //             token: tokenKey,
  //           },
  //         }
  //       );
  //       // setLoading(false);
  //       if (response.data.status === 200) {
  //         Swal.fire({
  //           title: "Pages Deactivated successfully!",
  //           icon: "success",
  //           confirmButtonText: "Close",
  //         });
  //       } else {
  //         Swal.fire({
  //           title: "Couldn't Deactivate!",
  //           icon: "error",
  //           confirmButtonText: "Close",
  //         });
  //       }
  //       getData();
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     Swal.fire({
  //       title: "Failed. Please try after some time!",
  //       text: "Could not Deactivate Pages",
  //       icon: "error",
  //       confirmButtonText: "Close",
  //     });
  //     console.log("Couldn't deactivate the record!", error.message);
  //   }
  // };
  // const handleMultipleActivate = async () => {
  //   try {
  //     const confirmationResult = await Swal.fire({
  //       title: "Activate Pages?",
  //       text: "Do you want to Activate Pages?",
  //       icon: "question",
  //       showCancelButton: true,
  //       confirmButtonText: "Yes",
  //       cancelButtonText: "No",
  //     });
  //     if (confirmationResult.isConfirmed) {
  //       let ids = checkedData.toString();
  //       console.log(ids);
  //       // setLoading(true);
  //       const response = await axios.post(
  //         BaseApi + "/admin/page/index",
  //         {
  //           idList: ids,
  //           action: "activate",
  //         }, // Pass null as the request body if not required
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             key: ApiKey,
  //             token: tokenKey,
  //           },
  //         }
  //       );
  //       // setLoading(false);
  //       if (response.data.status === 200) {
  //         Swal.fire({
  //           title: "Pages Activated successfully!",
  //           icon: "success",
  //           confirmButtonText: "Close",
  //         });
  //       } else {
  //         Swal.fire({
  //           title: "Couldn't Activate!",
  //           icon: "error",
  //           confirmButtonText: "Close",
  //         });
  //       }
  //       getData();
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     Swal.fire({
  //       title: "Failed. Please try after some time!",
  //       text: "Could not Activate Pages",
  //       icon: "error",
  //       confirmButtonText: "Close",
  //     });
  //     console.log("Couldn't activate the record!", error.message);
  //   }
  // };
  // const handleMultipleDelete = async () => {
  //   try {
  //     const confirmationResult = await Swal.fire({
  //       title: "Delete Pages?",
  //       text: "Do you want to Delete Pages?",
  //       icon: "question",
  //       showCancelButton: true,
  //       confirmButtonText: "Yes",
  //       cancelButtonText: "No",
  //     });
  //     if (confirmationResult.isConfirmed) {
  //       let ids = checkedData.toString();
  //       console.log(ids);
  //       // setLoading(true);
  //       const response = await axios.post(
  //         BaseApi + "/admin/page/index",
  //         {
  //           idList: ids,
  //           action: "delete",
  //         }, // Pass null as the request body if not required
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             key: ApiKey,
  //             token: tokenKey,
  //           },
  //         }
  //       );
  //       // setLoading(false);
  //       if (response.data.status === 200) {
  //         Swal.fire({
  //           title: "Pages Deleted successfully!",
  //           icon: "success",
  //           confirmButtonText: "Close",
  //         });
  //       } else {
  //         Swal.fire({
  //           title: "Couldn't Delete!",
  //           icon: "error",
  //           confirmButtonText: "Close",
  //         });
  //       }
  //       getData();
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     Swal.fire({
  //       title: "Failed. Please try after some time!",
  //       text: "Could not Delete Pages",
  //       icon: "error",
  //       confirmButtonText: "Close",
  //     });
  //     console.log("Couldn't Delete the record!", error.message);
  //   }
  // };

  const sortAndFilterData = (array, key, direction, query) => {
    const sortedAndFilteredArray = [...array]
      .filter(
        item =>
          item.title?.toLowerCase().includes(query) ||
          item.subject?.toLowerCase().includes(query),
      )
      .sort((a, b) => {
        if (a[key] < b[key]) {
          return direction === 'ascending' ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    return sortedAndFilteredArray;
  };
  const [sortConfig, setSortConfig] = useState({
    key: '',
    direction: 'ascending',
  });
  const [symbol, setSymbol] = useState('🔺');

  const sortedAndFilteredData = sortAndFilterData(
    listData,
    sortConfig.key,
    sortConfig.direction,
    searchQuery,
  );

  const filteredData = listData.filter(item => {
    const searchString = searchQuery.toLowerCase();
    return (
      item.title?.toLowerCase().includes(searchString) ||
      item.subject?.toLowerCase().includes(searchString)
    );
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedAndFilteredData.slice(
    indexOfFirstItem,
    indexOfLastItem,
  );

  const totalPages = Math.ceil(sortedAndFilteredData.length / itemsPerPage);

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }
  useEffect(() => {
    // Check if tokenKey is not present
    if (!tokenKey) {
      // Redirect to the home page
      navigate('/admin');
    } else {
      // TokenKey is present, fetch data or perform other actions
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]);

  useEffect(() => {
    setCurrentPage(1); // Reset currentPage to 1 when searchQuery changes
  }, [searchQuery]);

  const handleColumnClick = key => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
      setSymbol('🔻');
    } else {
      setSymbol('🔺');
    }
    setSortConfig({ key, direction });
  };
  // const handleSearchChange = (e) => {
  //   const newQuery = e.target.value.toLowerCase();
  //   setSearchQuery(newQuery);
  // };

  return (
    <>
      <APNavBar />
      <div className='APBasic APManageSubadmin APTextPages'>
        <APSidebar />

        {loading ? (
          <>
            <div className='loader-container'></div>
          </>
        ) : (
          <>
            <div className='site-min-height'>
              <div className='breadCumb1' role='presentation'>
                <Breadcrumbs
                  aria-label='breadcrumb'
                  separator={<NavigateNextIcon fontSize='small' />}
                >
                  <Link
                    to='/admin/admins/dashboard'
                    underline='hover'
                    color='inherit'
                  >
                    Dashboard
                  </Link>
                  <Link underline='hover' color='inherit'>
                    Email Template Management
                  </Link>

                  <Typography color='text.primary'>
                    Email Template List
                  </Typography>
                </Breadcrumbs>
              </div>
              <div className='ManageSubAdminHeader'>
                <h2 className=''>Email Template List</h2>
              </div>
              <div className='manageSubadminPart1 APTPSec1'>
                <h4>
                  No. of Email Templates {indexOfFirstItem + 1}-
                  {Math.min(
                    indexOfLastItem,
                    filteredData.length || listData.length,
                  )}{' '}
                  of {listData.length}
                </h4>
              </div>

              <div className='manageSubadminPart2'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th onClick={() => handleColumnClick('title')}>
                        Title{symbol}
                      </th>
                      <th onClick={() => handleColumnClick('subject')}>
                        Subject{symbol}
                      </th>

                      <th scope='col'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map(i => (
                      <>
                        <tr>
                          <td className=''>
                            <Link
                              to={`/admin/emailtemplates/editEmailtemplate/${i.slug}`}
                            >
                              {i.title}
                            </Link>
                          </td>
                          <td className=''>
                            <Link
                              to={`/admin/emailtemplates/editEmailtemplate/${i.slug}`}
                            >
                              {i.subject}
                            </Link>
                          </td>

                          <td className='APActionButton'>
                            {/* {i.status === 1 ? (
                                  <button
                                    className="btn-secondary"
                                    onClick={() => handleDeactivate(i.slug)}
                                  >
                                    <Tooltip title="Deactivate">
                                      <CheckIcon />
                                    </Tooltip>
                                  </button>
                                ) : (
                                  <button
                                    className="btn-secondary"
                                    onClick={() => handleActivate(i.slug)}
                                  >
                                    <Tooltip title="Activate">
                                      <BlockIcon />
                                    </Tooltip>
                                  </button>
                                )} */}
                            <button
                              className='btn-primary'
                              onClick={() =>
                                navigate(
                                  `/admin/emailtemplates/editEmailtemplate/${i.slug}`,
                                )
                              }
                            >
                              <Tooltip title='Edit'>
                                <CreateIcon />
                              </Tooltip>
                            </button>
                            <button
                              className='btn btn-secondary'
                              onClick={() => handleOpen(i)}
                            >
                              <Tooltip title='Delete'>
                                <RemoveRedEyeIcon />
                              </Tooltip>
                            </button>
                          </td>
                        </tr>
                      </>
                    ))}
                    <div>
                      <Modal
                        className='modalMain'
                        open={open}
                        onClose={handleClose}
                        aria-labelledby='modal-modal-title'
                        aria-describedby='modal-modal-description'
                      >
                        <Box className='modal adminModal modal-content'>
                          <IconButton
                            onClick={handleClose}
                            className='close-button'
                            style={{ position: 'absolute', top: 10, right: 10 }}
                          >
                            &times;
                          </IconButton>

                          {/* <Typography
                            id="modal-modal-title"
                            variant="h4"
                            component="h3"
                          >
                            {selectedPlan &&
                              HTMLReactParser(selectedPlan.title)}
                          </Typography> */}
                          {/* <hr /> */}
                          <Typography
                            id='modal-modal-description'
                            sx={{ mt: 3 }}
                          >
                            {selectedPlan && (
                              <div className='modals '>
                                <div className='modalHead'>
                                  <h1>{HTMLReactParser(selectedPlan.title)}</h1>
                                </div>
                                <div className='modalBody mt-4'>
                                  <div className='row'>
                                    <div className='col-md-4 fw-bold m-2 leftData'>
                                      {/* Amount:{" "} */}
                                    </div>
                                    <div className='col-md-4 m-2 rightData'>
                                      {/* {selectedPlan.amount} */}
                                    </div>
                                  </div>

                                  {/* <div className="row"> */}
                                  {/* <div className="col-md-4 fw-bold m-2 leftData"> */}
                                  {/* Plan Type:{" "} */}
                                  {/* </div> */}
                                  <div className='emailTemplateModalAdmin'>
                                    {HTMLReactParser(selectedPlan.template)}
                                  </div>
                                  {/* </div> */}
                                </div>
                              </div>
                            )}
                            {/* {selectedPlan &&
                              HTMLReactParser(selectedPlan.template)} */}
                          </Typography>
                          <Typography
                            id='modal-modal-description'
                            sx={{ mt: 3 }}
                          ></Typography>
                        </Box>
                      </Modal>
                    </div>
                  </tbody>
                </table>
              </div>
              <div className='manageSubadminpart3'>
                <div className='pagination'>
                  <Stack spacing={2}>
                    <Pagination
                      count={totalPages}
                      color='primary'
                      page={currentPage}
                      onChange={(event, page) => setCurrentPage(page)}
                    />
                  </Stack>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default APEmailTemplateSetting;
