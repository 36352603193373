import axios from 'axios';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import HTMLReactParser from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// api
import ApiKey from '../api/ApiKey';
import BaseApi from '../api/BaseApi';
// components
import Sidebar from './Sidebar';
import NavBar from '../element/NavBar';
import Footer from '../element/Footer';
// helpers
import { logOutIfTokenExpired } from '../helpers';

const MyProfileNew = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('global');
  const tokenKey = Cookies.get('tokenClient');
  const employerId = Cookies.get('employer_id');

  const [loading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState([]);

  const getData = async () => {
    try {
      setLoading(true);

      const response = await axios.post(BaseApi + '/users/myaccount', null, {
        headers: {
          key: ApiKey,
          token: tokenKey,
          'Content-Type': 'application/json',
        },
      });

      setLoading(false);

      if(response.data.status === 200) {
        setProfileData(response.data.response);
      }
    } catch (error) {
      setLoading(false);

      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      }

      console.error('Cannot get profile data!', error);
    }
  };

  useEffect(() => {
    if (!tokenKey) {
      navigate('/user/employerlogin');
    } else {
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]); //eslint-disable-line

  return (
    <>
      <NavBar />
      <div className='container myProfile'>
        <div className='row'>
          <div className='col-lg-3'>
            <Sidebar />
          </div>
          {
            loading
              ? (
                <div className='loader-container'></div>
              ) : (
                <>
                  <div
                    className='col-lg-9 mb-5'
                    style={{
                      borderLeft: '2px solid #e6e8e7',
                      borderRight: '2px solid #e6e8e7',
                    }}
                  >
                    <div className='d-flex mx-3'>
                      <img src='/Images/employerSide/icon7color.png' alt='' />
                      <h3 className='mx-2'>{t('employerMyProfile.myProfile')}</h3>
                    </div>
                    <div className='myProfileEmployerBody mx-3'>
                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='card cardOne'>
                            <div className='card-title'>
                              <h4>{t('employerMyProfile.yourProfile')}</h4>
                              <p className='text-muted'>
                                {t('employerMyProfile.joined')}:
                                {' '}
                                {profileData.created}
                              </p>
                            </div>
                            <div>
                              <div className='profileImageBox'>
                                <img
                                  className='profileImage'
                                  alt='company logo'
                                  src={
                                    profileData.company_logo
                                      ? profileData.company_logo
                                      : '/Images/jobseekerSide/dummy-profile.png'
                                  }
                                />
                              </div>
                              <div className='nameSection'>
                                <p>
                                  {profileData.first_name}
                                  {' '}
                                  {profileData.last_name}
                                </p>
                                <Link
                                  to='/user/editprofile'
                                  className='cardOneEditButton'
                                >
                                  <i className='fa-solid fa-pen-to-square' />{' '}
                                  {t('employerMyProfile.edit')}
                                </Link>
                              </div>
                              <p className='text-muted'>
                                {t('employerMyProfile.position')}:
                                {' '}
                                {profileData.position}
                              </p>
                              <p className='text-muted'>
                                {t('employerMyProfile.contact')}:
                                {' '}
                                <a href={`tel:${profileData.contact}`}>
                                  {profileData.contact}
                                </a>
                              </p>
                            </div>
                          </div>
                          <div className='card cardTwo'>
                            <div className='card-title'>
                              <h4>{t('employerMyProfile.emailAddress')}</h4>
                            </div>
                            <div className='primaryButton'>
                              <button className='cardTwoPrimaryButton'>
                                {t('employerMyProfile.primary')}
                              </button>
                            </div>
                            <div className='nameSection'>
                              <a href={`mailto:${profileData.email_address}`}>
                                {profileData.email_address}
                              </a>
                            </div>
                          </div>
                          <div className='card cardThree'>
                            <div className='card-title'>
                              <h4>{t('employerMyProfile.phoneNumber')}</h4>
                            </div>
                            <div className='primaryButton'>
                              <button className='cardTwoPrimaryButton'>
                                {t('employerMyProfile.primary')}
                              </button>
                            </div>
                            <div className='row'>
                              <div className='col-md-6'>
                                {t('employerMyProfile.contactNumber')}:
                              </div>
                              <div className='col-md-6 text-muted'>
                                <a href={`tel:${profileData.contact}`}>
                                  {profileData.contact}
                                </a>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-6'>
                                {t('employerMyProfile.companyContactNumber')}:
                              </div>
                              <div className='col-md-6 text-muted'>
                                <a href={`tel:${profileData.company_contact}`}>
                                  {profileData.company_contact}
                                </a>
                              </div>
                            </div>
                            <div className='nameSection'>
                              <Link
                                to='/user/editprofile'
                                className='cardThreeChangeButton'
                              >
                                <i className='fa-solid fa-pen-to-square' />
                                {' '}
                                {t('employerMyProfile.changeContactNumber')}
                              </Link>
                            </div>
                          </div>
                          <div className='card cardFour'>
                            <div className='card-title'>
                              <h4>{t('employerMyProfile.address')}</h4>
                            </div>
                            <div className='primaryButton'>
                              <button className='cardTwoPrimaryButton'>
                                {t('employerMyProfile.primary')}
                              </button>
                            </div>
                            <div className='row'>
                              <div className='col-md-4'>
                                <p>{t('employerMyProfile.address')}:</p>
                              </div>
                              <div className='col-md-8'>
                                <p className='text-muted'>
                                  {profileData.address}
                                </p>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-4'>
                                <p>{t('employerMyProfile.location')}:</p>
                              </div>
                              <div className='col-md-8'>
                                <p className='text-muted'>
                                  {profileData.location}
                                </p>
                              </div>
                            </div>
                            <div className='nameSection'>
                              <Link
                                to='/user/editprofile'
                                className='cardThreeChangeButton'
                              >
                                <i className='fa-solid fa-pen-to-square' />
                                {' '}
                                {t('employerMyProfile.changeAddress')}
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='card cardFive'>
                            <div className='card-title'>
                              <h4>{t('employerMyProfile.otherDetails')}</h4>
                            </div>
                            <div className='row'>
                              <div className='col-md-4'>
                                {t('employerMyProfile.companyId')}:
                              </div>
                              <div className='col-md-8 text-muted'>
                                {employerId}
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-4'>
                                {t('employerMyProfile.companyName')}:
                              </div>
                              <div className='col-md-8 text-muted'>
                                {profileData.company_name}
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-4'>
                                {t('employerMyProfile.url')}:
                              </div>
                              <div className='col-md-8 text-muted'>
                                <a href={profileData.url} target='blank'>
                                  {profileData.url}
                                </a>
                              </div>
                            </div>
                            <div className='row standartListStyle'>
                              <p>{t('employerMyProfile.aboutCompany')}:</p>
                              <div className='text-muted'>
                                {
                                  profileData.company_about && (
                                    HTMLReactParser(profileData.company_about)
                                  )
                                }
                              </div>
                            </div>
                            <div className='nameSection'>
                              <Link
                                to='/user/editprofile'
                                className='cardThreeChangeButton'
                              >
                                <i className='fa-solid fa-pen-to-square' />
                                {' '}
                                {t('employerMyProfile.changeDetails')}
                              </Link>
                            </div>
                          </div>
                          <div className='card cardSix'>
                            <div className='card-title'>
                              <h4>{t('employerMyProfile.availablePlanDetails')}</h4>
                            </div>
                            {
                              profileData.is_plan_active === 1
                                ? (
                                  <>
                                    <div className='planNameBox'>
                                      <p className='mb-2 list1'>
                                        {t('employerMyProfile.planName')}:
                                        {' '}
                                        <span className='text-muted'>
                                          {profileData.plan_name}
                                        </span>
                                      </p>
                                      <div className='list2'>
                                        <Link
                                          to='/plans/purchase'
                                          className='changePlanButton'
                                        >
                                          <i className='fa-solid fa-pen-to-square' />
                                          {' '}
                                          {t('employerMyProfile.changePlan')}
                                        </Link>
                                      </div>
                                    </div>
                                    <div className='planDetailBox'>
                                      <h5>{t('employerMyProfile.availableFeatures')}</h5>
                                      <div className='featuresDescription'>
                                        <div className='row'>
                                          <p className='col-7'>{t('employerMyProfile.noJobPost')}:</p>
                                          <p className='col-5 text-muted'>
                                            {
                                              profileData.AvailbleplanFeature.availableJobpost === '1000000'
                                                ? t('employerMyProfile.unlimited')
                                                : profileData.AvailbleplanFeature.availableJobpost
                                            }
                                          </p>
                                        </div>
                                        {/* <div className='row'>
                                          <p className='col-7'>{t('employerMyProfile.noResumeDownload')}:</p>
                                          <p className='col-5 text-muted'>
                                            {
                                              profileData.AvailbleplanFeature.availableDownloadCount === '1000000'
                                                ? t('employerMyProfile.unlimited')
                                                : profileData.AvailbleplanFeature.availableDownloadCount
                                            }
                                          </p>
                                        </div>
                                        <div className='row'>
                                          <p className='col-7'>{t('employerMyProfile.noCandidateView')}:</p>
                                          <p className='col-5 text-muted'>
                                            {
                                              profileData.AvailbleplanFeature.availableProfileView === '1000000'
                                                ? t('employerMyProfile.unlimited')
                                                : profileData.AvailbleplanFeature.availableProfileView
                                            }
                                          </p>
                                        </div>
                                        <div className='row'>
                                          <p className='col-7'>{t('employerMyProfile.accessCandidateSearch')}:</p>
                                          <p className='col-5 text-muted'>
                                            {
                                              profileData.AvailbleplanFeature.searchCandidate
                                                ? profileData.AvailbleplanFeature.searchCandidate
                                                : 'N/A'
                                            }
                                          </p>
                                        </div> */}
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <div className='noPlanAvl'>
                                    <p>{t('employerMyProfile.noPlanAvailable')}</p>
                                    <Link
                                      to='/plans/purchase'
                                      className='cardThreeChangeButton noPlanAvlLink'
                                    >
                                      <i className='fa-solid fa-pen-to-square' />
                                      {' '}
                                      {t('employerMyProfile.buyPlan')}
                                    </Link>
                                  </div>
                                )
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )
          }
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MyProfileNew;
