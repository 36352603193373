import axiosInstance from '../../../axiosConfig';

export const getCities = async (id, inputValue) => {
  const body = { country_id: id, query: inputValue };

  try {
    const data = await axiosInstance.post('/locality/search', body);

    return data;
  } catch (error) {
    console.error('Error fetching cities:', error);
    return [];
  }
};
