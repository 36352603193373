import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
// api
import ApiKey from '../api/ApiKey';
import BaseApi from '../api/BaseApi';
// hooks
import { useWindowSize } from '../../Hooks/use-window-size';

const Sidebar = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const { t } = useTranslation('global');
  const tokenKey = Cookies.get('tokenClient');

  const [isQuickLinksVisible, setIsQuickLinksVisible] = useState(false);
  const [isDashboardLinksVisible, setIsDashboardLinksVisible] = useState(false);

  const toggle1 = () => {
    setIsQuickLinksVisible(!isQuickLinksVisible);
  };
  const toggle2 = () => {
    setIsDashboardLinksVisible(!isDashboardLinksVisible);
  };

  const handleLogOut = async () => {
    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        showCancelButton: true,
        text: t('employerSidebar.confirmTxt'),
        title: t('employerSidebar.confirmTitle'),
        cancelButtonText: t('employerSidebar.no'),
        confirmButtonText: t('employerSidebar.yes'),
      });

      if (confirmationResult.isConfirmed) {
        await axios.post(BaseApi + '/users/logout', null, {
          headers: {
            key: ApiKey,
            token: tokenKey,
            'Content-Type': 'application/json',
          },
        });

        Cookies.remove('fname');
        Cookies.remove('user_id');
        Cookies.remove('user_type');
        Cookies.remove('tokenClient');

        navigate('/');

        window.location.reload();

        const Toast = Swal.mixin({
          timer: 3000,
          toast: true,
          position: 'top-end',
          timerProgressBar: true,
          showConfirmButton: false,
          didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: 'success',
          title: t('employerSidebar.successTitle'),
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: t('employerSidebar.failedTitle'),
        confirmButtonText: t('employerSidebar.close'),
      });
    }
  };

  return (
    <>
      {width > 768 ? (
        <>
          <div className='SidebarSection1 pe-4'>
            <div className='SBHeader'>
              <h3>{t('employerSidebar.quickLinks')}</h3>
              <hr style={{ border: '1px solid rgb(211 209 209)' }} />
            </div>
            <div className='SBBody'>
              <Link to='/user/createjob' className='bodyItem SidebarCreatJob'>
                <div className='SidebarImages SidebarCreatJobBg'>
                  <img
                    className=''
                    src='/Images/employerSide/icon1color.png'
                    alt=''
                  />
                </div>
                <div className='menuTitle'>{t('employerSidebar.createJob')}</div>
              </Link>
              <Link to='/user/managejob' className='bodyItem SidebarManageJob'>
                <div className='SidebarImages SidebarManageJobBg'>
                  <img
                    className=''
                    src='/Images/employerSide/icon2color.png'
                    alt=''
                  />
                </div>
                <div className='menuTitle'>{t('employerSidebar.manageJobs')}</div>
              </Link>
              <Link
                to='/plans/purchase'
                className='bodyItem SidebarPaymentJob'
              >
                <div className='SidebarImages SidebarMailHistoryBg'>
                  <img
                    className=''
                    src='/Images/employerSide/us-dollar-32.png'
                    alt=''
                  />
                </div>
                <div className='menuTitle'>{t('employerSidebar.purchasePlan')}</div>
              </Link>
              <Link
                to='/user/paymenthistory'
                className='bodyItem SidebarPaymentJob'
              >
                <div className='SidebarImages SidebarPaymentJobBg'>
                  <img
                    className=''
                    src='/Images/employerSide/icon3color.png'
                    alt=''
                  />
                </div>
                <div className='menuTitle'>{t('employerSidebar.paymentHistory')}</div>
              </Link>
              <Link
                to='/user/documents'
                className='bodyItem SidebarPaymentJob'
              >
                <div className='SidebarImages SidebarDocumentsBg'>
                  <img
                    className=''
                    src='/Images/employerSide/documents-32.png'
                    alt=''
                  />
                </div>
                <div className='menuTitle'>{t('employerSidebar.documents')}</div>
              </Link>
              {/* <Link
                to='/user/favouritelist'
                className='bodyItem SidebarFavouriteJob'
              >
                <div className='SidebarImages SidebarFavouriteJobBg'>
                  <img
                    className=''
                    src='/Images/employerSide/icon4color.png'
                    alt=''
                  />
                </div>
                <div className='menuTitle'>{t('employerSidebar.favouriteList')}</div>
              </Link> */}
              {/* <Link
                to='/user/importjobseekers'
                className='bodyItem SidebarImportJob'
              >
                <div className='SidebarImages SidebarImportJobBg'>
                  <img
                    className=''
                    src='/Images/employerSide/icon5color.png'
                    alt=''
                  />
                </div>
                <div className='menuTitle'>{t('employerSidebar.importJobseekers')}</div>
              </Link> */}
            </div>
          </div>
          <div className='SidebarSection2 pe-4'>
            <div className='SBHeader mt-5'>
              <h3>{t('employerSidebar.myDashboard')}</h3>
              <hr style={{ border: '1px solid rgb(211 209 209)' }} />
            </div>
            <div className='body'>
              {/* <Link
                to='/user/mailhistory'
                className='bodyItem SidebarMailHistory'
              >
                <div className='SidebarImages SidebarMailHistoryBg'>
                  <img
                    className=''
                    src='/Images/employerSide/icon6color.png'
                    alt=''
                  />
                </div>
                <div className='menuTitle'>{t('employerSidebar.mailHistory')}</div>
              </Link> */}
              <Link to='/user/myprofile' className='bodyItem SidebarMyProfile'>
                <div className='SidebarImages SidebarMyProfileBg'>
                  <img
                    className=''
                    src='/Images/employerSide/icon7color.png'
                    alt=''
                  />
                </div>
                <div className='menuTitle'>{t('employerSidebar.myProfile')}</div>
              </Link>
              <Link
                to='/user/editprofile'
                className='bodyItem SidebarEditProfile'
              >
                <div className='SidebarImages SidebarEditProfileBg'>
                  <img
                    className=''
                    src='/Images/employerSide/icon8color.png'
                    alt=''
                  />
                </div>
                <div className='menuTitle'>{t('employerSidebar.editProfile')}</div>
              </Link>
              <Link
                to='/user/changepassword'
                className='bodyItem SidebarChangePass'
              >
                <div className='SidebarImages SidebarChangePassBg'>
                  <img
                    className=''
                    src='/Images/employerSide/icon9color.png'
                    alt=''
                  />
                </div>
                <div className='menuTitle'>{t('employerSidebar.changePassword')}</div>
              </Link>
              {/* <Link
                to='/user/changelogo'
                className='bodyItem SidebarChangeLogo'
              >
                <div className='SidebarImages SidebarChangeLogoBg'>
                  <img
                    className=''
                    src='/Images/employerSide/icon10color.png'
                    alt=''
                  />
                </div>
                <div className='menuTitle'>{t('employerSidebar.changeLogo')}</div>
              </Link> */}
              <Link className='bodyItem SidebarLogOut'>
                <div className='SidebarImages SidebarLogOutBg'>
                  <img
                    className=''
                    src='/Images/employerSide/icon11color.png'
                    alt=''
                  />
                </div>
                <div className='menuTitle' onClick={handleLogOut}>
                  {t('employerSidebar.logout')}
                </div>
              </Link>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='SidebarSection1 pe-4'>
            <div className='SBHeader'>
              <div className='sidebarEachHeader'>
                <h3>{t('employerSidebar.quickLinks')}</h3>
                <Link className='sidebarPlusLink' onClick={toggle1}>
                  {isQuickLinksVisible ? (
                    <i className='fa-solid fa-circle-minus'></i>
                  ) : (
                    <i className='fa-solid fa-circle-plus'></i>
                  )}
                </Link>
              </div>
              <hr style={{ border: '1px solid rgb(211 209 209)' }} />
            </div>
            <div className={`SBBody ${isQuickLinksVisible ? 'open' : ''}`}>
              <ul
                style={{
                  display: isQuickLinksVisible ? 'block' : 'none',

                }}
              >
                <li>
                  <Link
                    to='/user/createjob'
                    className='bodyItem SidebarCreatJob'
                  >
                    <div className='SidebarImages SidebarCreatJobBg'>
                      <img
                        className=''
                        src='/Images/employerSide/icon1color.png'
                        alt=''
                      />
                    </div>
                    <div className='menuTitle'>{t('employerSidebar.createJob')}</div>
                  </Link>
                </li>
                <li>
                  <Link
                    to='/user/managejob'
                    className='bodyItem SidebarManageJob'
                  >
                    <div className='SidebarImages SidebarManageJobBg'>
                      <img
                        className=''
                        src='/Images/employerSide/icon2color.png'
                        alt=''
                      />
                    </div>
                    <div className='menuTitle'>{t('employerSidebar.manageJobs')}</div>
                  </Link>
                </li>
                <li>
                  <Link
                    to='/user/paymenthistory'
                    className='bodyItem SidebarPaymentJob'
                  >
                    <div className='SidebarImages SidebarPaymentJobBg'>
                      <img
                        className=''
                        src='/Images/employerSide/icon3color.png'
                        alt=''
                      />
                    </div>
                    <div className='menuTitle'>{t('employerSidebar.paymentHistory')}</div>
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to='/user/favouritelist'
                    className='bodyItem SidebarFavouriteJob'
                  >
                    <div className='SidebarImages SidebarFavouriteJobBg'>
                      <img
                        className=''
                        src='/Images/employerSide/icon4color.png'
                        alt=''
                      />
                    </div>
                    <div className='menuTitle'>{t('employerSidebar.favouriteList')}</div>
                  </Link>
                </li> */}
                {/* <li>
                  <Link
                    to='/user/importjobseekers'
                    className='bodyItem SidebarImportJob'
                  >
                    <div className='SidebarImages SidebarImportJobBg'>
                      <img
                        className=''
                        src='/Images/employerSide/icon5color.png'
                        alt=''
                      />
                    </div>
                    <div className='menuTitle'>{t('employerSidebar.importJobseekers')}</div>
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
          <div className='SidebarSection2 pe-4'>
            <div className='SBHeader mt-3'>
              <div className='sidebarEachHeader'>
                <h3>{t('employerSidebar.myDashboard')}</h3>
                <Link className='sidebarPlusLink' onClick={toggle2}>
                  {isDashboardLinksVisible ? (
                    <i className='fa-solid fa-circle-minus'></i>
                  ) : (
                    <i className='fa-solid fa-circle-plus'></i>
                  )}
                </Link>
              </div>
              <hr style={{ border: '1px solid rgb(211 209 209)' }} />
            </div>
            <div className={`SBBody ${isDashboardLinksVisible ? 'open' : ''}`}>
              <ul
                style={{
                  display: isDashboardLinksVisible ? 'block' : 'none',
                }}
              >
                <li>
                  <Link
                    to='/user/mailhistory'
                    className='bodyItem SidebarMailHistory'
                  >
                    <div className='SidebarImages SidebarMailHistoryBg'>
                      <img
                        className=''
                        src='/Images/employerSide/icon6color.png'
                        alt=''
                      />
                    </div>
                    <div className='menuTitle'>{t('employerSidebar.mailHistory')}</div>
                  </Link>
                </li>
                <li>
                  <Link
                    to='/user/myprofile'
                    className='bodyItem SidebarMyProfile'
                  >
                    <div className='SidebarImages SidebarMyProfileBg'>
                      <img
                        className=''
                        src='/Images/employerSide/icon7color.png'
                        alt=''
                      />
                    </div>
                    <div className='menuTitle'>{t('employerSidebar.myProfile')}</div>
                  </Link>
                </li>
                <li>
                  <Link
                    to='/user/editprofile'
                    className='bodyItem SidebarEditProfile'
                  >
                    <div className='SidebarImages SidebarEditProfileBg'>
                      <img
                        className=''
                        src='/Images/employerSide/icon8color.png'
                        alt=''
                      />
                    </div>
                    <div className='menuTitle'>{t('employerSidebar.editProfile')}</div>
                  </Link>
                </li>
                <li>
                  <Link
                    to='/user/changepassword'
                    className='bodyItem SidebarChangePass'
                  >
                    <div className='SidebarImages SidebarChangePassBg'>
                      <img
                        className=''
                        src='/Images/employerSide/icon9color.png'
                        alt=''
                      />
                    </div>
                    <div className='menuTitle'>{t('employerSidebar.changePassword')}</div>
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to='/user/changelogo'
                    className='bodyItem SidebarChangeLogo'
                  >
                    <div className='SidebarImages SidebarChangeLogoBg'>
                      <img
                        className=''
                        src='/Images/employerSide/icon10color.png'
                        alt=''
                      />
                    </div>
                    <div className='menuTitle'>{t('employerSidebar.changeLogo')}</div>
                  </Link>
                </li> */}
                <li>
                  <Link className='bodyItem SidebarLogOut'>
                    <div className='SidebarImages SidebarLogOutBg'>
                      <img
                        className=''
                        src='/Images/employerSide/icon11color.png'
                        alt=''
                      />
                    </div>
                    <div className='menuTitle' onClick={handleLogOut}>
                      {t('employerSidebar.logout')}
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Sidebar;
