import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
// api
import ApiKey from '../api/ApiKey';
import BaseApi from '../api/BaseApi';
// components
import JSSidebar from './JSSidebar';
import Footer from '../element/Footer';
import NavBar from '../element/NavBar';
// helpers
import { logOutIfTokenExpired, resultInfo } from '../helpers';

const JSAddDocuments = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('global');
  const tokenKey = Cookies.get('tokenClient');

  const [imagePath, setImagePath] = useState();
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [docDownloadPath, setDocDownloadPath] = useState();
  const [downloadActive, setDownloadActive] = useState(false);
  const [imageDownloadActive, setImageDownloadActive] = useState(false);

  const getData = async () => {
    try {
      setLoading(true);

      const response = await axios.post(
        BaseApi + '/candidates/editcvdocuments',
        null,
        {
          headers: {
            key: ApiKey,
            token: tokenKey,
            'Content-Type': 'application/json',
          },
        },
      );

      setLoading(false);
      setDocuments(response.data.response.showOldDocs);
      setCertificates(response.data.response.showOldImages);
    } catch (error) {
      setLoading(false);

      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      }

      console.error('Cannot get data', error);
    }
  };

  const handleFileUpload = async e => {
    const files = e.target.files;
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      if (files[i].size > 5 * 1024 * 1024) {
        resultInfo('warning', t('jobseekerAddDocuments.fileSizeValidation'), t('jobseekerAddDocuments.close'));
        e.target.value = null;

        return;
      }

      formData.append('selectedCV[]', files[i]);
    }

    try {
      setLoading(true);

      const response = await axios.post(
        BaseApi + '/candidates/editcvdocuments',
        formData,
        {
          headers: {
            key: ApiKey,
            token: tokenKey,
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      setLoading(false);

      if (response.data.status == 200) {
        resultInfo('success', t('jobseekerAddDocuments.documentUploadSuccessTitle'), t('jobseekerAddDocuments.close'));
      } else {
        resultInfo('error', response.data.message, t('jobseekerAddDocuments.close'));
      }

      getData();
    } catch (error) {
      setLoading(false);

      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      } else {
        resultInfo('error', t('jobseekerAddDocuments.documentUploadFailedTitle'), t('jobseekerAddDocuments.close'));
      }

      console.error(error);
    }
  };

  const handleDocDownload = async (path, doc) => {
    setDocDownloadPath(path + doc);
    setDownloadActive(true);
  };

  const handleImageDownload = async path => {
    setImagePath(path);
    setImageDownloadActive(true);
  };

  const handleDocumentsRemove = async slug => {
    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: t('jobseekerAddDocuments.no'),
        confirmButtonText: t('jobseekerAddDocuments.yes'),
        text: t('jobseekerAddDocuments.deleteDocumentConfirmTxt'),
        title: t('jobseekerAddDocuments.deleteDocumentConfirmTitle'),
      });

      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/candidates/deleteCertificate/${slug}`,
          null,
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              'Content-Type': 'application/json',
            },
          },
        );

        if (response.data.status === 200) {
          getData();

          resultInfo('success', t('jobseekerAddDocuments.deleteDocumentSuccessTitle'), t('jobseekerAddDocuments.close')); // eslint-disable-line
        } else if (response.data.status === 400) {
          navigate('/');

          Cookies.remove('fname');
          Cookies.remove('user_id');
          Cookies.remove('user_type');
          Cookies.remove('tokenClient');

          resultInfo('warning', response.data.message, t('jobseekerAddDocuments.close'));
        } else {
          resultInfo('error', response.data.message, t('jobseekerAddDocuments.close'));
        }
      }
    } catch (error) {
      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      } else {
        resultInfo('error', t('jobseekerAddDocuments.deleteDocumentFailedTitle'), t('jobseekerAddDocuments.close'));
      }

      console.error('Cannot delete certificate', error);
    }
  };

  const handleCertificateRemove = async slug => {
    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: t('jobseekerAddDocuments.no'),
        confirmButtonText: t('jobseekerAddDocuments.yes'),
        text: t('jobseekerAddDocuments.deleteCertificateConfirmTxt'),
        title: t('jobseekerAddDocuments.deleteCertificateConfirmTitle'),
      });

      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/candidates/deleteCertificate/${slug}`,
          null,
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              'Content-Type': 'application/json',
            },
          },
        );

        if (response.data.status === 200) {
          getData();

          resultInfo('success', t('jobseekerAddDocuments.deleteCertificateSuccessTitle'), t('jobseekerAddDocuments.close')); // eslint-disable-line
        } else if (response.data.status === 400) {
          navigate('/');

          Cookies.remove('fname');
          Cookies.remove('user_id');
          Cookies.remove('user_type');
          Cookies.remove('tokenClient');

          resultInfo('warning', response.data.message, t('jobseekerAddDocuments.close'));
        } else {
          resultInfo('error', response.data.message, t('jobseekerAddDocuments.close'));
        }
      }
    } catch (error) {
      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      } else {
        resultInfo('error', t('jobseekerAddDocuments.deleteCertificateFailedTitle'), t('jobseekerAddDocuments.close'));
      }

      console.error('Cannot delete certificate', error);
    }
  };

  useEffect(() => {
    if (downloadActive && docDownloadPath) {
      const link = document.createElement('a');
      link.style.display = 'none';
      link.href = docDownloadPath;
      link.download = 'generated-cv.doc';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setDownloadActive(false);
    }
  }, [downloadActive, docDownloadPath]);

  useEffect(() => {
    if (imageDownloadActive && imagePath) {
      const link = document.createElement('a');
      link.style.display = 'none';
      link.href = imagePath;
      link.download = 'generated-img.jpeg';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setImageDownloadActive(false);
    }
  }, [imageDownloadActive, imagePath]);

  useEffect(() => {
    if (!tokenKey) {
      navigate('/user/jobseekerlogin');
    } else {
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]); // eslint-disable-line

  return (
    <>
      <NavBar />
      <div className='container editProfile JSAddDocuments'>
        <div className='row'>
          <div className='col-lg-3'>
            <JSSidebar />
          </div>
          {
            loading
              ? (
                <div className='loader-container'></div>
              ) : (
                <div
                  className='col-lg-9 mb-5'
                  style={{
                    borderLeft: '2px solid #e6e8e7',
                    borderRight: '2px solid #e6e8e7',
                  }}
                >
                  <div className='mx-3 d-flex PageHeader'>
                    <img src='/Images/employerSide/icon8color.png' alt='' />
                    <h3 className=''>
                      {t('jobseekerAddDocuments.addCvDocuments')}
                    </h3>
                  </div>
                  <form>
                    <div className='mb-5 mt-5 mx-4'>
                      <div className='form-outline mb-4 DashBoardInputBx'>
                        <label className='form-label' htmlFor='form3Example3'>
                          {t('jobseekerAddDocuments.addCvDocuments')}
                        </label>
                        <input
                          multiple
                          type='file'
                          name='files'
                          id='formFile'
                          className='form-control'
                          onChange={e => handleFileUpload(e)}
                        />
                        <div id='emailHelp' className='form-text'>
                          {t('jobseekerAddDocuments.belowTxt1')}
                        </div>
                      </div>
                      <div className='JobseekerCertificatesDetails '>
                        <p className='existingUploadsHeading'>
                          {t('jobseekerAddDocuments.existingUploads')}
                        </p>
                        <hr />
                        <ul className='certificatesSection'>
                          <p>{t('jobseekerMyProfile.certificates')}: </p>
                          {
                            certificates.length > 0
                              ? certificates.map((i, index) => (
                                <div key={index}>
                                  <li className='eachCertificateMyprofile'>
                                    <i
                                      className='fa-regular fa-circle-xmark jsprofileCross'
                                      onClick={() => handleCertificateRemove(i.slug)}
                                    />
                                    <i onClick={() => handleImageDownload(i.image)}>
                                      <img
                                        alt='icon'
                                        src={i.image}
                                        className='JSmyProfileCertificateImage'
                                      />
                                    </i>
                                    <span>
                                      {t('jobseekerMyProfile.certificate')}
                                      {' '}
                                      {index + 1}
                                    </span>
                                  </li>
                                </div>
                              ))
                              : t('jobseekerMyProfile.notAvailable')
                          }
                        </ul>
                        <ul className='certificatesSection'>
                          <p>{t('jobseekerMyProfile.documents')}: </p>
                          {
                            documents.length > 0
                              ? documents.map((i, index) => (
                                <div key={index}>
                                  <li className='eachDocumentMyprofile'>
                                    <i
                                      className='fa-regular fa-circle-xmark jsprofileCross'
                                      onClick={() => handleDocumentsRemove(i.slug)}
                                    />
                                    <i
                                      className='fa-solid fa-file'
                                      onClick={() => handleDocDownload(i.path, i.doc)}
                                    />
                                    {t('jobseekerAddDocuments.document')}
                                    {' '}
                                    {index + 1}
                                  </li>
                                </div>
                              ))
                              : t('jobseekerMyProfile.notAvailable')
                          }
                        </ul>
                      </div>
                      <div id='emailHelp' className='form-text'>
                        {t('jobseekerAddDocuments.belowTxt2')}
                      </div>
                    </div>
                  </form>
                </div>
              )
          }
        </div>
      </div>
      <Footer />
    </>
  );
};

export default JSAddDocuments;
