import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
// api
import ApiKey from '../api/ApiKey';
import BaseApi from '../api/BaseApi';
// components
import Footer from './Footer';
import NavBar from './NavBar';
// mui
import { Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const ResetPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('global');
  const { slug1, slug2, slug3 } = useParams();
  const tokenKey = Cookies.get('tokenClient');

  const initialData = {
    id: slug1,
    md5id: slug2,
    email: slug3,
  };

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errors, setErrors] = useState({
    password: '',
    confirm_password: '',
  });
  const [formData, setFormData] = useState({
    id: slug1,
    email: slug3,
    md5id: slug2,
    password: '',
    confirm_password: '',
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const getData = async () => {
    try {
      setLoading(true);

      await axios.post(
        BaseApi + '/candidates/resetPassword',
        initialData,
        {
          headers: {
            key: ApiKey,
            token: tokenKey,
            'Content-Type': 'application/json',
          },
        },
      );

      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.error('Cannot get data of benefit page', error);
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;

    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
    setErrors(prev => ({
      ...prev,
      [name]: '',
    }));
  };

  const handleClick = async e => {
    e.preventDefault();

    try {
      const newErrors = {};

      if (formData.password === '') {
        newErrors.password = t('resetPassword.newPassRequired');
      }
      if (formData.confirm_password === '') {
        newErrors.confirm_password = t('resetPassword.confirmPassRequired');
      }
      if (formData.password) {
        if (formData.password.length < 8) {
          newErrors.password = t('resetPassword.passwordLengthError');
        }
      }
      if (formData.confirm_password) {
        if (formData.confirm_password.length < 8) {
          newErrors.confirm_password = t('resetPassword.passwordLengthError');
        }
      }
      if (formData.password && formData.confirm_password) {
        if (formData.password !== formData.confirm_password) {
          newErrors.confirm_password =
          t('resetPassword.passwordMatchError');
        }
      }

      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0) {
        setLoading(true);

        const response = await axios.post(
          BaseApi + '/candidates/resetPassword',
          formData,
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              'Content-Type': 'application/json',
            },
          },
        );

        setLoading(false);

        if (response.data.status === 200) {
          navigate('/user/employerlogin');

          Swal.fire({
            icon: 'success',
            confirmButtonText: 'Close',
            title: response.data.message,
          });
        } else if (response.data.status === 500) {
          Swal.fire({
            icon: 'warning',
            confirmButtonText: 'Close',
            title: response.data.message,
          });
        } else {
          Swal.fire({
            icon: 'error',
            confirmButtonText: 'Close',
            title: response.data.message,
          });
        }
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        text: t('resetPassword.failedTxt'),
        title: t('resetPassword.failedTitle'),
        confirmButtonText: t('resetPassword.close'),
      });
    }
  };

  useEffect(() => {
    getData();

    window.scrollTo(0, 0);
  }, []); // eslint-disable-line

  return (
    <>
      <NavBar />
      {loading ? (
        <div className='loader-container'></div>
      ) : (
        <>
          <div className='privacyPolicy'>
            <div className='aboutusSection1 text-center'>
              <h1 className=''>{t('resetPassword.resetPassword')}</h1>
              <h6 className='text-muted fw-normal'>
                {' '}
                <Link to='/' style={{ color: 'grey' }}>
                  {t('navHeaders.home')}
                </Link>
                {' / '}
                {t('resetPassword.resetPassword')}
              </h6>
            </div>
            <div className='container'>
              <div className='row'>
                <div className='card forgotPasswordCard'>
                  <div className='card-body'>
                    <h2 className='text-center pb-4 pt-2'>
                      {t('resetPassword.resetYour')}
                      <span className='textGradient'>
                        {' '}
                        <span className='SubHaddingTxt'> {t('resetPassword.password')}</span>
                      </span>
                    </h2>
                    <p className='forgotPasswordProcessTxt'>
                      {t('resetPassword.text1')}
                    </p>
                    <form>
                      <div className='mb-2 passwordBox'>
                        <input
                          type={showPassword ? 'text' : 'password'}
                          className={`form-control ${
                            errors.password && 'input-error'
                          }`}
                          id='exampleInputEmail1'
                          aria-describedby='emailHelp'
                          name='password'
                          value={formData.password}
                          placeholder={t('resetPassword.newPassword')}
                          onChange={handleChange}
                        />
                        <div className='passwordVisibility'>
                          <p
                            className='btn-primary'
                            type='button'
                            onClick={togglePasswordVisibility}
                          >
                            {showPassword ? (
                              <>
                                <Tooltip title='Hide Password'>
                                  <VisibilityOffIcon />
                                </Tooltip>
                              </>
                            ) : (
                              <>
                                <Tooltip title='View Password'>
                                  <VisibilityIcon />
                                </Tooltip>
                              </>
                            )}
                          </p>
                        </div>
                        {errors.password && (
                          <div className='text-danger'>{errors.password}</div>
                        )}
                      </div>
                      <div className='mb-2 passwordBox'>
                        <input
                          type={showConfirmPassword ? 'text' : 'password'}
                          className={`form-control ${
                            errors.confirm_password && 'input-error'
                          }`}
                          id='exampleInputEmail1'
                          aria-describedby='emailHelp'
                          name='confirm_password'
                          value={formData.confirm_password}
                          placeholder={t('resetPassword.confirmPassword')}
                          onChange={handleChange}
                        />
                        <div className='passwordVisibility'>
                          <p
                            className='btn-primary'
                            type='button'
                            onClick={toggleConfirmPasswordVisibility}
                          >
                            {showConfirmPassword ? (
                              <>
                                <Tooltip title='Hide Password'>
                                  <VisibilityOffIcon />
                                </Tooltip>
                              </>
                            ) : (
                              <>
                                <Tooltip title='View Password'>
                                  <VisibilityIcon />
                                </Tooltip>
                              </>
                            )}
                          </p>
                        </div>
                        {errors.confirm_password && (
                          <div className='text-danger'>
                            {errors.confirm_password}
                          </div>
                        )}
                      </div>
                      <button
                        type='submit'
                        onClick={handleClick}
                        className='btn button1 w-100 mt-4'
                      >
                        {t('resetPassword.changePasswordButton')}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default ResetPassword;
