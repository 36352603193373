import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import React, { useEffect, useState } from 'react';
// api
import BaseApi from '../api/BaseApi';
// components
import NavBar from '../element/NavBar';
import Footer from '../element/Footer';

const Contact = () => {
  const { t } = useTranslation('global');
  const captchaKey = Cookies.get('captchaKey');
  const primaryColor = Cookies.get('primaryColor');
  const secondaryColor = Cookies.get('secondaryColor');
  const currentLanguage = Cookies.get('selectedLanguage') || 'ukr';
  const recaptchaLanguage = currentLanguage ? 'uk' : currentLanguage;

  const [loading, setLoading] = useState(false);
  const [contactData, setContactData] = useState([]);
  const [hoverColor, setHoverColor] = useState(false);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleMouseEnter = () => {
    setHoverColor(true);
  };

  const handleMouseLeave = () => {
    setHoverColor(false);
  };

  const getData = async () => {
    try {
      const response = await axios.get(BaseApi + '/page/contact-us');

      setContactData(response.data.response.contact_details);
    } catch (error) {
      console.error('Error getting contact information!');
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;

    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
    setErrors(prev => ({
      ...prev,
      [name]: '',
    }));
  };

  const handleClick = async e => {
    e.preventDefault();

    try {
      const newErrors = {};

      const isValidEmail = email => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        return emailPattern.test(email);
      };

      if (formData.name === '') {
        newErrors.name = t('messageForm.nameRequired');
      }
      if (formData.email === '') {
        newErrors.email = t('messageForm.emailRequired');
      } else if (!isValidEmail(formData.email)) {
        newErrors.email = t('messageForm.invalidEmail');
      }
      if (formData.subject === '') {
        newErrors.subject = t('messageForm.subjectRequired');
      }
      if (formData.message === '') {
        newErrors.message = t('messageForm.messageRequired');
      }
      if (!isCaptchaVerified) {
        newErrors.captcha = t('messageForm.captchaRequired');
      }

      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0) {
        if (isCaptchaVerified) {
          setLoading(true);

          const response = await axios.post(
            BaseApi + '/page/contact-us',
            formData,
          );

          setLoading(false);

          if (response.data.status === 200) {
            Swal.fire({
              icon: 'success',
              confirmButtonText: t('messageForm.close'),
              title: t('messageForm.messageSuccessTitle'),
            });

            setFormData({
              ...formData,
              name: '',
              email: '',
              subject: '',
              message: '',
            });
          } else if (response.data.status === 500) {
            Swal.fire({
              icon: 'error',
              title: response.data.message,
              confirmButtonText: t('messageForm.close'),
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: response.data.message,
              confirmButtonText: t('messageForm.close'),
            });
          }
        }
      }
    } catch (error) {
      setLoading(false);

      Swal.fire({
        icon: 'error',
        text: t('messageForm.messageFailedTxt'),
        confirmButtonText: t('messageForm.close'),
        title: t('messageForm.messageFailedTitle'),
      });
    }
  };

  useEffect(() => {
    const metaTitle = document.querySelector('title');
    const metaDescription = document.querySelector('meta[name="description"]');

    if (metaTitle) {
      metaTitle.textContent = t('contactPage.metaTitleValue');
    }

    if (metaDescription) {
      metaDescription.content = t('contactPage.metaDescriptionValue');
    }

    getData();
    window.scrollTo(0, 0);
  }, [t]);

  return (
    <>
      <div className='contactUs'>
        <NavBar />
        {
          loading
            ? (
              <div className='loader-container'></div>
            ) : (
              <>
                <div className='ContactSection1 text-center'>
                  <h1>{t('navHeaders.contactus')}</h1>
                  <h6 className='text-muted fw-normal'>
                    {' '}
                    <Link to='/' style={{ color: 'grey' }}>
                      {t('navHeaders.home')}
                    </Link>
                    {' / '}
                    {t('navHeaders.contactus')}
                  </h6>
                </div>
                <div className='ContactSection3 container'>
                  <div className='row justify-content-center'>
                    <div className='col-lg-12 col-md-12'>
                      <div className='contactInfo'>
                        <div className='card mb-3'>
                          <div className='card-body'>
                            <div className='row justify-content-center'>
                              <div className='ContactDetails col-sm-12'>
                                <i>
                                  <img src='/Images/email-icon.png' alt='' />
                                </i>
                                <div className='ContactDetailsBx'>
                                  <strong>{t('contactPage.contactEmail')}:</strong>
                                  <a href={`mailto:${contactData.email}`}>{contactData.email}</a>
                                </div>
                              </div>
                              <div className='ContactDetails col-sm-12'>
                                <i>
                                  <img src='/Images/call-icon.png' alt='' />
                                </i>
                                <div className='ContactDetailsBx'>
                                  <strong>{t('contactPage.contactPhone')}: </strong>
                                  <a href={`tel:${contactData.contact}`}>{contactData.contact}</a>
                                </div>
                              </div>
                              <div className='ContactDetails col-sm-12'>
                                <i>
                                  <img src='/Images/location-icon.png' alt='' />
                                </i>
                                <div className='ContactDetailsBx'>
                                  <strong>{t('contactPage.contactLocation')}:</strong>{' '}
                                  <span> {contactData.address}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-9'>
                      <div className='ContactForm'>
                        <form>
                          <div className='mb-4'>
                            <input
                              id='name'
                              type='text'
                              name='name'
                              value={formData.name}
                              onChange={handleChange}
                              aria-describedby='emailHelp'
                              placeholder={t('faq.namePlaceholder')}
                              className={`form-control ${errors.name && 'input-error'}`}
                            />
                            {
                              errors.name && (
                                <div className='text-danger'>{errors.name}</div>
                              )
                            }
                          </div>
                          <div className='mb-4'>
                            <input
                              id='email'
                              type='email'
                              name='email'
                              value={formData.email}
                              onChange={handleChange}
                              aria-describedby='emailHelp'
                              placeholder={t('faq.emailPlaceholder')}
                              className={`form-control ${errors.email && 'input-error'}`}
                            />
                            {
                              errors.email && (
                                <div className='text-danger'>{errors.email}</div>
                              )
                            }
                          </div>
                          <div className='mb-4'>
                            <select
                              name='subject'
                              onChange={handleChange}
                              value={formData.subject}
                              aria-label='Default select example'
                              className={`form-select ${errors.subject && 'input-error'}`}
                            >
                              <option>{t('faq.selectPlaceholder')}</option>
                              <option value='1'>{t('faq.messageFormSelectOption1')}</option>
                              <option value='2'>{t('faq.messageFormSelectOption2')}</option>
                              <option value='3'>{t('faq.messageFormSelectOption3')}</option>
                              <option value='4'>{t('faq.messageFormSelectOption4')}</option>
                              <option value='5'>{t('faq.messageFormSelectOption5')}</option>
                            </select>
                            {
                              errors.subject && (
                                <div className='text-danger'>{errors.subject}</div>
                              )
                            }
                          </div>
                          <div className='mb-4'>
                            <textarea
                              rows='3'
                              id='message'
                              name='message'
                              onChange={handleChange}
                              value={formData.message}
                              placeholder={t('faq.descPlaceholder')}
                              className={`form-control ${errors.message && 'input-error'}`}
                            />
                            {
                              errors.message && (
                                <div className='text-danger'>{errors.message}</div>
                              )
                            }
                          </div>
                          <div className='reCaptchaLogin mb-4'>
                            <ReCAPTCHA
                              sitekey={captchaKey}
                              hl={recaptchaLanguage}
                              onChange={value => setIsCaptchaVerified(value)}
                            />
                            {
                              errors.captcha && (
                                <div className='text-danger'>{errors.captcha}</div>
                              )
                            }
                          </div>
                          <button
                            type='submit'
                            className='btn w-100'
                            onClick={handleClick}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            style={{ backgroundColor: `${secondaryColor && (
                              hoverColor ? secondaryColor : primaryColor
                            )}` }}
                          >
                            {t('faq.sendMessageButton')}
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <Footer />
              </>
            )
        }
      </div>
    </>
  );
};

export default Contact;
