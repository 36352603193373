import axios from 'axios';
import { useRef } from 'react';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import JoditEditor from 'jodit-react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
// api
import ApiKey from '../api/ApiKey';
import BaseApi from '../api/BaseApi';
// components
import Sidebar from './Sidebar';
import NavBar from '../element/NavBar';
import Footer from '../element/Footer';
// helpers
import { editorConfig, logOutIfTokenExpired, resultInfo } from '../helpers';

const EditProfile = () => {
  const editor = useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation('global');
  const tokenKey = Cookies.get('tokenClient');

  const [loading, setLoading] = useState(false);
  const [companyLogo, setCompanyLogo] = useState('');
  const [profileData, setProfileData] = useState({
    url: '',
    address: '',
    contact: '',
    location: '',
    position: '',
    last_name: '',
    first_name: '',
    company_name: '',
    company_about: '',
    company_contact: '',
  });

  const [errors, setErrors] = useState({
    url: '',
    address: '',
    contact: '',
    location: '',
    position: '',
    last_name: '',
    first_name: '',
    company_name: '',
    company_about: '',
    company_contact: '',
  });

  // const charsElement = document.querySelector('.jodit-status-bar__item-right span');
  // const charsText = charsElement && charsElement.textContent;
  // const charsNumber = charsText && charsText.split(': ')[1];
  // const charsCount = parseInt(charsNumber, 10);

  const handleChange = async e => {
    const { name, value } = e.target;
    setProfileData(prev => ({
      ...prev,
      [name]: value,
    }));
    setErrors(prev => ({
      ...prev,
      [name]: '',
    }));
  };

  const handleFileUpload = async e => {
    const file = e.target.files[0];
    const fileSizeInBytes = file.size;
    const maxSizeInBytes = 1000 * 1024; // 1Mb

    if (fileSizeInBytes > maxSizeInBytes) {
      // File size exceeds 500kb, show an error message or take appropriate action
      resultInfo('warning', t('employerEditProfile.fileSizeExceed'), t('employerCreateJob.close'));
      e.target.value = '';

      return;
    }

    const image = new Image();
    image.src = URL.createObjectURL(file);

    image.onload = async function() {
      // if (image.width < 250 || image.height < 250) {
      //   // Image dimensions are less than 250px, show an error message or take appropriate action
      //   Swal.fire({
      //     icon: 'warning',
      //     title: t('employerChangeLogo.imageSizeError'),
      //     confirmButtonText: t('employerCreateJob.close'),
      //   });
      //   e.target.value = '';

      //   return;
      // }

      const base64 = await convertToBase64(file);

      setCompanyLogo(base64);
    };
  };

  const handleClick = async () => {
    try {
      const newErrors = {};

      if (!profileData.company_name) {
        newErrors.company_name = t('employerEditProfile.companyNameRequired');
        window.scrollTo(0, 0);
      }
      if (!profileData.company_about) {
        newErrors.company_about = t('employerEditProfile.companyProfileRequired');
        window.scrollTo(0, 0);
      }
      // else if (charsCount < 2000) {
      //   newErrors.company_about = t('employerEditProfile.companyProfileLength');
      //   window.scrollTo(0, 0);
      // }
      if (!profileData.position) {
        newErrors.position = t('employerEditProfile.positionRequired');
        window.scrollTo(0, 0);
      }
      if (!profileData.first_name) {
        newErrors.first_name = t('employerEditProfile.firstNameRequired');
        window.scrollTo(0, 0);
      }
      if (!profileData.last_name) {
        newErrors.last_name = t('employerEditProfile.lastNameRequired');
        window.scrollTo(0, 0);
      }
      if (!profileData.address) {
        newErrors.address = t('employerEditProfile.addressRequired');
        window.scrollTo(0, 0);
      }
      if (!profileData.location) {
        newErrors.location = t('employerEditProfile.locationRequired');
        window.scrollTo(0, 0);
      }
      if (!profileData.contact) {
        newErrors.contact = t('employerEditProfile.contactNumberRequired');
        window.scrollTo(0, 0);
      } else if (!/^\+?3?8?(0\d{9})$/.test(profileData.contact)) {
        newErrors.contact = t('employerEditProfile.contactNumberLength');
        window.scrollTo(0, 0);
      }
      if (!profileData.company_contact) {
        newErrors.company_contact = t('employerEditProfile.companyContactRequired');
        window.scrollTo(0, 0);
      } else if (!/^\+?3?8?(0\d{9})$/.test(profileData.company_contact)) {
        newErrors.company_contact = t('employerEditProfile.contactNumberLength');
        window.scrollTo(0, 0);
      }
      if (profileData.url) {
        const urlFormat = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;

        if (profileData.url && !urlFormat.test(profileData.url)) {
          newErrors.url = t('employerCreateJob.urlInvalid');
        }
        window.scrollTo(0, 0);
      }

      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0) {
        const confirmationResult = await Swal.fire({
          icon: 'question',
          showCancelButton: true,
          cancelButtonText: t('employerEditProfile.no'),
          text: t('employerEditProfile.editConfirmTxt'),
          confirmButtonText: t('employerEditProfile.yes'),
          title: t('employerEditProfile.editConfirmTitle'),
        });

        if (confirmationResult.isConfirmed) {
          setLoading(true);

          const body = { ...profileData, company_logo: companyLogo };
          const response = await axios.post(
            BaseApi + '/users/editProfile',
            body,
            {
              headers: {
                key: ApiKey,
                token: tokenKey,
                'Content-Type': 'application/json',
              },
            },
          );

          setLoading(false);

          if (response.data.status === 200) {
            resultInfo('success', t('employerEditProfile.successTitle'), t('employerEditProfile.close'));

            navigate('/user/myprofile');
          } else {
            Swal.fire({
              icon: 'error',
              text: response.data.message,
              title: t('employerCreateJob.createJobFailedTitle'),
              confirmButtonText: t('employerCreateJob.close'),
            });
          }
        }
      }
    } catch (error) {
      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      } else {
        resultInfo('error', t('employerEditProfile.failedTitle'), t('employerEditProfile.close'));
      }

      console.error('Could not submit edit data!', error);
    }
  };

  useEffect(() => {
    if (!tokenKey) {
      navigate('/user/employerlogin');
    } else {
      const fetchData = async () => {
        try {
          setLoading(true);

          const response = await axios.post(BaseApi + '/users/editProfile', null, {
            headers: {
              key: ApiKey,
              token: tokenKey,
              'Content-Type': 'application/json',
            },
          });

          setLoading(false);

          if (response.data.status === 200) {
            setProfileData(response.data.response);
            setCompanyLogo(response.data.response.company_logo);
          } else {
            resultInfo('error', response.data.message, t('employerCreateJob.close'));
          }
        } catch (error) {
          setLoading(false);

          if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
            navigate('/');

            logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
          }

          console.error('User details not received!', error);
        }
      };

      fetchData();
      window.scrollTo(0, 0);
    }
  }, [t, tokenKey, navigate]);

  return (
    <>
      <NavBar />
      <div className='container editProfile'>
        <div className='row'>
          <div className='col-lg-3'>
            <Sidebar />
          </div>
          {
            loading
              ? (
                <div className='loader-container'></div>
              ) : (
                <>
                  <div
                    className='col-lg-9 mb-5 CLPanelRight'
                    style={{
                      borderLeft: '2px solid #e6e8e7',
                      borderRight: '2px solid #e6e8e7',
                    }}
                  >
                    <div className='d-flex PageHeader'>
                      <img src='/Images/employerSide/icon8color.png' alt='' />
                      <h3 className='mx-2'>
                        {' '}
                        {t('employerEditProfile.editProfileInfo')}
                      </h3>
                    </div>
                    <form className=''>
                      <div className='mb-5 mt-5'>
                        <div className='form-outline mb-4 DashBoardInputBx'>
                          <label id='company_name' className='form-label' htmlFor='form3Example1'>
                            {t('employerEditProfile.companyName')}
                            <span className='RedStar'>*</span>
                          </label>
                          <input
                            disabled
                            type='text'
                            name='company_name'
                            onChange={handleChange}
                            value={profileData.company_name}
                            placeholder={t('employerEditProfile.companyName')}
                            className={`form-control ${errors.company_name && 'input-error'}`}
                          />
                          {
                            errors.company_name && (
                              <div className='text-danger'>{errors.company_name}</div>
                            )
                          }
                        </div>
                      </div>
                      <div className='form-outline mb-5 DashBoardInputBx APJoditEditor'>
                        <label id='company_about' className='form-label' htmlFor='form3Example3'>
                          {t('employerEditProfile.companyProfile')}
                          <span className='RedStar'>*</span>
                        </label>
                        <JoditEditor
                          ref={editor}
                          name='company_about'
                          config={editorConfig}
                          value={profileData.company_about}
                          onBlur={newContent =>
                            handleChange({
                              target: {
                                value: newContent,
                                name: 'company_about',
                              },
                            })
                          }
                        />
                        {
                          errors.company_about && (
                            <div className='text-danger'>{errors.company_about}</div>
                          )
                        }
                      </div>
                      <div className='form-outline mb-5 DashBoardInputBx'>
                        <label id='position' className='form-label' htmlFor='form3Example3'>
                          {t('employerEditProfile.position')}
                          <span className='RedStar'>*</span>
                        </label>
                        <input
                          type='text'
                          name='position'
                          onChange={handleChange}
                          value={profileData.position}
                          placeholder={t('employerEditProfile.position')}
                          className={`form-control ${errors.position && 'input-error'}`}
                        />
                        {
                          errors.position && (
                            <div className='text-danger'>{errors.position}</div>
                          )
                        }
                      </div>
                      <div className='form-outline mb-5 DashBoardInputBx'>
                        <label id='first_name' className='form-label' htmlFor='form3Example4'>
                          {t('employerEditProfile.firstName')}
                          <span className='RedStar'>*</span>
                        </label>
                        <input
                          type='text'
                          name='first_name'
                          onChange={handleChange}
                          value={profileData.first_name}
                          placeholder={t('employerEditProfile.firstName')}
                          className={`form-control ${errors.first_name && 'input-error'}`}
                        />
                        {
                          errors.first_name && (
                            <div className='text-danger'>{errors.first_name}</div>
                          )
                        }
                      </div>
                      <div className='form-outline mb-5 DashBoardInputBx'>
                        <label id='last_name' className='form-label' htmlFor='form3Example5'>
                          {t('employerEditProfile.lastName')}
                          <span className='RedStar'>*</span>
                        </label>
                        <input
                          type='text'
                          name='last_name'
                          onChange={handleChange}
                          value={profileData.last_name}
                          placeholder={t('employerEditProfile.lastName')}
                          className={`form-control ${errors.last_name && 'input-error'}`}
                        />
                        {
                          errors.last_name && (
                            <div className='text-danger'>{errors.last_name}</div>
                          )
                        }
                      </div>
                      <div className='form-outline mb-5 DashBoardInputBx'>
                        <label id='address' className='form-label' htmlFor='form3Example6'>
                          {t('employerEditProfile.address')}
                          <span className='RedStar'>*</span>
                        </label>
                        <input
                          type='text'
                          name='address'
                          onChange={handleChange}
                          value={profileData.address}
                          placeholder={t('employerEditProfile.address')}
                          className={`form-control ${errors.address && 'input-error'}`}
                        />
                        {
                          errors.address && (
                            <div className='text-danger'>{errors.address}</div>
                          )
                        }
                      </div>
                      <div className='form-outline mb-5 DashBoardInputBx'>
                        <label id='location' className='form-label' htmlFor='form3Example7'>
                          {t('employerEditProfile.location')}
                          <span className='RedStar'>*</span>
                        </label>
                        <input
                          type='text'
                          name='location'
                          onChange={handleChange}
                          value={profileData.location}
                          placeholder={t('employerEditProfile.location')}
                          className={`form-control ${errors.location && 'input-error'}`}
                        />
                        {
                          errors.location && (
                            <div className='text-danger'>{errors.location}</div>
                          )
                        }
                      </div>
                      <div className='form-outline mb-5 DashBoardInputBx'>
                        <label id='contact' className='form-label' htmlFor='form3Example8'>
                          {t('employerEditProfile.contactNumber')}
                          <span className='RedStar'>*</span>
                        </label>
                        <input
                          type='text'
                          name='contact'
                          onChange={handleChange}
                          value={profileData.contact}
                          placeholder={t('employerEditProfile.contactNumber')}
                          className={`form-control ${errors.contact && 'input-error'}`}
                        />
                        {
                          errors.contact && (
                            <div className='text-danger'>{errors.contact}</div>
                          )
                        }
                        <div id='emailHelp' className='form-text'>
                          Eg: 0671234567 or +380671234567
                        </div>
                      </div>
                      <div className='form-outline mb-5 DashBoardInputBx'>
                        <label id='company_contact' className='form-label' htmlFor='form3Example8'>
                          {t('employerEditProfile.companyContactNumber')}
                          <span className='RedStar'>*</span>
                        </label>
                        <input
                          type='text'
                          name='company_contact'
                          onChange={handleChange}
                          value={profileData.company_contact}
                          placeholder={t('employerEditProfile.companyContactNumber')}
                          className={`form-control ${errors.company_contact && 'input-error'}`}
                        />
                        {
                          errors.company_contact && (
                            <div className='text-danger'>{errors.company_contact}</div>
                          )
                        }
                        <div id='emailHelp' className='form-text'>
                          Eg: 0671234567 or +380671234567
                        </div>
                      </div>
                      <div className='form-outline mb-5 DashBoardInputBx'>
                        <label id='url' className='form-label' htmlFor='form3Example9'>
                          {t('employerEditProfile.companyWebsite')}
                        </label>
                        <input
                          name='url'
                          type='text'
                          onChange={handleChange}
                          value={profileData.url}
                          placeholder={t('employerEditProfile.companyWebsite')}
                          className={`form-control ${errors.url && 'input-error'}`}
                        />
                        {
                          errors.url && (
                            <div className='text-danger'>{errors.url}</div>
                          )
                        }
                        <div id='emailHelp' className='form-text'>
                          {t('employerEditProfile.belowTxt1')}
                        </div>
                      </div>
                      <div className='form-outline mb-5 DashBoardInputBx'>
                        <label htmlFor='formFile' className='form-label'>
                          {t('employerEditProfile.uploadCompanyLogo')}
                        </label>
                        <input
                          type='file'
                          id='formFile'
                          name='company_logo'
                          className='form-select'
                          accept='.jpeg, .png, .jpg, .gif'
                          aria-label='Default select example'
                          onChange={e => handleFileUpload(e)}
                        />
                        <div id='emailHelp' className='form-text'>
                          {t('employerEditProfile.belowTxt2')}
                          {' '}
                        </div>
                        <div className='ChangePhotoEmployers mb-4 mt-5'>
                          {
                            companyLogo
                              ? (
                                <img src={companyLogo} alt='profile image' />
                              ) : (
                                <img
                                  src='/Images/jobseekerSide/dummy-profile.png'
                                  alt='profile image'
                                />
                              )
                          }
                        </div>
                      </div>
                      <div className='bottomButtons'>
                        <button
                          type='button'
                          onClick={handleClick}
                          className='btn btn-primary button1'
                        >
                          {t('employerEditProfile.updateButton')}
                        </button>
                        <button
                          type='button'
                          className='btn btn-primary button2'
                          onClick={() => window.history.back()}
                        >
                          {t('employerEditProfile.cancelButton')}
                        </button>
                      </div>
                    </form>
                  </div>
                </>
              )
          }
        </div>
      </div>

      <Footer />
    </>
  );
};

export default EditProfile;

function convertToBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = error => {
      reject(error);
    };
  });
}
