import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
// api
import ApiKey from '../api/ApiKey';
import BaseApi from '../api/BaseApi';
// components
import JSSidebar from './JSSidebar';
import Footer from '../element/Footer';
import NavBar from '../element/NavBar';
// helpers
import { logOutIfTokenExpired, resultInfo } from '../helpers';

const JSProfessionalRegistration = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('global');
  const tokenKey = Cookies.get('tokenClient');

  const [loading, setLoading] = useState(false);
  const [professional, setProfessional] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);

  const getData = async () => {
    try {
      setLoading(true);

      const response = await axios.post(
        BaseApi + '/candidates/editProfessional',
        null,
        {
          headers: {
            key: ApiKey,
            token: tokenKey,
            'Content-Type': 'application/json',
          },
        },
      );

      setLoading(false);

      if (response.data.status === 200) {
        setProfessional(response.data.response);
      } else {
        resultInfo('error', response.data.message, t('jobseekerProfessionalRegistration.close'));
      }
    } catch (error) {
      setLoading(false);

      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      }

      console.error(error);
    }
  };

  const validateFields = () => {
    const errors = professional.map(prof => ({
      registration: prof.registration.trim() === '',
    }));

    setValidationErrors(errors);

    return errors.every(error => !error.registration);
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;

    setProfessional(prevProfessionalDetails => {
      const updatedDetails = [...prevProfessionalDetails];

      updatedDetails[index] = {
        ...updatedDetails[index],
        [name]: value,
      };

      return updatedDetails;
    });
  };

  const handleClick = async () => {
    if (!validateFields()) {
      window.scrollTo(0, 0);

      return;
    }

    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        showCancelButton: true,
        text: t('jobseekerProfessionalRegistration.confirmTxt'),
        title: t('jobseekerProfessionalRegistration.confirmTitle'),
        cancelButtonText: t('jobseekerProfessionalRegistration.no'),
        confirmButtonText: t('jobseekerProfessionalRegistration.yes'),
      });

      if (confirmationResult.isConfirmed) {
        setLoading(true);

        const response = await axios.post(
          BaseApi + '/candidates/editProfessional',
          { Professional: professional },
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              'Content-Type': 'application/json',
            },
          },
        );

        setLoading(false);

        if (response.data.status) {
          getData();

          Swal.fire({
            icon: 'success',
            text: t('jobseekerProfessionalRegistration.successTxt'),
            title: t('jobseekerProfessionalRegistration.successTitle'),
            confirmButtonText: t('jobseekerProfessionalRegistration.close'),
          });
        } else {
          resultInfo('error', response.data.message, t('jobseekerProfessionalRegistration.close'));
        }
      }
    } catch (error) {
      setLoading(false);

      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      } else {
        Swal.fire({
          icon: 'error',
          text: t('jobseekerProfessionalRegistration.failedTxt'),
          title: t('jobseekerProfessionalRegistration.failedTitle'),
          confirmButtonText: t('jobseekerProfessionalRegistration.close'),
        });
      }

      console.error(error);
    }
  };

  const handleAdd = () => {
    const newRegistration = {
      registration: '',
    };

    setProfessional(prevProfessional => [...prevProfessional, newRegistration]);
  };

  const handleRemoveWithoutId = indexToRemove => {
    setProfessional(prevProfessional =>
      prevProfessional.filter((_, index) => index !== indexToRemove),
    );
  };

  const handleRemove = async id => {
    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: t('jobseekerProfessionalRegistration.no'),
        confirmButtonText: t('jobseekerProfessionalRegistration.yes'),
        text: t('jobseekerProfessionalRegistration.removeConfirmTxt'),
        title: t('jobseekerProfessionalRegistration.removeConfirmTitle'),
      });

      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/candidates/deleteprofessional/${id}`,
          null,
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              'Content-Type': 'application/json',
            },
          },
        );

        if (response.data.status === 200) {
          getData();

          resultInfo('success', t('jobseekerProfessionalRegistration.removeSuccessTitle'), t('jobseekerProfessionalRegistration.close')); // eslint-disable-line

          window.scrollTo(0, 0);
        } else {
          resultInfo('error', response.data.message, t('jobseekerProfessionalRegistration.close'));
        }
      }
    } catch (error) {
      setLoading(false);

      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      } else {
        Swal.fire({
          icon: 'error',
          text: t('jobseekerProfessionalRegistration.failedTxt'),
          title: t('jobseekerProfessionalRegistration.failedTitle'),
          confirmButtonText: t('jobseekerProfessionalRegistration.close'),
        });
      }

      console.error(error);
    }
  };

  useEffect(() => {
    if (!tokenKey) {
      navigate('/user/jobseekerlogin');
    } else {
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]); // eslint-disable-line

  return (
    <>
      <NavBar />
      <div className='container editProfile'>
        <div className='row'>
          <div className='col-lg-3'>
            <JSSidebar />
          </div>
          {
            loading
              ? (
                <div className='loader-container'></div>
              ) : (
                <div
                  className='col-lg-9 mb-5'
                  style={{
                    borderLeft: '2px solid #e6e8e7',
                    borderRight: '2px solid #e6e8e7',
                  }}
                >
                  <div className='mx-3 d-flex PageHeader'>
                    <img
                      alt=''
                      src='/Images/jobseekerSide/Professional-Registration-Color.png'
                    />
                    <h3 className='ms-1' style={{ color: '#ac7ce4' }}>
                      {t('jobseekerProfessionalRegistration.professionalRegistration')}
                    </h3>
                  </div>
                  <form>
                    <div className='mb-5 mt-4 mx-4'>
                      {
                        professional.map((i, index) => (
                          <>
                            <h4 className='mt-5 mb-5'>
                              {t('jobseekerProfessionalRegistration.professionalRegistration')}
                              {' №'}
                              {index + 1}
                            </h4>
                            <div className='form-outline mb-4 DashBoardInputBx specialJSPR'>
                              <label
                                className='form-label'
                                htmlFor='form3Example3'
                              >
                                {t('jobseekerProfessionalRegistration.professionalGoverningBody')}
                                <span className='RedStar'>*</span>
                              </label>
                              <input
                                type='text'
                                id='form3Example3'
                                name='registration'
                                value={i.registration}
                                className='form-control'
                                onChange={e => handleChange(e, index)}
                                placeholder={t('jobseekerProfessionalRegistration.professionalRegistration')}
                              />
                              <div className='mt-0'>
                                {
                                  validationErrors[index]?.registration && (
                                    <small className='text-danger'>
                                      {t('jobseekerProfessionalRegistration.requiredField')}
                                    </small>
                                  )
                                }
                              </div>
                              <div className='row'>
                                <div className='removeButtonJobseeker mt-4 mb-4'>
                                  <button
                                    type='button'
                                    className='btn btn-primary button2'
                                    onClick={() => i.id ? handleRemove(i.id) : handleRemoveWithoutId(index)}
                                  >
                                    {t('jobseekerProfessionalRegistration.removeButton')}
                                  </button>
                                  <button
                                    type='button'
                                    onClick={handleAdd}
                                    className='btn btn-primary button1'
                                  >
                                    {t('jobseekerProfessionalRegistration.addMoreButton')}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        ))
                      }
                      {
                        professional.length <= 0 && (
                          <>
                            <div className='noData'>{t('jobseekerProfessionalRegistration.noInfoAvl')}</div>
                            <div className='PRAddMore mb-4 jobseekerAddDetailsButton'>
                              <button
                                type='button'
                                onClick={handleAdd}
                                className='btn btn-primary button1'
                              >
                                {t('jobseekerProfessionalRegistration.addDetails')}
                              </button>
                            </div>
                          </>
                        )
                      }
                      {
                        professional.length > 0 && (
                          <div className='mt-4 bottomButtons'>
                            <button
                              type='button'
                              onClick={handleClick}
                              className='btn btn-primary button1'
                            >
                              {t('jobseekerProfessionalRegistration.updateButton')}
                            </button>
                            <button
                              type='button'
                              className='btn btn-primary button2'
                              onClick={() => navigate('/candidates/myaccount')}
                            >
                              {t('jobseekerProfessionalRegistration.cancelButton')}
                            </button>
                          </div>
                        )
                      }
                    </div>
                  </form>
                </div>
              )
          }
        </div>
      </div>
      <Footer />
    </>
  );
};

export default JSProfessionalRegistration;
