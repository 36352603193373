/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import HTMLReactParser from 'html-react-parser';

const JobCard = props => {
  const { t } = useTranslation('global');

  const currencies = { 1: '₴', 2: '$', 3: '€' };

  const handleJobView = () => window.open(`/jobdescription/${props.slug}/${props.cat_slug}`, '_blank');

  return (
    <div className='card Jcard' onMouseUp={handleJobView} style={{ cursor: 'pointer' }}>
      <div className='card-body JcardBody'>
        <button className='h5 card-title JcardTitle homeJobs'>
          {props.title}
        </button>
        <div className='JcardBodySection1 float-left'>
          {currencies[props.salary_currency]}
          {props.salary_min}-{currencies[props.salary_currency]}
          {props.salary_max}/{t('messageForm.jobCardMonth')}
        </div>
        <div className='JcardBodySection2 flex-row'>
          <div className='section2Item1 float-left'>
            {t('messageForm.jobCardExperience')}: {props.min_exp}{' '}
            {t('messageForm.jobCardYears')}
          </div>
          <div className='section2Item2 text-muted'>
            <i className='fa-regular fa-calendar-days' /> {props.created}{' '}
            {t('messageForm.jobCardDaysAgo')}
          </div>
        </div>
        <div className='JcardBodySection3'>
          {props.description && HTMLReactParser(props.description.substring(0, 90))}
        </div>
        <h6 className='card-footer JcardFooter'>
          <div className='JcardFooterItem1DynamicImage'>
            <img
              alt='logo'
              id='jobcard-company-logo'
              src={
                props.logo === 'https://dev.grc.com.ua/job-board-script/public/files/user/employer/' || !props.logo
                  ? '/Images/jobseekerSide/dummy-profile.png'
                  : props.logo
              }
            />
          </div>
          <div className='JcardFooterItem2'>
            <h6 id='jobcard-company-name'>{props.company_name}</h6>
            <p id='jobcard-location' className='text-muted'>
              <i id='jobcard-location-icon' className='fa-solid fa-location-dot' style={{ color: '#a3a3a3' }} />
              {' '}
              {props.job_city}
            </p>
          </div>
          <div className='JcardFooterItem3'>{props.work_type}</div>
        </h6>
      </div>
    </div>
  );
};

export default JobCard;
