export const companiesList = [
  'PrivatBank',
  'DTEK',
  'NewPost',
  'Puma',
  'UNDP',
  'OschadBank',
  'OKKO',
  'Novus',
  'lifecell',
  'AmericanRedCross',
  'CreditAgricol',
  'Arterium',
  'KimberlyClark',
  'Kernel',
  'epam',
  'Fozzy',
  'Wargaming',
];
