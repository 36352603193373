import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// api
import ApiKey from '../api/ApiKey';
import BaseApi from '../api/BaseApi';
// components
import Footer from './Footer';
import NavBar from './NavBar';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('global');
  const tokenKey = Cookies.get('tokenClient');

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({ email: '' });
  const [formData, setFormData] = useState({ email: '' });

  const handleChange = e => {
    const { name, value } = e.target;

    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
    setErrors(prev => ({
      ...prev,
      [name]: '',
    }));
  };

  const handleClick = async e => {
    e.preventDefault();

    const isValidEmail = email => {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    };

    try {
      const newErrors = {};

      if (formData.email === '') {
        newErrors.email = t('forgotPassword.emailRequired');
      } else if (!isValidEmail(formData.email)) {
        newErrors.email = t('forgotPassword.invalidEmail');
      }

      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0) {
        setLoading(true);

        const response = await axios.post(
          BaseApi + '/users/forgotPassword',
          formData,
          {
            headers: {
              'Content-Type': 'application/json',
              key: ApiKey,
              token: tokenKey,
            },
          },
        );

        setLoading(false);

        if (response.data.status === 200) {
          navigate(window.history.back());

          Swal.fire({
            icon: 'success',
            title: response.data.message,
            confirmButtonText: t('forgotPassword.close'),
          });
        } else if (response.data.status === 500) {
          Swal.fire({
            icon: 'error',
            title: response.data.message,
            confirmButtonText: t('forgotPassword.close'),
          });
        } else {
          Swal.fire({
            icon: 'warning',
            title: response.data.message,
            confirmButtonText: t('forgotPassword.close'),
          });
        }
      }
    } catch (error) {
      setLoading(false);

      Swal.fire({
        icon: 'error',
        text: t('forgotPassword.failedTxt'),
        title: t('forgotPassword.failedTitle'),
        confirmButtonText: t('forgotPassword.close'),
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavBar />
      {loading ? (
        <div className='loader-container'></div>
      ) : (
        <>
          <div className='privacyPolicy'>
            <div className='aboutusSection1 text-center'>
              <h1 className=''>{t('forgotPassword.forgotPassword')}</h1>
              <h6 className='text-muted fw-normal'>
                {' '}
                <Link to='/' style={{ color: 'grey' }}>
                  {t('navHeaders.home')}
                </Link>
                {' / '}
                {t('forgotPassword.forgotPassword')}
              </h6>
            </div>
            <div className='container'>
              <div className='row'>
                <div className='card forgotPasswordCard'>
                  <div className='card-body'>
                    <h2 className='text-center pb-4 pt-2'>
                      {t('forgotPassword.forgot')}
                      <span className='textGradient'>
                        {' '}
                        <span className='SubHaddingTxt'> {t('forgotPassword.password')}</span>
                      </span>
                    </h2>
                    <p className='forgotPasswordProcessTxt'>
                      {t('forgotPassword.text1')}
                    </p>
                    <form>
                      <div className='mb-2'>
                        <input
                          type='email'
                          className={`form-control ${
                            errors.email && 'input-error'
                          }`}
                          id='exampleInputEmail1'
                          aria-describedby='emailHelp'
                          name='email'
                          value={formData.email}
                          placeholder={t('forgotPassword.emailAddress')}
                          onChange={handleChange}
                        />
                        {errors.email && (
                          <div className='text-danger'>{errors.email}</div>
                        )}
                      </div>
                      <p className='forgotPasswordReturnLogin text-muted'>
                        {t('forgotPassword.text2')}{' '}
                        <Link onClick={() => window.history.back()}>
                          {t('forgotPassword.loginPage')}
                        </Link>
                      </p>
                      <button
                        type='submit'
                        onClick={handleClick}
                        className='btn button1 w-100 mt-4 mb-3'
                      >
                        {t('forgotPassword.submitButton')}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default ForgotPassword;
