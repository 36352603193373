import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import PasswordStrengthBar from 'react-password-strength-bar';
// api
import ApiKey from '../api/ApiKey';
import BaseApi from '../api/BaseApi';
// components
import Sidebar from './Sidebar';
import Footer from '../element/Footer';
import NavBar from '../element/NavBar';
// helpers
import { logOutIfTokenExpired, resultInfo } from '../helpers';

const ChangePassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('global');
  const tokenKey = Cookies.get('tokenClient');
  const currentLanguage = Cookies.get('selectedLanguage') || 'ukr';

  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState({
    old_password: '',
    new_password: '',
    conf_password: '',
  });
  const [errors, setErrors] = useState({
    old_password: '',
    new_password: '',
    conf_password: '',
  });

  const handleChange = async e => {
    const { name, value } = e.target;
    setPassword(prev => ({
      ...prev,
      [name]: value,
    }));
    setErrors(prev => ({
      ...prev,
      [name]: '',
    }));
  };

  const handleClick = async () => {
    try {
      const newErrors = {};

      if (password.old_password === '') {
        newErrors.old_password = t('employerChangePassword.oldPassRequired');
        window.scrollTo(0, 0);
      }

      if (password.new_password === '') {
        newErrors.new_password = t('employerChangePassword.newPassRequired');
        window.scrollTo(0, 0);
      }
      if (password.conf_password === '') {
        newErrors.conf_password = t('employerChangePassword.confPassRequired');
        window.scrollTo(0, 0);
      }
      if (password.new_password) {
        if (password.new_password.length < 8) {
          newErrors.new_password = t('employerChangePassword.passLengthError');
        }
      }
      if (password.conf_password) {
        if (password.conf_password.length < 8) {
          newErrors.conf_password = t('employerChangePassword.passLengthError');
        }
      }
      if (password.new_password && password.conf_password) {
        if (password.new_password !== password.conf_password) {
          newErrors.conf_password = t('employerChangePassword.passMatchError');
        }
      }

      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0) {
        const confirmationResult = await Swal.fire({
          icon: 'question',
          showCancelButton: true,
          cancelButtonText: t('employerChangePassword.no'),
          text: t('employerChangePassword.passConfirmTxt'),
          confirmButtonText: t('employerChangePassword.yes'),
          title: t('employerChangePassword.passConfirmTitle'),
        });

        if (confirmationResult.isConfirmed) {
          setLoading(true);

          const updatedData = {
            ...password,
            language: currentLanguage,
          };

          const response = await axios.post(
            BaseApi + '/users/changePassword',
            updatedData,
            {
              headers: {
                key: ApiKey,
                token: tokenKey,
                'Content-Type': 'application/json',
              },
            },
          );

          setLoading(false);

          if (response.data.status === 200) {
            resultInfo('success', t('employerChangePassword.passSuccessTitle'), t('employerChangePassword.close'));

            navigate('/user/myprofile');
          } else if (response.data.status === 400) {
            navigate('/');

            Cookies.remove('fname');
            Cookies.remove('user_id');
            Cookies.remove('user_type');
            Cookies.remove('tokenClient');

            resultInfo('warning', response.data.message, t('employerChangePassword.close'));
          } else {
            resultInfo('warning', response.data.message, t('employerChangePassword.close'));
          }
        }
      }
    } catch (error) {
      setLoading(false);

      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      } else {
        resultInfo('error', t('employerChangePassword.passFailedTitle'), t('employerChangePassword.close'));
      }

      console.error('Could not change password!', error);
    }
  };

  useEffect(() => {
    if (!tokenKey) {
      navigate('/user/employerlogin');
    } else {
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]);

  return (
    <>
      <NavBar />
      <div className='container changePassword editProfile'>
        <div className='row'>
          <div className='col-lg-3'>
            <Sidebar />
          </div>
          {
            loading
              ? (
                <div className='loader-container'></div>
              ) : (
                <div
                  className='col-lg-9 mb-5'
                  style={{
                    borderLeft: '2px solid #e6e8e7',
                    borderRight: '2px solid #e6e8e7',
                  }}
                >
                  <div className='d-flex mx-3 PageHeader'>
                    <img src='/Images/employerSide/icon9color.png' alt='' />
                    <h3 className='mx-2'>
                      {t('employerChangePassword.changeYourPassword')}
                    </h3>
                  </div>
                  <form>
                    <div className='mb-5 mt-5'>
                      <div className='form-outline mb-5 DashBoardInputBx'>
                        <label className='form-label' htmlFor='form3Example1'>
                          {t('employerChangePassword.oldPassword')}{' '}
                          <span className='RedStar'>*</span>
                        </label>
                        <input
                          type='password'
                          id='form3Example1'
                          name='old_password'
                          onChange={handleChange}
                          value={password.old_password}
                          placeholder={t('employerChangePassword.oldPassword')}
                          className={`form-control ${errors.old_password && 'input-error'}`}
                        />
                        {
                          errors.old_password && (
                            <div className='text-danger'>{errors.old_password}</div>
                          )
                        }
                      </div>
                      <div className='form-outline mb-5 DashBoardInputBx'>
                        <label className='form-label' htmlFor='form3Example3'>
                          {t('employerChangePassword.newPassword')}
                          <span className='RedStar'>*</span>
                        </label>
                        <input
                          type='password'
                          id='form3Example3'
                          name='new_password'
                          onChange={handleChange}
                          value={password.new_password}
                          placeholder={t('employerChangePassword.newPassword')}
                          className={`form-control ${errors.new_password && 'input-error'}`}
                        />
                        {
                          errors.new_password && (
                            <div className='text-danger'>{errors.new_password}</div>
                          )
                        }
                        {
                          password.new_password && (
                            <PasswordStrengthBar password={password.new_password} />
                          )
                        }
                      </div>
                      <div className='form-outline mb-5 DashBoardInputBx'>
                        <label className='form-label' htmlFor='form3Example3'>
                          {t('employerChangePassword.confirmPassword')}{' '}
                          <span className='RedStar'>*</span>
                        </label>
                        <input
                          type='password'
                          id='form3Example3'
                          name='conf_password'
                          onChange={handleChange}
                          value={password.conf_password}
                          placeholder={t('employerChangePassword.confirmPassword')}
                          className={`form-control ${errors.conf_password && 'input-error'}`}
                        />
                        {
                          errors.conf_password && (
                            <div className='text-danger'>{errors.conf_password}</div>
                          )
                        }
                        {
                          password.conf_password && (
                            <PasswordStrengthBar password={password.conf_password} />
                          )
                        }
                      </div>
                    </div>
                    <button
                      type='button'
                      onClick={handleClick}
                      className='btn btn-primary button1 mx-3'
                    >
                      {t('employerChangePassword.updateButton')}
                    </button>
                    <button
                      type='button'
                      className='btn btn-primary button2'
                      onClick={() => window.history.back()}
                    >
                      {t('employerChangePassword.cancelButton')}
                    </button>
                  </form>
                </div>
              )
          }
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ChangePassword;
