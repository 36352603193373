import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
// hooks
import { useWindowSize } from '../../../Hooks/use-window-size';
// settings
import { quickLinks, socialLinks, contractsLinks, assistanceLinks } from './settings/links';

const Footer = () => {
  const { width } = useWindowSize();
  const { t } = useTranslation('global');

  const [isQuickLinksVisible, setIsQuickLinksVisible] = useState(false);
  const [isAboutusLinksVisible, setIsAboutusLinksVisible] = useState(false);
  const [isJobseekerLinksVisible, setIsJobseekerLinksVisible] = useState(false);

  const toggle3 = () => {
    setIsQuickLinksVisible(!isQuickLinksVisible);
  };
  const toggle2 = () => {
    setIsAboutusLinksVisible(!isAboutusLinksVisible);
  };
  const toggle1 = () => {
    setIsJobseekerLinksVisible(!isJobseekerLinksVisible);
  };

  useEffect(() => {
    const handleWindowResize = () => {
      if (width < 768) {
        setIsQuickLinksVisible(false);
        setIsAboutusLinksVisible(false);
        setIsJobseekerLinksVisible(false);
      } else {
        setIsQuickLinksVisible(false);
        setIsAboutusLinksVisible(false);
        setIsJobseekerLinksVisible(true);
      }
    };

    handleWindowResize();

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [width]);

  const screenWidth = window.innerWidth;

  return (
    <>
      {screenWidth > 767 ? (
        <>
          <div className='footer'>
            <div className='container'>
              <div className='row footerHeadersRow'>
                <div className='col-md-3'>
                  <div className='FooterLinks'>
                    <h3>{t('footer.assistance')}</h3>
                    <ul className='assistanceLinks'>
                      <li>
                        {t('footer.techSupport')}:
                        <div className='iconLinks'>
                          {
                            assistanceLinks && (
                              assistanceLinks.links.map(({ link, name, type, prop }) => (
                                <a key={link} href={link} title={type} target='_blank' rel='noreferrer'>
                                  <i className={`fa-${prop} fa-${name}`} />
                                </a>
                              ))
                            )
                          }
                        </div>
                      </li>
                      <li>
                        {t('footer.contactUs')}:
                      </li>
                      {
                        assistanceLinks && (
                          assistanceLinks.phoneNumbers.map(({ link, title }) => (
                            <li key={link}>
                              <a href={link} className='' target='_blank' rel='noreferrer'>
                                {title}
                              </a>
                            </li>
                          ))
                        )
                      }
                    </ul>
                  </div>
                </div>
                <div className='col-xs-12 col-md-3 col-lg-3'>
                  <div className='FooterLinks'>
                    <h3>{t('footer.contracts')}</h3>
                    <ul className='contractsLinks'>
                      {
                        contractsLinks && (
                          contractsLinks.map(({ link, title }) => (
                            <li key={link}>
                              <Link to={link} className=''>
                                {t(`footer.${title}`)}
                              </Link>
                            </li>
                          ))
                        )
                      }
                    </ul>
                  </div>
                </div>
                <div className='col-xs-12 col-md-3 col-lg-3'>
                  <div className='FooterLinks'>
                    <h3>{t('footer.quickLinks')}</h3>
                    <ul className='quickLinks'>
                      <li>
                        {' '}
                        <Link to='/user/myprofile' className=''>
                          {t('footer.postJob')}
                        </Link>
                      </li>
                      <li>
                        {' '}
                        <Link to='/searchjob' className=''>
                          {t('footer.searchJob')}
                        </Link>
                      </li>
                      {
                        quickLinks && (
                          quickLinks.map(({ link, title }) => (
                            <li key={link}>
                              <a href={link} className='' target='_blank' rel='noreferrer'>
                                <i className='fa-brands fa-telegram' />
                                {t(`footer.${title}`)}
                              </a>
                            </li>
                          ))
                        )
                      }
                    </ul>
                  </div>
                </div>
                <div className='col-xs-12 col-md-3 col-lg-3'>
                  <div className='FooterLinks'>
                    <h3>{t('footer.followUs')}</h3>
                    <div className='SocialIcons'>
                      {
                        socialLinks && (
                          socialLinks.map(({ icon, link }) => (
                            <Link key={link} to={link} target='_blank'>
                              <i className={`fa-brands fa-${icon}`} />
                            </Link>
                          ))
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className='footerFooter'>
                <hr />
                <p>
                  &copy; Copyright@2024 | <Link to='/'>GRC</Link> | All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='footer'>
            <div className='container'>
              <div className='row footerHeadersRow'>
                <div className='col-xs-12 col-md-3 col-lg-3'>
                  <div className='FooterLinks'>
                    <div className='footerEachHeader' onClick={toggle1}>
                      <h3>{t('footer.assistance')}</h3>
                      <Link className='footerPlusLink'>
                        {
                          isJobseekerLinksVisible
                            ? <i className='fa-solid fa-circle-minus' />
                            : <i className='fa-solid fa-circle-plus' />
                        }
                      </Link>
                    </div>
                    <ul className='assistanceLinks' style={{ display: isJobseekerLinksVisible ? 'block' : 'none' }}>
                      <li>
                        {t('footer.techSupport')}:
                        <div className='iconLinks'>
                          {
                            assistanceLinks && (
                              assistanceLinks.links.map(({ link, name, type, prop }) => (
                                <a key={link} href={link} title={type} target='_blank' rel='noreferrer'>
                                  <i className={`fa-${prop} fa-${name}`} />
                                </a>
                              ))
                            )
                          }
                        </div>
                      </li>
                      <li>
                        {t('footer.contactUs')}:
                      </li>
                      {
                        assistanceLinks && (
                          assistanceLinks.phoneNumbers.map(({ link, title }) => (
                            <li key={link}>
                              <a href={link} className='' target='_blank' rel='noreferrer'>
                                {title}
                              </a>
                            </li>
                          ))
                        )
                      }
                    </ul>
                  </div>
                </div>
                <div className='col-xs-12 col-md-3 col-lg-3'>
                  <div className='FooterLinks'>
                    <div className='footerEachHeader' onClick={toggle2}>
                      <h3>{t('footer.contracts')}</h3>
                      <Link className='footerPlusLink'>
                        {
                          isAboutusLinksVisible
                            ? <i className='fa-solid fa-circle-minus' />
                            : <i className='fa-solid fa-circle-plus' />
                        }
                      </Link>
                    </div>
                    <ul className='contractsLinks' style={{ display: isAboutusLinksVisible ? 'block' : 'none' }}>
                      {
                        contractsLinks && (
                          contractsLinks.map(({ link, title }) => (
                            <li key={link}>
                              <Link to={link} className=''>
                                {t(`footer.${title}`)}
                              </Link>
                            </li>
                          ))
                        )
                      }
                    </ul>
                  </div>
                </div>
                <div className='col-xs-12 col-md-3 col-lg-3'>
                  <div className='FooterLinks'>
                    <div className='footerEachHeader' onClick={toggle3}>
                      <h3>{t('footer.quickLinks')}</h3>
                      <Link className='footerPlusLink'>
                        {
                          isQuickLinksVisible
                            ? <i className='fa-solid fa-circle-minus' />
                            : <i className='fa-solid fa-circle-plus' />
                        }
                      </Link>
                    </div>
                    <ul className='quickLinks' style={{ display: isQuickLinksVisible ? 'block' : 'none' }}>
                      <li>
                        {' '}
                        <Link to='/user/createjob' className=''>
                          {t('footer.postJob')}
                        </Link>
                      </li>
                      <li>
                        {' '}
                        <Link to='/searchjob' className=''>
                          {t('footer.searchJob')}
                        </Link>
                      </li>
                      {
                        quickLinks && (
                          quickLinks.map(({ link, title }) => (
                            <li key={link}>
                              <a href={link} className='' target='_blank' rel='noreferrer'>
                                <i className='fa-brands fa-telegram' />
                                {t(`footer.${title}`)}
                              </a>
                            </li>
                          ))
                        )
                      }
                    </ul>
                  </div>
                </div>
                <div className='col-xs-12 col-md-3 col-lg-3'>
                  <div className='FooterLinks'>
                    <h3>{t('footer.followUs')}</h3>
                    <div className='SocialIcons'>
                      {
                        socialLinks && (
                          socialLinks.map(({ icon, link }) => (
                            <Link key={link} to={link} target='_blank'>
                              <i className={`fa-brands fa-${icon}`} />
                            </Link>
                          ))
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className='footerFooter'>
                <hr />
                <p>
                  &copy; Copyright@2024 | <Link to='/'>GRC</Link> | All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Footer;
