import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import HTMLReactParser from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
// api
import ApiKey from '../../api/ApiKey';
import BaseApi from '../../api/BaseApi';
// components
import APNavBar from '../Elements/APNavBar';
import APSidebar from '../APSidebar/APSidebar';
// mui
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CreateIcon from '@mui/icons-material/Create';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const APTextPages = () => {
  const navigate = useNavigate();
  const tokenKey = Cookies.get('token');
  const adminID = Cookies.get('adminID');

  const [open, setOpen] = useState(false);
  const [symbol, setSymbol] = useState('🔺');
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'ascending' });

  const itemsPerPage = 20;

  const sortAndFilterData = (array, key, direction) => {
    const sortedAndFilteredArray = [...array]
      .sort((a, b) => {
        if (a[key] < b[key]) {
          return direction === 'ascending' ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    return sortedAndFilteredArray;
  };

  const sortedAndFilteredData = sortAndFilterData(
    listData,
    sortConfig.key,
    sortConfig.direction,
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedAndFilteredData.slice(
    indexOfFirstItem,
    indexOfLastItem,
  );

  const totalPages = Math.ceil(sortedAndFilteredData.length / itemsPerPage);

  const handleOpen = plan => {
    setSelectedPlan(plan);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedPlan(null);
    setOpen(false);
  };

  const getData = async () => {
    try {
      setLoading(true);

      const response = await axios.post(
        BaseApi + '/admin/page/index',
        null,
        {
          headers: {
            key: ApiKey,
            token: tokenKey,
            adminid: adminID,
            'Content-Type': 'application/json',
          },
        },
      );

      setLoading(false);
      setListData(response.data.response);
    } catch (error) {
      setLoading(false);

      console.error('Cannot get plans data at APmanageplans', error);
    }
  };

  const handleActivate = async slug => {
    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        cancelButtonText: 'No',
        showCancelButton: true,
        title: 'Activate Page?',
        confirmButtonText: 'Yes',
        text: 'Do you want to Activate this Page?',
      });

      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/admin/page/admin_activate/${slug}`,
          null,
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              adminid: adminID,
              'Content-Type': 'application/json',
            },
          },
        );

        if (response.data.status === 200) {
          Swal.fire({
            icon: 'success',
            confirmButtonText: 'Close',
            title: 'Page Activated successfully!',
          });
        } else {
          Swal.fire({
            icon: 'error',
            confirmButtonText: 'Close',
            title: response.data.message,
          });
        }

        getData();
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        confirmButtonText: 'Close',
        text: 'Could not Activate Page',
        title: 'Failed. Please try after some time!',
      });

      console.error('Couldn\'t activate the record!', error.message);
    }
  };

  const handleDeactivate = async slug => {
    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        cancelButtonText: 'No',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        title: 'Deactivate Page?',
        text: 'Do you want to Deactivate this Page?',
      });

      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/admin/page/admin_deactivate/${slug}`,
          null,
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              adminid: adminID,
              'Content-Type': 'application/json',
            },
          },
        );

        if (response.data.status === 200) {
          Swal.fire({
            icon: 'success',
            confirmButtonText: 'Close',
            title: 'Page Deactivated successfully!',
          });
        } else {
          Swal.fire({
            icon: 'error',
            confirmButtonText: 'Close',
            title: response.data.message,
          });
        }

        getData();
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        confirmButtonText: 'Close',
        text: 'Could not Deactivate Page',
        title: 'Failed. Please try after some time!',
      });

      console.error('Couldn\'t deactivate the record!', error.message);
    }
  };

  const handleColumnClick = key => {
    let direction = 'ascending';

    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
      setSymbol('🔻');
    } else {
      setSymbol('🔺');
    }

    setSortConfig({ key, direction });
  };

  useEffect(() => {
    if (!tokenKey) {
      navigate('/admin');
    } else {
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]); // eslint-disable-line

  return (
    <>
      <APNavBar />
      <div className='APBasic APManageSubadmin APTextPages'>
        <APSidebar />
        {
          loading
            ? (
              <div className='loader-container'></div>
            ) : (
              <div className='site-min-height'>
                <div className='breadCumb1' role='presentation'>
                  <Breadcrumbs
                    aria-label='breadcrumb'
                    separator={<NavigateNextIcon fontSize='small' />}
                  >
                    <Link
                      color='inherit'
                      underline='hover'
                      to='/admin/admins/dashboard'
                    >
                      Dashboard
                    </Link>
                    <Link underline='hover' color='inherit'>
                      Content
                    </Link>
                    <Typography color='text.primary'>Pages List</Typography>
                  </Breadcrumbs>
                </div>
                <div className='ManageSubAdminHeader'>
                  <h2 className=''>Text Pages</h2>
                </div>
                <div className='manageSubadminPart2'>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th onClick={() => handleColumnClick('static_page_title')}>
                          <span style={{ cursor: 'pointer' }}>Title {symbol}</span>
                        </th>
                        <th scope='col'>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        currentItems.map(i => (
                          <tr key={i.id}>
                            <td>{i.static_page_title}</td>
                            <td className='APActionButton'>
                              {
                                i.status === 1
                                  ? (
                                    <button
                                      className='btn-secondary'
                                      onClick={() => handleDeactivate(i.slug)}
                                    >
                                      <Tooltip title='Deactivate'>
                                        <CheckIcon />
                                      </Tooltip>
                                    </button>
                                  ) : (
                                    <button
                                      className='btn-secondary'
                                      onClick={() => handleActivate(i.slug)}
                                    >
                                      <Tooltip title='Activate'>
                                        <BlockIcon />
                                      </Tooltip>
                                    </button>
                                  )
                              }
                              <button
                                className='btn-primary'
                                onClick={() => navigate(`/admin/pages/editPage/${i.slug}`)}
                              >
                                <Tooltip title='Edit'>
                                  <CreateIcon />
                                </Tooltip>
                              </button>
                              <button
                                className='btn btn-secondary'
                                onClick={() => handleOpen(i)}
                              >
                                <Tooltip title='Delete'>
                                  <RemoveRedEyeIcon />
                                </Tooltip>
                              </button>
                            </td>
                          </tr>
                        ))
                      }
                      <div>
                        <Modal
                          open={open}
                          className='modalMain'
                          onClose={handleClose}
                          aria-labelledby='modal-modal-title'
                          aria-describedby='modal-modal-description'
                        >
                          <Box className='modal adminModal modal-content'>
                            <IconButton
                              onClick={handleClose}
                              className='close-button'
                              style={{ position: 'absolute', top: 10, right: 10 }}
                            >
                              &times;
                            </IconButton>
                            <Typography
                              variant='h6'
                              component='h2'
                              id='modal-modal-title'
                              style={{ color: '#f3734c', fontSize: '30px' }}
                            >
                              {selectedPlan && HTMLReactParser(selectedPlan.static_page_title)}
                            </Typography>
                            <Typography
                              sx={{ mt: 3 }}
                              id='modal-modal-description'
                            >
                              {selectedPlan && HTMLReactParser(selectedPlan.static_page_description)}
                            </Typography>
                          </Box>
                        </Modal>
                      </div>
                    </tbody>
                  </table>
                </div>
                <div className='manageSubadminpart3'>
                  <div className='pagination'>
                    <Stack spacing={2}>
                      <Pagination
                        color='primary'
                        count={totalPages}
                        page={currentPage}
                        onChange={(event, page) => setCurrentPage(page)}
                      />
                    </Stack>
                  </div>
                </div>
              </div>
            )
        }
      </div>
    </>
  );
};

export default APTextPages;
