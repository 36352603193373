import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
// api
import ApiKey from '../../api/ApiKey';
import BaseApi from '../../api/BaseApi';
// components
import APNavBar from '../Elements/APNavBar';
import APSidebar from '../APSidebar/APSidebar';
// mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import VerifiedIcon from '@mui/icons-material/Verified';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const APEmployerList = () => {
  const navigate = useNavigate();
  const tokenKey = Cookies.get('token');
  const adminID = Cookies.get('adminID');

  const [open, setOpen] = useState(false);
  const [symbol, setSymbol] = useState('🔺');
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [checkedData, setCheckedData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'ascending' });

  const itemsPerPage = 20;

  const sortAndFilterData = (array, key, direction, query) => {
    const sortedAndFilteredArray = [...array]
      .filter(
        item =>
          item.company_name?.toLowerCase().includes(query) ||
          item.employer_id?.toString().toLowerCase().includes(query) ||
          item.company_contact?.toLowerCase().includes(query) ||
          item.email_address?.toLowerCase().includes(query) ||
          item.created?.toLowerCase().includes(query),
      )
      .sort((a, b) => {
        if (a[key] < b[key]) {
          return direction === 'ascending' ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });

    return sortedAndFilteredArray;
  };

  const sortedAndFilteredData = sortAndFilterData(
    listData,
    sortConfig.key,
    sortConfig.direction,
    searchQuery,
  );

  const filteredData = listData.filter(item => {
    const searchString = searchQuery.toLowerCase();

    return (
      item.company_name?.toLowerCase().includes(searchString) ||
      item.employer_id?.toString().toLowerCase().includes(searchString) ||
      item.company_contact?.toLowerCase().includes(searchString) ||
      item.email_address?.toLowerCase().includes(searchString) ||
      item.created?.toLowerCase().includes(searchString)
    );
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedAndFilteredData.slice(
    indexOfFirstItem,
    indexOfLastItem,
  );

  const totalPages = Math.ceil(sortedAndFilteredData.length / itemsPerPage);

  const handleOpen = plan => {
    setSelectedPayment(plan);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedPayment(null);
    setOpen(false);
  };

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        BaseApi + '/admin/users/index',
        null,
        {
          headers: {
            key: ApiKey,
            token: tokenKey,
            adminid: adminID,
            'Content-Type': 'application/json',
          },
        },
      );

      setLoading(false);
      setListData(response.data.response.user_array);
    } catch (error) {
      setLoading(false);

      console.error('Cannot get plans data at APmanageplans', error);
    }
  };

  const handleCheck = id => {
    if (id === 'selectAll') {
      setSelectAll(!selectAll);

      const updatedCheckedData = selectAll
        ? filteredData.map(item => item.id)
        : [];
      setCheckedData(updatedCheckedData);
    } else {
      if (checkedData.includes(id)) {
        setCheckedData(checkedData.filter(checkedId => checkedId !== id));
      } else {
        setCheckedData([...checkedData, id]);
      }
    }
  };

  const handleActivate = async slug => {
    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        cancelButtonText: 'No',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        title: 'Activate Employer?',
        text: 'Do you want to Activate this Employer?',
      });

      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/admin/users/activateuser/${slug}`,
          null,
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              adminid: adminID,
              'Content-Type': 'application/json',
            },
          },
        );

        if (response.data.status === 200) {
          Swal.fire({
            icon: 'success',
            confirmButtonText: 'Close',
            title: 'Employer Activated successfully!',
          });
        } else {
          Swal.fire({
            icon: 'error',
            confirmButtonText: 'Close',
            title: response.data.message,
          });
        }

        getData();
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        confirmButtonText: 'Close',
        text: 'Could not Activate Employer',
        title: 'Failed. Please try after some time!',
      });

      console.error('Couldn\'t activate the record!', error.message);
    }
  };

  const handleDeactivate = async slug => {
    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        cancelButtonText: 'No',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        title: 'Deactivate Employer?',
        text: 'Do you want to Deactivate this Employer?',
      });

      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/admin/users/deactivateuser/${slug}`,
          null,
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              adminid: adminID,
              'Content-Type': 'application/json',
            },
          },
        );

        if (response.data.status === 200) {
          Swal.fire({
            icon: 'success',
            confirmButtonText: 'Close',
            title: 'Employer Deactivated successfully!',
          });
        } else {
          Swal.fire({
            icon: 'error',
            confirmButtonText: 'Close',
            title: response.data.message,
          });
        }

        getData();
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        confirmButtonText: 'Close',
        text: 'Could not Deactivate Employer',
        title: 'Failed. Please try after some time!',
      });

      console.error('Couldn\'t deactivate the record!', error.message);
    }
  };

  const handleDelete = async slug => {
    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        cancelButtonText: 'No',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        title: 'Delete Employer?',
        text: 'Do you want to Delete this Employer?',
      });

      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/admin/users/deleteusers/${slug}`,
          null,
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              adminid: adminID,
              'Content-Type': 'application/json',
            },
          },
        );

        if (response.data.status === 200) {
          Swal.fire({
            icon: 'success',
            confirmButtonText: 'Close',
            title: 'Employer deleted successfully!',
          });
        } else {
          Swal.fire({
            icon: 'error',
            confirmButtonText: 'Close',
            title: response.data.message,
          });
        }

        getData();
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        confirmButtonText: 'Close',
        text: 'Could not Delete Employer',
        title: 'Failed. Please try after some time!',
      });

      console.error('Couldn\'t delete the record!', error.message);
    }
  };

  const handleVerify = async slug => {
    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        cancelButtonText: 'No',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        title: 'Verify Employer?',
        text: 'Do you want to Verify this Employer?',
      });

      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/admin/users/verifyNow/${slug}`,
          null,
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              adminid: adminID,
              'Content-Type': 'application/json',
            },
          },
        );

        if (response.data.status === 200) {
          Swal.fire({
            icon: 'success',
            confirmButtonText: 'Close',
            title: 'Employer verified successfully!',
          });
        } else {
          Swal.fire({
            icon: 'error',
            confirmButtonText: 'Close',
            title: response.data.message,
          });
        }

        getData();
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        confirmButtonText: 'Close',
        text: 'Could not Verify Employer',
        title: 'Failed. Please try after some time!',
      });

      console.error('Couldn\'t delete the record!', error.message);
    }
  };

  const handleMultipleDeactivate = async () => {
    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        cancelButtonText: 'No',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        title: 'Deactivate Employers?',
        text: 'Do you want to Deactivate Employers?',
      });

      if (confirmationResult.isConfirmed) {
        const ids = checkedData.join(',');

        const response = await axios.post(
          BaseApi + '/admin/users/index',
          {
            idList: ids,
            action: 'deactivate',
          },
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              adminid: adminID,
              'Content-Type': 'application/json',
            },
          },
        );

        setSelectAll(true);
        setCheckedData('');

        if (response.data.status === 200) {
          Swal.fire({
            icon: 'success',
            confirmButtonText: 'Close',
            title: 'Employers Deactivated successfully!',
          });
        } else {
          Swal.fire({
            icon: 'error',
            confirmButtonText: 'Close',
            title: 'Couldn\'t Deactivate!',
          });
        }

        getData();
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        confirmButtonText: 'Close',
        text: 'Could not Deactivate Employers',
        title: 'Failed. Please try after some time!',
      });

      console.error('Couldn\'t deactivate the record!', error.message);
    }
  };

  const handleMultipleActivate = async () => {
    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        cancelButtonText: 'No',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        title: 'Activate Employers?',
        text: 'Do you want to Activate Employers?',
      });

      if (confirmationResult.isConfirmed) {
        const ids = checkedData.toString();

        const response = await axios.post(
          BaseApi + '/admin/users/index',
          {
            idList: ids,
            action: 'activate',
          },
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              adminid: adminID,
              'Content-Type': 'application/json',
            },
          },
        );

        setSelectAll(true);
        setCheckedData('');

        if (response.data.status === 200) {
          Swal.fire({
            icon: 'success',
            confirmButtonText: 'Close',
            title: 'Employers Activated successfully!',
          });
        } else {
          Swal.fire({
            icon: 'error',
            confirmButtonText: 'Close',
            title: 'Couldn\'t Activate!',
          });
        }

        getData();
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        confirmButtonText: 'Close',
        text: 'Could not Activate Employers',
        title: 'Failed. Please try after some time!',
      });

      console.error('Couldn\'t activate the record!', error.message);
    }
  };

  const handleMultipleDelete = async () => {
    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        cancelButtonText: 'No',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        title: 'Delete Employers?',
        text: 'Do you want to Delete Employers?',
      });

      if (confirmationResult.isConfirmed) {
        const ids = checkedData.toString();

        const response = await axios.post(
          BaseApi + '/admin/users/index',
          {
            idList: ids,
            action: 'delete',
          },
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              adminid: adminID,
              'Content-Type': 'application/json',
            },
          },
        );

        setSelectAll(true);
        setCheckedData('');

        if (response.data.status === 200) {
          Swal.fire({
            icon: 'success',
            confirmButtonText: 'Close',
            title: 'Employers Deleted successfully!',
          });
        } else {
          Swal.fire({
            icon: 'error',
            confirmButtonText: 'Close',
            title: 'Couldn\'t Delete!',
          });
        }

        getData();
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        confirmButtonText: 'Close',
        text: 'Could not Delete Employers',
        title: 'Failed. Please try after some time!',
      });

      console.error('Couldn\'t Delete the record!', error.message);
    }
  };

  const handleColumnClick = key => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
      setSymbol('🔻');
    } else {
      setSymbol('🔺');
    }
    setSortConfig({ key, direction });
  };

  const handleSearchChange = e => {
    const newQuery = e.target.value.toLowerCase();
    setSearchQuery(newQuery);
  };

  const handleExport = () => {
    const fileUrl = BaseApi + '/admin/users/generatecsv';

    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = fileUrl;

    link.download = 'Export List';

    document.body.appendChild(link);

    link.click();
  };

  useEffect(() => {
    if (!tokenKey) {

      navigate('/admin');
    } else {
      getData();

      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]); // eslint-disable-line

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  return (
    <>
      <APNavBar />
      <div className='APBasic APManageSubadmin'>
        <APSidebar />
        {
          loading
            ? (
              <div className='loader-container'></div>
            ) : (
              <>
                <div className='site-min-height'>
                  <div className='breadCumb1' role='presentation'>
                    <Breadcrumbs
                      aria-label='breadcrumb'
                      separator={<NavigateNextIcon fontSize='small' />}
                    >
                      <Link
                        underline='hover'
                        color='inherit'
                        onClick={() => navigate('/admin/admins/dashboard')}
                      >
                        Dashboard
                      </Link>
                      <Link
                        underline='hover'
                        color='inherit'
                        onClick={() => navigate('')}
                      >
                        Employers
                      </Link>
                      <Typography color='text.primary'>Employers List</Typography>
                    </Breadcrumbs>
                  </div>
                  <div className='ManageSubAdminHeader'>
                    <h2 className=''>Employer List</h2>
                    <button className='btn navButton1 APMSbutton' onClick={() => handleExport()}>
                      Export Employers
                    </button>
                    <button className='btn navButton1 APMSbutton' onClick={() => navigate('/admin/users/addusers')}>
                      Add Employer
                    </button>
                  </div>
                  {
                    listData != ''
                      ? (
                        <>
                          <div className='manageSubadminPart1'>
                            <form>
                              <div className='manageSubadminPart1Sec1'>
                                <h4>
                                  Search employer by typing Company name, Company ID, Contact Number, Email or
                                  Created date
                                </h4>
                                <div className='APDashboardSearchBx '>
                                  <input
                                    type='text'
                                    id='searchQuery'
                                    name='searchQuery'
                                    value={searchQuery}
                                    placeholder='Search..'
                                    className='form-control'
                                    onChange={handleSearchChange}
                                  />
                                </div>
                              </div>
                            </form>
                            <h4>
                              No. of Results {indexOfFirstItem + 1}-
                              {Math.min(
                                indexOfLastItem,
                                filteredData.length || listData.length,
                              )}
                              {' '}
                              of {listData.length}
                            </h4>
                          </div>
                          <div className='manageSubadminPart2'>
                            <table className='table'>
                              <thead>
                                <tr>
                                  <td className='checkBoxCol checkBxHead'>
                                    <input
                                      type='checkbox'
                                      className='tableCheckBox'
                                      onClick={() => handleCheck('selectAll')}
                                    />
                                  </td>
                                  <th onClick={() => handleColumnClick('company_name')}>
                                    <span style={{ cursor: 'pointer' }}>
                                      Company Name {symbol}
                                    </span>
                                  </th>
                                  <th onClick={() => handleColumnClick('id')}>
                                    <span style={{ cursor: 'pointer' }}>
                                      Company ID {symbol}
                                    </span>
                                  </th>
                                  <th onClick={() => handleColumnClick('company_contact')}>
                                    <span style={{ cursor: 'pointer' }}>
                                      Contact Number {symbol}
                                    </span>
                                  </th>
                                  <th onClick={() => handleColumnClick('email_address')}>
                                    <span style={{ cursor: 'pointer' }}>
                                      Email {symbol}
                                    </span>
                                  </th>
                                  <th>
                                    Comments
                                  </th>
                                  <th>
                                    Current Plan
                                  </th>
                                  <th onClick={() => handleColumnClick('created')}>
                                    <span style={{ cursor: 'pointer' }}>
                                      <CalendarMonthIcon /> Created {symbol}
                                    </span>
                                  </th>
                                  <th scope='col'>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  currentItems.map(i => (
                                    <tr key={i.id}>
                                      <td className='checkBoxCol'>
                                        <input
                                          type='checkbox'
                                          className='tableCheckBox'
                                          onChange={() => handleCheck(i.id)}
                                          checked={checkedData.includes(i.id)}
                                        />
                                      </td>
                                      <td>
                                        {i.company_name}
                                        <span className='verifiedUsername'>
                                          {i.verify === 1 ? <VerifiedIcon /> : ''}
                                        </span>
                                      </td>
                                      <td>{i.employer_id}</td>
                                      <td>{i.contact}</td>
                                      <td>{i.email_address}</td>
                                      <td>{i.notes}</td>
                                      <td>{i.plan}</td>
                                      <td>{i.created}</td>
                                      <td className='APActionButton'>
                                        {
                                          i.status === 1
                                            ? (
                                              <button
                                                className='btn-secondary'
                                                onClick={() => handleDeactivate(i.slug)}
                                              >
                                                <Tooltip title='Deactivate'>
                                                  <CheckIcon />
                                                </Tooltip>
                                              </button>
                                            ) : (
                                              <button
                                                className='btn-secondary'
                                                onClick={() => handleActivate(i.slug)}
                                              >
                                                <Tooltip title='Activate'>
                                                  <BlockIcon />
                                                </Tooltip>
                                              </button>
                                            )
                                        }
                                        <button
                                          className='btn-primary'
                                          onClick={() =>navigate(`/admin/users/editusers/${i.slug}`)}
                                        >
                                          <Tooltip title='Edit'>
                                            <CreateIcon />
                                          </Tooltip>
                                        </button>
                                        <button
                                          className='btn btn-secondary'
                                          onClick={() => handleDelete(i.slug)}
                                        >
                                          <Tooltip title='Delete'>
                                            <DeleteIcon />
                                          </Tooltip>
                                        </button>
                                        {
                                          !i.verify && (
                                            <button
                                              className='btn-primary'
                                              onClick={() => handleVerify(i.slug)}
                                            >
                                              <Tooltip title='Verify Now'>
                                                <VerifiedUserIcon />
                                              </Tooltip>
                                            </button>
                                          )
                                        }
                                        <button
                                          className='btn-primary'
                                          onClick={() => handleOpen(i)}
                                        >
                                          <Tooltip title='View'>
                                            <RemoveRedEyeIcon />
                                          </Tooltip>
                                        </button>
                                      </td>
                                    </tr>
                                  ))
                                }
                                <div>
                                  <Modal
                                    open={open}
                                    className='modalMain'
                                    onClose={handleClose}
                                    aria-labelledby='modal-modal-title'
                                    aria-describedby='modal-modal-description'
                                  >
                                    <Box className='modal adminModal modal-content'>
                                      <IconButton
                                        onClick={handleClose}
                                        className='close-button'
                                        style={{
                                          top: 10,
                                          right: 10,
                                          position: 'absolute',
                                        }}
                                      >
                                        &times;
                                      </IconButton>
                                      <Typography
                                        variant='h6'
                                        component='h2'
                                        id='modal-modal-title'
                                      ></Typography>
                                      <Typography
                                        sx={{ mt: 3 }}
                                        id='modal-modal-description'
                                      >
                                        {
                                          selectedPayment && (
                                            <div className='modals'>
                                              <div className='modalHead'>
                                                <h1>
                                                  {selectedPayment.first_name}
                                                  {' '}
                                                  {selectedPayment.last_name}
                                                </h1>
                                              </div>
                                              <div className='modalBody mt-4'>
                                                <div className='modalParent'>
                                                  <div className='modalLeft'>
                                                    Company Name:{' '}
                                                  </div>
                                                  <div className='modalRight'>
                                                    {selectedPayment.company_name}
                                                  </div>
                                                </div>
                                                <div className='modalParent'>
                                                  <div className='modalLeft'>
                                                    Position:{' '}
                                                  </div>
                                                  <div className='modalRight'>
                                                    {selectedPayment.position}
                                                  </div>
                                                </div>
                                                <div className='modalParent'>
                                                  <div className='modalLeft'>
                                                    First Name:{' '}
                                                  </div>
                                                  <div className='modalRight'>
                                                    {selectedPayment.first_name}
                                                  </div>
                                                </div>
                                                <div className='modalParent'>
                                                  <div className='modalLeft'>
                                                    Last Name:{' '}
                                                  </div>
                                                  <div className='modalRight'>
                                                    {selectedPayment.last_name}
                                                  </div>
                                                </div>
                                                <div className='modalParent'>
                                                  <div className='modalLeft'>
                                                    Address:{' '}
                                                  </div>
                                                  <div className='modalRight'>
                                                    {selectedPayment.address}
                                                  </div>
                                                </div>
                                                <div className='modalParent'>
                                                  <div className='modalLeft'>
                                                    Email Address:{' '}
                                                  </div>
                                                  <div className='modalRight'>
                                                    {selectedPayment.email_address}
                                                  </div>
                                                </div>
                                                <div className='modalParent'>
                                                  <div className='modalLeft'>
                                                    Contact Number:{' '}
                                                  </div>
                                                  <div className='modalRight'>
                                                    {selectedPayment.contact}
                                                  </div>
                                                </div>
                                                <div className='modalParent'>
                                                  <div className='modalLeft'>
                                                    Company Number:{' '}
                                                  </div>
                                                  <div className='modalRight'>
                                                    {selectedPayment.company_contact}
                                                  </div>
                                                </div>
                                                <div className='modalParent'>
                                                  <div className='modalLeft'>
                                                    Website:{' '}
                                                  </div>
                                                  <div className='modalRight'>
                                                    {selectedPayment.url}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        }
                                      </Typography>
                                    </Box>
                                  </Modal>
                                </div>
                              </tbody>
                            </table>
                          </div>
                          <div className='manageSubadminpart3'>
                            <div className='functionalityButton'>
                              <button
                                type='button'
                                className='btn btn-primary APButton3'
                                onClick={() => handleMultipleActivate()}
                              >
                                MASS ACTIVATE
                              </button>
                              <button
                                type='button'
                                className='btn btn-primary APButton3'
                                onClick={() => handleMultipleDeactivate()}
                              >
                                MASS DEACTIVATE
                              </button>
                              <button
                                type='button'
                                className='btn btn-primary APButton4'
                                onClick={() => handleMultipleDelete()}
                              >
                                MASS DELETE
                              </button>
                            </div>
                            <div className='pagination'>
                              <Stack spacing={2}>
                                <Pagination
                                  color='primary'
                                  count={totalPages}
                                  page={currentPage}
                                  onChange={(event, page) => setCurrentPage(page)}
                                />
                              </Stack>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className='appliedJobListNoData'>No Records Found</div>
                      )
                  }
                </div>
              </>
            )
        }
      </div>
    </>
  );
};

export default APEmployerList;
