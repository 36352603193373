import React, { useEffect, useState } from 'react';
import APNavBar from '../Elements/APNavBar';
import APSidebar from '../APSidebar/APSidebar';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import axios from 'axios';
import BaseApi from '../../api/BaseApi';
import ApiKey from '../../api/ApiKey';
import Swal from 'sweetalert2';
import { useNavigate, Link } from 'react-router-dom';
import Cookies from 'js-cookie';

const APAddCurrency = () => {
  const [userData, setUserData] = useState({
    name: '',
    code: '',
    symbol: '',
  });
  const [errors, setErrors] = useState({
    name: '',
    code: '',
    symbol: '',
  });
  const [loading, setLoading] = useState(false);
  const tokenKey = Cookies.get('token');
  const adminID = Cookies.get('adminID');

  const navigate = useNavigate();

  const handleChange = e => {
    const { name, value } = e.target;
    setUserData(prev => ({
      ...prev,
      [name]: value,
    }));
    setErrors(prev => ({
      ...prev,
      [name]: '',
    }));
  };

  // const getData = async () => {
  //   try {
  //     const response = await axios.post(
  //       BaseApi + "/admin/changeusername",
  //       null,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           key: ApiKey,
  //           token: tokenKey,
  //         },
  //       }
  //     );
  //     setUserData(response.data.response);
  //   } catch (error) {
  //     console.log("Error at chnage username at Admin panel");
  //   }
  // };

  const handleClick = async () => {
    try {
      const newErrors = {};

      if (userData.name === '') {
        newErrors.name = 'Currency Name is required';
        window.scrollTo(0, 0);
      }
      if (userData.code === '') {
        newErrors.code = 'Currency Code is required';
        window.scrollTo(0, 0);
      }
      if (userData.symbol === '') {
        newErrors.symbol = 'Currency Symbol is required';
        window.scrollTo(0, 0);
      }

      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0) {
        const confirmationResult = await Swal.fire({
          title: 'Add Currency?',
          text: 'Do you want to Add Currency?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        });

        if (confirmationResult.isConfirmed) {
          setLoading(true);

          const response = await axios.post(
            BaseApi + '/admin/currency/admin_add',
            userData,
            {
              headers: {
                'Content-Type': 'application/json',
                key: ApiKey,
                token: tokenKey,
                adminid: adminID,
              },
            },
          );

          setLoading(false);

          if (response.data.status === 200) {
            Swal.fire({
              title: 'Currency added successfully!',
              icon: 'success',
              confirmButtonText: 'Close',
            });
            // getData();
            // setUserData({
            //   ...userData,
            //   new_username: "",
            //   conf_username: "",
            // });
            // window.scrollTo(0, 0)
            navigate('/admin/currencies');
          } else {
            Swal.fire({
              title: response.data.message,
              icon: 'error',
              confirmButtonText: 'Close',
            });
          }
        }
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: 'Failed',
        text: 'Could not add currency. Please try again later!',
        icon: 'error',
        confirmButtonText: 'Close',
      });
      console.log('Could not change username!', error);
    }
  };

  const handleReset = () => {
    setUserData({
      name: '',
      code: '',
      symbol: '',
    });
    window.location.reload();
  };

  useEffect(() => {
    // Check if tokenKey is not present
    if (!tokenKey) {
      // Redirect to the home page
      navigate('/admin');
    } else {
      // TokenKey is present, fetch data or perform other actions
      // getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]);

  return (
    <>
      <APNavBar />
      <div className='APBasic'>
        <APSidebar />

        {loading ? (
          <>
            <div className='loader-container'></div>
          </>
        ) : (
          <>
            <div className='site-min-height'>
              <div className='breadCumb1' role='presentation'>
                <Breadcrumbs
                  aria-label='breadcrumb'
                  separator={<NavigateNextIcon fontSize='small' />}
                >
                  <Link
                    to='/admin/admins/dashboard'
                    underline='hover'
                    color='inherit'
                  >
                    Dashboard
                  </Link>
                  <Link
                    to='/admin/currencies'
                    underline='hover'
                    color='inherit'
                  >
                    Currency List
                  </Link>

                  <Typography color='text.primary'>Add Currency</Typography>
                </Breadcrumbs>
              </div>

              <h2 className='adminPageHeading'>Add Currency</h2>
              <form className='adminForm'>
                <div className='mb-4 mt-5'>
                  <div className='mb-5 DashBoardInputBx'>
                    <label htmlFor='formFile' className='form-label'>
                      Currency Name<span className='RedStar'>*</span>
                    </label>
                    <input
                      type='text'
                      id='form3Example1'
                      className={`form-control ${errors.name && 'input-error'}`}
                      name='name'
                      placeholder='Currency Name'
                      value={userData.name}
                      onChange={handleChange}
                    />
                    {errors.name && (
                      <div className='text-danger'>{errors.name}</div>
                    )}
                  </div>
                  <div className='mb-5 DashBoardInputBx'>
                    <label htmlFor='formFile' className='form-label'>
                      Currency Code<span className='RedStar'>*</span>
                    </label>
                    <input
                      type='text'
                      id='form3Example1'
                      className={`form-control ${errors.code && 'input-error'}`}
                      name='code'
                      placeholder='Currency Code'
                      value={userData.code}
                      onChange={handleChange}
                    />
                    {errors.code && (
                      <div className='text-danger'>{errors.code}</div>
                    )}
                  </div>
                  <div className='mb-5 DashBoardInputBx'>
                    <label htmlFor='formFile' className='form-label'>
                      Currency Symbol<span className='RedStar'>*</span>
                    </label>
                    <input
                      type='text'
                      id='form3Example1'
                      className={`form-control ${
                        errors.symbol && 'input-error'
                      }`}
                      name='symbol'
                      placeholder='Currency Symbol'
                      value={userData.symbol}
                      onChange={handleChange}
                    />
                    {errors.symbol && (
                      <div className='text-danger'>{errors.symbol}</div>
                    )}
                  </div>
                  <div className='adminBottomButtons'>
                    <button
                      type='button'
                      className='btn btn-primary button1'
                      onClick={handleClick}
                    >
                      SAVE
                    </button>
                    <button
                      type='button'
                      className='btn btn-primary button2'
                      onClick={() => handleReset()}
                    >
                      RESET
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default APAddCurrency;
