/* eslint-disable react/prop-types */
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const BrowseJobToggler = ({ bySkill = [], byTitle = [], byCategory = [], byPopularSearch = [] }) => {
  const { t } = useTranslation('global');

  const [toggle1, setToggle1] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const [toggle3, setToggle3] = useState(true);
  const [toggle4, setToggle4] = useState(false);
  const [titleActive, setTitleActive] = useState(false);
  // const [skillActive, setSkillActive] = useState(false);
  const [categoryActive, setCategoryActive] = useState(true);
  // const [popularSearchActive, setPopularSearchActive] = useState(false);

  const encodeKeyword = keyword => encodeURIComponent(keyword);

  const Title = () => {
    setToggle1(true);
    setToggle2(false);
    setToggle3(false);
    setToggle4(false);
    setTitleActive(true);
    // setSkillActive(false);
    setCategoryActive(false);
    // setPopularSearchActive(false);
  };
  // const Skill = () => {
  //   setToggle2(true);
  //   setToggle1(false);
  //   setToggle3(false);
  //   setToggle4(false);
  //   setSkillActive(true);
  //   setTitleActive(false);
  //   setCategoryActive(false);
  //   setPopularSearchActive(false);
  // };
  const Category = () => {
    setToggle3(true);
    setToggle1(false);
    setToggle2(false);
    setToggle4(false);
    // setSkillActive(false);
    setTitleActive(false);
    setCategoryActive(true);
    // setPopularSearchActive(false);
  };
  // const popularSearches = () => {
  //   setToggle4(true);
  //   setToggle1(false);
  //   setToggle2(false);
  //   setToggle3(false);
  //   // setSkillActive(false);
  //   setTitleActive(false);
  //   setCategoryActive(false);
  //   setPopularSearchActive(true);
  // };

  return (
    <>
      <div className='buttons BrowseJobstab'>
        <button
          onClick={() => Category()}
          className={`btn ${categoryActive === true && 'active'}`}
        >
          {t('userpage.byCategory')}
        </button>
        <button
          onClick={() => Title()}
          className={`btn ${titleActive === true && 'active'}`}
        >
          {t('userpage.byTitle')}
        </button>
        {/* <button
          onClick={() => Skill()}
          className={`btn ${skillActive === true && 'active'}`}
        >
          {t('userpage.bySkills')}
        </button> */}
        {/* <button
          onClick={() => popularSearches()}
          className={`btn ${popularSearchActive === true && 'active'}`}
        >
          {t('userpage.popularSearches')}
        </button> */}
      </div>
      <div className='BrowseJobstabs toggledata'>
        {
          toggle1 && (
            <div className='first'>
              <div className='row'>
                {
                  byTitle.map(i => (
                    <div key={i.id} className='col-md-3 col-lg-3'>
                      <Link
                        className='list'
                        to={`/searchjob/k-${encodeKeyword(i.title)}`}
                      >
                        <p>{i.title}</p>
                      </Link>
                    </div>
                  ))
                }
              </div>
            </div>
          )
        }
        {
          toggle2 && (
            <div className='first'>
              <div className='row'>
                {
                  bySkill.map(i => (
                    <div key={i.id} className='col-md-3 col-lg-3'>
                      <Link to={`/searchjob/${i.slug}`} className='list'>
                        <p>{i.name}</p>
                      </Link>
                    </div>
                  ))
                }
              </div>
            </div>
          )
        }
        {
          toggle3 && (
            <div className='first'>
              <div className='row'>
                {
                  byCategory.map(i => (
                    <div key={i.id} className='col-md-3 col-lg-3'>
                      <Link to={`/searchjob/c-${i.slug}`} className='list'>
                        <p>{i.name}</p>
                      </Link>
                    </div>
                  ))
                }
              </div>
            </div>
          )
        }
        {
          toggle4 && (
            <div className='first'>
              <div className='row'>
                {
                  byPopularSearch.map(i => (
                    <div key={i.id} className='col-md-3 col-lg-3'>
                      <Link to={`/searchjob/k-${encodeKeyword(i.name)}`} className='list'>
                        <p>{i.name}</p>
                      </Link>
                    </div>
                  ))
                }
              </div>
            </div>
          )
        }
      </div>
    </>
  );
};

export default BrowseJobToggler;
