import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// api
import ApiKey from '../api/ApiKey';
import BaseApi from '../api/BaseApi';
// components
import Sidebar from './Sidebar';
import NavBar from '../element/NavBar';
import Footer from '../element/Footer';
// helpers
import { logOutIfTokenExpired, resultInfo } from '../helpers';

const MailDetail = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation('global');
  const tokenKey = Cookies.get('tokenClient');

  const [loading, setLoading] = useState(false);
  const [mailDetail, setMailDetail] = useState([]);
  const [mailImages, setMailImages] = useState([]);
  const [mailSender, setMailSender] = useState([]);
  const [mailReceiver, setMailReceiver] = useState([]);

  const getData = async () => {
    try {
      setLoading(true);

      const response = await axios.post(
        BaseApi + `/users/maildetail/${slug}`,
        null,
        {
          headers: {
            key: ApiKey,
            token: tokenKey,
            'Content-Type': 'application/json',
          },
        },
      );

      setLoading(false);

      if (response.data.status === 200) {
        setMailDetail(response.data.response.mails);
        setMailImages(response.data.response.mailImagePaths);

        if (response.data.response.mails.reciever !== null) {
          setMailReceiver(response.data.response.mails.reciever);
        }

        if (response.data.response.mails.sender !== null) {
          setMailSender(response.data.response.mails.sender);
        }
      } else if (response.data.status === 400) {
        navigate('/');
        Cookies.remove('fname');
        Cookies.remove('user_id');
        Cookies.remove('user_type');
        Cookies.remove('tokenClient');

        resultInfo('warning', response.data.message, t('membershipPlan.close'));
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message,
          confirmButtonText: t('membershipPlan.close'),
          title: t('employerFavouriteListProfile.addFavFailedTitle'),
        });
      }
    } catch (error) {
      setLoading(false);

      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      }

      console.error('Cannot get mail detail of mail history page at employer side', error);
    }
  };

  useEffect(() => {
    if (!tokenKey) {
      navigate('/user/employerlogin');
    } else {
      getData();

      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]); // eslint-disable-line

  return (
    <>
      <NavBar />
      <div className='container editProfile'>
        {/* Reply Modal  */}
        <div
          className='modal fade'
          id='ReplyModal'
          tabIndex='-1'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
        >
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h1 className='modal-title fs-5' id='exampleModalLabel'>
                  Reply Mail
                </h1>
                <button
                  type='button'
                  className='btn-close'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                ></button>
              </div>
              <div className='modal-body'>
                <div className='form-outline mb-5 mt-4 DashBoardInputBx'>
                  <label className='form-label' htmlFor='form3Example3'>
                    Subject <span className='RedStar'>*</span>
                  </label>
                  <input
                    type='text'
                    id='form3Example3'
                    className='form-control'
                    placeholder='Subject'
                    // value={password.new_password}
                    // name="new_password"
                    // onChange={handleChange}
                  />
                </div>
                <div className='form-outline mb-5 DashBoardInputBx'>
                  <label className='form-label' htmlFor='form3Example3'>
                    Message <span className='RedStar'>*</span>
                  </label>
                  <input
                    type='text'
                    id='form3Example3'
                    className='form-control'
                    placeholder='Message'
                    // value={password.new_password}
                    // name="new_password"
                    // onChange={handleChange}
                  />
                </div>
                <div className='form-outline mb-3 DashBoardInputBx'>
                  <label htmlFor='formFile' className='form-label'>
                    Multiple Images
                  </label>
                  <input className='form-control' type='file' id='formFile' />
                  <div id='emailHelp' className='form-text mt-2'>
                    Select multiple file with Ctrl press, Supported File Types:
                    gif, jpg, jpeg, png, pdf, doc, docx (Max 5 images and Max.
                    10MB).
                  </div>
                </div>
                <button type='button' className='btn btn-primary button1'>
                  SUBMIT
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-3'>
            <Sidebar />
          </div>
          {
            loading
              ? (
                <div className='loader-container'></div>
              ) : (
                <div
                  className='col-lg-9 mb-5 mailDetail'
                  style={{
                    borderLeft: '2px solid #e6e8e7',
                    borderRight: '2px solid #e6e8e7',
                  }}
                >
                  <div className='mx-3 d-flex mb-4 PageHeader'>
                    <img src='/Images/employerSide/icon8color.png' alt='' />
                    <h3 className=''>{t('employerMailDetail.mailDetail')}</h3>
                  </div>
                  <div className='mx-4 mb-5 mailDetailBx'>
                    {
                      mailReceiver !== '' || mailReceiver !== null && (
                        <h5>
                          {t('employerMailDetail.dear')}{' '}
                          {mailReceiver.first_name
                            ? mailReceiver.first_name
                            : 'N/A'}{' '}
                          {mailReceiver.last_name
                            ? mailReceiver.last_name
                            : 'N/A'}
                        ,
                        </h5>
                      )
                    }
                    {
                      mailSender !== '' || mailSender !== null && (
                        <>
                          <p>
                            {t('employerMailDetail.line1')} <br />
                            {t('employerMailDetail.companyName')} :{' '}
                            {mailSender.company_name
                              ? mailSender.company_name
                              : 'N/A'}
                            <br />
                            {t('employerMailDetail.emailAddress')} :{' '}
                            {mailSender.email_address
                              ? mailSender.email_address
                              : 'N/A'}
                            <br />
                            {t('employerMailDetail.subject')} :{' '}
                            {mailDetail.subject ? mailDetail.subject : 'N/A'}
                            <br />
                            {t('employerMailDetail.message')} :{' '}
                            {mailDetail.message ? mailDetail.message : 'N/A'}
                          </p>
                          <h5 className='mt-2'>
                            {t('employerMailDetail.attachments')}
                          </h5>
                        </>
                      )
                    }
                    <div className='emailImageAttachments'>
                      {Object.entries(mailImages).map(([index, path]) => (
                        <div className='mailAttachment' key={index}>
                          <img src={path} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )
          }
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MailDetail;
