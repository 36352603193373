import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PasswordStrengthBar from 'react-password-strength-bar';
// api
import BaseApi from '../api/BaseApi';
// components
import Footer from '../element/Footer';
import NavBar from '../element/NavBar';
// mui
import { Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const JobseekerRegister = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('global');
  const userType = Cookies.get('user_type');
  const captchaKey = Cookies.get('captchaKey');
  const tokenClient = Cookies.get('tokenClient');
  const currentLanguage = Cookies.get('selectedLanguage') || 'ukr';
  const recaptchaLanguage = currentLanguage === 'ukr' ? 'uk' : currentLanguage;

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [registerData, setRegisterData] = useState({
    email: '',
    password: '',
    last_name: '',
    first_name: '',
    middle_name: '',
    confirm_password: '',
    user_type: 'candidate',
  });
  const [errors, setErrors] = useState({
    email: '',
    captcha: '',
    password: '',
    last_name: '',
    first_name: '',
    middle_name: '',
    confirm_password: '',
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleReset = e => {
    e.preventDefault();
    setRegisterData({
      email: '',
      password: '',
      last_name: '',
      first_name: '',
      middle_name: '',
      confirm_password: '',
    });
  };

  const handleChange = e => {
    const { name, value } = e.target;

    setRegisterData(prevData => ({
      ...prevData,
      [name]: value,
    }));
    setErrors(prev => ({
      ...prev,
      [name]: '',
    }));
  };

  const handleSubmit = async e => {
    const isValidEmail = email => {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      return emailPattern.test(email);
    };

    e.preventDefault();

    try {
      const newErrors = {};

      if (registerData.first_name === '') {
        newErrors.first_name = t('jobseekerRegister.firstNameRequired');
        window.scrollTo(0, 0);
      }
      if (registerData.last_name === '') {
        newErrors.last_name = t('jobseekerRegister.lastNameRequired');
        window.scrollTo(0, 0);
      }
      if (registerData.email === '') {
        newErrors.email = t('jobseekerRegister.emailRequired');
        window.scrollTo(0, 0);
      } else if (!isValidEmail(registerData.email)) {
        newErrors.email = t('jobseekerRegister.invalidEmail');
        window.scrollTo(0, 0);
      }
      if (registerData.password === '') {
        newErrors.password = t('jobseekerRegister.passwordRequired');
        window.scrollTo(0, 0);
      }
      if (registerData.confirm_password === '') {
        newErrors.confirm_password = t('jobseekerRegister.confirmPasswordRequired');
        window.scrollTo(0, 0);
      }
      if (registerData.password) {
        if (registerData.password.length < 8) {
          newErrors.password = t('jobseekerRegister.passwordLengthError');
          window.scrollTo(0, 0);
        }
      }
      if (registerData.confirm_password) {
        if (registerData.confirm_password.length < 8) {
          newErrors.confirm_password = t('jobseekerRegister.passwordLengthError');
          window.scrollTo(0, 0);
        }
      }
      if (registerData.confirm_password && registerData.confirm_password) {
        if (registerData.password != registerData.confirm_password) {
          newErrors.confirm_password = t('jobseekerRegister.passwordMatchError');
          window.scrollTo(0, 0);
        }
      }
      if (!isCaptchaVerified) {
        newErrors.captcha = t('jobseekerRegister.captchaRequired');
      }

      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0) {
        if (isCaptchaVerified) {
          const updatedProfile = {
            ...registerData,
            language: currentLanguage,
          };

          setLoading(true);

          const response = await axios.post(
            BaseApi + '/users/registration',
            updatedProfile,
          );
          const status = response.data.status;

          if (status === 200) {
            Swal.fire({
              icon: 'success',
              confirmButtonText: t('jobseekerRegister.close'),
              title: t('jobseekerRegister.creationSuccessMessage'),
            });

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              'event': 'registration',
              'authentication_method': 'email',
              'user_email': response.data.response.email_address,
            });

            setIsCaptchaVerified(false);

            navigate('/user/jobseekerlogin');
          } else {
            Swal.fire({
              icon: 'error',
              title: response.data.message,
              confirmButtonText: t('jobseekerRegister.close'),
            });

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              'event': 'website_error',
              'error_type': 'registration error',
              'error_message': response.data.message,
            });

            setIsCaptchaVerified(false);
          }

          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);

      Swal.fire({
        icon: 'error',
        title: t('jobseekerRegister.failedTitle'),
        text: t('jobseekerRegister.failedMessage'),
        confirmButtonText: t('jobseekerRegister.close'),
      });

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'website_error',
        'error_message': error.message,
        'error_type': 'registration error',
      });

      setIsCaptchaVerified(false);
      console.error('Error sending register credentails');
    }
  };

  useEffect(() => {
    if (isCaptchaVerified) {
      setErrors({
        captcha: '',
      });
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (tokenClient && userType === 'recruiter' || tokenClient && userType === 'candidate') {
      navigate('/');

      const Toast = Swal.mixin({
        timer: 3000,
        toast: true,
        position: 'top-end',
        timerProgressBar: true,
        showConfirmButton: false,
        didOpen: toast => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: 'warning',
        title: t('jobseekerRegister.alreadyLoggedIn'),
      });
    }

    const metaTitle = document.querySelector('title');
    const metaDescription = document.querySelector('meta[name="description"]');

    if (metaTitle) {
      metaTitle.textContent = t('jobseekerRegister.metaTitleValue');
    }

    if (metaDescription) {
      metaDescription.content = t('jobseekerRegister.metaDescriptionValue');
    }

    window.scrollTo(0, 0);
  }, [t]); // eslint-disable-line

  return (
    <>
      <NavBar />
      {
        loading
          ? (
            <div className='loader-container'></div>
          ) : (
            <>
              <div className='container employerLogin'>
                <div className='card rounded'>
                  <div className='row'>
                    <div className='col-md-6 leftSection'>
                      <img src='/Images/employerlogin.jpg' alt='' />
                    </div>
                    <div className='col-md-6 mt-5'>
                      <div className='text-center'>
                        <img src='/Images/Logo.png' alt='logo' width='50px' />
                      </div>
                      <div className='text-center card-title h3 pt-5'>
                        <h1 style={{ fontSize: '28px' }}>{t('jobseekerRegister.jobseekAcRegistration')}</h1>
                      </div>
                      <div className='card-body'>
                        <form className='border border-light'>
                          <div className='mb-4'>
                            <input
                              type='text'
                              id='first_name'
                              name='first_name'
                              onChange={handleChange}
                              value={registerData.first_name}
                              placeholder={t('jobseekerRegister.firstName')}
                              className={`form-control ${errors.first_name && 'input-error'}`}
                            />
                            {
                              errors.first_name && (
                                <div className='text-danger'>{errors.first_name}</div>
                              )
                            }
                          </div>
                          <div className='mb-4'>
                            <input
                              type='text'
                              id='last_name'
                              name='last_name'
                              onChange={handleChange}
                              value={registerData.last_name}
                              placeholder={t('jobseekerRegister.lastName')}
                              className={`form-control ${errors.last_name && 'input-error'}`}
                            />
                            {
                              errors.last_name && (
                                <div className='text-danger'>{errors.last_name}</div>
                              )
                            }
                          </div>
                          <div className='mb-4'>
                            <input
                              type='text'
                              id='middle_name'
                              name='middle_name'
                              onChange={handleChange}
                              value={registerData.middle_name}
                              placeholder={t('jobseekerRegister.middleName')}
                              className={`form-control ${errors.middle_name && 'input-error'}`}
                            />
                            {
                              errors.middle_name && (
                                <div className='text-danger'>{errors.middle_name}</div>
                              )
                            }
                          </div>
                          <div className='mb-4'>
                            <input
                              id='email'
                              name='email'
                              type='email'
                              onChange={handleChange}
                              value={registerData.email}
                              placeholder={t('jobseekerRegister.email')}
                              className={`form-control ${errors.email && 'input-error'}`}
                            />
                            {
                              errors.email && (
                                <div className='text-danger'>{errors.email}</div>
                              )
                            }
                          </div>
                          <div className='mb-4 passwordBox'>
                            <input
                              id='password'
                              name='password'
                              onChange={handleChange}
                              value={registerData.password}
                              type={showPassword ? 'text' : 'password'}
                              placeholder={t('jobseekerRegister.password')}
                              className={`form-control ${errors.password && 'input-error'}`}
                            />
                            <div className='passwordVisibility'>
                              <p
                                type='button'
                                className='btn-primary'
                                onClick={togglePasswordVisibility}
                              >
                                {
                                  showPassword
                                    ? (
                                      <>
                                        <Tooltip title='Hide Password'>
                                          <VisibilityOffIcon />
                                        </Tooltip>
                                      </>
                                    ) : (
                                      <>
                                        <Tooltip title='View Password'>
                                          <VisibilityIcon />
                                        </Tooltip>
                                      </>
                                    )
                                }
                              </p>
                            </div>
                            {
                              errors.password && (
                                <div className='text-danger'>{errors.password}</div>
                              )
                            }
                            {
                              registerData.password && (
                                <PasswordStrengthBar
                                  password={registerData.password}
                                />
                              )
                            }
                          </div>
                          <div className='mb-4 passwordBox'>
                            <input
                              id='confirm_password'
                              name='confirm_password'
                              onChange={handleChange}
                              value={registerData.confirm_password}
                              type={showConfirmPassword ? 'text' : 'password'}
                              placeholder={t('jobseekerRegister.confirmPassword')}
                              className={`form-control ${errors.confirm_password && 'input-error'}`}
                            />
                            <div className='passwordVisibility'>
                              <p
                                type='button'
                                className='btn-primary'
                                onClick={toggleConfirmPasswordVisibility}
                              >
                                {
                                  showConfirmPassword
                                    ? (
                                      <>
                                        <Tooltip title='Hide Password'>
                                          <VisibilityOffIcon />
                                        </Tooltip>
                                      </>
                                    ) : (
                                      <>
                                        <Tooltip title='View Password'>
                                          <VisibilityIcon />
                                        </Tooltip>
                                      </>
                                    )
                                }
                              </p>
                            </div>
                            {
                              errors.confirm_password && (
                                <div className='text-danger'>
                                  {errors.confirm_password}
                                </div>
                              )
                            }
                            {
                              registerData.confirm_password && (
                                <PasswordStrengthBar
                                  password={registerData.confirm_password}
                                />
                              )
                            }
                          </div>
                          <div className='reCaptchaLogin'>
                            <ReCAPTCHA
                              sitekey={captchaKey}
                              hl={recaptchaLanguage}
                              onChange={value => setIsCaptchaVerified(value)}
                            />
                            {
                              errors.captcha && (
                                <div className='text-danger CaptchaVerify'>
                                  {errors.captcha}
                                </div>
                              )
                            }
                          </div>
                          <div className='d-flex justify-content-evenly'>
                            <button
                              type='submit'
                              onClick={handleSubmit}
                              className='btn button1 my-4'
                            >
                              {t('jobseekerRegister.submitButton')}
                            </button>
                            <button
                              type='button'
                              onClick={handleReset}
                              className='btn button2 my-4'
                            >
                              {t('jobseekerRegister.resetButton')}
                            </button>
                          </div>
                          <div className='text-center'>
                            <p className='EPRegistrationBottomText'>
                              {t('employerRegister.bottomTxt1')}
                              {' '}
                              <Link to='/terms-of-use'>
                                {t('employerRegister.bottomTxt2')}
                              </Link>
                              {', '}
                              {t('employerRegister.bottomTxt7')}
                              {' '}
                              <Link to='/offer-agreement'>
                                {t('employerRegister.bottomTxt8')}
                              </Link>
                              {' '}
                              {t('employerRegister.bottomTxt5')}
                              {' '}
                              <Link to='/data-processing-consent'>
                                {t('employerRegister.bottomTxt6')}
                              </Link>
                            </p>
                            <p className='mt-2 float-left'>
                              {t('jobseekerRegister.bottomTxt3')}
                              {' '}
                              <Link to='/user/jobseekerlogin'>
                                {t('jobseekerRegister.bottomTxt4')}
                              </Link>
                            </p>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </>
          )
      }
    </>
  );
};

export default JobseekerRegister;
