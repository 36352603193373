import axios from 'axios';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// api
import BaseApi from '../api/BaseApi';
// components
import Footer from '../element/Footer';
import NavBar from '../element/NavBar';
import JobCard from '../element/JobCard';

const SearchJobPage4 = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('global');
  const GUID = localStorage.getItem('GUID');
  const userId = Cookies.get('user_id') || '';
  const { slug1, slug2, slug3, slug4 } = useParams();

  const encodeKeyword = keyword => encodeURIComponent(keyword);

  const getInitialValue = (slug, prefix) => slug && slug.startsWith(prefix) ? decodeURIComponent(slug.slice(2)) : '';

  const initialRegion = getInitialValue(slug1, 'r-') || getInitialValue(slug2, 'r-') || getInitialValue(slug3, 'r-') || getInitialValue(slug4, 'r-') || ''; // eslint-disable-line
  const initialKeyword = getInitialValue(slug1, 'k-') || getInitialValue(slug2, 'k-') || getInitialValue(slug3, 'k-') || getInitialValue(slug4, 'k-') || ''; // eslint-disable-line
  const initialCategorySlug = getInitialValue(slug1, 'c-') || getInitialValue(slug2, 'c-') || getInitialValue(slug3, 'c-') || getInitialValue(slug4, 'c-') || ''; // eslint-disable-line
  const initialSubCategorySlug = getInitialValue(slug1, 's-') || getInitialValue(slug2, 's-') || getInitialValue(slug3, 's-') || getInitialValue(slug4, 's-') || ''; // eslint-disable-line

  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [searchData, setSearchData] = useState([]);
  const [subCatData, setSubCatData] = useState([]);
  const [positionFrom, setPositionFrom] = useState(0);
  const [categoryList, setCategoryList] = useState([]);
  const [filterItem, setFilterItem] = useState({
    guid: GUID,
    user_id: userId,
    job_city: initialRegion,
    keyword: initialKeyword,
    category_slug: initialCategorySlug,
    subcategory_slug: initialSubCategorySlug,
  });

  const jobsPerPage = 12;

  const indexOfFirstJob = positionFrom + 1;
  const indexOfLastJob = (positionFrom + jobsPerPage) >= totalCount ? totalCount : (positionFrom + jobsPerPage);

  const getCategoryId = categorySlug => {
    const category = categoryList.find(category => category.slug == categorySlug);

    return category ? category.id : '';
  };

  const getCategorySlug = categoryId => {
    const category = categoryList.find(category => category.id == categoryId);

    return category ? category.slug : '';
  };

  const getCategoryName = categorySlug => {
    const category = categoryList.find(category => category.slug == categorySlug);

    return category ? category.name : '';
  };

  const getSubCategoryName = subCategorySlug => {
    const subCategory = subCatData.find(subCategory => subCategory.slug == subCategorySlug);

    return subCategory ? subCategory.name : '';
  };

  const categoryName = initialCategorySlug ? getCategoryName(initialCategorySlug) : '';
  const subCategoryName = initialSubCategorySlug ? getSubCategoryName(initialSubCategorySlug) : '';
  const region = initialRegion ? initialRegion.charAt(0).toUpperCase() + initialRegion.slice(1) : '';

  const handlePageChange = async positionNumber => {
    try {
      setLoading(true);
      setPositionFrom(positionNumber);

      const response = await axios.post(BaseApi + '/job/listing', { ...filterItem, positionFrom: positionNumber });

      setLoading(false);
      setSearchData(response.data.response.jobs);
    } catch (error) {
      setLoading(false);

      console.error('Error fetching list of categories', error);
    }
  };

  const handleChange = e => {
    e.preventDefault();

    const { name, value } = e.target;

    setFilterItem(prevFilter => ({
      ...prevFilter,
      [name]: value,
    }));

    if (name === 'category_slug') {
      fetchJobListingsByCategory(getCategoryId(value));
    }
  };

  const handleClick = e => {
    e.preventDefault();

    const { keyword, job_city, category_slug, subcategory_slug } = filterItem;

    let path = '/searchjob';

    if (keyword) {
      path += `/k-${encodeKeyword(keyword)}`;
    }
    if (category_slug) {
      path += `/c-${category_slug}`;
    }
    if (subcategory_slug) {
      path += `/s-${subcategory_slug}`;
    }
    if (job_city) {
      path += `/r-${encodeKeyword(job_city)}`;
    }

    navigate(path, { replace: true });
  };

  const fetchJobListingsByCategory = async categoryId => {
    setSubCatData([]);
    setFilterItem({ ...filterItem, subcategory_slug: '', category_slug: getCategorySlug(categoryId) });

    try {
      const response = categoryId && await axios.post(
        BaseApi + `/categories/getSubCategory/${categoryId}`,
      );

      setSubCatData(response.data.response);
    } catch (error) {
      console.error('Couldn\'t get sub category data at listing page', error);
    }
  };

  useEffect(() => {
    const categoryId = initialCategorySlug ? getCategoryId(initialCategorySlug) : '';

    if (filterItem.category_slug && categoryList.length > 0) {
      fetchJobListingsByCategory(categoryId);
    }

    setFilterItem(prevFilterItem => ({
      ...prevFilterItem,
      subcategory_slug: initialSubCategorySlug,
    }));
  }, [categoryList.length, initialCategorySlug]); // eslint-disable-line

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);

        const response = await axios.post(BaseApi + '/job/listing', filterItem);

        setLoading(false);
        setSearchData(response.data.response.jobs);
        setCategoryList(response.data.response.category);
        setTotalCount(response.data.response.totalCount);
        setPositionFrom(response.data.response.positionFrom);
      } catch (error) {
        setLoading(false);

        console.error('Error fetching data', error);
      }
    };

    getData();
  }, [initialRegion, initialKeyword, initialCategorySlug, initialSubCategorySlug]); // eslint-disable-line

  useEffect(() => {
    const metaTitle = document.querySelector('title');
    const metaDescription = document.querySelector('meta[name="description"]');

    if (metaTitle && categoryList.length > 0) {
      metaTitle.textContent = (region || categoryName || subCategoryName)
        ? `Робота${categoryName && ` в категорії ${categoryName}`}${subCategoryName && ` за спеціальністю ${subCategoryName}`}${region && ` в місті ${region}`} | GRC.ua` // eslint-disable-line
        : t('searchJobPage.metaTitleValue');
    }

    if (metaDescription && categoryList.length > 0) {
      metaDescription.content = (region || categoryName || subCategoryName)
        ? `Шукаєте роботу? Актуальні вакансії сьогодні на сайті GRC.ua${categoryName && ` в категорії ${categoryName}`}${subCategoryName && ` за спеціальністю ${subCategoryName}`}${region && ` в місті ${region}`}` // eslint-disable-line
        : t('searchJobPage.metaDescriptionValue');
    }

    window.scrollTo(0, 0);
  }, [t, subCatData, categoryList, initialCategorySlug, initialRegion, initialSubCategorySlug]); // eslint-disable-line

  return (
    <>
      <NavBar />
      {
        loading
          ? (
            <div className='loader-container'></div>
          ) : (
            <>
              <div className='SJPSection1'>
                <h1 style={{ display: 'none' }}>
                  {
                    (initialRegion || initialCategorySlug || initialSubCategorySlug)
                      ? `Робота ${initialCategorySlug && `в категорії ${getCategoryName(initialCategorySlug)}`} ${initialSubCategorySlug && `за спеціальністю ${getSubCategoryName(initialSubCategorySlug)}`} ${initialRegion && `в місті ${initialRegion.charAt(0).toUpperCase() + initialRegion.slice(1)}`}` // eslint-disable-line
                      : t('searchJobPage.findJobs')
                  }
                </h1>
                <form>
                  <div className='formItems'>
                    <div className='searchItems me-2'>
                      <input
                        type='text'
                        id='keyword'
                        name='keyword'
                        onChange={handleChange}
                        className='form-control'
                        value={filterItem.keyword}
                        aria-describedby='emailHelp'
                        placeholder={t('searchJobPage.keyword')}
                      />
                    </div>
                    <div className='searchItems me-2'>
                      <select
                        name='category_slug'
                        onChange={handleChange}
                        value={filterItem.category_slug}
                        className='form-select text-muted'
                        aria-label='Default select example'
                      >
                        <option value={''}>{t('searchJobPage.anyCategory')}</option>
                        {
                          categoryList.map(i => (
                            <option key={i.id} value={i.slug}>
                              {i.name}
                            </option>
                          ))
                        }
                      </select>
                    </div>
                    <div className='searchItems me-2'>
                      <select
                        name='subcategory_slug'
                        onChange={handleChange}
                        className='form-select text-muted'
                        aria-label='Default select example'
                        value={filterItem.subcategory_slug}
                      >
                        <option value={''}>{t('searchJobPage.subCategory')}</option>
                        {
                          subCatData.map(i => (
                            <option key={i.id} value={i.slug}>
                              {i.name}
                            </option>
                          ))
                        }
                      </select>
                    </div>
                    <div className='searchItems me-2'>
                      <input
                        type='text'
                        id='job_city'
                        name='job_city'
                        onChange={handleChange}
                        className='form-control'
                        value={filterItem.job_city}
                        aria-describedby='emailHelp'
                        placeholder={t('searchJobPage.enterLocation')}
                      />
                    </div>
                    <button
                      type='submit'
                      onClick={handleClick}
                      style={{ fontSize: '16px' }}
                      className='searchItems btn button1'
                    >
                      {t('searchJobPage.findJobs')}
                    </button>
                  </div>
                </form>
              </div>
              <div className='blogPagination'>
                <p className='text-muted paginationDetail'>
                  {t('pagination.NoofRecords')}
                  {' '}
                  {indexOfFirstJob} - {indexOfLastJob}
                  {' '}{t('pagination.of')}{' '}
                  {totalCount}
                </p>
                <div className='blogPaginationButtons'>
                  <button
                    className='navButton1 me-2'
                    disabled={positionFrom === 0}
                    onClick={() => handlePageChange(indexOfFirstJob - jobsPerPage - 1)}
                  >
                    {t('pagination.Prev')}
                  </button>
                  <button
                    className='navButton1'
                    disabled={indexOfLastJob >= totalCount}
                    onClick={() => handlePageChange(indexOfFirstJob + jobsPerPage - 1)}
                  >
                    {t('pagination.Next')}
                  </button>
                </div>
              </div>
              <div className='SJPSection2 container'>
                <div className='FBitem'>
                  <div className='row'>
                    {
                      searchData.length > 0
                        ? (
                          searchData.map(i => (
                            <div key={i.job_number} className='col-md-6 col-lg-4'>
                              <JobCard
                                logo={i.logo}
                                slug={i.slug}
                                title={i.title}
                                min_exp={i.min_exp}
                                created={i.created}
                                desc={i.description}
                                cat_slug={i.cat_slug}
                                job_city={i.job_city}
                                work_type={i.work_type}
                                salary_max={i.salary_max}
                                salary_min={i.salary_min}
                                company_name={i.company_name}
                                salary_currency={i.salary_currency}
                              />
                            </div>
                          ))
                        ) : (
                          <div className='col-12'>
                            <h3 className='text-center'>
                              {t('searchJobPage.noJobsTxt1')}{' '}
                            </h3>
                            <h6 className='text-muted text-center mb-5 mt-3'>
                              {t('searchJobPage.noJobsTxt2')}
                            </h6>
                          </div>
                        )
                    }
                  </div>
                </div>
              </div>
              <div className='blogPagination'>
                <p className='text-muted paginationDetail'>
                  {t('pagination.NoofRecords')}
                  {' '}
                  {indexOfFirstJob} - {indexOfLastJob}
                  {' '}{t('pagination.of')}{' '}
                  {totalCount}
                </p>
                <div className='blogPaginationButtons'>
                  <button
                    className='navButton1 me-2'
                    disabled={positionFrom === 0}
                    onClick={() => handlePageChange(indexOfFirstJob - jobsPerPage - 1)}
                  >
                    {t('pagination.Prev')}
                  </button>
                  <button
                    className='navButton1'
                    disabled={indexOfLastJob >= totalCount}
                    onClick={() => handlePageChange(indexOfFirstJob + jobsPerPage - 1)}
                  >
                    {t('pagination.Next')}
                  </button>
                </div>
              </div>
              <Footer />
            </>
          )
      }
    </>
  );
};

export default SearchJobPage4;
