import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// components
import Footer from '../element/Footer';
import NavBar from '../element/NavBar';

const ForEmployers = () => {
  const { t } = useTranslation('global');

  useEffect(() => {
    const metaTitle = document.querySelector('title');
    const metaDescription = document.querySelector('meta[name="description"]');

    if (metaTitle) {
      metaTitle.textContent = t('forEmployersPage.metaTitleValue');
    }

    if (metaDescription) {
      metaDescription.content = t('forEmployersPage.metaDescriptionValue');
    }

    window.scrollTo(0, 0);
  }, [t]);

  return (
    <div className='forEmployers'>
      <NavBar />
      <div className='aboutusSection1 text-center'>
        <h1>
          {t('forEmployersPage.pageTitle')}
        </h1>
        <h6 className='text-muted fw-normal'>
          <Link to='/' style={{ color: 'grey' }}>
            {t('navHeaders.home')}
          </Link>
          {' / '}
          {t('navHeaders.forEmployers')}
        </h6>
      </div>
      <div className='container forEmployersSection2'>
        <div className='row pb-5'>
          <div className='col-lg-6 forEmloyersDesc'>
            <div className='row mb-3 mb-md-4 align-items-center'>
              <img
                alt='image-1'
                className='forEmloyersIcon'
                src='/Images/forEmployers/forEmployers-icon1.png'
              />
              <p className='col-8 fw-medium'>
                {t('forEmployersPage.item1')}
              </p>
            </div>
            <div className='row mb-3 mb-md-4 align-items-center'>
              <img
                alt='image-1'
                className='forEmloyersIcon'
                src='/Images/forEmployers/forEmployers-icon2.png'
              />
              <p className='col-8 fw-medium'>
                {t('forEmployersPage.item2')}
              </p>
            </div>
            <div className='row align-items-center'>
              <img
                alt='image-1'
                className='forEmloyersIcon'
                src='/Images/forEmployers/forEmployers-icon3.png'
              />
              <p className='col-9 fw-medium'>
                {t('forEmployersPage.item3')}
              </p>
            </div>
          </div>
          <div className='col-lg-6'>
            <img
              alt='image-1'
              className=''
              src='/Images/forEmployers/forEmployers-image.png'
            />
          </div>
        </div>
        <div className='aboutusDivider'>
          <hr />
        </div>
      </div>
      <div className='container aboutusSection4 text-center'>
        <h2 className='aboutusSec4Header mx-auto' style={{ margin: '5% 0 5% 0' }}>
          {t('forEmployersPage.header1.1')}
          <span className='textGradient'>
            {' '}
            <span className='SubHaddingTxt'>
              {t('forEmployersPage.header1.2')}
            </span>
          </span>
        </h2>
        <div className='cards row justify-content-center'>
          <div className='card col-lg-3 col-sm-12'>
            <div className='card-body p-2'>
              <img
                className='aboutUsCardImage2'
                src='/Images/forEmployers/forEmployers-card1.png'
                alt=''
              />
              <h3 className='pt-3 mb-4' style={{ fontSize: 26 }}>
                <span className='fw-semi-bold'>
                  {t('forEmployersPage.card1.header1.1')}
                </span>
              </h3>
              <div className='text-start'>
                <p className='mb-3'>{t('forEmployersPage.card1.desc1')}</p>
                <p>{t('forEmployersPage.card1.desc2')}</p>
                <p>{t('forEmployersPage.card1.desc3')}</p>
                <p>{t('forEmployersPage.card1.desc4')}</p>
                <p>{t('forEmployersPage.card1.desc5')}</p>
              </div>
            </div>
          </div>
          <div className='card col-lg-3 col-sm-12'>
            <div className='card-body py-2 px-3'>
              <img
                className='aboutUsCardImage2'
                src='/Images/forEmployers/forEmployers-card2.png'
                alt=''
              />
              <h3 className='pt-3' style={{ fontSize: 26 }}>
                <span className='fw-semi-bold'>
                  {t('forEmployersPage.card2.header1.1')}
                </span>
              </h3>
              <div className='text-start'>
                <p className='mb-3'>{t('forEmployersPage.card2.desc1')}</p>
                <p className='mb-3'>{t('forEmployersPage.card2.desc2')}</p>
                <p>{t('forEmployersPage.card2.desc3')}</p>
                <p>{t('forEmployersPage.card2.desc4')}</p>
                <p>{t('forEmployersPage.card2.desc5')}</p>
                <p>{t('forEmployersPage.card2.desc6')}</p>
                <p>{t('forEmployersPage.card2.desc7')}</p>
                <p>{t('forEmployersPage.card2.desc8')}</p>
              </div>
            </div>
          </div>
          <div className='card col-lg-3 col-sm-12'>
            <div className='card-body p-2'>
              <img
                className='aboutUsCardImage2'
                src='/Images/forEmployers/forEmployers-card3.png'
                alt=''
              />
              <h3 className='pt-3 pb-0' style={{ fontSize: 26 }}>
                <span className='fw-semi-bold'>
                  {t('forEmployersPage.card3.header1.1')}
                </span>
              </h3>
              <h3 className='mb-4' style={{ fontSize: 26 }}>
                <span className='fw-semi-bold'>
                  {t('forEmployersPage.card3.header1.2')}
                </span>
              </h3>
              <div className='text-start'>
                <p>{t('forEmployersPage.card3.desc1')}</p>
                <p className='mb-3'>{t('forEmployersPage.card3.desc2')}</p>
                <p>{t('forEmployersPage.card3.desc3')}</p>
                <p className='mb-3'>{t('forEmployersPage.card3.desc4')}</p>
                <p className='mb-3'>{t('forEmployersPage.card3.desc5')}</p>
                <p>{t('forEmployersPage.card3.desc6')}</p>
                <p>{t('forEmployersPage.card3.desc7')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ForEmployers;
