import axios from 'axios';
import { useRef } from 'react';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import Select from 'react-select';
import JoditEditor from 'jodit-react';
import AsyncSelect from 'react-select/async';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// api
import ApiKey from '../api/ApiKey';
import BaseApi from '../api/BaseApi';
import { getCities } from '../employersSide/api/getCities';
import { getSubCategories } from '../employersSide/api/getSubCategories';
// components
import JSSidebar from './JSSidebar';
import NavBar from '../element/NavBar';
import Footer from '../element/Footer';
// helpers
import { editorConfig, logOutIfTokenExpired, resultInfo, scrollToElement, selectStyles } from '../helpers';

const educationLevels = [
  { name: 'Доктор наук', value: 5 },
  { name: 'Кандидат наук', value: 4 },
  { name: 'Магістр', value: 3 },
  { name: 'Бакалавр', value: 2 },
  { name: 'Вища', value: 1 },
  { name: 'Незакінчена вища', value: 6 },
  { name: 'Середня спеціальна', value: 8 },
  { name: 'Середня', value: 7 },
];

const skillList = [
  { id: 1, name: 'Все вмію' },
  { id: 2, name: 'Все можу' },
];

const langs = [
  {
    'id': 74,
    'name': 'Абазинська',
    'slug': 'abq',
  },
  {
    'id': 74,
    'name': 'Абазинська',
    'slug': 'abq',
  },
  {
    'id': 176,
    'name': 'Аварська',
    'slug': 'av',
  },
];

const lang_skills = [
  {
    'id': 6,
    'name': 'A1 (початковий) або вище',
    'slug': 'A1',
  },
  {
    'id': 7,
    'name': 'A2 (елементарний) або вище',
    'slug': 'A2',
  },
  {
    'id': 8,
    'name': 'B1 (середній) або вище',
    'slug': 'B1',
  },
  {
    'id': 9,
    'name': 'B2 (вищий середній) або вище',
    'slug': 'B2',
  },
  {
    'id': 10,
    'name': 'C1 (просунутий) або вище',
    'slug': 'C1',
  },
  {
    'id': 11,
    'name': 'C2 (доскональний) або вище',
    'slug': 'C2',
  },
  {
    'id': 12,
    'name': 'L1 рідна',
    'slug': 'L1',
  },
];

const socials = [
  'LinkedIn',
  'Facebook',
  'Twitter',
  'Instagram',
  'GitHub',
  'TikTok',
];

const messengers = [
  'Telegram',
  'WhatsApp',
  'Viber',
  'Skype',
  'Signal',
];

const JSEditProfile = () => {
  const editor = useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation('global');
  const tokenKey = Cookies.get('tokenClient');

  const months = [
    { id: 1, name: t('jobseekerExperience.january') },
    { id: 2, name: t('jobseekerExperience.february') },
    { id: 3, name: t('jobseekerExperience.march') },
    { id: 4, name: t('jobseekerExperience.april') },
    { id: 5, name: t('jobseekerExperience.may') },
    { id: 6, name: t('jobseekerExperience.june') },
    { id: 7, name: t('jobseekerExperience.july') },
    { id: 8, name: t('jobseekerExperience.august') },
    { id: 9, name: t('jobseekerExperience.september') },
    { id: 10, name: t('jobseekerExperience.october') },
    { id: 11, name: t('jobseekerExperience.november') },
    { id: 12, name: t('jobseekerExperience.december') },
  ];

  const [loading, setLoading] = useState(false);
  const [directories, setDirectories] = useState({});
  const [profileBody, setProfileBody] = useState({});
  const [subCategories, setSubCategories] = useState([]);
  const [selectedRelocations, setSelectedRelocations] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  console.log('profileBody', profileBody);

  const [selectedCity, setSelectedCity] = useState({
    label: profileBody.city_region || t('jobseekerEditProfile.selectCity'),
  });
  const [selectedDesiredPosition, setSelectedDesiredPosition] = useState({
    label: profileBody.position || t('jobseekerEditProfile.selectPosition'),
  });

  const {
    yearList = [],
    worktype = [],
    countries = [],
    categories = [],
    currencies = [],
    industries = [],
  } = directories;

  const {
    gender = '',
    skills = [],
    country = '',
    city_id = '',
    contact = '',
    category = '',
    birthday = '',
    position = '',
    languages = [],
    last_name = '',
    work_type = '',
    educations = [],
    first_name = '',
    salary_max = '',
    salary_min = '',
    experiences = [],
    middle_name = '',
    relocations = [],
    social_links = [],
    sub_category = [],
    email_address = '',
    profile_image = '',
    video_cv_link = '',
    contact_number = '',
    messenger_links = [],
    salary_currency = '',
    contact_number_2 = '',
    contact_number_3 = '',
    additional_educations = [],
  } = profileBody;

  const [errors, setErrors] = useState({
    first_name: '',
    last_name: '',
    gender: '',
    birthday: '',
    country: '',
    city_id: '',
    email_address: '',
    contact_number: '',
    contact_number_2: '',
    contact_number_3: '',
    social_links: '',
    video_cv_link: '',
    position: '',
    category: '',
    sub_category: '',
    relocations: '',
    salary_max: '',
    salary_min: '',
    salary_currency: '',
    work_type: '',
    experiences: [],
    educations: [],
  });

  const getDefaultIndustryName = id => {
    for (let i = 0; i < industries.length; i++) {
      if (industries[i].id == id) {
        return { label: industries[i].name, value: industries[i].id };
      }
    }
  };

  const getData = async () => {
    try {
      setLoading(true);

      const response = await axios.post(
        BaseApi + '/candidates/editProfile',
        null,
        {
          headers: {
            key: ApiKey,
            token: tokenKey,
            'Content-Type': 'application/json',
          },
        },
      );

      setLoading(false);

      if (response.data.status === 200) {
        const data = response.data.response;
        const userDetail = { ...data.userdetail, country: data.userdetail.country ?? 5 };

        if (!userDetail.social_links || userDetail.social_links.length === 0) {
          userDetail.social_links = [{
            link: '',
            type: '',
          }];
        }

        if (!userDetail.messenger_links || userDetail.messenger_links.length === 0) {
          userDetail.messenger_links = [{
            link: '',
            type: '',
          }];
        }

        if (!userDetail.experiences || userDetail.experiences.length === 0) {
          userDetail.experiences = [{
            position: '',
            industry_id: [],
            to_year_exp: '',
            achievements: '',
            organization: '',
            still_working: 0,
            to_month_exp: '',
            from_year_exp: '',
            from_month_exp: '',
          }];
        }

        if (!userDetail.educations || userDetail.educations.length === 0) {
          userDetail.educations = [{
            faculty: '',
            edu_type: '',
            specialty: '',
            institution: '',
            to_year_edu: '',
            to_month_edu: '',
            from_year_edu: '',
            still_learning: 0,
            from_month_edu: '',
          }];
        }

        if (!userDetail.languages || userDetail.languages.length === 0) {
          userDetail.languages = [{
            lng: '',
            lng_skill: '',
          }];
        }

        if (!userDetail.additional_educations || userDetail.additional_educations.length === 0) {
          userDetail.additional_educations = [{
            certificate: '',
            course_link: '',
            course_name: '',
          }];
        }

        setDirectories(data);
        setProfileBody(userDetail);
      } else if (response.data.status === 400) {
        navigate('/');

        Cookies.remove('fname');
        Cookies.remove('user_id');
        Cookies.remove('user_type');
        Cookies.remove('tokenClient');

        resultInfo('warning', response.data.message, t('jobseekerEditProfile.close'));
      } else {
        resultInfo('error', response.data.message, t('jobseekerEditProfile.close'));
      }
    } catch (error) {
      setLoading(false);

      if (
        error.message === 'Network Error' || (error.response && error.response.data.message === 'Token has expired')
      ) {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      }

      console.error(error);
    }
  };

  const getCitiesOptions = async inputValue => {
    if (!country || inputValue.length < 2) {
      return [];
    }

    try {
      const cities = await getCities(parseInt(country), inputValue);

      const options = cities.data.response.map(city => ({
        value: city.id,
        label: `${city.name}${city.region && `, ${city.region}`}`,
      }));

      return options;
    } catch (error) {
      console.error('Error fetching cities:', error);
      return [];
    }
  };

  const getPositionOptions = async inputValue => {
    if (inputValue.length < 2) {
      return [];
    }

    try {
      const positions = await axios.post(
        BaseApi + '/candidates/getPositions',
        { query: inputValue },
        {
          headers: {
            key: ApiKey,
            token: tokenKey,
            'Content-Type': 'application/json',
          },
        },
      );

      const options = positions.data.response.map(position => ({
        value: position.id,
        label: position.name,
      }));

      return options;
    } catch (error) {
      console.error('Error fetching cities:', error);
      return [];
    }
  };

  const getSubCategoriesOptions = async id => {
    setSelectedSubCategories([]);
    setProfileBody((prevState => ({
      ...prevState,
      sub_category: [],
    })));

    try {
      const response = await getSubCategories(id);
      const data = response.data.response;

      setSubCategories(data);
    } catch (error) {
      console.error('Couldn\'t get Sub category data!', error);
    }
  };

  const handleChange = (e, index) => {
    const { name, value, type, checked } = e.target;

    setProfileBody(prevProfileBody => {
      const updatedProfileBody = { ...prevProfileBody };

      switch (name) {
        case 'gender':
          updatedProfileBody.gender = value === 'female' ? '0' : '1';
          break;
        case 'organization':
        case 'achievements':
        case 'from_month_exp':
        case 'from_year_exp':
        case 'to_month_exp':
        case 'to_year_exp':
          updatedProfileBody.experiences[index] = {
            ...updatedProfileBody.experiences[index],
            [name]: value,
          };

          if ((name === 'to_month_exp' || name === 'to_year_exp') && updatedProfileBody.experiences[index].still_working) {
            updatedProfileBody.experiences[index].still_working = 0;
          }
          break;
        case 'still_working':
          updatedProfileBody.experiences[index] = {
            ...updatedProfileBody.experiences[index],
            still_working: type === 'checkbox' ? checked : value,
          };

          if (name === 'still_working' && checked) {
            updatedProfileBody.experiences[index].to_year_exp = '';
            updatedProfileBody.experiences[index].to_month_exp = '';
          }
          break;
        case 'edu_type':
        case 'from_month_edu':
        case 'from_year_edu':
        case 'to_month_edu':
        case 'to_year_edu':
          updatedProfileBody.educations[index] = {
            ...updatedProfileBody.educations[index],
            [name]: value,
          };

          if ((name === 'to_month_edu' || name === 'to_year_edu') && updatedProfileBody.educations[index].still_learning) {
            updatedProfileBody.educations[index].still_learning = 0;
          }
          break;
        case 'still_learning':
          updatedProfileBody.educations[index] = {
            ...updatedProfileBody.educations[index],
            still_learning: type === 'checkbox' ? checked : value,
          };

          if (name === 'still_learning' && checked) {
            updatedProfileBody.educations[index].to_year_edu = '';
            updatedProfileBody.educations[index].to_month_edu = '';
          }
          break;
        case 'course_name':
        case 'course_link':
          updatedProfileBody.additional_educations[index] = {
            ...updatedProfileBody.additional_educations[index],
            [name]: value,
          };
          break;
        case 'lng':
        case 'lng_skill':
          updatedProfileBody.languages[index] = {
            ...updatedProfileBody.languages[index],
            [name]: value,
          };
          break;
        default:
          updatedProfileBody[name] = value;
      }

      return updatedProfileBody;
    });

    setErrors(prevErrors => {
      const updatedErrors = { ...prevErrors };

      if (
        name === 'organization' || name === 'achievements' || name === 'from_month_exp' || name === 'from_year_exp' ||
        name === 'to_month_exp' || name === 'to_year_exp' || name === 'still_working'
      ) {
        updatedErrors.experiences[index] = {
          ...updatedErrors.experiences[index],
          [name]: '',
        };
      } else {
        updatedErrors[name] = '';
      }

      return updatedErrors;
    });
  };

  const handleCityChange = selectedOption => {
    setSelectedCity(selectedOption);

    setProfileBody(prevState => ({
      ...prevState,
      city_id: selectedOption ? selectedOption.value : '',
    }));

    setErrors(prev => ({
      ...prev,
      city_id: '',
    }));
  };

  const handleMessengerChange = (index, field, value) => {
    const newMessengerLinks = [...messenger_links];

    newMessengerLinks[index][field] = value;

    setProfileBody({
      ...profileBody,
      messenger_links: newMessengerLinks,
    });

    setErrors(prev => ({
      ...prev,
      messenger_links: '',
    }));
  };

  const handleSocialChange = (index, field, value) => {
    const newSocialLinks = [...social_links];

    newSocialLinks[index][field] = value;

    setProfileBody({
      ...profileBody,
      social_links: newSocialLinks,
    });

    setErrors(prev => ({
      ...prev,
      social_links: '',
    }));
  };

  const handleDesiredPositionChange = selectedOption => {
    setSelectedDesiredPosition(selectedOption);

    setProfileBody(prevState => ({
      ...prevState,
      position: selectedOption ? selectedOption.value : '',
    }));

    setErrors(prev => ({
      ...prev,
      position: '',
    }));
  };

  const handleSubCategory = selectedOption => {
    if (selectedOption.length > 2) {
      setErrors(prev => ({
        ...prev,
        sub_category: t('jobseekerEditProfile.subCategoryLimit'),
      }));

      return;
    }

    setSelectedSubCategories(selectedOption);

    const ids = selectedOption.map(item => item.value);

    setProfileBody(prevJobBody => ({
      ...prevJobBody,
      sub_category: ids,
    }));

    setErrors(prev => ({
      ...prev,
      sub_category: '',
    }));
  };

  const handleRelocationsChange = selectedOptions => {
    if (selectedOptions.length > 5) {
      setErrors(prev => ({
        ...prev,
        relocations: t('jobseekerEditProfile.relocationsLimit'),
      }));

      return;
    }

    setSelectedRelocations(selectedOptions);

    const ids = selectedOptions.map(item => item.value);

    setProfileBody(prevState => ({
      ...prevState,
      relocations: ids,
    }));

    setErrors(prev => ({
      ...prev,
      relocations: '',
    }));
  };

  const handleSkillsChange = selectedOptions => {
    if (selectedOptions.length > 5) {
      setErrors(prev => ({
        ...prev,
        skills: t('jobseekerEditProfile.relocationsLimit'),
      }));

      return;
    }

    setProfileBody(prevState => ({
      ...prevState,
      skills: selectedOptions,
    }));

    setErrors(prev => ({
      ...prev,
      relocations: '',
    }));
  };

  const handleAddExperience = () => {
    const newExperience = {
      position: '',
      industry_id: [],
      to_year_exp: '',
      achievements: '',
      organization: '',
      still_working: 0,
      to_month_exp: '',
      from_year_exp: '',
      from_month_exp: '',
    };

    setProfileBody(prevState => ({
      ...prevState,
      experiences: [...prevState.experiences, newExperience],
    }));
  };

  const handlePositionChange = (selectedOption, index) => {
    setProfileBody(prevState => {
      const newExperiences = [...prevState.experiences];
      newExperiences[index].position = selectedOption;

      return {
        ...prevState,
        experiences: newExperiences,
      };
    });

    // setErrors(prev => {
    //   const newErrors = { ...prev };
    //   newErrors.experiences[index].position = '';
    //   return newErrors;
    // });
  };

  const handleIndustryChange = (selectedOptions, index) => {
    if (selectedOptions.length > 5) {
      setErrors(prev => ({
        ...prev,
        experiences: prev.experiences.map((error, i) =>
          i === index ? { ...error, industry_id: t('jobseekerEditProfile.industryLimit') } : error,
        ),
      }));

      return;
    }

    const ids = selectedOptions.map(item => item.value);

    setProfileBody(prevState => {
      const newExperiences = [...prevState.experiences];
      newExperiences[index].industry_id = ids;

      return {
        ...prevState,
        experiences: newExperiences,
      };
    });

    setErrors(prev => {
      const newErrors = { ...prev };
      newErrors.experiences[index].industry_id = '';
      return newErrors;
    });
  };

  const handleRemoveExperience = index => {
    setProfileBody(prevState => ({
      ...prevState,
      experiences: prevState.experiences.filter((_, i) => i !== index),
    }));
  };

  const handleAddEducation = () => {
    const newEducation = {
      faculty: '',
      edu_type: '',
      specialty: '',
      institution: '',
      to_year_edu: '',
      to_month_edu: '',
      from_year_edu: '',
      still_learning: 0,
      from_month_edu: '',
    };

    setProfileBody(prevState => ({
      ...prevState,
      educations: [...prevState.educations, newEducation],
    }));
  };

  const handleInstitutionChange = (selectedOption, index) => {
    setProfileBody(prevState => {
      const newEducations = [...prevState.educations];
      newEducations[index].institution = selectedOption;

      return {
        ...prevState,
        educations: newEducations,
      };
    });

    // setErrors(prev => {
    //   const newErrors = { ...prev };
    //   newErrors.experiences[index].position = '';
    //   return newErrors;
    // });
  };

  const handleRemoveEducation = index => {
    setProfileBody(prevState => ({
      ...prevState,
      educations: prevState.educations.filter((_, i) => i !== index),
    }));
  };

  const handleAddLanguage = () => {
    const newLanguage = {
      lng: '',
      lng_skill: '',
    };

    setProfileBody(prevState => ({
      ...prevState,
      languages: [...prevState.languages, newLanguage],
    }));
  };

  const handleRemoveLanguage = index => {
    const newLanguage = languages.filter((_, i) => i !== index);

    setProfileBody({
      ...profileBody,
      languages: newLanguage,
    });
  };

  const handleAddAdditionalEducation = () => {
    const newAdditionalEducation = {
      certificate: '',
      course_link: '',
      course_name: '',
    };

    setProfileBody(prevState => ({
      ...prevState,
      additional_educations: [...prevState.additional_educations, newAdditionalEducation],
    }));
  };

  const handleRemoveAdditionalEducation = index => {
    setProfileBody(prevState => ({
      ...prevState,
      additional_educations: prevState.additional_educations.filter((_, i) => i !== index),
    }));
  };

  const handleAddMessengerLink = () => {
    const newMessengerLink = {
      link: '',
      type: '',
    };

    setProfileBody(prevState => ({
      ...prevState,
      messenger_links: [...prevState.messenger_links, newMessengerLink],
    }));
  };

  const handleRemoveMessengerLink = index => {
    const newMessengerLinks = messenger_links.filter((_, i) => i !== index);

    setProfileBody({
      ...profileBody,
      messenger_links: newMessengerLinks,
    });
  };

  const handleAddSocialLink = () => {
    const newSocialLink = {
      link: '',
      type: '',
    };

    setProfileBody(prevState => ({
      ...prevState,
      social_links: [...prevState.social_links, newSocialLink],
    }));
  };

  const handleRemoveSocialLink = index => {
    const newSocialLinks = social_links.filter((_, i) => i !== index);

    setProfileBody({
      ...profileBody,
      social_links: newSocialLinks,
    });
  };

  const handleClick = async () => {
    var interest_categories = document.getElementsByName('interest_categories');

    var categoryArray = [];

    interest_categories.forEach(element => {
      categoryArray.push(element.value);
    });

    try {
      const newErrors = {};
      const salaryFormat = /^\d*\.?\d+$/;
      const contactNumberFormat = /^\+?3?8?(0\d{9})$/;
      const urlFormat = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
      const emailAddressFormat = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

      if (!first_name) {
        newErrors.first_name = t('jobseekerEditProfile.firstNameRequired');
        scrollToElement('first_name');
      }
      if (!last_name) {
        newErrors.last_name = t('jobseekerEditProfile.lastNameRequired');
        scrollToElement('last_name');
      }
      if (!gender) {
        newErrors.gender = t('jobseekerEditProfile.genderRequired');
        scrollToElement('gender');
      }
      if (!birthday) {
        newErrors.birthday = t('jobseekerEditProfile.birthdayRequired');
        scrollToElement('birthday');
      }
      if (!country) {
        newErrors.country = t('jobseekerEditProfile.countryRequired');
        scrollToElement('country');
      }
      if (!city_id) {
        newErrors.city_id = t('jobseekerEditProfile.cityRequired');
        scrollToElement('city_id');
      }
      if (!contact) {
        newErrors.contact = t('jobseekerEditProfile.contactNumberRequired');
        scrollToElement('contact');
      } else if (!contactNumberFormat.test(contact)) {
        newErrors.contact = t('jobseekerEditProfile.contactNumbervalidation');
        scrollToElement('contact');
      }
      if (!email_address) {
        newErrors.email_address = t('jobseekerEditProfile.emailAddressRequired');
        scrollToElement('email_address');
      } else if (!emailAddressFormat.test(email_address)) {
        newErrors.email_address = t('jobseekerEditProfile.emailAddressFormat');
        scrollToElement('email_address');
      }
      if (video_cv_link && !urlFormat.test(video_cv_link.trim())) {
        newErrors.video_cv_link = t('jobseekerEditProfile.urlInvalid');
        scrollToElement('video_cv_link');
      }
      if (!position) {
        newErrors.position = t('jobseekerEditProfile.positionRequired');
        scrollToElement('position');
      }
      if (!category) {
        newErrors.category = t('jobseekerEditProfile.categoryRequired');
        scrollToElement('category');
      }
      if (sub_category.length === 0) {
        newErrors.sub_category = t('jobseekerEditProfile.subCategoryRequired');
        scrollToElement('sub_category');
      }
      if (relocations.length === 0) {
        newErrors.relocations = t('jobseekerEditProfile.relocationsRequired');
        scrollToElement('relocations');
      }
      if (salary_min && !salaryFormat.test(salary_min)) {
        newErrors.salary_min = t('jobseekerEditProfile.salaryNumeric');
        scrollToElement('salary_min');
      }
      if (salary_max) {
        if (!salaryFormat.test(salary_max)) {
          newErrors.salary_max = t('jobseekerEditProfile.salaryNumeric');
          scrollToElement('salary_max');
        }
        if (parseFloat(salary_max) < parseFloat(salary_min)) {
          newErrors.salary_max = t('jobseekerEditProfile.salaryMaxLowerSalaryMin');
          scrollToElement('salary_max');
        }
      }
      if (!salary_currency && (salary_min || salary_max)) {
        newErrors.salary_currency = t('jobseekerEditProfile.salaryCurrencyRequired');
        scrollToElement('salary_currency');
      }

      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0) {
        const confirmationResult = await Swal.fire({
          icon: 'question',
          showCancelButton: true,
          cancelButtonText: t('jobseekerEditProfile.no'),
          text: t('jobseekerEditProfile.editConfirmTxt'),
          confirmButtonText: t('jobseekerEditProfile.yes'),
          title: t('jobseekerEditProfile.editConfirmTitle'),
        });

        if (confirmationResult.isConfirmed) {
          const userdetail = {
            first_name,
            last_name,
            middle_name,
            gender,
            birthday,
            country,
            city_id,
            contact,
            email_address,
            video_cv_link,
            position,
            category,
            sub_category,
            relocations,
            salary_max,
            salary_min,
            salary_currency,
            work_type,
          };

          const response = await axios.post(
            BaseApi + '/candidates/editProfile',
            userdetail,
            {
              headers: {
                key: ApiKey,
                token: tokenKey,
                'Content-Type': 'application/json',
              },
            },
          );

          setLoading(false);

          if (response.data.status === 200) {
            navigate('/candidates/myaccount');

            resultInfo('success', t('jobseekerEditProfile.editSuccessTitle'), t('jobseekerEditProfile.close'));
          } else if (response.data.status === 400) {
            navigate('/');

            Cookies.remove('fname');
            Cookies.remove('user_id');
            Cookies.remove('user_type');
            Cookies.remove('tokenClient');

            resultInfo('warning', response.data.message, t('jobseekerEditProfile.close'));
          } else {
            resultInfo('error', response.data.message, t('jobseekerEditProfile.close'));
          }
        }
      }
    } catch (error) {
      setLoading(false);

      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      } else {
        resultInfo('error', t('jobseekerEditProfile.editFailedTitle'), t('jobseekerEditProfile.close'));
      }

      console.error('Could not submit edit data!', error);
    }
  };

  useEffect(() => {
    if (!tokenKey) {
      navigate('/user/jobseekerlogin');
    } else {
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]); // eslint-disable-line

  return (
    <>
      <NavBar />
      <div className='container editProfile'>
        <div className='row'>
          <div className='col-lg-3'>
            <JSSidebar />
          </div>
          {
            loading
              ? (
                <div className='loader-container'></div>
              ) : (
                <div
                  className='col-lg-9 mb-5'
                  style={{
                    borderLeft: '2px solid #e6e8e7',
                    borderRight: '2px solid #e6e8e7',
                  }}
                >
                  <div className='mx-3 d-flex PageHeader'>
                    <img src='/Images/employerSide/icon8color.png' alt='' />
                    <h3>{t('jobseekerEditProfile.editProfile')}</h3>
                  </div>
                  <div className='JSEPFirstSegment'>
                    <div className='profileImageBox'>
                      <img
                        alt='company logo'
                        className='profileImage'
                        src={profile_image ? profile_image : '/Images/jobseekerSide/dummy-profile.png'}
                      />
                    </div>
                    <Link
                      to='/candidates/uploadPhoto'
                      className='btn btn-primary button1'
                    >
                      {t('jobseekerEditProfile.changePhotoButton')}
                    </Link>
                  </div>
                  {
                    profileBody && (
                      <form>
                        <h3 className='ms-4'>{t('jobseekerEditProfile.personalInformation')}</h3>
                        <div className='mb-5 mt-4 mx-4'>
                          <div className='form-outline mb-4 DashBoardInputBx'>
                            <label id='first_name' className='form-label' htmlFor='first_name'>
                              {t('jobseekerEditProfile.firstName')}
                              <span className='RedStar'>*</span>
                            </label>
                            <input
                              type='text'
                              name='first_name'
                              value={first_name}
                              onChange={handleChange}
                              placeholder={t('jobseekerEditProfile.firstName')}
                              className={`form-control ${errors.first_name && 'input-error'}`}
                            />
                            {
                              errors.first_name && (
                                <div className='text-danger'>{errors.first_name}</div>
                              )
                            }
                          </div>
                          <div className='form-outline mb-4 DashBoardInputBx'>
                            <label id='last_name' className='form-label' htmlFor='last_name'>
                              {t('jobseekerEditProfile.lastName')}
                              <span className='RedStar'>*</span>
                            </label>
                            <input
                              type='text'
                              name='last_name'
                              value={last_name}
                              onChange={handleChange}
                              placeholder={t('jobseekerEditProfile.lastName')}
                              className={`form-control ${errors.last_name && 'input-error'}`}
                            />
                            {
                              errors.last_name && (
                                <div className='text-danger'>{errors.last_name}</div>
                              )
                            }
                          </div>
                          <div className='form-outline mb-4 DashBoardInputBx'>
                            <label id='middle_name' className='form-label' htmlFor='middle_name'>
                              {t('jobseekerEditProfile.middleName')}
                            </label>
                            <input
                              type='text'
                              name='middle_name'
                              value={middle_name}
                              onChange={handleChange}
                              placeholder={t('jobseekerEditProfile.middleName')}
                              className={`form-control ${errors.middle_name && 'input-error'}`}
                            />
                            {
                              errors.middle_name && (
                                <div className='text-danger'>{errors.middle_name}</div>
                              )
                            }
                          </div>
                          <div className='mb-4'>
                            <div
                              className={`${errors.gender && 'input-error'} radioInput form-outline DashBoardInputBx`}
                            >
                              <label id='gender' className='form-label' htmlFor='form3Example3'>
                                {t('jobseekerEditProfile.gender')}
                                <span className='RedStar'>*</span>
                              </label>
                              <div>
                                <label className='me-4' htmlFor='female'>
                                  <input
                                    id='female'
                                    type='radio'
                                    name='gender'
                                    value='female'
                                    onChange={handleChange}
                                    checked={gender === '0'}
                                  />
                                  {t('jobseekerEditProfile.female')}
                                </label>
                                <label htmlFor='male'>
                                  <input
                                    id='male'
                                    type='radio'
                                    value='male'
                                    name='gender'
                                    onChange={handleChange}
                                    checked={gender === '1'}
                                  />
                                  {t('jobseekerEditProfile.male')}
                                </label>
                              </div>
                            </div>
                            {
                              errors.gender && (
                                <div className='text-danger'>{errors.gender}</div>
                              )
                            }
                          </div>
                          <div className='form-outline mb-4 DashBoardInputBx'>
                            <label id='birthday' className='form-label' htmlFor='birthday'>
                              {t('jobseekerEditProfile.birthday')}
                              <span className='RedStar'>*</span>
                            </label>
                            <input
                              type='date'
                              name='birthday'
                              value={birthday}
                              onChange={handleChange}
                              placeholder={t('jobseekerEditProfile.birthday')}
                              className={`form-control ${errors.birthday && 'input-error'}`}
                            />
                            {
                              errors.birthday && (
                                <div className='text-danger'>{errors.birthday}</div>
                              )
                            }
                          </div>
                          <div className='form-outline mb-4 DashBoardInputBx DashBoardCreatBx'>
                            <label id='country' className='form-label' htmlFor='country'>
                              {t('jobseekerEditProfile.country')}
                              <span className='RedStar'>*</span>
                            </label>
                            <select
                              name='country'
                              value={country}
                              onChange={e => handleChange(e)}
                              aria-label='Default select example'
                              className={`form-select ${errors.country && 'input-error'}`}
                            >
                              <option value=''>
                                {t('jobseekerEditProfile.selectCountry')}
                              </option>
                              {
                                countries.map((i, index) => (
                                  <option value={i.id} key={index}>
                                    {i.name}
                                  </option>
                                ))
                              }
                            </select>
                            {
                              errors.country && (
                                <div className='text-danger'>{errors.country}</div>
                              )
                            }
                          </div>
                          {
                            country && (
                              <div
                                className='form-outline mb-5 DashBoardInputBx DashBoardCreatBx'
                              >
                                <label id='city_id' className='form-label' htmlFor='city_id'>
                                  {t('jobseekerEditProfile.city')}
                                  <span className='RedStar'>*</span>
                                </label>
                                <AsyncSelect
                                  defaultOptions
                                  value={selectedCity}
                                  styles={selectStyles}
                                  onChange={handleCityChange}
                                  loadOptions={getCitiesOptions}
                                  placeholder={t('jobseekerEditProfile.selectCity')}
                                  className={`${errors.city_id && 'input-error-react-select'}`}
                                  noOptionsMessage={() => t('jobseekerEditProfile.enterCityName')}
                                />
                                {
                                  errors.city_id && (
                                    <div className='text-danger'>{errors.city_id}</div>
                                  )
                                }
                              </div>
                            )
                          }
                          <h3 className='mb-4'>{t('jobseekerEditProfile.contactInformation')}</h3>
                          <div className='form-outline mb-4 DashBoardInputBx'>
                            <label id='email_address' className='form-label' htmlFor='email_address'>
                              {t('jobseekerEditProfile.emailAddress')}
                              <span className='RedStar'>*</span>
                            </label>
                            <input
                              disabled
                              type='text'
                              name='email_address'
                              value={email_address}
                              onChange={handleChange}
                              placeholder={t('jobseekerEditProfile.emailAddress')}
                              className={`form-control ${errors.email_address && 'input-error'}`}
                            />
                            {
                              errors.email_address && (
                                <div className='text-danger'>{errors.email_address}</div>
                              )
                            }
                          </div>
                          <div className='row mb-4'>
                            <div className='col-lg-4 form-outline mb-4 mb-lg-0 DashBoardInputBx DashBoardCreatBx'>
                              <label id='contact_number' className='form-label' htmlFor='contact_number'>
                                {t('jobseekerEditProfile.contactNumber')}
                                <span className='RedStar'>*</span>
                              </label>
                              <input
                                type='text'
                                name='contact_number'
                                value={contact_number}
                                onChange={handleChange}
                                placeholder={t('jobseekerEditProfile.contactNumber')}
                                className={`form-control ${errors.contact_number && 'input-error'}`}
                              />
                            </div>
                            <div className='col-lg-4 form-outline mb-4 mb-lg-0 DashBoardInputBx DashBoardCreatBx'>
                              <label id='contact_number_2' className='form-label' htmlFor='contact_number_2'>
                                {t('jobseekerEditProfile.contactNumber')}
                              </label>
                              <input
                                type='text'
                                name='contact_number_2'
                                onChange={handleChange}
                                value={contact_number_2}
                                placeholder={t('jobseekerEditProfile.contactNumber')}
                                className={`form-control ${errors.contact_number_2 && 'input-error'}`}
                              />
                            </div>
                            <div className='col-lg-4 form-outline DashBoardInputBx DashBoardCreatBx'>
                              <label id='contact_number_3' className='form-label' htmlFor='contact_number_3'>
                                {t('jobseekerEditProfile.contactNumber')}
                              </label>
                              <input
                                type='text'
                                name='contact_number_3'
                                onChange={handleChange}
                                value={contact_number_3}
                                placeholder={t('jobseekerEditProfile.contactNumber')}
                                className={`form-control ${errors.contact_number_3 && 'input-error'}`}
                              />
                            </div>
                            {
                              (errors.contact_number || errors.contact_number_2 || errors.contact_number_3) && (
                                <div className='text-danger'>
                                  {errors.contact_number || errors.contact_number_2 || errors.contact_number_3}
                                </div>
                              )
                            }
                            <div id='emailHelp' className='form-text'>
                              Eg: 0671234567 or +380671234567
                            </div>
                          </div>
                          <div className='mt-4 mb-4'>
                            {
                              messenger_links.map((i, index) => {
                                const selectedMessengers = messenger_links.map(link => link.type);
                                const availableMessengers = messengers.filter(
                                  social => !selectedMessengers.includes(social) || social === i.type,
                                );

                                return (
                                  <div className='row' key={index}>
                                    <div className='col-lg-3 mb-4 form-outline DashBoardInputBx DashBoardCreatBx'>
                                      <label id='messenger_type' className='form-label' htmlFor='messenger_type'>
                                        {t('jobseekerEditProfile.messenger')}
                                      </label>
                                      <select
                                        value={i.type}
                                        name='messenger_type'
                                        className='form-select'
                                        aria-label='Default select example'
                                        onChange={e => handleMessengerChange(index, 'type', e.target.value)}
                                      >
                                        <option value=''>
                                          {t('jobseekerEditProfile.messenger')}
                                        </option>
                                        {
                                          availableMessengers.map(social => (
                                            <option key={social} value={social}>
                                              {social}
                                            </option>
                                          ))
                                        }
                                      </select>
                                    </div>
                                    <div className='col-lg-6 mb-4 form-outline DashBoardInputBx DashBoardCreatBx'>
                                      <label id='messenger_link' className='form-label' htmlFor='messenger_link'>
                                        {t('jobseekerEditProfile.messengerLink')}
                                      </label>
                                      <input
                                        type='text'
                                        value={i.link}
                                        name='messenger_link'
                                        placeholder={t('jobseekerEditProfile.messengerLink')}
                                        className={`form-control ${errors.contact && 'input-error'}`}
                                        onChange={e => handleMessengerChange(index, 'link', e.target.value)}
                                      />
                                      {
                                        errors.messenger_links && (
                                          <div className='text-danger'>{errors.messenger_links}</div>
                                        )
                                      }
                                    </div>
                                    <div className='col-lg-3 mb-4 d-flex align-items-center justify-content-center'>
                                      {
                                        index === messenger_links.length - 1 &&
                                        availableMessengers.length > 1 &&
                                        messenger_links.length < messengers.length
                                          ? (
                                            <button
                                              type='button'
                                              onClick={handleAddMessengerLink}
                                              className='btn btn-primary button1'
                                            >
                                              {t('jobseekerEditProfile.addMoreButton')}
                                            </button>
                                          ) : (
                                            <button
                                              type='button'
                                              className='btn btn-danger button2'
                                              onClick={() => handleRemoveMessengerLink(index)}
                                            >
                                              {t('jobseekerEditProfile.removeButton')}
                                            </button>
                                          )
                                      }
                                    </div>
                                  </div>
                                );
                              })
                            }
                          </div>
                          <hr />
                          <div className='mt-5'>
                            {
                              social_links.map((i, index) => {
                                const selectedSocials = social_links.map(link => link.type);
                                const availableSocials = socials.filter(
                                  social => !selectedSocials.includes(social) || social === i.type,
                                );

                                return (
                                  <div className='row' key={index}>
                                    <div className='col-lg-3 mb-4 form-outline DashBoardInputBx DashBoardCreatBx'>
                                      <label id='social_type' className='form-label' htmlFor='social_type'>
                                        {t('jobseekerEditProfile.social')}
                                      </label>
                                      <select
                                        value={i.type}
                                        name='social_type'
                                        className='form-select'
                                        aria-label='Default select example'
                                        onChange={e => handleSocialChange(index, 'type', e.target.value)}
                                      >
                                        <option value=''>
                                          {t('jobseekerEditProfile.social')}
                                        </option>
                                        {
                                          availableSocials.map(social => (
                                            <option key={social} value={social}>
                                              {social}
                                            </option>
                                          ))
                                        }
                                      </select>
                                    </div>
                                    <div className='col-lg-6 mb-4 form-outline DashBoardInputBx DashBoardCreatBx'>
                                      <label id='social_link' className='form-label' htmlFor='social_link'>
                                        {t('jobseekerEditProfile.socialLink')}
                                      </label>
                                      <input
                                        type='text'
                                        value={i.link}
                                        name='social_link'
                                        placeholder={t('jobseekerEditProfile.socialLink')}
                                        className={`form-control ${errors.contact && 'input-error'}`}
                                        onChange={e => handleSocialChange(index, 'link', e.target.value)}
                                      />
                                      {
                                        errors.social_links && (
                                          <div className='text-danger'>{errors.social_links}</div>
                                        )
                                      }
                                    </div>
                                    <div className='col-lg-3 mb-4 d-flex align-items-center justify-content-center'>
                                      {
                                        index === social_links.length - 1 &&
                                        availableSocials.length > 1 &&
                                        social_links.length < socials.length
                                          ? (
                                            <button
                                              type='button'
                                              onClick={handleAddSocialLink}
                                              className='btn btn-primary button1'
                                            >
                                              {t('jobseekerEditProfile.addMoreButton')}
                                            </button>
                                          ) : (
                                            <button
                                              type='button'
                                              className='btn btn-danger button2'
                                              onClick={() => handleRemoveSocialLink(index)}
                                            >
                                              {t('jobseekerEditProfile.removeButton')}
                                            </button>
                                          )
                                      }
                                    </div>
                                  </div>
                                );
                              })
                            }
                          </div>
                          <div className='form-outline mb-5 DashBoardInputBx'>
                            <label id='video_cv_link' className='form-label' htmlFor='video_cv_link'>
                              {t('jobseekerEditProfile.videoCV')}
                            </label>
                            <input
                              type='text'
                              name='video_cv_link'
                              value={video_cv_link}
                              className='form-control'
                              onChange={e => handleChange(e)}
                              placeholder={t('jobseekerEditProfile.addLink')}
                            />
                            {
                              errors.video_cv_link && (
                                <div className='text-danger'>{errors.video_cv_link}</div>
                              )
                            }
                            <div id='emailHelp' className='form-text'>
                              {t('jobseekerEditProfile.videoCVBelowTxt')}
                            </div>
                          </div>
                          <h3 className='mb-4'>{t('jobseekerEditProfile.desiredPosition')}</h3>
                          <div className='form-outline mb-4 DashBoardInputBx DashBoardCreatBx'>
                            <label id='position' className='form-label' htmlFor='position'>
                              {t('jobseekerEditProfile.desiredPosition')}
                              <span className='RedStar'>*</span>
                            </label>
                            <AsyncSelect
                              defaultOptions
                              styles={selectStyles}
                              value={selectedDesiredPosition}
                              loadOptions={getPositionOptions}
                              onChange={handleDesiredPositionChange}
                              placeholder={t('jobseekerEditProfile.selectPosition')}
                              className={`${errors.position && 'input-error-react-select'}`}
                              noOptionsMessage={() => t('jobseekerEditProfile.enterPosition')}
                            />
                            {
                              errors.position && (
                                <div className='text-danger'>{errors.position}</div>
                              )
                            }
                          </div>
                          <div className='form-outline mb-4 DashBoardInputBx DashBoardCreatBx'>
                            <label id='category' className='form-label' htmlFor='form3Example1'>
                              {t('jobseekerEditProfile.category')}
                              <span className='RedStar'>*</span>
                            </label>
                            <select
                              name='category'
                              value={category}
                              aria-label='Default select example'
                              className={`form-select ${errors.category && 'input-error'}`}
                              onChange={e => {
                                handleChange(e);
                                getSubCategoriesOptions(e.target.value);
                              }}
                            >
                              <option value=''>
                                {t('jobseekerEditProfile.selectCategory')}
                              </option>
                              {
                                categories.map((i, index) => (
                                  <option value={i.id} key={index}>
                                    {i.name}
                                  </option>
                                ))
                              }
                            </select>
                            {
                              errors.category && (
                                <div className='text-danger'>{errors.category}</div>
                              )
                            }
                          </div>
                          <div className='form-outline mb-4 DashBoardInputBx DashBoardCreatBx'>
                            <label id='sub_category' className='form-label' htmlFor='sub_category'>
                              {t('jobseekerEditProfile.subCategory')}
                              <span className='RedStar'>*</span>
                            </label>
                            <Select
                              isMulti
                              isSearchable
                              name='sub_category'
                              styles={selectStyles}
                              onChange={handleSubCategory}
                              value={selectedSubCategories}
                              placeholder={t('jobseekerEditProfile.selectSubCategory')}
                              className={`${errors.sub_category && 'input-error-react-select'}`}
                              options={subCategories.map(i => ({ value: i.id, label: i.name }))}
                              noOptionsMessage={() => t('jobseekerEditProfile.selectCategoryFirst')}
                            />
                            {
                              errors.sub_category && (
                                <div className='text-danger'>{errors.sub_category}</div>
                              )
                            }
                          </div>
                          <div className='form-outline mb-4 DashBoardInputBx DashBoardCreatBx'>
                            <label id='relocations' className='form-label' htmlFor='relocations'>
                              {t('jobseekerEditProfile.relocations')}
                              <span className='RedStar'>*</span>
                            </label>
                            <AsyncSelect
                              isMulti
                              defaultOptions
                              styles={selectStyles}
                              value={selectedRelocations}
                              loadOptions={getCitiesOptions}
                              onChange={handleRelocationsChange}
                              placeholder={t('jobseekerEditProfile.selectRelocations')}
                              noOptionsMessage={() => t('jobseekerEditProfile.enterCityName')}
                              className={`${errors.relocations && 'input-error-react-select'}`}
                            />
                            {
                              errors.relocations && (
                                <div className='text-danger'>{errors.relocations}</div>
                              )
                            }
                          </div>
                          <div className='row'>
                            <div className='col-lg-4 form-outline mb-4 DashBoardInputBx DashBoardCreatBx'>
                              <label id='salary_min' className='form-label' htmlFor='salary_min'>
                                {t('jobseekerEditProfile.expectedSalaryFrom')}
                              </label>
                              <input
                                type='text'
                                name='salary_min'
                                value={salary_min}
                                onChange={handleChange}
                                placeholder={t('jobseekerEditProfile.from')}
                                className={`form-control ${errors.salary_min && 'input-error'}`}
                              />
                              {
                                errors.salary_min && (
                                  <div className='text-danger'>
                                    {errors.salary_min}
                                  </div>
                                )
                              }
                            </div>
                            <div className='col-lg-4 form-outline mb-4 DashBoardInputBx DashBoardCreatBx'>
                              <label id='salary_max' className='form-label' htmlFor='salary_max'>
                                {t('jobseekerEditProfile.expectedSalaryTo')}
                              </label>
                              <input
                                type='text'
                                name='salary_max'
                                value={salary_max}
                                onChange={handleChange}
                                placeholder={t('jobseekerEditProfile.to')}
                                className={`form-control ${errors.salary_max && 'input-error'}`}
                              />
                              {
                                errors.salary_max && (
                                  <div className='text-danger'>
                                    {errors.salary_max}
                                  </div>
                                )
                              }
                            </div>
                            <div
                              className='col-lg-4 form-outline mb-4 DashBoardInputBx DashBoardCreatBx selectCurrency'
                            >
                              <label id='salary_currency' className='form-label' htmlFor='form3Example1'>
                                {t('jobseekerEditProfile.selectCurrency')}
                                {
                                  (salary_min || salary_max) && <span className='RedStar'>*</span>
                                }
                              </label>
                              <select
                                name='salary_currency'
                                onChange={handleChange}
                                value={salary_currency}
                                aria-label='Default select example'
                                className={`form-select ${errors.salary_currency && 'input-error'}`}
                              >
                                <option selected value=''>
                                  {t('jobseekerEditProfile.selectCurrency')}
                                </option>
                                {
                                  Object.entries(currencies).map(([key, value]) => (
                                    <option key={key} value={key}>
                                      {value}
                                    </option>
                                  ))
                                }
                              </select>
                              {
                                errors.salary_currency && (
                                  <div className='text-danger'>{errors.salary_currency}</div>
                                )
                              }
                            </div>
                          </div>
                          <div className='form-outline mb-5 DashBoardInputBx DashBoardCreatBx'>
                            <label id='work_type' className='form-label' htmlFor='work_type'>
                              {t('jobseekerEditProfile.workType')}
                            </label>
                            <select
                              name='work_type'
                              value={work_type}
                              onChange={handleChange}
                              aria-label='Default select example'
                              className={`form-select ${errors.work_type && 'input-error'}`}
                            >
                              <option selected value=''>
                                {t('jobseekerEditProfile.selectWorkType')}
                              </option>
                              {
                                Object.entries(worktype).map(([key, value]) => (
                                  <option key={key} value={key}>
                                    {value}
                                  </option>
                                ))
                              }
                            </select>
                            {
                              errors.work_type && (
                                <div className='text-danger'>{errors.work_type}</div>
                              )
                            }
                          </div>
                          <h3 className='mb-4'>{t('jobseekerEditProfile.workExperience')}</h3>
                          <div className='mt-4 mb-5'>
                            {
                              experiences.map((i, index) => (
                                <>
                                  <div className={`${index === 0 ? 'mt-4' : 'mt-5'} mb-4 form-outline DashBoardInputBx`}>
                                    <label id='position' className='form-label' htmlFor='position'>
                                      {t('jobseekerEditProfile.position')}
                                      <span className='RedStar'>*</span>
                                    </label>
                                    <AsyncSelect
                                      defaultOptions
                                      styles={selectStyles}
                                      loadOptions={getPositionOptions}
                                      value={experiences[index].position}
                                      placeholder={t('jobseekerEditProfile.selectPosition')}
                                      noOptionsMessage={() => t('jobseekerEditProfile.enterPosition')}
                                      onChange={selectedOption => handlePositionChange(selectedOption, index)}
                                      className={`${errors.experiences[index]?.position && 'input-error-react-select'}`}
                                    />
                                    {
                                      errors.experiences[index]?.position && (
                                        <div className='text-danger'>
                                          {errors.experiences[index]?.position}
                                        </div>
                                      )
                                    }
                                  </div>
                                  <div className='form-outline mb-4 DashBoardInputBx'>
                                    <label id='organization' className='form-label' htmlFor='organization'>
                                      {t('jobseekerEditProfile.organization')}
                                      <span className='RedStar'>*</span>
                                    </label>
                                    <input
                                      type='text'
                                      name='organization'
                                      value={i.organization}
                                      onChange={e => handleChange(e, index)}
                                      placeholder={t('jobseekerEditProfile.organization')}
                                      className={`form-control ${errors.experiences[index]?.organization && 'input-error'}`}
                                    />
                                    {
                                      errors.experiences[index]?.organization && (
                                        <div className='text-danger'>
                                          {errors.experiences[index]?.organization}
                                        </div>
                                      )
                                    }
                                  </div>
                                  <div className='form-outline mb-4 DashBoardInputBx DashBoardCreatBx'>
                                    <label id='industry' className='form-label' htmlFor='form3Example1'>
                                      {t('jobseekerEditProfile.industry')}
                                      <span className='RedStar'>*</span>
                                    </label>
                                    <Select
                                      isMulti
                                      isSearchable
                                      name='industry'
                                      styles={selectStyles}
                                      placeholder={t('jobseekerEditProfile.selectIndustry')}
                                      value={i.industry_id.map(i => getDefaultIndustryName(i))}
                                      options={industries.map(i => ({ value: i.id, label: i.name }))}
                                      noOptionsMessage={() => t('jobseekerEditProfile.noIndustryToSelect')}
                                      onChange={selectedOption => handleIndustryChange(selectedOption, index)}
                                      className={`${errors.experiences[index]?.industry_id && 'input-error-react-select'}`}
                                    />
                                    {
                                      errors.experiences[index]?.industry_id && (
                                        <div className='text-danger'>
                                          {errors.experiences[index]?.industry_id}
                                        </div>
                                      )
                                    }
                                  </div>
                                  <div className='row'>
                                    <div className='col-lg-6'>
                                      <p className='mb-4'>
                                        {t('jobseekerEditProfile.startOfWork')}
                                      </p>
                                      <div className='row'>
                                        <div className='col-lg-6 form-outline mb-4 DashBoardInputBx'>
                                          <label id='from_month_exp' className='form-label' htmlFor='from_month_exp'>
                                            {t('jobseekerEditProfile.month')}
                                            <span className='RedStar'>*</span>
                                          </label>
                                          <select
                                            name='from_month_exp'
                                            className='form-select'
                                            value={i.from_month_exp}
                                            aria-label='Default select example'
                                            onChange={e => handleChange(e, index)}
                                          >
                                            <option selected value=''>
                                              {t('jobseekerEditProfile.selectMonth')}
                                            </option>
                                            {
                                              months.map(i => (
                                                <option value={i.id} key={i.id}>
                                                  {i.name}
                                                </option>
                                              ))
                                            }
                                          </select>
                                          <div className='mt-0'>
                                            {/* {
                                          validationErrors[index]?.from_month_exp && (
                                            <small className='text-danger'>
                                              {t('jobseekerExperience.monthRequired')}
                                            </small>
                                          )
                                        } */}
                                          </div>
                                        </div>
                                        <div className='col-lg-6 form-outline mb-4 DashBoardInputBx'>
                                          <label id='from_year_exp' className='form-label' htmlFor='from_year_exp'>
                                            {t('jobseekerEditProfile.year')}
                                            <span className='RedStar'>*</span>
                                          </label>
                                          <select
                                            name='from_year_exp'
                                            className='form-select'
                                            value={i.from_year_exp}
                                            aria-label='Default select example'
                                            onChange={e => handleChange(e, index)}
                                          >
                                            <option selected value=''>
                                              {t('jobseekerEditProfile.selectYear')}
                                            </option>
                                            {
                                              Object.entries(yearList).map(([index, value]) => (
                                                <option key={index} value={value}>{value}</option>
                                              ))
                                            }
                                          </select>
                                          <div className='mt-0'>
                                            {/* {
                                          validationErrors[index]?.from_year_exp && (
                                            <small className='text-danger'>
                                              {t('jobseekerExperience.yearRequired')}
                                            </small>
                                          )
                                        } */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='col-lg-6'>
                                      <p className='mb-4'>
                                        {t('jobseekerEditProfile.endOfWork')}
                                      </p>
                                      <div className='row'>
                                        <div className='col-lg-6 form-outline mb-4 DashBoardInputBx'>
                                          <label id='to_month_exp' className='form-label' htmlFor='to_month_exp'>
                                            {t('jobseekerEditProfile.month')}
                                            {
                                              !experiences[index].still_working && (
                                                <span className='RedStar'>*</span>
                                              )
                                            }
                                          </label>
                                          <select
                                            name='to_month_exp'
                                            value={i.to_month_exp}
                                            className='form-select'
                                            aria-label='Default select example'
                                            onChange={e => handleChange(e, index)}
                                          >
                                            <option selected value=''>
                                              {t('jobseekerEditProfile.selectMonth')}
                                            </option>
                                            {
                                              months.map(i => (
                                                <option value={i.id} key={i.id}>
                                                  {i.name}
                                                </option>
                                              ))
                                            }
                                          </select>
                                          <div className='mt-0'>
                                            {/* {
                                          validationErrors[index]?.to_month_exp && (
                                            <small className='text-danger'>
                                              {t('jobseekerExperience.monthRequired')}
                                            </small>
                                          )
                                        } */}
                                          </div>
                                        </div>
                                        <div className='col-lg-6 form-outline mb-4 DashBoardInputBx'>
                                          <label id='to_year_exp' className='form-label' htmlFor='to_year_exp'>
                                            {t('jobseekerEditProfile.year')}
                                            {
                                              !experiences[index].still_working && (
                                                <span className='RedStar'>*</span>
                                              )
                                            }
                                          </label>
                                          <select
                                            name='to_year_exp'
                                            value={i.to_year_exp}
                                            className='form-select'
                                            aria-label='Default select example'
                                            onChange={e => handleChange(e, index)}
                                          >
                                            <option selected value=''>
                                              {t('jobseekerEditProfile.selectYear')}
                                            </option>
                                            {
                                              Object.entries(yearList).map(([index, value]) => (
                                                <option key={index} value={value}>{value}</option>
                                              ))
                                            }
                                          </select>
                                          <div className='mt-0'>
                                            {/* {
                                          validationErrors[index]?.to_year_exp && (
                                            <small className='text-danger'>
                                              {t('jobseekerExperience.yearRequired')}
                                            </small>
                                          )
                                        } */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <label className='mb-4' style={{ display: 'flex', alignItems: 'center' }}>
                                      <input
                                        type='checkbox'
                                        name='still_working'
                                        className='me-2 checkboxInput'
                                        onChange={e => handleChange(e, index)}
                                        checked={experiences[index].still_working}
                                      />
                                      {t('jobseekerEditProfile.stillWorking')}
                                    </label>
                                  </div>
                                  <div className='form-outline mb-4 DashBoardInputBx DashBoardCreatBx APJoditEditor'>
                                    <label id='job_description' className='form-label' htmlFor='form3Example3'>
                                      {t('jobseekerEditProfile.achievements')}
                                      <span className='RedStar'>*</span>
                                    </label>
                                    <JoditEditor
                                      ref={editor}
                                      name='achievements'
                                      config={editorConfig}
                                      value={i.achievements}
                                      placeholder={t('jobseekerEditProfile.achievements')}
                                      onBlur={achievements =>
                                        handleChange(
                                          {
                                            target: {
                                              name: 'achievements',
                                              value: achievements,
                                            },
                                          },
                                          index,
                                        )
                                      }
                                    />
                                    {
                                      errors.experiences[index]?.achievements && (
                                        <div className='text-danger'>
                                          {errors.experiences[index]?.achievements}
                                        </div>
                                      )
                                    }
                                  </div>
                                  <div className={`${experiences.length > 1 && index < experiences.length - 1 && 'mb-5'} row`}>
                                    <div className='removeButtonJobseeker'>
                                      <button
                                        type='button'
                                        className='btn btn-primary button2'
                                        disabled={experiences.length === 1}
                                        onClick={() => handleRemoveExperience(index)}
                                      >
                                        {t('jobseekerEditProfile.removeButton')}
                                      </button>
                                      <button
                                        type='button'
                                        onClick={handleAddExperience}
                                        className='btn btn-primary button1'
                                      >
                                        {t('jobseekerEditProfile.addMoreButton')}
                                      </button>
                                    </div>
                                  </div>
                                  {
                                    experiences.length > 1 && index < experiences.length - 1 && <hr />
                                  }
                                </>
                              ))
                            }
                          </div>
                          <h3 className='mb-4'>{t('jobseekerEditProfile.educationInfo')}</h3>
                          <div className='mt-4 mb-4'>
                            {
                              educations.map((i, index) => (
                                <>
                                  <div className={`${index === 0 ? 'mt-4' : 'mt-5'} mb-4 form-outline DashBoardInputBx`}>
                                    <label id='edu_type' className='form-label' htmlFor='edu_type'>
                                      {t('jobseekerEditProfile.educationalDegree')}
                                      <span className='RedStar'>*</span>
                                    </label>
                                    <select
                                      name='edu_type'
                                      value={i.edu_type}
                                      aria-label='Default select example'
                                      onChange={e => handleChange(e, index)}
                                      className={`form-select ${errors.edu_type && 'input-error'}`}
                                    >
                                      <option selected value=''>
                                        {t('jobseekerEditProfile.selectEducationalDegree')}
                                      </option>
                                      {
                                        educationLevels.map(({ name, value }) => (
                                          <option key={value} value={value}>
                                            {name}
                                          </option>
                                        ))
                                      }
                                    </select>
                                    {/* {
                                      validationErrors[index]?.basic_university && (
                                        <small className='text-danger'>
                                          {t('jobseekerEducation.universityRequired')}
                                        </small>
                                      )
                                    } */}
                                  </div>
                                  <div className='form-outline mb-4 DashBoardInputBx'>
                                    <label id='institution' className='form-label' htmlFor='institution'>
                                      {t('jobseekerEditProfile.institution')}
                                      <span className='RedStar'>*</span>
                                    </label>
                                    <AsyncSelect
                                      defaultOptions
                                      styles={selectStyles}
                                      value={i.institution}
                                      loadOptions={getPositionOptions}
                                      placeholder={t('jobseekerEditProfile.selectInstitution')}
                                      noOptionsMessage={() => t('jobseekerEditProfile.enterInstitutionName')}
                                      onChange={selectedOption => handleInstitutionChange(selectedOption, index)}
                                      className={`${errors.educations[index]?.institution && 'input-error-react-select'}`}
                                    />
                                    {/* {
                                      validationErrors[index]?.basic_course_id && (
                                        <small className='text-danger'>
                                          {t('jobseekerEducation.courseNameRequired')}
                                        </small>
                                      )
                                    } */}
                                  </div>
                                  <div className='form-outline mb-4 DashBoardInputBx DashBoardCreatBx'>
                                    <label id='faculty' className='form-label' htmlFor='faculty'>
                                      {t('jobseekerEditProfile.faculty')}
                                      <span className='RedStar'>*</span>
                                    </label>
                                    <Select
                                      isSearchable
                                      name='faculty'
                                      styles={selectStyles}
                                      // onChange={handleSubCategory}
                                      // value={selectedSubCategories}
                                      placeholder={t('jobseekerEditProfile.selectFaculty')}
                                      className={`${errors.sub_category && 'input-error-react-select'}`}
                                      options={subCategories.map(i => ({ value: i.id, label: i.name }))}
                                      noOptionsMessage={() => t('jobseekerEditProfile.selectInstitutionFirst')}
                                    />
                                    {
                                      errors.sub_category && (
                                        <div className='text-danger'>{errors.sub_category}</div>
                                      )
                                    }
                                  </div>
                                  <div className='form-outline mb-4 DashBoardInputBx DashBoardCreatBx'>
                                    <label id='specialty' className='form-label' htmlFor='specialty'>
                                      {t('jobseekerEditProfile.specialty')}
                                      <span className='RedStar'>*</span>
                                    </label>
                                    <Select
                                      isSearchable
                                      name='specialty'
                                      styles={selectStyles}
                                      // onChange={handleSubCategory}
                                      // value={selectedSubCategories}
                                      placeholder={t('jobseekerEditProfile.selectSpecialty')}
                                      className={`${errors.sub_category && 'input-error-react-select'}`}
                                      options={subCategories.map(i => ({ value: i.id, label: i.name }))}
                                      noOptionsMessage={() => t('jobseekerEditProfile.selectFacultyFirst')}
                                    />
                                    {
                                      errors.sub_category && (
                                        <div className='text-danger'>{errors.sub_category}</div>
                                      )
                                    }
                                  </div>
                                  <div className='form-outline mb-4 DashBoardInputBx'>
                                    <div className='row'>
                                      <div className='col-lg-6'>
                                        <p className='mb-4'>
                                          {t('jobseekerEditProfile.startOfEducation')}
                                        </p>
                                        <div className='row'>
                                          <div className='col-lg-6 form-outline mb-4 DashBoardInputBx'>
                                            <label id='from_month_edu' className='form-label' htmlFor='from_month_edu'>
                                              {t('jobseekerEditProfile.month')}
                                              <span className='RedStar'>*</span>
                                            </label>
                                            <select
                                              name='from_month_edu'
                                              className='form-select'
                                              value={i.from_month_edu}
                                              aria-label='Default select example'
                                              onChange={e => handleChange(e, index)}
                                            >
                                              <option selected value=''>
                                                {t('jobseekerEditProfile.selectMonth')}
                                              </option>
                                              {
                                                months.map(i => (
                                                  <option value={i.id} key={i.id}>
                                                    {i.name}
                                                  </option>
                                                ))
                                              }
                                            </select>
                                            <div className='mt-0'>
                                              {/* {
                                                validationErrors[index]?.from_month_edu && (
                                                  <small className='text-danger'>
                                                    {t('jobseekerExperience.monthRequired')}
                                                  </small>
                                                )
                                              } */}
                                            </div>
                                          </div>
                                          <div className='col-lg-6 form-outline mb-4 DashBoardInputBx'>
                                            <label id='from_year_edu' className='form-label' htmlFor='from_year_edu'>
                                              {t('jobseekerEditProfile.year')}
                                              <span className='RedStar'>*</span>
                                            </label>
                                            <select
                                              name='from_year_edu'
                                              className='form-select'
                                              value={i.from_year_edu}
                                              aria-label='Default select example'
                                              onChange={e => handleChange(e, index)}
                                            >
                                              <option selected value=''>
                                                {t('jobseekerEditProfile.selectYear')}
                                              </option>
                                              {
                                                Object.entries(yearList).map(([index, value]) => (
                                                  <option key={index} value={value}>{value}</option>
                                                ))
                                              }
                                            </select>
                                            <div className='mt-0'>
                                              {/* {
                                                validationErrors[index]?.from_year_edu && (
                                                  <small className='text-danger'>
                                                    {t('jobseekerExperience.yearRequired')}
                                                  </small>
                                                )
                                              } */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='col-lg-6'>
                                        <p className='mb-4'>
                                          {t('jobseekerEditProfile.endOfEducation')}
                                        </p>
                                        <div className='row'>
                                          <div className='col-lg-6 form-outline mb-4 DashBoardInputBx'>
                                            <label id='to_month_edu' className='form-label' htmlFor='to_month_edu'>
                                              {t('jobseekerEditProfile.month')}
                                              {
                                                !educations[index].still_learning && (
                                                  <span className='RedStar'>*</span>
                                                )
                                              }
                                            </label>
                                            <select
                                              name='to_month_edu'
                                              value={i.to_month_edu}
                                              className='form-select'
                                              aria-label='Default select example'
                                              onChange={e => handleChange(e, index)}
                                            >
                                              <option selected value=''>
                                                {t('jobseekerEditProfile.selectMonth')}
                                              </option>
                                              {
                                                months.map(i => (
                                                  <option value={i.id} key={i.id}>
                                                    {i.name}
                                                  </option>
                                                ))
                                              }
                                            </select>
                                            <div className='mt-0'>
                                              {/* {
                                                validationErrors[index]?.to_month_edu && (
                                                  <small className='text-danger'>
                                                    {t('jobseekerExperience.monthRequired')}
                                                  </small>
                                                )
                                              } */}
                                            </div>
                                          </div>
                                          <div className='col-lg-6 form-outline mb-4 DashBoardInputBx'>
                                            <label id='to_year_edu' className='form-label' htmlFor='to_year_edu'>
                                              {t('jobseekerEditProfile.year')}
                                              {
                                                !educations[index].still_learning && (
                                                  <span className='RedStar'>*</span>
                                                )
                                              }
                                            </label>
                                            <select
                                              name='to_year_edu'
                                              value={i.to_year_edu}
                                              className='form-select'
                                              aria-label='Default select example'
                                              onChange={e => handleChange(e, index)}
                                            >
                                              <option selected value=''>
                                                {t('jobseekerEditProfile.selectYear')}
                                              </option>
                                              {
                                                Object.entries(yearList).map(([index, value]) => (
                                                  <option key={index} value={value}>{value}</option>
                                                ))
                                              }
                                            </select>
                                            <div className='mt-0'>
                                              {/* {
                                                validationErrors[index]?.to_year_edu && (
                                                  <small className='text-danger'>
                                                    {t('jobseekerExperience.yearRequired')}
                                                  </small>
                                                )
                                              } */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <label className='mb-4' style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                          type='checkbox'
                                          name='still_learning'
                                          className='me-2 checkboxInput'
                                          onChange={e => handleChange(e, index)}
                                          checked={educations[index].still_learning}
                                        />
                                        {t('jobseekerEditProfile.stillLearning')}
                                      </label>
                                    </div>
                                    <div className={`${educations.length > 1 && index < educations.length - 1 && 'mb-5'} row`}>
                                      <div className='removeButtonJobseeker'>
                                        <button
                                          type='button'
                                          disabled={educations.length === 1}
                                          className='btn btn-primary button2'
                                          onClick={() => handleRemoveEducation(index)}
                                        >
                                          {t('jobseekerEditProfile.removeButton')}
                                        </button>
                                        <button
                                          type='button'
                                          onClick={handleAddEducation}
                                          className='btn btn-primary button1'
                                        >
                                          {t('jobseekerEditProfile.addMoreButton')}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  {
                                    educations.length > 1 && index < educations.length - 1 && <hr />
                                  }
                                </>
                              ))
                            }
                          </div>
                          <h4 className='mb-4'>{t('jobseekerEditProfile.additionalEducation')}</h4>
                          <div className='mb-5'>
                            {
                              additional_educations.map((i, index) => (
                                <>
                                  <div className={`${index === 0 ? 'mt-4' : 'mt-5'} mb-4 form-outline DashBoardInputBx`}>
                                    <label id='course_name' className='form-label' htmlFor='course_name'>
                                      {t('jobseekerEditProfile.courseName')}
                                    </label>
                                    <input
                                      type='text'
                                      name='course_name'
                                      value={i.course_name}
                                      onChange={e => handleChange(e, index)}
                                      placeholder={t('jobseekerEditProfile.courseName')}
                                      className={`form-control ${errors.course_name && 'input-error'}`}
                                    />
                                    {/* {
                                      validationErrors[index]?.basic_university && (
                                        <small className='text-danger'>
                                          {t('jobseekerEducation.universityRequired')}
                                        </small>
                                      )
                                    } */}
                                  </div>
                                  <div className='form-outline mb-4 DashBoardInputBx'>
                                    <label id='course_link' className='form-label' htmlFor='course_link'>
                                      {t('jobseekerEditProfile.courseLink')}
                                    </label>
                                    <input
                                      type='text'
                                      name='course_link'
                                      value={i.course_link}
                                      className='form-control'
                                      onChange={e => handleChange(e, index)}
                                      placeholder={t('jobseekerEditProfile.courseLink')}
                                    />
                                    {
                                      errors.course_link && (
                                        <div className='text-danger'>{errors.course_link}</div>
                                      )
                                    }
                                  </div>
                                  <div className='form-outline mb-4 DashBoardInputBx'>
                                    <label id='certificate' className='form-label' htmlFor='certificate'>
                                      {t('jobseekerEditProfile.certificate')}
                                    </label>
                                    <input
                                      type='file'
                                      name='certificate'
                                      className='form-control'
                                      accept='.jpeg, .png, .jpg'
                                      // onChange={e => handleFileUpload(e)}
                                    />
                                    {
                                      errors.certificate && (
                                        <div className='text-danger'>{errors.certificate}</div>
                                      )
                                    }
                                  </div>
                                  <div
                                    className={
                                      `${additional_educations.length > 1 && index < additional_educations.length - 1 && 'mb-5'} row`
                                    }
                                  >
                                    <div className='removeButtonJobseeker'>
                                      <button
                                        type='button'
                                        className='btn btn-primary button2'
                                        disabled={additional_educations.length === 1}
                                        onClick={() => handleRemoveAdditionalEducation(index)}
                                      >
                                        {t('jobseekerEditProfile.removeButton')}
                                      </button>
                                      <button
                                        type='button'
                                        className='btn btn-primary button1'
                                        onClick={handleAddAdditionalEducation}
                                      >
                                        {t('jobseekerEditProfile.addMoreButton')}
                                      </button>
                                    </div>
                                  </div>
                                  {
                                    additional_educations.length > 1 && index < additional_educations.length - 1 && <hr />
                                  }
                                </>
                              ))
                            }
                          </div>
                          <h3 className='mb-4'>{t('jobseekerEditProfile.additionalInfo')}</h3>
                          <div className='form-outline mb-4 DashBoardInputBx APJoditEditor'>
                            <label id='about_yourself' className='form-label' htmlFor='about_yourself'>
                              {t('jobseekerEditProfile.aboutYourself')}
                              <span className='RedStar'>*</span>
                            </label>
                            <JoditEditor
                              ref={editor}
                              config={{
                                ...editorConfig,
                                minHeight: '240px',
                                placeholder: t('jobseekerEditProfile.aboutYourselfPlaceholder'),
                              }}
                              name='about_yourself'
                              value={profileBody.about_yourself}
                              onBlur={about_yourself =>
                                handleChange({
                                  target: {
                                    value: about_yourself,
                                    name: 'about_yourself',
                                  },
                                })
                              }
                            />
                            {
                              errors.about_yourself && (
                                <div className='text-danger'>
                                  {errors.about_yourself}
                                </div>
                              )
                            }
                          </div>
                          <div className='form-outline mb-4 DashBoardInputBx DashBoardCreatBx'>
                            <label id='skills' className='form-label' htmlFor='skills'>
                              {t('jobseekerEditProfile.skills')}
                              <span className='RedStar'>*</span>
                            </label>
                            <Select
                              isMulti
                              isSearchable
                              name='skills'
                              value={skills}
                              styles={selectStyles}
                              onChange={handleSkillsChange}
                              placeholder={t('jobseekerEditProfile.selectSkills')}
                              className={`${errors.skills && 'input-error-react-select'}`}
                              options={skillList.map(i => ({
                                value: i.id,
                                label: i.name,
                              }))}
                            />
                          </div>
                          <div className='mt-4 mb-5'>
                            {
                              languages.map((i, index) => (
                                <>
                                  <div className='row'>
                                    <div className='col-lg-4 mb-4 form-outline DashBoardInputBx DashBoardCreatBx'>
                                      <label id='lng' className='form-label' htmlFor='lng'>
                                        {t('jobseekerEditProfile.language')}
                                      </label>
                                      <select
                                        name='lng'
                                        value={i.lng}
                                        className='form-select'
                                        aria-label='Default select example'
                                        onChange={e => handleChange(e, index)}
                                      >
                                        <option value=''>
                                          {t('jobseekerEditProfile.selectLanguage')}
                                        </option>
                                        {
                                          langs.map(i => (
                                            <option key={i.id} value={i.id}>
                                              {i.name}
                                            </option>
                                          ))
                                        }
                                      </select>
                                    </div>
                                    <div className='col-lg-4 mb-4 form-outline DashBoardInputBx DashBoardCreatBx'>
                                      <label id='lng_skill' className='form-label' htmlFor='lng_skill'>
                                        {t('jobseekerEditProfile.proficiency')}
                                      </label>
                                      <select
                                        name='lng_skill'
                                        value={i.lng_skill}
                                        className='form-select'
                                        aria-label='Default select example'
                                        onChange={e => handleChange(e, index)}
                                      >
                                        <option value=''>
                                          {t('jobseekerEditProfile.selectProficiency')}
                                        </option>
                                        {
                                          lang_skills.map(i => (
                                            <option key={i.id} value={i.id}>
                                              {i.name}
                                            </option>
                                          ))
                                        }
                                      </select>
                                    </div>
                                    <div className='col-lg-4 mb-4 d-flex align-items-center justify-content-center'>
                                      {
                                        index === languages.length - 1 &&
                                        languages.length < 5
                                          ? (
                                            <button
                                              type='button'
                                              onClick={handleAddLanguage}
                                              className='btn btn-primary button1'
                                            >
                                              {t('jobseekerEditProfile.addMoreButton')}
                                            </button>
                                          ) : (
                                            <button
                                              type='button'
                                              className='btn btn-danger button2'
                                              onClick={() => handleRemoveLanguage(index)}
                                            >
                                              {t('jobseekerEditProfile.removeButton')}
                                            </button>
                                          )
                                      }
                                    </div>
                                  </div>
                                </>
                              ))
                            }
                          </div>
                          <div className='bottomButtons'>
                            <button
                              type='button'
                              onClick={handleClick}
                              className='btn btn-primary button1'
                            >
                              {t('jobseekerEditProfile.updateButton')}
                            </button>
                            <button
                              type='button'
                              onClick={handleClick}
                              className='btn btn-primary button1'
                            >
                              {t('jobseekerEditProfile.createCVButton')}
                            </button>
                            <button
                              type='button'
                              className='btn btn-primary button2'
                            >
                              {t('jobseekerEditProfile.cancelButton')}
                            </button>
                          </div>
                        </div>
                      </form>
                    )
                  }
                </div>
              )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default JSEditProfile;
