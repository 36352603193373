/* eslint-disable unused-imports/no-unused-vars */
import React, { useEffect, useState } from 'react';
import Footer from '../element/Footer';
import JSSidebar from './JSSidebar';
import NavBar from '../element/NavBar';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import BaseApi from '../api/BaseApi';
import ApiKey from '../api/ApiKey';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { logOutIfTokenExpired, resultInfo } from '../helpers';

const JSChangePhoto = () => {
  const [loading, setLoading] = useState(false);
  const [photo, setPhoto] = useState({
    profile_image: '',
  });

  const [picture, setPicture] = useState('');
  const tokenKey = Cookies.get('tokenClient');
  const primaryColor = Cookies.get('primaryColor');
  const secondaryColor = Cookies.get('secondaryColor');
  const [t, i18n] = useTranslation('global');

  const [hoverFirstButtonColor, setHoverFirstButtonColor] = useState(false);

  const handleFirstButtonMouseEnter = () => {
    setHoverFirstButtonColor(true);
  };

  const handleFirstButtonMouseLeave = () => {
    setHoverFirstButtonColor(false);
  };

  const [hoverSecondButtonColor, setHoverSecondButtonColor] = useState(false);

  const handleSecondButtonMouseEnter = () => {
    setHoverSecondButtonColor(true);
  };

  const handleSecondButtonMouseLeave = () => {
    setHoverSecondButtonColor(false);
  };

  const navigate = useNavigate();

  const getData = async () => {
    try {
      setLoading(true);

      const response = await axios.post(
        BaseApi + '/candidates/uploadPhoto',
        null,
        {
          headers: {
            key: ApiKey,
            token: tokenKey,
            'Content-Type': 'application/json',
          },
        },
      );

      setLoading(false);

      if (response.data.status === 200) {
        setPhoto(response.data.response.profile_image);
        setPicture(response.data.response.profile_image);
      }

      if (response.data.status === 400) {
        navigate('/');

        Cookies.remove('fname');
        Cookies.remove('user_id');
        Cookies.remove('user_type');
        Cookies.remove('tokenClient');

        resultInfo('warning', response.data.message, t('searchJobPage.close'));
      }
    } catch (error) {
      setLoading(false);

      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      }

      console.error('Cannot get profile photo data', error);
    }
  };

  useEffect(() => {
    // Check if tokenKey is not present
    if (!tokenKey) {
      // Redirect to the home page
      navigate('/user/jobseekerlogin');
    } else {
      // TokenKey is present, fetch data or perform other actions
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]);

  const handleClick = async () => {
    try {
      if (!photo.profile_image || photo.profile_image === null) {
        resultInfo('warning', t('jobseekerChangePhoto.imageRequired'), t('jobseekerChangePhoto.close'));
      } else {
        const confirmationResult = await Swal.fire({
          title: t('jobseekerChangePhoto.confirmTitle'),
          text: t('jobseekerChangePhoto.confirmText'),
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: t('jobseekerChangePhoto.yes'),
          cancelButtonText: t('jobseekerChangePhoto.no'),
        });

        if (confirmationResult.isConfirmed) {
          setLoading(true);

          const response = await axios.post(
            BaseApi + '/candidates/uploadPhoto',
            photo,
            {
              headers: {
                key: ApiKey,
                token: tokenKey,
                'Content-Type': 'application/json',
              },
            },
          );

          setLoading(false);

          if (response.data.status === 200) {
            getData();

            resultInfo('success', t('jobseekerChangePhoto.successTxt'), t('jobseekerChangePhoto.close'));

            window.scrollTo(0, 0);
          } else if (response.data.status === 400) {
            navigate('/');

            Cookies.remove('fname');
            Cookies.remove('user_id');
            Cookies.remove('user_type');
            Cookies.remove('tokenClient');

            resultInfo('warning', response.data.message, t('searchJobPage.close'));
          } else if (response.data.status === 500) {
            resultInfo('warning', response.data.message, t('searchJobPage.close'));
          } else {
            resultInfo('error', response.data.message, t('searchJobPage.close'));
          }
        }
      }
    } catch (error) {
      setLoading(false);

      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      } else {
        resultInfo('error', t('jobseekerChangePhoto.failedTxt'), t('searchJobPage.close'));
      }

      console.error('Could not update photo!', error);
    }
  };

  // const handleFileUpload = async (e) => {
  //   const file = e.target.files[0];
  //   const base64 = await convertToBase64(file);
  //   console.log(base64);
  //   setPhoto({ ...photo, profile_image: base64 });
  // };

  const handleFileUpload = async e => {
    const file = e.target.files[0];

    // Check if file size exceeds 500KB
    if (file.size > 500 * 1024) {
      resultInfo('warning', t('jobseekerChangePhoto.fileSizeExceed'), t('jobseekerChangePhoto.close'));
      e.target.value = '';

      return; // Exit function
    }

    // Create an object URL to get the image dimensions
    const objectURL = URL.createObjectURL(file);
    const image = new Image();

    // Set up a promise to get image dimensions
    const imageSizePromise = new Promise(resolve => {
      image.onload = () => {
        resolve({
          width: image.width,
          height: image.height,
        });
      };
    });

    image.src = objectURL;

    const dimensions = await imageSizePromise;

    // Check if minimum dimensions are met
    if (dimensions.width < 250 || dimensions.height < 250) {
      resultInfo('warning', t('jobseekerChangePhoto.minFileSize'), t('jobseekerChangePhoto.close'));
      e.target.value = '';

      return; // Exit function
    }

    // Convert the file to base64
    const base64 = await convertToBase64(file);

    // Set the photo state
    setPhoto({ ...photo, profile_image: base64 });
  };

  return (
    <>
      <NavBar />
      <div className='container changeLogo'>
        <div className='row'>
          <div className='col-lg-3'>
            <JSSidebar />
          </div>
          {loading ? (
            <div className='loader-container'></div>
          ) : (
            <>
              <div
                className='col-lg-9 mb-5'
                style={{
                  borderLeft: '2px solid #e6e8e7',
                  borderRight: '2px solid #e6e8e7',
                }}
              >
                <div className='d-flex mx-3 PageHeader'>
                  <img src='/Images/employerSide/icon10color.png' alt='' />
                  <h3 className='mx-2'>
                    {t('jobseekerChangePhoto.changeProfilePicture')}
                  </h3>
                </div>
                <div className='profileImageBox mt-4 ms-3'>
                  {picture ? (
                    <img src={picture} alt='profile' />
                  ) : (
                    <img src='/Images/jobseekerSide/dummy-profile.png' alt='' />
                  )}
                </div>
                <form>
                  <div className='mb-4 mt-5 mx-3'>
                    <div className='mb-5 DashBoardInputBx'>
                      <label htmlFor='formFile' className='form-label'>
                        {t('jobseekerChangePhoto.newImage')}
                        <span className='RedStar'>*</span>
                      </label>
                      <input
                        className='form-control'
                        type='file'
                        id='formFile'
                        // eslint-disable-next-line react/no-unknown-property
                        lable='Image'
                        name='profile_image'
                        accept='.jpeg, .png, .jpg'
                        onChange={e => handleFileUpload(e)}
                      />
                      <div id='emailHelp' className='form-text'>
                        {t('jobseekerChangePhoto.belowTxt')}
                      </div>
                    </div>
                  </div>
                  <div className='buttonsSec'>
                    <button
                      type='button'
                      className='btn btn-primary button1 mx-3'
                      onClick={handleClick}
                      style={{
                        backgroundColor: hoverFirstButtonColor
                          ? secondaryColor
                          : primaryColor,
                        border: hoverFirstButtonColor
                          ? secondaryColor
                          : primaryColor,
                      }}
                      onMouseEnter={handleFirstButtonMouseEnter}
                      onMouseLeave={handleFirstButtonMouseLeave}
                    >
                      {t('jobseekerChangePhoto.uploadButton')}
                    </button>
                    <button
                      type='button'
                      className='btn btn-primary button2'
                      onClick={() => navigate('/candidates/myaccount')}
                      style={{
                        color: hoverSecondButtonColor
                          ? primaryColor
                          : secondaryColor,
                        backgroundColor: 'white',
                        border: hoverSecondButtonColor
                          ? `2px solid ${primaryColor}`
                          : `2px solid ${secondaryColor}`,
                      }}
                      onMouseEnter={handleSecondButtonMouseEnter}
                      onMouseLeave={handleSecondButtonMouseLeave}
                    >
                      {t('jobseekerChangePhoto.cancelButton')}
                    </button>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default JSChangePhoto;

function convertToBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = error => {
      reject(error);
    };
  });
}
