import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// api
import BaseApi from '../api/BaseApi';
// components
import NavBar from '../element/NavBar';
import Footer from '../element/Footer';
// mui
import { Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const EmployerLogin = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('global');
  const captchaKey = Cookies.get('captchaKey');
  const currentLanguage = Cookies.get('selectedLanguage') || 'ukr';
  const recaptchaLanguage = currentLanguage === 'ukr' ? 'uk' : currentLanguage;

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setLoginData(prev => ({
      ...prev,
      [name]: value,
    }));
    setErrors(prev => ({
      ...prev,
      [name]: '',
    }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async e => {
    e.preventDefault();

    try {
      const newErrors = {};

      const isValidEmail = email => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        return emailPattern.test(email);
      };

      if (loginData.email === '') {
        newErrors.email = t('employerLogin.emailRequired');
        window.scrollTo(0, 0);
      } else if (!isValidEmail(loginData.email)) {
        newErrors.email = t('employerLogin.invalidEmail');
        window.scrollTo(0, 0);
      }
      if (loginData.password === '') {
        newErrors.password = t('employerLogin.passwordRequired');
        window.scrollTo(0, 0);
      }
      if (!isCaptchaVerified) {
        newErrors.captcha = t('employerLogin.captchaRequired');
      }

      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0 && isCaptchaVerified) {
        setLoading(true);

        const updatedData = {
          ...loginData,
          language: currentLanguage === 'ukr' ? 'uk' : currentLanguage,
        };

        const response = await axios.post(
          BaseApi + '/users/login',
          updatedData,
        );

        const status = response.data.status;

        setLoading(false);

        if (status === 200 && response.data.response.user.token !== '') {
          const { token, user_id, user_type, first_name, employer_id } = response.data.response.user;

          if (user_type !== 'recruiter') {
            Swal.fire({
              icon: 'error',
              title: t('employerLogin.wrongCredentials'),
              confirmButtonText: t('employerLogin.close'),
            });

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              'event': 'website_error',
              'error_type': 'login error',
              'error_message': t('employerLogin.wrongCredentials'),
            });

            setIsCaptchaVerified(false);
          } else {
            await axios.post(BaseApi + '/setcontext', {
              user_id,
              user_type,
              lang: currentLanguage === 'ukr' ? 'uk' : currentLanguage,
            });

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              'event': 'login',
              'user_id': user_id,
              'authentication_method': 'email',
            });

            Cookies.set('user_id', user_id);
            Cookies.set('fname', first_name);
            Cookies.set('tokenClient', token);
            Cookies.set('user_type', user_type);
            Cookies.set('employer_id', employer_id);

            navigate('/user/myprofile');

            const Toast = Swal.mixin({
              toast: true,
              timer: 3000,
              position: 'top-end',
              timerProgressBar: true,
              showConfirmButton: false,
              didOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: 'success',
              title: t('employerLogin.welcome')+' '+ first_name,
            });

            setIsCaptchaVerified(false);
          }
        }

        if (status == 500) {

          Swal.fire({
            icon: 'error',
            timerProgressBar: true,
            title: response.data.message,
          });

          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event': 'website_error',
            'error_type': 'login error',
            'error_message': response.data.message,
          });

          setIsCaptchaVerified(false);
        } else {
          console.error('Nothing works');
        }
      }
    } catch (error) {
      setLoading(false);

      Swal.fire({
        icon: 'error',
        title: t('employerLogin.failedTitle'),
        confirmButtonText: t('employerLogin.close'),
      });

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'website_error',
        'error_type': 'login error',
        'error_message': error.message,
      });

      setIsCaptchaVerified(false);
      console.error('Error sending login credentails');
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavBar />
      {
        loading
          ? (
            <div className='loader-container'></div>
          ) : (
            <>
              <div className='container employerLogin'>
                <div className='card rounded'>
                  <div className='row'>
                    <div className='col-md-6 leftSection'>
                      <img src='/Images/employerlogin.jpg' alt='' />
                    </div>
                    <div className='col-md-6  mt-5'>
                      <div className='text-center'>
                        <img src='/Images/Logo.png' alt='logo' width='50px' />
                      </div>
                      <div className='card-title text-center h3 pt-5'>
                        {t('employerLogin.EmployerLogin')}
                      </div>
                      <div className='card-body'>
                        <form className=' border border-light'>
                          <div className='mb-4'>
                            <input
                              name='email'
                              type='email'
                              onChange={handleChange}
                              value={loginData.email}
                              id='defaultLoginFormEmail'
                              placeholder={t('employerLogin.email')}
                              className={`form-control ${errors.email && 'input-error'}`}
                            />
                            {
                              errors.email && (
                                <div className='text-danger'>{errors.email}</div>
                              )
                            }
                          </div>
                          <div className='passwordBox'>
                            <input
                              name='password'
                              onChange={handleChange}
                              value={loginData.password}
                              id='defaultLoginFormPassword'
                              placeholder={t('employerLogin.password')}
                              type={showPassword ? 'text' : 'password'}
                              className={`form-control ${errors.password && 'input-error'}`}
                            />
                            <div className='passwordVisibility'>
                              <p
                                type='button'
                                className='btn-primary'
                                onClick={togglePasswordVisibility}
                              >
                                {
                                  showPassword
                                    ? (
                                      <Tooltip title='Hide Password'>
                                        <VisibilityOffIcon />
                                      </Tooltip>
                                    ) : (
                                      <Tooltip title='View Password'>
                                        <VisibilityIcon />
                                      </Tooltip>
                                    )
                                }
                              </p>
                            </div>
                            {
                              errors.password && (
                                <div className='text-danger'>{errors.password}</div>
                              )
                            }
                          </div>
                          <div className='reCaptchaLogin'>
                            <ReCAPTCHA
                              sitekey={captchaKey}
                              hl={recaptchaLanguage}
                              onChange={value => setIsCaptchaVerified(value)}
                            />
                            {
                              errors.captcha && (
                                <div className='text-danger CaptchaVerify'>
                                  {errors.captcha}
                                </div>
                              )
                            }
                          </div>
                          <div className='forgotPassword'>
                            <Link to='/users/forgotpassword'>{t('employerLogin.forgotPassword')}</Link>
                          </div>
                          <div className='text-center'>
                            <button
                              type='submit'
                              onClick={handleSubmit}
                              className='btn button1 my-4'
                            >
                              {t('employerLogin.login')}
                            </button>
                          </div>
                          <p className='EPRegistrationBottomText'>
                            {t('employerLogin.bottomTxt3')}
                            {' '}
                            <Link to='/user/register/employer'>
                              {t('employerLogin.bottomTxt4')}
                            </Link>
                          </p>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </>
          )
      }
    </>
  );
};

export default EmployerLogin;
