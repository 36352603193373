export const advantagesSettings = [
  {
    bgColor: '#FFF',
    image: 'advantages-1.png',
    text: 'Робота у самій міжнародній компанії',
  },
  {
    bgColor: '#FFCB05',
    image: 'advantages-2.png',
    text: 'Офіційне працевлаштування згідно з КЗпП України',
  },
  {
    bgColor: '#FFF',
    image: 'advantages-3.png',
    text: 'Конкурентна стабільна заробітна плата та система бонусів',
  },
  {
    bgColor: '#FFF',
    image: 'advantages-4.png',
    text: 'Повний соціальний пакет',
  },
  {
    bgColor: '#FFF',
    image: 'advantages-5.png',
    text: 'Медичне страхування та страхування життя',
  },
  {
    bgColor: '#FFF',
    image: 'advantages-6.png',
    text: 'Грошова компенсація харчування',
  },
  {
    bgColor: '#FFF',
    image: 'advantages-7.png',
    text: 'Професійне та корпоративне навчання',
  },
  {
    bgColor: '#FFF',
    image: 'advantages-8.png',
    text: 'Програми відзнак та нагород наших співробітників',
  },
  {
    bgColor: '#FFF',
    image: 'advantages-9.png',
    text: 'Можливості для самореалізації та подальшого розвитку кар’єри',
  },
  {
    bgColor: '#FFF',
    image: 'advantages-10.png',
    text: 'Можливості для старту кар’єри',
  },
  {
    bgColor: '#FFF',
    image: 'advantages-11.png',
    text: 'Доставка на роботу / з роботи корпоративним транспортом',
  },
];
