import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import Select from 'react-select';
import JoditEditor from 'jodit-react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
// api
import ApiKey from '../api/ApiKey';
import BaseApi from '../api/BaseApi';
// components
import JSSidebar from './JSSidebar';
import Footer from '../element/Footer';
import NavBar from '../element/NavBar';
// helpers
import { editorConfig, logOutIfTokenExpired, resultInfo } from '../helpers';

const JSExperience = () => {
  const editor = useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation('global');
  const tokenKey = Cookies.get('tokenClient');

  const months = [
    { id: 1, name: t('jobseekerExperience.january') },
    { id: 2, name: t('jobseekerExperience.february') },
    { id: 3, name: t('jobseekerExperience.march') },
    { id: 4, name: t('jobseekerExperience.april') },
    { id: 5, name: t('jobseekerExperience.may') },
    { id: 6, name: t('jobseekerExperience.june') },
    { id: 7, name: t('jobseekerExperience.july') },
    { id: 8, name: t('jobseekerExperience.august') },
    { id: 9, name: t('jobseekerExperience.september') },
    { id: 10, name: t('jobseekerExperience.october') },
    { id: 11, name: t('jobseekerExperience.november') },
    { id: 12, name: t('jobseekerExperience.december') },
  ];

  const [loading, setLoading] = useState(false);
  const [yearsList, setYearsList] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [experience, setExperience] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);

  const getDefaultIndustryName = id => {
    for (let i = 0; i < industries.length; i++) {
      if (industries[i].id == id) {
        return { label: industries[i].name, value: industries[i].id };
      }
    }
  };

  const getData = async () => {
    try {
      setLoading(true);

      const response = await axios.post(
        BaseApi + '/candidates/editExperience',
        null,
        {
          headers: {
            key: ApiKey,
            token: tokenKey,
            'Content-Type': 'application/json',
          },
        },
      );

      setLoading(false);

      if (response.data.status === 200) {
        setYearsList(response.data.response.yearList);
        setIndustries(response.data.response.industries || []);
        setExperience(response.data.response.expDetails || []);
      } else if (response.data.status === 400) {
        navigate('/');

        Cookies.remove('fname');
        Cookies.remove('user_id');
        Cookies.remove('user_type');
        Cookies.remove('tokenClient');

        resultInfo('warning', response.data.message, t('jobseekerExperience.close'));
      } else {
        resultInfo('error', response.data.message, t('jobseekerExperience.close'));
      }
    } catch (error) {
      setLoading(false);

      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      }

      console.error(error);
    }
  };

  const validateFields = () => {
    const errors = experience.map(exp => {
      const isChecked = exp.stillWorking;

      return {
        role: exp.role.trim() === '',
        industry: exp.industry === '',
        from_year: exp.from_year === '',
        from_month: exp.from_month === '',
        designation: exp.designation.trim() === '',
        company_name: exp.company_name.trim() === '',
        to_year: isChecked ? false : exp.to_year === '',
        to_month: isChecked ? false : exp.to_month === '',
      };
    });

    setValidationErrors(errors);

    return errors.every(
      error =>
        !error.role &&
        !error.to_year &&
        !error.industry &&
        !error.to_month &&
        !error.from_year &&
        !error.from_month &&
        !error.designation &&
        !error.company_name,
    );
  };

  const handleChange = (e, index) => {
    const { name, value, type, checked } = e.target;

    setExperience(prevExperience => {
      const updatedDetails = [...prevExperience];

      updatedDetails[index] = {
        ...updatedDetails[index],
        [name]: type === 'checkbox' ? checked : value,
      };

      if ((name === 'to_month' || name === 'to_year') && updatedDetails[index].stillWorking) {
        updatedDetails[index].stillWorking = 0;
      }

      if (name === 'stillWorking' && checked) {
        updatedDetails[index].to_month = '';
        updatedDetails[index].to_year = '';
      }

      const errors = updatedDetails.map((exp, i) => {
        const isChecked = name === 'stillWorking' ? checked : exp.stillWorking;

        return {
          role: i === index ? exp.role.trim() === '' : validationErrors[i]?.role,
          from_year: i === index ? exp.from_year === '' : validationErrors[i]?.from_year,
          from_month: i === index ? exp.from_month === '' : validationErrors[i]?.from_month,
          industry: i === index ? exp.industry.length === 0 : validationErrors[i]?.industry,
          designation: i === index ? exp.designation.trim() === '' : validationErrors[i]?.designation,
          company_name: i === index ? exp.company_name.trim() === '' : validationErrors[i]?.company_name,
          to_year: i === index ? (isChecked ? false : exp.to_year === '') : validationErrors[i]?.to_year,
          to_month: i === index ? (isChecked ? false : exp.to_month === '') : validationErrors[i]?.to_month,
        };
      });

      setValidationErrors(errors);

      return updatedDetails;
    });
  };

  const handleIndustryChange = (selectedOption, index) => {
    if (selectedOption.length > 3) {
      return;
    }

    setExperience(prevExperience => {
      const updatedDetails = [...prevExperience];

      updatedDetails[index] = {
        ...updatedDetails[index],
        industry: selectedOption.map(option => option.value),
      };

      const errors = updatedDetails.map((exp, i) => ({
        role: i === index ? exp.role.trim() === '' : validationErrors[i]?.role,
        to_year: i === index ? exp.to_year === '' : validationErrors[i]?.to_year,
        to_month: i === index ? exp.to_month === '' : validationErrors[i]?.to_month,
        from_year: i === index ? exp.from_year === '' : validationErrors[i]?.from_year,
        from_month: i === index ? exp.from_month === '' : validationErrors[i]?.from_month,
        industry: i === index ? exp.industry.length === 0 : validationErrors[i]?.industry,
        designation: i === index ? exp.designation.trim() === '' : validationErrors[i]?.designation,
        company_name: i === index ? exp.company_name.trim() === '' : validationErrors[i]?.company_name,
      }));

      setValidationErrors(errors);

      return updatedDetails;
    });
  };

  const handleClick = async () => {
    if (!validateFields()) {
      window.scrollTo(0, 0);

      return;
    }

    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: t('jobseekerExperience.no'),
        confirmButtonText: t('jobseekerExperience.yes'),
        text: t('jobseekerExperience.experienceConfirmTxt'),
        title: t('jobseekerExperience.experienceConfirmTitle'),
      });

      if (confirmationResult.isConfirmed) {
        setLoading(true);

        const response = await axios.post(
          BaseApi + '/candidates/editExperience',
          { Experience: experience },
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              'Content-Type': 'application/json',
            },
          },
        );

        setLoading(false);

        if (response.data.status === 200) {
          Swal.fire({
            icon: 'success',
            confirmButtonText: t('jobseekerExperience.close'),
            text: t('jobseekerExperience.experienceSuccessTxt'),
            title: t('jobseekerExperience.experienceSuccessTitle'),
          });

          setExperience([]);
          getData();
        }
        else if (response.data.status === 400) {
          navigate('/');

          Cookies.remove('fname');
          Cookies.remove('user_id');
          Cookies.remove('user_type');
          Cookies.remove('tokenClient');

          resultInfo('warning', response.data.message, t('jobseekerExperience.close'));
        }
        else if (response.data.status === 500) {
          resultInfo('warning', response.data.message, t('jobseekerExperience.close'));
        } else {
          resultInfo('error', response.data.message, t('jobseekerExperience.close'));
        }
      }
    } catch (error) {
      setLoading(false);

      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      } else {
        Swal.fire({
          icon: 'error',
          confirmButtonText: t('jobseekerExperience.close'),
          text: t('jobseekerExperience.experienceFailedTxt'),
          title: t('jobseekerExperience.experienceFailedTitle'),
        });
      }

      console.error(error);
    }
  };

  const handleAdd = () => {
    const newExperience = {
      role: '',
      to_year: '',
      industry: [],
      to_month: '',
      from_year: '',
      from_month: '',
      designation: '',
      stillWorking: 0,
      company_name: '',
    };

    setExperience(prevExperience => [...prevExperience, newExperience]);
  };

  const handleRemove = async id => {
    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: t('jobseekerExperience.no'),
        confirmButtonText: t('jobseekerExperience.yes'),
        text: t('jobseekerExperience.removeConfirmTxt'),
        title: t('jobseekerExperience.removeConfirmTitle'),
      });

      if (id !== null && confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/candidates/deleteexperience/${id}`,
          null,
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              'Content-Type': 'application/json',
            },
          },
        );

        if (response.data.status === 200) {
          getData();

          resultInfo('success', t('jobseekerExperience.removeSuccessTitle'), t('jobseekerExperience.close'));

          window.scrollTo(0, 0);
        } else if (response.data.status === 400) {
          navigate('/');

          Cookies.remove('fname');
          Cookies.remove('user_id');
          Cookies.remove('user_type');
          Cookies.remove('tokenClient');

          resultInfo('warning', response.data.message, t('jobseekerExperience.close'));
        }
        else if (response.data.status === 500) {
          resultInfo('warning', response.data.message, t('jobseekerExperience.close'));
        } else {
          resultInfo('error', response.data.message, t('jobseekerExperience.close'));
        }
      }
    } catch (error) {
      setLoading(false);

      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      } else {
        Swal.fire({
          icon: 'error',
          text: t('jobseekerExperience.removeFailedTxt'),
          confirmButtonText: t('jobseekerExperience.close'),
          title: t('jobseekerExperience.removeFailedTitle'),
        });
      }

      console.error(error);
    }
  };

  const handleRemoveWithoutId = indexToRemove => {
    setExperience(prevExperience =>
      prevExperience.filter((_, index) => index !== indexToRemove),
    );

    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (!tokenKey) {
      navigate('/user/jobseekerlogin');
    } else {
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]); // eslint-disable-line

  return (
    <>
      <NavBar />
      <div className='container editProfile'>
        <div className='row'>
          <div className='col-lg-3'>
            <JSSidebar />
          </div>
          {
            loading
              ? (
                <div className='loader-container'></div>
              ) : (
                <div
                  className='col-lg-9 mb-5'
                  style={{
                    borderLeft: '2px solid #e6e8e7',
                    borderRight: '2px solid #e6e8e7',
                  }}
                >
                  <div className='mx-3 d-flex PageHeader'>
                    <img
                      alt=''
                      className='pb-2'
                      src='/Images/jobseekerSide/Experience-Color.png'
                    />
                    <h3 className='ms-1 pt-1' style={{ color: '#f46484' }}>
                      {t('jobseekerExperience.experienceInfo')}
                    </h3>
                  </div>
                  <form>
                    <div className='mt-4 mx-4'>
                      {
                        experience.map((i, index) => (
                          <>
                            <h4 className='mt-4 mb-5'>
                              {t('jobseekerExperience.experience')} №{index + 1}
                            </h4>
                            <div className='form-outline mb-4 DashBoardInputBx'>
                              <label className='form-label' htmlFor='form3Example3'>
                                {t('jobseekerExperience.workingRelation')}
                                <span className='RedStar'>*</span>
                              </label>
                              <input
                                type='text'
                                id='designation'
                                name='designation'
                                value={i.designation}
                                className='form-control'
                                onChange={e => handleChange(e, index)}
                                placeholder={t('jobseekerExperience.workingRelation')}
                              />
                              <div className='mt-0'>
                                {
                                  validationErrors[index]?.designation && (
                                    <small className='text-danger'>
                                      {t('jobseekerExperience.workingRelationRequired')}
                                    </small>
                                  )
                                }
                              </div>
                            </div>
                            <div className='form-outline mb-4 DashBoardInputBx'>
                              <label className='form-label' htmlFor='form3Example3'>
                                {t('jobseekerExperience.companyName')}
                                <span className='RedStar'>*</span>
                              </label>
                              <input
                                type='text'
                                id='company_name'
                                name='company_name'
                                value={i.company_name}
                                className='form-control'
                                onChange={e => handleChange(e, index)}
                                placeholder={t('jobseekerExperience.companyName')}
                              />
                              <div className='mt-0'>
                                {
                                  validationErrors[index]?.company_name && (
                                    <small className='text-danger'>
                                      {t('jobseekerExperience.companyNameRequired')}
                                    </small>
                                  )
                                }
                              </div>
                            </div>
                            <div className='form-outline mb-4 DashBoardInputBx DashBoardCreatBx skillPackage'>
                              <label id='industry' className='form-label' htmlFor='form3Example1'>
                                {t('jobseekerExperience.industry')}
                                <span className='RedStar'>*</span>
                              </label>
                              <Select
                                isMulti
                                isSearchable
                                name='industry'
                                className='basic-multi-select'
                                placeholder={t('jobseekerExperience.selectIndustry')}
                                value={i.industry.map(i => getDefaultIndustryName(i))}
                                options={industries.map(i => ({ value: i.id, label: i.name }))}
                                noOptionsMessage={() => t('jobseekerExperience.noIndustryToSelect')}
                                onChange={selectedOption => handleIndustryChange(selectedOption, index)}
                              />
                              {
                                validationErrors[index]?.industry && (
                                  <small className='text-danger'>
                                    {t('jobseekerExperience.industryRequired')}
                                  </small>
                                )
                              }
                            </div>
                            <div className='form-outline mb-4 DashBoardInputBx DashBoardCreatBx APJoditEditor'>
                              <label id='job_description' className='form-label' htmlFor='form3Example3'>
                                {t('jobseekerExperience.achievements')}
                                <span className='RedStar'>*</span>
                              </label>
                              <JoditEditor
                                name='role'
                                ref={editor}
                                value={i.role}
                                config={editorConfig}
                                placeholder={t('jobseekerExperience.achievements')}
                                onBlur={achievements =>
                                  handleChange(
                                    {
                                      target: {
                                        name: 'role',
                                        value: achievements,
                                      },
                                    },
                                    index,
                                  )
                                }
                              />
                              {
                                validationErrors[index]?.role && (
                                  <small className='text-danger'>
                                    {t('jobseekerExperience.achievementsRequired')}
                                  </small>
                                )
                              }
                            </div>
                            <div className='row'>
                              <div className='col-lg-6'>
                                <p className='mb-4'>
                                  {t('jobseekerExperience.startOfWork')}
                                </p>
                                <div className='row'>
                                  <div className='col-lg-6 form-outline mb-4 DashBoardInputBx'>
                                    <label className='form-label' htmlFor='form3Example3'>
                                      {t('jobseekerExperience.month')}
                                      <span className='RedStar'>*</span>
                                    </label>
                                    <select
                                      name='from_month'
                                      value={i.from_month}
                                      className='form-select'
                                      aria-label='Default select example'
                                      onChange={e => handleChange(e, index)}
                                    >
                                      <option selected value=''>
                                        {t('jobseekerExperience.selectMonth')}
                                      </option>
                                      {
                                        months.map(i => (
                                          <option value={i.id} key={i.id}>
                                            {i.name}
                                          </option>
                                        ))
                                      }
                                    </select>
                                    <div className='mt-0'>
                                      {
                                        validationErrors[index]?.from_month && (
                                          <small className='text-danger'>
                                            {t('jobseekerExperience.monthRequired')}
                                          </small>
                                        )
                                      }
                                    </div>
                                  </div>
                                  <div className='col-lg-6 form-outline mb-4 DashBoardInputBx'>
                                    <label className='form-label' htmlFor='form3Example3'>
                                      {t('jobseekerExperience.year')}
                                      <span className='RedStar'>*</span>
                                    </label>
                                    <select
                                      name='from_year'
                                      value={i.from_year}
                                      className='form-select'
                                      aria-label='Default select example'
                                      onChange={e => handleChange(e, index)}
                                    >
                                      <option selected value=''>
                                        {t('jobseekerExperience.selectYear')}
                                      </option>
                                      {
                                        Object.entries(yearsList).map(([index, value]) => (
                                          <option key={index} value={value}>{value}</option>
                                        ))
                                      }
                                    </select>
                                    <div className='mt-0'>
                                      {
                                        validationErrors[index]?.from_year && (
                                          <small className='text-danger'>
                                            {t('jobseekerExperience.yearRequired')}
                                          </small>
                                        )
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='col-lg-6'>
                                <p className='mb-4'>
                                  {t('jobseekerExperience.endOfWork')}
                                </p>
                                <div className='row'>
                                  <div className='col-lg-6 form-outline mb-4 DashBoardInputBx'>
                                    <label className='form-label' htmlFor='form3Example3'>
                                      {t('jobseekerExperience.month')}
                                      {
                                        !experience[index].stillWorking && (
                                          <span className='RedStar'>*</span>
                                        )
                                      }
                                    </label>
                                    <select
                                      name='to_month'
                                      value={i.to_month}
                                      className='form-select'
                                      aria-label='Default select example'
                                      onChange={e => handleChange(e, index)}
                                    >
                                      <option selected value=''>
                                        {t('jobseekerExperience.selectMonth')}
                                      </option>
                                      {
                                        months.map(i => (
                                          <option value={i.id} key={i.id}>
                                            {i.name}
                                          </option>
                                        ))
                                      }
                                    </select>
                                    <div className='mt-0'>
                                      {
                                        validationErrors[index]?.to_month && (
                                          <small className='text-danger'>
                                            {t('jobseekerExperience.monthRequired')}
                                          </small>
                                        )
                                      }
                                    </div>
                                  </div>
                                  <div className='col-lg-6 form-outline mb-4 DashBoardInputBx'>
                                    <label className='form-label' htmlFor='form3Example3'>
                                      {t('jobseekerExperience.year')}
                                      {
                                        !experience[index].stillWorking && (
                                          <span className='RedStar'>*</span>
                                        )
                                      }
                                    </label>
                                    <select
                                      name='to_year'
                                      value={i.to_year}
                                      className='form-select'
                                      aria-label='Default select example'
                                      onChange={e => handleChange(e, index)}
                                    >
                                      <option selected value=''>
                                        {t('jobseekerExperience.selectYear')}
                                      </option>
                                      {
                                        Object.entries(yearsList).map(([index, value]) => (
                                          <option key={index} value={value}>{value}</option>
                                        ))
                                      }
                                    </select>
                                    <div className='mt-0'>
                                      {
                                        validationErrors[index]?.to_year && (
                                          <small className='text-danger'>
                                            {t('jobseekerExperience.yearRequired')}
                                          </small>
                                        )
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <label className='mb-4' style={{ display: 'flex', alignItems: 'center' }}>
                                <input
                                  type='checkbox'
                                  name='stillWorking'
                                  className='me-2 checkboxInput'
                                  onChange={e => handleChange(e, index)}
                                  checked={experience[index].stillWorking}
                                />
                                {t('jobseekerExperience.stillWorking')}
                              </label>
                            </div>
                            <div className='row'>
                              <div className='removeButtonJobseeker mb-4'>
                                <button
                                  type='button'
                                  className='btn btn-primary button2'
                                  onClick={() => i.id ? handleRemove(i.id) : handleRemoveWithoutId(index)}
                                >
                                  {t('jobseekerExperience.removeButton')}
                                </button>
                                <button
                                  type='button'
                                  onClick={handleAdd}
                                  className='btn btn-primary button1'
                                >
                                  {t('jobseekerExperience.addMoreButton')}
                                </button>
                              </div>
                            </div>
                          </>
                        ))
                      }
                      {
                        experience.length <= 0 && (
                          <>
                            <div className='noData'>{t('jobseekerExperience.noInfoAvl')}</div>
                            <div className='mb-4 mt-4 PRAddMore jobseekerAddDetailsButton'>
                              <button
                                type='button'
                                onClick={handleAdd}
                                className='btn btn-primary button1'
                              >
                                {t('jobseekerExperience.addDetails')}
                              </button>
                            </div>
                          </>
                        )
                      }
                      {
                        experience.length > 0 && (
                          <div className='mt-4 bottomButtons'>
                            <button
                              type='button'
                              onClick={handleClick}
                              className='btn btn-primary button1'
                            >
                              {t('jobseekerExperience.updateButton')}
                            </button>
                            <button
                              type='button'
                              className='btn btn-primary button2'
                              onClick={() => navigate('/candidates/myaccount')}
                            >
                              {t('jobseekerExperience.cancelButton')}
                            </button>
                          </div>
                        )
                      }
                    </div>
                  </form>
                </div>
              )
          }
        </div>
      </div>
      <Footer />
    </>
  );
};

export default JSExperience;
