/* eslint-disable max-len */
/* eslint-disable unused-imports/no-unused-vars */
import React, { useEffect, useState } from 'react';
import Footer from '../element/Footer';
import Sidebar from './Sidebar';
import NavBar from '../element/NavBar';
import axios from 'axios';
import ApiKey from '../api/ApiKey';
import BaseApi from '../api/BaseApi';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { logOutIfTokenExpired, resultInfo } from '../helpers';

const FavouriteList = () => {
  const [favouriteData, setFavouriteData] = useState([]);
  const [loading, setLoading] = useState(false);
  const tokenKey = Cookies.get('tokenClient');
  const primaryColor = Cookies.get('primaryColor');
  const secondaryColor = Cookies.get('secondaryColor');
  const [t, i18n] = useTranslation('global');

  const navigate = useNavigate();

  useEffect(() => {
    // Check if tokenKey is not present
    if (!tokenKey) {
      // Redirect to the home page
      navigate('/user/employerlogin');
    } else {
      // TokenKey is present, fetch data or perform other actions
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]);

  const getData = async () => {
    try {
      setLoading(true);

      const response = await axios.post(
        BaseApi + '/candidates/favorite',
        null,
        {
          headers: {
            key: ApiKey,
            token: tokenKey,
            'Content-Type': 'application/json',
          },
        },
      );

      setLoading(false);

      if (response.data.status === 200) {
        setFavouriteData(response.data.response.Favorite);
      } else if (response.data.status === 400) {
        navigate('/');

        Cookies.remove('fname');
        Cookies.remove('user_id');
        Cookies.remove('user_type');
        Cookies.remove('tokenClient');

        resultInfo('warning', response.data.message, t('employerFavouriteList.close'));
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message,
          title: t('employerCreateJob.createJobFailedTitle'),
          confirmButtonText: t('employerFavouriteList.close'),
        });
      }
    } catch (error) {
      setLoading(false);

      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      }

      console.error(error);
    }
  };

  const handleDelete = async id => {
    try {
      const confirmationResult = await Swal.fire({
        title: t('employerFavouriteList.removeProfileConfirmTitle'),
        text: t('employerFavouriteList.removeProfileConfirmTxt'),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: t('employerFavouriteList.yes'),
        cancelButtonText: t('employerFavouriteList.no'),
      });
      if (confirmationResult.isConfirmed) {
        setLoading(true);
        const response = await axios.post(
          BaseApi + `/candidates/deleteFavoriteList/${id}`,
          null, // Pass null as the request body if not required
          {
            headers: {
              'Content-Type': 'application/json',
              key: ApiKey,
              token: tokenKey,
            },
          },
        );
        setLoading(false);
        if (response.data.status === 200) {
          resultInfo('success', t('employerFavouriteList.removeSuccessTitle'), t('employerFavouriteList.close'));
        } else if (response.data.status === 400) {
          navigate('/');

          Cookies.remove('fname');
          Cookies.remove('user_id');
          Cookies.remove('user_type');
          Cookies.remove('tokenClient');

          resultInfo('warning', response.data.message, t('employerFavouriteList.close'));
        } else {
          Swal.fire({
            icon: 'error',
            text: response.data.message,
            title: t('employerCreateJob.createJobFailedTitle'),
            confirmButtonText: t('employerFavouriteList.close'),
          });
        }

        getData();
      }
    } catch (error) {
      setLoading(false);

      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      } else {
        Swal.fire({
          icon: 'error',
          text: t('employerFavouriteList.removeFailedTxt'),
          confirmButtonText: t('employerFavouriteList.close'),
          title: t('employerFavouriteList.removeFailedTitle'),
        });
      }

      console.error('Couldn\'t delete the record!', error);
    }
  };

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const dataPerPage = 10;

  // Get current jobs to display based on pagination
  const indexOfLastRecord = currentPage * dataPerPage;
  const indexOfFirstRecord = indexOfLastRecord - dataPerPage;
  const currentData = favouriteData
    ? favouriteData.slice(indexOfFirstRecord, indexOfLastRecord)
    : favouriteData.slice(indexOfFirstRecord, indexOfLastRecord);

  // Function to handle pagination page change
  const handlePageChange = pageNumber => setCurrentPage(pageNumber);

  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <NavBar />
      <div className='container favouriteList'>
        <div className='row'>
          <div className='col-lg-3 col-md-3'>
            <Sidebar />
          </div>
          {loading ? (
            <div className='loader-container'></div>
          ) : (
            <>
              <div
                className='col-lg-9 col-md-9 mb-5'
                style={{
                  borderLeft: '2px solid #e6e8e7',
                  borderRight: '2px solid #e6e8e7',
                }}
              >
                <div className='FLHeader'>
                  <div className='d-flex PageHeader'>
                    <img src='/Images/employerSide/icon4color.png' alt='' />
                    <h3 className='mx-2'>
                      {t('employerFavouriteList.favouriteJobseeker')}
                    </h3>
                  </div>

                  <div className='PHBody mt-5'>
                    <table className='table'>
                      <thead>
                        <tr className='table-active TrFirst'>
                          <th className='' scope='col p-3'>
                            {t('employerFavouriteList.SNo.')}
                          </th>
                          <th className='' scope='col p-3'>
                            {t('employerFavouriteList.jobseekerName')}
                          </th>
                          <th className='' scope='col p-3'>
                            {t('employerFavouriteList.email')}
                          </th>
                          <th className='' scope='col p-3'>
                            {t('employerFavouriteList.action')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentData.map((i, index) => (
                          <tr key={index} className='TrDefoult'>
                            <td className=''>{index + 1}</td>
                            <td className=''>
                              {i.first_name} {i.last_name}
                            </td>
                            <td className=''>{i.email_address}</td>
                            <td className='TrActions'>
                              <Link
                                to={`/candidates/profile/${i.slug}`}
                                className='btn btn-primary'
                                style={{
                                  backgroundColor: primaryColor,
                                  color: 'white',
                                }}
                              >
                                <i className='fa fa-user-large'></i>
                              </Link>
                              <button
                                onClick={() => handleDelete(i.id)}
                                className='btn btn-secondary'
                                style={{
                                  backgroundColor: secondaryColor,
                                  color: 'white',
                                }}
                              >
                                <i className='fa fa-trash-can'></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className='favListPagination'>
                  <p className='text-muted empPaginationData'>
                    {t('pagination.NoofRecords')} {favouriteData.length > 0 ? indexOfFirstRecord + 1 : indexOfFirstRecord}-
                    {Math.min(indexOfLastRecord, favouriteData.length)} of{' '}
                    {favouriteData
                      ? favouriteData.length
                      : favouriteData.length}
                  </p>
                  {/* Custom Pagination */}
                  <div className='d-flex justify-content-center empPaginationButton'>
                    <button
                      className='navButton1 me-2'
                      disabled={currentPage === 1}
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      {t('pagination.Prev')}
                    </button>
                    <button
                      className='navButton1'
                      disabled={
                        favouriteData
                          ? indexOfLastRecord >= favouriteData.length
                          : indexOfLastRecord >= favouriteData.length
                      }
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      {t('pagination.Next')}
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default FavouriteList;
